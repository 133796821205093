import BootstrapTable from 'react-bootstrap-table-next';
import {
	// Modal,
	Col, Row
} from 'react-bootstrap';
import React, { Component } from 'react';
// import { toast } from 'react-toastify';
// import Swal from 'sweetalert2';
import moment from 'moment';

const readCookie = require('../../partials/cookie.js').readCookie;

export default class PlatformUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/' + this.props.match.params.userId, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ user: data.user });
				}
			});
	}

	formatLead = (cell, row) => {
		if (cell && row.id) {
			return (
				<a href={"/lead/" + row.id} rel="noreferrer noopener" target="_blank">{cell}</a>
			)
		} else return null;
	}

	formatIndex = (cell, row) => {
		if (!isNaN(cell)) {
			return parseInt(cell) + 1;
		} else return null;
	}

	render() {
		const columns = [
			{ dataField: 'index', text: 'Sl. No.', headerStyle: () => { return { width: '120px' } }, sort: false, export: false, formatter: this.formatIndex },
			{ dataField: 'mobileNumber', text: 'Phone No. of Candidate', sort: false, export: true },
			{ dataField: 'name', text: 'Name of Candidate', sort: false, export: true, formatter: this.formatLead }
		]
		return (
			<div className="user-page page-container">
				<Col style={{ padding: '0px', marginBottom: '20px' }} className="d-flex align-items-center">
					<a className="go-back-btn d-flex align-items-center" href={"/user-types/" + this.props.match.params.type}><i className="fas fa-chevron-left mr5"></i>Back</a>
					{this.state.user !== null ? (
						<h3 style={{ margin: '0px' }}>{this.state.user.fullName}</h3>
					) : (null)}
					{JSON.parse(readCookie('userData')).type === 'le' && JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' ? (
						<a className="go-back-btn d-flex align-items-center" href={window.location.pathname + "/edit"}><i className="fa fa-pencil mr5"></i>Edit</a>
					) : (null)}
				</Col>

				{this.state.user !== null ? (
					<Row className="user-details-overview-container">
						<Col md={4} className="d-flex align-items-center">
							<span className="heading">EMAIL : </span>
							<span className="value">{this.state.user.email}</span>
						</Col>
						<Col md={4} className="d-flex align-items-center">
							<span className="heading">MOBILE NUMBER : </span>
							<span className="value">{this.state.user.mobileNumber || 'N/A'}</span>
						</Col>
						<Col md={4} className="d-flex align-items-center">
							<span className="heading">REGISTERED ON : </span>
							<span className="value">{moment(this.state.user.createdAt).format('DD/MM/YYYY hh:mm A')}</span>
						</Col>
						{this.state.user.createdAt ?	<Col md={4} className="d-flex align-items-center">
							<span className="heading">LAST UPDATED AT : </span>
							<span className="value">{moment(this.state.user.createdAt).format('DD/MM/YYYY hh:mm A')}</span>
						</Col> : null}
						{this.state.user.location && this.state.user.location.latitude && this.state.user.location.longitude ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">LOCATION : </span>
								<span className="value"><a href={`https://maps.google.com/?q=${this.state.user.location.latitude},${this.state.user.location.longitude}`} rel="noreferrer noopener" target="_blank">{this.state.user.location.latitude + ', ' + this.state.user.location.longitude}</a></span>
							</Col>
						) : (null)}
						{this.state.user.status ? (
							<Col md={4} className="d-flex align-items-center justify-content-between">
								<div>
									<span className="heading">STATUS : </span>
									<span className="value">{this.state.user.status.toUpperCase()}</span>
								</div>
								{/*<div>
									<span className="org-change-status" onClick={this.changeStatus}>Change</span>
								</div>*/}
							</Col>
						) : (null)}
						{this.state.user.address && this.state.user.address.state ? (
							this.state.user.address.state.length ? (
								<Col md={4} className="d-flex align-items-center">
									<span className="heading">STATE : </span>
									<span className="value">{typeof this.state.user.address.state === 'string' ? this.state.user.address.state : this.state.user.address.state.join(', ')}</span>
								</Col>
							) : (null)
						) : (null)}
						{this.state.user.address && this.state.user.address.district ? (
							this.state.user.address.district.length ? (
								<Col md={4} className="d-flex align-items-center">
									<span className="heading">DISTRICT : </span>
									<span className="value">{typeof this.state.user.address.district === 'string' ? this.state.user.address.district : this.state.user.address.district.join(', ')}</span>
								</Col>
							) : (null)
						) : (null)}
						{this.state.user.address && this.state.user.address.city ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">CITY : </span>
								<span className="value">{this.state.user.address.city}</span>
							</Col>
						) : (null)}
						{this.state.user.address && this.state.user.address.line1 ? (
							<Col md={12} className="d-flex align-items-center">
								<span className="heading">ADDRESS LINE 1 : </span>
								<span className="value">{this.state.user.address.line1}</span>
							</Col>
						) : (null)}
						{this.state.user.address && this.state.user.address.line2 ? (
							<Col md={12} className="d-flex align-items-center">
								<span className="heading">ADDRESS LINE 2 : </span>
								<span className="value">{this.state.user.address.line2}</span>
							</Col>
						) : (null)}
						{this.state.user.reportingTo ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">REPORTING TO : </span>
								<span className="value">{this.state.user.reportingTo.fullName}</span>
							</Col>
						) : (null)}
						{this.state.user.role && this.state.user.role.name ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">ROLE : </span>
								<span className="value">{this.state.user.role.name}</span>
							</Col>
						) : (null)}
						{this.state.user.addedBy && this.state.user.addedBy.fullName ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">ADDED BY : </span>
								<span className="value">{this.state.user.addedBy.fullName}</span>
							</Col>
						) : (null)}
						{this.state.user.userType && this.state.user.userType.name ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">USER TYPE : </span>
								<span className="value">{this.state.user.userType.name}</span>
							</Col>
						) : (null)}
						{this.state.user.userType && this.state.user.userType.name === 'Swavalamban Fellows' && this.state.user.fellowAccessType ? (
							<Col md={4} className="d-flex align-items-center">
								<span className="heading">ACCESS TYPE : </span>
								<span className="value">{this.state.user.fellowAccessType}</span>
							</Col>
						) : (null)}
						{this.state.user.userType && this.state.user.userType.name === 'SAARTHIs' && this.state.user.leads ? (
							<Col md={12} className="d-flex flex-column align-items-start">
								<span className="heading mb5">ASSIGNED CANDIDATES : </span>
								<BootstrapTable wrapperClasses="saarthi-leads-table" keyField="index" data={this.state.user.leads} columns={columns} ref={(table) => { this.table = table; }} />
							</Col>
						) : (null)}
					</Row>
				) : (null)}
			</div>
		);
	}
}
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './AddEditUser.css';
import {
    CaretLeftFilled,
} from '@ant-design/icons';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Tab1 from '../../AddEditUserTabs/Tab1/Tab1';
import Tab2 from '../../AddEditUserTabs/Tab2/Tab2';
import Tab3 from '../../AddEditUserTabs/Tab3/Tab3';

function AddEditUser(params) {
    const { userId, userName } = useParams();
    // console.log(userId, userName);
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <div className='AddEditUserContainers'>
            <div className='AddEditUserHeader'>
                <div className='AddEditUserHeaderButton'>
                    <Link to='/stakeholders' className='link'>
                        <div className='HeaderText'>
                            <CaretLeftFilled />
                            Go Back
                        </div>
                    </Link>
                </div>
                {
                    userName && <div className='AddEditUserHeaderTitle'>
                        <div className='HeaderText'>{capitalizeFirstLetter(userName)}</div>
                    </div>
                }
            </div>
            <Tabs defaultActiveKey="introduction" id="uncontrolled-tab-example" >
                <Tab eventKey="introduction" title={<div className="add-edit-user-tab-header">Introduction</div>}>
                    <Tab1 userId={userId} userName={userName}/>
                </Tab>
                <Tab eventKey="interactionLog" title={<div className="add-edit-user-tab-header">Interaction Log</div>}>
                    <Tab2 userId={userId} userName={userName}/>
                </Tab>
                <Tab eventKey="datasetReceived" title={<div className="add-edit-user-tab-header">Dataset Received</div>}>
                    <Tab3 userId={userId} userName={userName}/>
                </Tab>
            </Tabs>
        </div>
    );
}

export default AddEditUser;

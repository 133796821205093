import
// React,
{ useState, useCallback, useRef, useEffect } from 'react';
const readCookie = require('../../../cookie.js').readCookie;

export const useHttpHook = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState([]);

    const activeHttpRequest = useRef([]);

    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = { 'x-auth-token': JSON.parse(readCookie('access_token')) }) => {

        setIsLoading(true);
        const httpAbortController = new AbortController();
        activeHttpRequest.current.push(httpAbortController);
        try {

            const response = await fetch(url, {
                method,
                body,
                headers,
                signal: httpAbortController.signal
            });

            const responseData = await response.json();

            activeHttpRequest.current = activeHttpRequest.current.filter(
                reqCtrl => reqCtrl !== httpAbortController
            );

            if (!response.ok) {
                throw new Error(responseData.message);
            }
            setIsLoading(false);
            return responseData;

        } catch (err) {
            setError(err.message);
            setIsLoading(false);
            throw err;
        }

    }, []);

    const clearError = () => {
        setError(null);
    }

    useEffect(() => {
        return () => {
            activeHttpRequest.current.forEach(abortCtrl => abortCtrl.abort());
        };
    }, []);

    return { isLoading, error, sendRequest, clearError };
};
import {
	ResponsiveContainer, PieChart, Pie, Cell, Tooltip,
	// FunnelChart, Funnel, Legend 
} from 'recharts';
import ToolkitProvider
	// , { Search }
	from 'react-bootstrap-table2-toolkit';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { ConfigProvider, DatePicker, Select } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
// import {Multiselect} from 'multiselect-react-dropdown';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
// import $ from 'jquery';

import FollowUpNav from './FollowUpNav';

import enUS from 'antd/es/locale/en_US';
import {
	Row,
	// Col,
	// Container,
} from 'react-bootstrap';

const readCookie = require('../cookie.js').readCookie;
const createCookie = require('../cookie.js').createCookie;

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const COLORS = ['#13538a', '#2c92d5', '#37c9ef', '#3edad8', '#86eae9'];

// const getRatiosString = (creditCount, selfInvestmentCount) => {
// 	console.log(creditCount, selfInvestmentCount);
// 	if (creditCount <= 0)
// 		return [].join('');

// 	if (selfInvestmentCount <= 0)
// 		return `Please ensure that the payment claims are raised only once a month and includes only credit linked candidates`;

// 	let ratios = [];
// 	// for ()
// 	let numer = Math.round(creditCount / 5);
// 	let denom = Math.round(selfInvestmentCount / 5);
// 	for (let i = 1; i <= 4; i++) {
// 		ratios.push(`${numer * i}:${denom * i}`);
// 	}
// 	// while (hcf < 5) {
// 	// 	numer = numer+1;
// 	// 	hcf = findHCF(numer, denom);
// 	// 	console.log(hcf, numer, denom);

// 	// }
// 	ratios.push(`${creditCount}:${selfInvestmentCount}`);
// 	return `Please ensure that the payment claims are raised only once a month and in the ratio (credit linked : 100 % self investment) - ${ratios.join(" or ")}`;
// };

moment.locale('en');

function InfographicIcon(props) {
	if (props.type === 'in-progress' || props.type === 'not-started' || props.type === 'on-hold') {
		return (
			<div className={props.type + ' infographic-icon'}>
				<div className="value">{props.value.toLocaleString()}</div>
				<div className="label">{props.label}</div>
				<div className="icon"><img src={"/images/infographic/" + props.type + ".png"} alt="" /></div>
			</div>
		)
	} else if (props.type === 'disqualified' || props.type === 'rejected') {
		return (
			<div className={props.type + ' infographic-icon'}>
				<div className="icon"><img alt="" src={"/images/infographic/disqualified.png"} /></div>
				<div className="value-label">{props.value.toLocaleString() + ' ' + props.label}</div>
			</div>
		)
	} else if (props.type === 'completed') {
		return (
			<div className={props.enterpriseSetup ? props.type + ' infographic-icon enterprise-setup' : props.type + ' infographic-icon'}>
				{props.enterpriseSetup ? <div className="icon" style={{ height: 80 }}><img alt="" src="/images/infographic/enterprise-setup-new.png" /*"/images/infographic/enterprise-setup.png"*/ /></div> : <div className="icon"><img alt="" src="/images/infographic/completed.png" /*"/images/infographic/enterprise-setup.png"*/ /></div>}
				<div className="value-label-container">
					<div className="value">{props.value.toLocaleString()}</div>
					<div className="label">{props.label}</div>
				</div>
			</div>
		)
	} else return null;
}

export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			state: '',
			// topScks: [],
			cluster: [],
			district: [],
			toDate: null,
			allStates: [],
			fromDate: null,
			claimsData: [],
			allDistricts: [],
			topDistricts: [],
			widthPercent: 16,
			totalCounts: null,
			ivrsLeadRatio: null,
			leadsFunnelData: [],
			infographicData: {
				profileCreationStage: { pendingProfilesOfAssignedCallersCount: 0, incompleteCount: 0, stageCount: 0, disqualifiedCount: 0, completedCount: 0 },
				dueDiligenceStage: { stageCount: 0, disqualifiedCount: 0, notStartedCount: 0, inProgressCount: 0, completedCount: 0, onHoldCount: 0 },
				bPlanStage: { onHoldCount: 0, inProgressCount: 0, notStartedCount: 0, stageCount: 0, disqualifiedCount: 0, completedCount: 0 },
				loanApplicationStage: { pendingDisbursementCount: 0, stageCount: 0, notStartedCount: 0, appliedCount: 0, completedCount: 0, rejectedCount: 0 },
				enterpriseSetupStage: { stageCount: 0, inProgressCount: 0, completedCount: 0 }
			},
			swavalambanFellows: [],
			leadsLoanFunnelData: [],
			leadsDemographyGraphData: [],
			leadsLoanDemographyGraphData: [],
			selectedLeadsDemography: 'phase',
			selectedLeadsLoanDemography: 'capitalFulfilledStatus',
			topPerformingDistrictColumns: [
				{ text: 'Name', sort: true, hidden: false, export: true, dataField: 'name', searchable: true, headerStyle: () => { return { width: '110px' } } },
				{ text: 'SCKs', sort: true, hidden: false, export: true, dataField: 'ngos', searchable: true, formatter: this.formatDistrictNGOs, headerStyle: () => { return { width: '160px' } } },
				{ text: 'Callers', sort: true, hidden: false, export: true, dataField: 'ivrs', searchable: true, headerStyle: () => { return { width: '60px' } } },
				{ text: 'Profiles', sort: true, hidden: false, export: true, dataField: 'leads', searchable: true, headerStyle: () => { return { width: '60px' } } },
				{ text: 'Sanctioned Leads', sort: true, hidden: false, export: true, dataField: 'sanctionedLeads', searchable: true, headerStyle: () => { return { width: '80px' } } },
				{ text: 'Profile Creation Percentage', sort: true, hidden: false, export: true, dataField: 'callerLeadRatio', searchable: true, headerStyle: () => { return { width: '80px' } } },
			],
			topPerformingSCKColumns: [
				{ text: '', sort: false, hidden: true, export: false, searchable: false, dataField: 'id' },
				{ text: 'NGO', sort: true, hidden: false, export: true, dataField: 'ngoName', searchable: true, formatter: this.formatNGOName, headerStyle: () => { return { width: '160px' } } },
				{ text: 'State', sort: true, hidden: false, export: true, dataField: 'state', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'District', sort: true, hidden: false, export: true, dataField: 'district', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Total assigned callers (A)', sort: true, hidden: false, export: true, dataField: 'districtIVRS', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Callers assigned to SCK (B)', sort: true, hidden: false, export: true, dataField: 'ivrs', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total Caller profiles created by SCK (C)', sort: true, hidden: false, export: true, dataField: 'leads', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Caller profiles disqualified (D)', sort: true, hidden: false, export: true, dataField: 'disqualifiedLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller profiles pending (E)', sort: true, hidden: false, export: true, dataField: 'pendingIVRS', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller Profile Creation % (C / (C + E))', sort: true, hidden: false, export: true, dataField: 'profileCreationRatio', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '90px' } }, sortFunc: this.ratioSort },
				{ text: 'Direct Profiles created by SCK (F)', sort: true, hidden: false, export: true, dataField: 'directLeads', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Direct profiles disqualified (G)', sort: true, hidden: false, export: true, dataField: 'directDisqualifiedLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total profiles created (H = C + F)', sort: true, hidden: false, export: true, dataField: 'totalLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total profiles not disqualified (J = H - G - D)', sort: true, hidden: false, export: true, dataField: 'totalLeadsNotDisqualified', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Disqualification % ((D + G) / H)', sort: true, hidden: false, export: true, dataField: 'disqualificationPercent', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '105px' } } },
				{ text: 'Complete profiles % (Total 100% complete profiles out of J / J)', sort: true, hidden: false, export: true, dataField: 'completedProfPercent', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller profiles conversion status', sort: true, hidden: false, export: true, dataField: 'profileCreationRatio', searchable: true, formatter: this.leadConversion, headerStyle: () => { return { width: '130px' } } },
			],
			claimsColumns: [
				{ text: '', sort: false, hidden: true, export: false, searchable: false, dataField: '_id' },
				{ text: 'Name', sort: true, hidden: false, export: true, dataField: 'name', searchable: true },
				{ text: 'Type', sort: true, hidden: false, export: true, dataField: 'type', searchable: true },
				{ text: 'Amount', sort: true, hidden: false, export: true, dataField: 'amount', searchable: true },
				{ text: 'Status', sort: true, hidden: false, export: true, dataField: 'status', searchable: true },
			],
			showFollowUpModule: false,
			enterpriseProgressStats: {
			}
		};
	}
	getCreditStats = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/stats/credit-stats', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token')),
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ enterpriseProgressStats: data.stats || {} });
				}
			})
	}

	componentDidMount() {
		this.getDashboardData();

		if (!(readCookie('project_id'))) {
			fetch(process.env.REACT_APP_API_URL + `/api/v1/project`, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token')),
					// 'project': userData.id
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status === "ok" && (data.projects || []).length>1) {
						window.location.href = "/project-landing";
					}
				})
		}
		if (JSON.parse(readCookie('userData')).type === 'partners') {
			this.getCreditStats();
		}

		if (JSON.parse(readCookie('userData')).type !== 'partners' || JSON.parse(readCookie('userData')).accountType === 'localGovernment') {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token')),
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) this.setState({ allStates: data.data.states });
					if (JSON.parse(readCookie('userData')).type === 'le') {
						fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full', {
							method: 'GET',
							headers: {
								'x-auth-token': JSON.parse(readCookie('access_token')),
							}
						}).then(data => data.json())
							.then(data => {
								if (data.status) {
									this.setState({ swavalambanFellows: data.users });
								}
								if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
							});
					} else {
						if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
					}
				});
		} else {
			if (!readCookie('noticed') && this.state.ivrsLeadRatio) {
				Swal.fire({
					type: 'warning',
					title: 'URGENT NOTICE!',
					text: '',
					focusConfirm: false,
					showCloseButton: true,
					confirmButtonText: 'Ok',
					showCancelButton: false,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						Swal.showLoading();
						this.checkIfGotValue = setInterval(() => {
							if (this.state.ivrsLeadRatio) {
								clearInterval(this.checkIfGotValue);
								if (parseInt(this.state.ivrsLeadRatio) < 80) {
									Swal.update({
										text: 'Please note that your caller profile completion rate is only ' + this.state.ivrsLeadRatio + '%. This means that you are not proactive enough in calling the interested aspirants and many candidates are awaiting your call. You can find them in the "IVRS callers" section.'
									});
									Swal.hideLoading();
								} else {
									Swal.close();
									createCookie('noticed', true, 7);
								}
							}
						}, 100);
					}
				}).then((result) => {
					if (result.value) {
						createCookie('noticed', true, 7);
					}
				});
			}
			if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
		}
	}

	getDashboardData = (query = '', demographyQuery = '') => {
		let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/dashboard';
		if (JSON.parse(readCookie('userData')).type === 'partners' && JSON.parse(readCookie('userData')).ngo) apiUrl += '/ngo/' + JSON.parse(readCookie('userData')).ngo._id;
         
		fetch(apiUrl + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token')),
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					let widthPercent = 16,
						totalCounts = {
							ngos: data.totalCounts.ngos || 0,
							ivrsCalls: data.totalCounts.ivrsCalls || 0,
							uniqueCallers: data.totalCounts.uniqueCallers || 0,
							legitCallers: data.totalCounts.legitCallers || 0,
							assignedCallers: data.totalCounts.assignedCallers || 0,
							profilesOfAssignedCallers: data.totalCounts.profilesOfAssignedCallers || 0,
							pendingProfilesOfAssignedCallers: data.totalCounts.pendingProfilesOfAssignedCallers || 0,
							walkInProfiles: data.totalCounts.walkInProfiles || 0,
							totalProfiles: data.totalCounts.totalProfiles || 0,
							disqualifiedProfiles: data.totalCounts.disqualifiedProfiles || 0,
							enterprisesSetup: data.totalCounts.enterprisesSetup || 0,
							enterprisesScaledup: data.totalCounts.enterprisesScaledup || 0
						}
					if (JSON.parse(readCookie('userData')).type === 'partners') {
						delete totalCounts['ngos'];
						widthPercent = 20;
					}
					this.setState({ totalCounts, claimsData: [], widthPercent });
				}

				fetch(apiUrl + '/infographic-data' + query, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token')),
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ infographicData: data.result });
						}

						let funnelPromise = fetch(apiUrl + '/funnel/funnel-data' + query, {
							method: 'GET',
							headers: {
								'x-auth-token': JSON.parse(readCookie('access_token')),
							}
						}).then(data => data.json())
							.then(data => {
								if (data.status) {
									let leadsFunnelData = data.funnelData || [];
									if (data.basicMaleFemaleData) leadsFunnelData[0] = { ...leadsFunnelData[0], ...data.basicMaleFemaleData };
									if (data.advancedMaleFemaleData) leadsFunnelData[1] = { ...leadsFunnelData[1], ...data.advancedMaleFemaleData };
									if (data.skillTrainedMaleFemaleData) leadsFunnelData[2] = { ...leadsFunnelData[2], ...data.skillTrainedMaleFemaleData };
									if (data.enterpriseTrainedMaleFemaleData) leadsFunnelData[3] = { ...leadsFunnelData[3], ...data.enterpriseTrainedMaleFemaleData };
									if (data.businessPlanReadyMaleFemaleData) leadsFunnelData[4] = { ...leadsFunnelData[4], ...data.businessPlanReadyMaleFemaleData };
									// if(data.capitalFulfilledMaleFemaleData) leadsFunnelData[5] = {...leadsFunnelData[5], ...data.capitalFulfilledMaleFemaleData};
									if (data.enterpriseSetupMaleFemaleData) leadsFunnelData[5] = { ...leadsFunnelData[5], ...data.enterpriseSetupMaleFemaleData };
									this.setState({ leadsFunnelData });
								}
							});

						let leadsDemographyPromise = fetch(apiUrl + '/' + this.state.selectedLeadsDemography + demographyQuery, {
							method: 'GET',
							headers: {
								'x-auth-token': JSON.parse(readCookie('access_token')),
							}
						}).then(data => data.json())
							.then(data => {
								if (data.status) {
									let leadsDemographyGraphData = data.data;
									this.setState({ leadsDemographyGraphData });
								}
							});

						Promise.all([funnelPromise, leadsDemographyPromise]).then(() => {
							fetch(apiUrl + '/funnel/loan-funnel-data' + query, {
								method: 'GET',
								headers: {
									'x-auth-token': JSON.parse(readCookie('access_token')),
								}
							}).then(data => data.json())
								.then(data => {
									if (data.status) {
										let leadsLoanFunnelData = data.loanFunnelData || [];
										this.setState({ leadsLoanFunnelData });
									}

									// fetch(apiUrl + '/top-performers/sck' + query, {
									// 	method: 'GET',
									// 	headers: {
									// 		'x-auth-token': JSON.parse(readCookie('access_token'))
									// 	}
									// }).then(data => data.json())
									// .then(data => {
									// 	if(data.status) {
									// 		if(data.hasOwnProperty('ivrsLeadRatio')) this.setState({ ivrsLeadRatio: data.ivrsLeadRatio });
									// 		this.setState({ topScks: data.topScks || [] });
									// 	}
									// });
								});
						});
					});
			});

		// fetch(apiUrl + '/top-performers/district' + query, {
		// 	method: 'GET',
		// 	headers: {
		// 		'x-auth-token': JSON.parse(readCookie('access_token'))
		// 	}
		// }).then(data => data.json())
		// .then(data => {
		// 	if(data.status) {
		// 		this.setState({ topDistricts: data.topDistricts || [] });
		// 	}
		// });

		// fetch(apiUrl + '/' + this.state.selectedLeadsLoanDemography + demographyQuery, {
		// 	method: 'GET',
		// 	headers: {
		// 		'x-auth-token': JSON.parse(readCookie('access_token'))
		// 	}
		// }).then(data => data.json())
		// .then(data => {
		// 	if(data.status) {
		// 		let leadsLoanDemographyGraphData = data.data;
		// 		this.setState({ leadsLoanDemographyGraphData });
		// 	}
		// });
	}

	formatDistrictNGOs = (cell, row) => {
		if (cell && cell.length) {
			return (
				<span className="ngo-name-urls">
					{cell.map((ngo, ngoI) => {
						return (
							<span key={ngoI}>
								{ngoI > 0 ? ', ' : ''}
								<a style={{ marginLeft: 2 }} href={"/org-types/swavalamban-connect-kendra/" + ngo._id}>{ngo.ngoName}</a>
							</span>
						)
					})}
				</span>
			);
		} else return null;
	}

	formatNGOName = (cell, row) => {
		if (cell && row.id) {
			return (
				<a href={"/org-types/swavalamban-connect-kendra/" + row.id}>{cell}</a>
			)
		} else return null;
	}

	formatPercentage = (cell, row) => {
		if (cell === 'N/A') return cell;
		else if (cell || cell === 0) {
			return cell + ' %';
		} else return null;
	}

	leadConversion = (cell, row) => {
		if (parseInt(cell) >= 80) return 'Proactive-Above Average';
		else if (parseInt(cell) < 80 && parseInt(cell) >= 65) return 'Needs improvement in profiling callers';
		else if (parseInt(cell) < 65) return 'Very Poor- Pending Callers';
		else return null;
	}

	onDateChange = (value) => {
		if (value) {
			let fromDate = value[0], toDate = value[1];
			this.setState({ fromDate, toDate }, () => this.submitFilter());
		} else {
			this.setState({ fromDate: null, toDate: null }, () => this.submitFilter());
		}
	}

	onLeadsDemographyChange = (value) => {
		if (value !== "") {
			let selectedLeadsDemography = value;//, leadsDemographyGraphData = [];
			this.setState({ selectedLeadsDemography }, () => this.submitFilter());
		}
	}

	onLeadsLoanDemographyChange = (value) => {
		if (value !== "") {
			let selectedLeadsLoanDemography = value;
			// , leadsLoanDemographyGraphData = [];
			this.setState({ selectedLeadsLoanDemography }, () => this.submitFilter());
		}
	}

	onChange = (type, value) => {
		if (type === 'state') {
			if (value) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + value, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token')),
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ allDistricts: data.districts, district: [], state: value }, () => this.submitFilter());
						}
					});
			} else {
				this.setState({ allDistricts: [], district: [], state: value }, () => this.submitFilter());
			}
		} else if (type === 'cluster') {
			for (let i = 0; i < this.state.swavalambanFellows.length; i++) {
				if (value.includes(this.state.swavalambanFellows[i]._id)) {
					fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + this.state.swavalambanFellows[i].state[0], {
						method: 'GET',
						headers: {
							'x-auth-token': JSON.parse(readCookie('access_token')),
						}
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								this.setState({ allDistricts: data.districts, district: this.state.swavalambanFellows[i].district, state: this.state.swavalambanFellows[i].state[0], cluster: value }, () => this.submitFilter());
							}
						});
				}
			}
		} else {
			if (value.hasOwnProperty('target')) this.setState({ [type]: value.target.value }, () => this.submitFilter());
			else this.setState({ [type]: value }, () => this.submitFilter());
		}
	}

	submitFilter = () => {
		let { fromDate, toDate, state, district } = this.state, query = '', demographyQuery = '';
		if (state) {
			if (query) query += `&state=${state}`;
			else query = `?state=${state}`;
			if (demographyQuery) demographyQuery += `&state=${state}`;
			else demographyQuery = `?state=${state}`;
		}
		if (district) {
			if (query) query += `&district=${district}`;
			else query = `?district=${district}`;
			if (demographyQuery) demographyQuery += `&district=${district}`;
			else demographyQuery = `?district=${district}`;
		}
		if (toDate) {
			if (query) query += `&toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			else query = `?toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			if (demographyQuery) demographyQuery += `&toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			else demographyQuery = `?toDate=${moment(toDate).format('DD-MM-YYYY')}`;
		}
		if (fromDate) {
			if (query) query += `&fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			else query = `?fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			if (demographyQuery) demographyQuery += `&fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			else demographyQuery = `?fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
		}

		this.getDashboardData(query, demographyQuery);
	}

	resetFilter = () => {
		// let state = '', district = '', fromDate = null, toDate = null;
		this.setState({ state: '', district: [], cluster: [], fromDate: null, toDate: null, allDistricts: [] }, () => this.getDashboardData());
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if (!Array.isArray(option.props.children)) {
			if (typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}

	downloadData = () => {
		let { fromDate, toDate, state, district } = this.state, query = '';
		if (state) {
			if (query) query += `&state=${state}`;
			else query = `?state=${state}`;
		}
		if (district) {
			if (query) query += `&district=${district}`;
			else query = `?district=${district}`;
		}
		if (toDate) {
			if (query) query += `&toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			else query = `?toDate=${moment(toDate).format('DD-MM-YYYY')}`;
		}
		if (fromDate) {
			if (query) query += `&fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			else query = `?fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
		}

		if (query) query += '&x-auth-token=' + JSON.parse(readCookie('access_token'));
		else query = '?x-auth-token=' + JSON.parse(readCookie('access_token'));

		window.open(process.env.REACT_APP_API_URL + '/api/v1/dashboard/export-dashboard-data' + query, "_blank");
		// fetch(process.env.REACT_APP_API_URL + '/api/v1/dashboard/export-dashboard-data', {
		// 	method: 'GET',
		// 	headers: {
		// 		'x-auth-token': JSON.parse(readCookie('access_token'))
		// 	}
		// }).then(data => data.json())
		// .then(data => {
		// 	if(!data.status) {
		// 		this.setState({ allStates: data.data.states });
		// 	}
		// });
	}

	rowClassNameFormat = (row, index) => {
		if (parseInt(row.profileCreationRatio) >= 80) return { color: '#fff', backgroundColor: '#78D245' };
		else if (parseInt(row.profileCreationRatio) < 80 && parseInt(row.profileCreationRatio) >= 65) return { backgroundColor: '#FFDF80' };
		else if (parseInt(row.profileCreationRatio) < 65) return { color: '#fff', backgroundColor: '#ff4f4f' };
	}

	ratioSort = (a, b, order, dataField, rowA, rowB) => {
		if (order === 'asc') {
			if (a === 'N/A') return 1;
			else if (b === 'N/A') return -1;
			else return b - a;
		}
		if (a === 'N/A') return -1;
		else if (b === 'N/A') return 1;
		else return a - b;
	}

	showLeads = (type) => {
		let { fromDate, toDate, state, district } = this.state,
			getAllLeadsFilter = sessionStorage.getItem('getAllLeadsFilter');

		if (getAllLeadsFilter) {
			getAllLeadsFilter = JSON.parse(getAllLeadsFilter);
		} else {
			getAllLeadsFilter = { pwd: '', idea: '', phase: '', gender: '', category: '', state: '', district: [], fromDate: null, toDate: null, hasComments: '', collateral: '', bankAccounts: '', financeType: '', overallSentiment: '', strengthRating: '', maturityStatus: [], capitalFulfilledStatus: [], disqualified: false, starred: false, tentativeHorizonToCloseThis: '' };
		}

		if (type === 'self-investment') {
			getAllLeadsFilter['financeType'] = '100% self-financed';
			getAllLeadsFilter['capitalFulfilledStatus'] = [];
		} else {
			getAllLeadsFilter['financeType'] = '';
		}

		if (type === 'applied') getAllLeadsFilter['capitalFulfilledStatus'] = ['Applied', 'Sanctioned', 'Disbursed'];
		else if (type === 'sanctioned') getAllLeadsFilter['capitalFulfilledStatus'] = ['Sanctioned', 'Disbursed'];
		else if (type === 'disbursed') getAllLeadsFilter['capitalFulfilledStatus'] = ['Disbursed'];
		else if (type === 'rejected') getAllLeadsFilter['capitalFulfilledStatus'] = ['Rejected'];
		else if (type === 'bPlanCompleted' || type === "loanAppApplied" || type === "eSetupInProgress") {
			delete getAllLeadsFilter['bPlanCompleted'];
			delete getAllLeadsFilter['loanAppApplied'];
			delete getAllLeadsFilter['eSetupInProgress'];

			if (type === 'bPlanCompleted') getAllLeadsFilter['bPlanCompleted'] = true;
			else if (type === 'loanAppApplied') getAllLeadsFilter['loanAppApplied'] = true;
			else if (type === 'eSetupInProgress') getAllLeadsFilter['eSetupInProgress'] = true;
		}

		if (state) getAllLeadsFilter['state'] = state;
		if (toDate) getAllLeadsFilter['toDate'] = toDate;
		if (district) getAllLeadsFilter['district'] = district;
		if (fromDate) getAllLeadsFilter['fromDate'] = fromDate;

		sessionStorage.setItem('getAllLeadsFilter', JSON.stringify(getAllLeadsFilter));
		window.open(window.location.origin + '/leads', '_blank');
	}

	render() {
		// const options = { sizePerPage: 10 };
		return (
			<div className="page-container dashboard-page">
				{this.state.showFollowUpModule ? (
					<FollowUpNav />
				) : (null)}
				<div
					className="row"
					style={
						window.screen.width < 500 && this.state.showFollowUpModule
							? { paddingTop: 120 }
							: { paddingTop: 30 }
					}
				>
					{this.props.userData.userType.name === 'Funder' ? <div style={{ color: "red", textAlign: "center", margin: "0px auto" }}><note>Please note that the figure in this dashboard correspond to the overall mission. You would find the data of entrepreneurs supported by you in the LEADS PROFILED tab.</note></div> : null}
					<div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
					<div className="filter form-group col-lg-3 col-md-3 col-sm-6 col-xsm-6 p-0">
						<label className="control-label">{"From - To date: "}</label>
						<ConfigProvider locale={enUS}>
							<RangePicker
								locale={enUS}
								style={
									window.screen.width > 500
										? { width: "200px" }
										: { width: 300 }
								}
								format="DD/MM/YYYY"
								value={[this.state.fromDate, this.state.toDate]}
								type="date"
								onChange={this.onDateChange}
							/>
						</ConfigProvider>
					</div>

					<div className="col-lg-2 col-md-2 col-sm-6 col-xsm-6 p-0">
						{JSON.parse(readCookie("userData")).userType.name !==
							"Swavalamban Connect Kendra" ? (
							<div>
								<label className="control-label">{"State: "}</label>
								<Select
									showSearch
									style={
										window.screen.width > 500
											? { width: "120px" }
											: { width: 300 }
									}
									value={this.state.state}
									onChange={this.onChange.bind(this, "state")}
									filterOption={(input, option) =>
										this.searchFilter(input, option)
									}
								>
									<Option value="">All States</Option>
									<OptGroup label="Focus States">
										{this.state.allStates.map((state, stateIndex) => {
											if (state.inFocusRegion) {
												if (
													JSON.parse(readCookie("userData")).userType.name ===
													"Swavalamban Fellows" &&
													JSON.parse(
														readCookie("userData")
													).address.state.includes(state.name)
												) {
													return (
														<Option key={stateIndex} value={state.name}>
															{state.name}
														</Option>
													);
												} else if (
													JSON.parse(readCookie("userData")).userType.name !==
													"Swavalamban Fellows"
												) {
													return (
														<Option key={stateIndex} value={state.name}>
															{state.name}
														</Option>
													);
												} else return null;
											} else return null;
										})}
									</OptGroup>
									<OptGroup label="Not Focussed States">
										{this.state.allStates.map((state, stateIndex) => {
											if (!state.inFocusRegion) {
												if (
													JSON.parse(readCookie("userData")).userType.name ===
													"Swavalamban Fellows" &&
													JSON.parse(
														readCookie("userData")
													).address.state.includes(state.name)
												) {
													return (
														<Option key={stateIndex} value={state.name}>
															{state.name}
														</Option>
													);
												} else if (
													JSON.parse(readCookie("userData")).userType.name !==
													"Swavalamban Fellows"
												) {
													return (
														<Option key={stateIndex} value={state.name}>
															{state.name}
														</Option>
													);
												} else return null;
											} else return null;
										})}
									</OptGroup>
								</Select>
							</div>
						) : null}
					</div>
					<div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 p-0">
						{JSON.parse(readCookie("userData")).userType.name !==
							"Swavalamban Connect Kendra" ? (
							<div>
								<label className="control-label">{"District: "}</label>
								<Select
									mode="multiple"
									showSearch
									style={
										window.screen.width > 500
											? { width: "130px" }
											: { width: 300 }
									}
									value={this.state.district}
									onChange={this.onChange.bind(this, "district")}
									filterOption={(input, option) =>
										this.searchFilter(input, option)
									}
									placeholder="All Districts"
								>
									{this.state.state
										? this.state.allDistricts.map((district, districtIndex) => {
											if (
												JSON.parse(readCookie("userData")).userType.name ===
												"Swavalamban Fellows" &&
												JSON.parse(
													readCookie("userData")
												).address.district.includes(district.name)
											) {
												return (
													<Option key={districtIndex} value={district.name}>
														{district.name}
													</Option>
												);
											} else if (
												JSON.parse(readCookie("userData")).userType.name !==
												"Swavalamban Fellows"
											) {
												return (
													<Option key={districtIndex} value={district.name}>
														{district.name}
													</Option>
												);
											} else return null;
										})
										: null}
								</Select>
							</div>
						) : null}
					</div>
					{JSON.parse(readCookie("userData")).type === "le" ? (
						<div className="p-0">
							<label className="control-label">{"Cluster: "}</label>
							<Select
								showSearch
								style={
									window.screen.width > 500
										? { width: "130px" }
										: { width: 300 }
								}
								value={this.state.cluster}
								onChange={this.onChange.bind(this, "cluster")}
								filterOption={(input, option) =>
									this.searchFilter(input, option)
								}
								placeholder="All Clusters"
							>
								{this.state.swavalambanFellows.map((fellow, fellowIndex) => {
									return (
										<Option
											key={fellowIndex}
											title={`${fellow.fullName} (${fellow.email})`}
											value={fellow._id}
										>
											{fellow.fullName}
										</Option>
									);
								})}
							</Select>
						</div>
					) : null}
					<div className="filter form-group">
						<button
							style={
								window.screen.width > 500
									? { marginTop: 40, marginLeft: 20 }
									: { marginTop: 20 }
							}
							className="btn reset-filters"
							onClick={this.resetFilter}
						>
							Reset
						</button>
					</div>
				</div>
				{/* <div className={this.state.showFollowUpModule ? "filters-container follow-up-margin" : "filters-container"}>
					<div className="filter form-group">
						<label className="control-label">{'From - To date: '}</label>
						<ConfigProvider locale={enUS}>
							<RangePicker locale={enUS} style={{ width: "200px" }} format="DD/MM/YYYY" value={[this.state.fromDate, this.state.toDate]} type="date" onChange={this.onDateChange} />
						</ConfigProvider>
					</div>
					{JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Connect Kendra' ? (
						<div className="filter form-group">
							<label className="control-label">{'State: '}</label>
							<Select showSearch style={{ width: '120px' }} value={this.state.state} onChange={this.onChange.bind(this, 'state')} filterOption={(input, option) => this.searchFilter(input, option)}>
								<Option value="">All States</Option>
								<OptGroup label="Focus States">
									{this.state.allStates.map((state, stateIndex) => {
										if (state.inFocusRegion) {
											if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).userType.name !== 'Zonal Manager') {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else return null;
										} else return null;
									})}
								</OptGroup>
								<OptGroup label="Not Focussed States">
									{this.state.allStates.map((state, stateIndex) => {
										if (!state.inFocusRegion) {
											if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).userType.name !== 'Zonal Manager') {
												return <Option key={stateIndex} value={state.name}>{state.name} </Option>
											} else return null;
										} else return null;
									})}
								</OptGroup>
							</Select>
						</div>
					) : (null)}
					{JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Connect Kendra' ? (
						<div className="filter form-group">
							<label className="control-label">{'District: '}</label>
							<Select mode="multiple" showSearch style={{ width: '130px' }} value={this.state.district} onChange={this.onChange.bind(this, 'district')} filterOption={(input, option) => this.searchFilter(input, option)} placeholder="All Districts">
								{this.state.state ? (
									this.state.allDistricts.map((district, districtIndex) => {
										if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.district.includes(district.name)) {
											return <Option key={districtIndex} value={district.name}>{district.name}</Option>
										} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
											return <Option key={districtIndex} value={district.name}>{district.name}</Option>
										} else return null;
									})
								) : (null)}
							</Select>
						</div>
					) : (null)}
					{JSON.parse(readCookie('userData')).type === 'le' ? (
						<div className="filter form-group">
							<label className="control-label">{'Cluster: '}</label>
							<Select showSearch style={{ width: '130px' }} value={this.state.cluster} onChange={this.onChange.bind(this, 'cluster')} filterOption={(input, option) => this.searchFilter(input, option)} placeholder="All Clusters">
								{this.state.swavalambanFellows.map((fellow, fellowIndex) => {
									return (
										<Option key={fellowIndex} title={`${fellow.fullName} (${fellow.email})`} value={fellow._id}>
											{fellow.fullName}
										</Option>
									)
								})}
							</Select>
						</div>
					) : (null)}
					<div className="filter form-group">
						<button className="btn reset-filters" onClick={this.resetFilter}>Reset</button>
						// <button className="btn download-data-btn ml10" onClick={this.downloadData}>Download Analytics</button>
					</div>
				</div> */}
				{this.state.totalCounts ? (
					<div className="numbers-container">
						{JSON.parse(readCookie('userData')).type !== 'partners' ? (
							<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
								<div className="top">
									<span className="value">{(this.state.totalCounts.ngos).toLocaleString()}</span>
									<img alt="" className="number-icon" src="/images/ngo.png" />
								</div>
								<div className="bottom">
									{'SCKs'}
								</div>
							</div>
						) : (null)}
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.ivrsCalls).toLocaleString()}</span>
								<img alt="" className="number-icon" src="/images/calls.png" />
							</div>
							<div className="bottom">
								{'Total IVRS Calls'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.uniqueCallers).toLocaleString()}</span>
								<img className="number-icon" src="/images/ivrs2.png" alt="" />
							</div>
							<div className="bottom">
								{'Total Unique Callers'}
							</div>
						</div>
						{/*<div className="number-container" style={{width: `calc(${this.state.widthPercent}% - 20px)`}} title="Assigned, Unassigned callers in Focus Region">
							<div className="top">
								<span className="value">{(this.state.totalCounts.legitCallers).toLocaleString()}</span>
								<img className="number-icon" src="/images/ivrs2.png" />
							</div>
							<div className="bottom">
								{'Total Legit Callers'}
							</div>
						</div>*/}
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }
						}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.assignedCallers).toLocaleString()}</span>
								<img className="number-icon" src="/images/ivrs.png" alt="" />
							</div>
							<div className="bottom">
								{'Total Assigned Leads'}<br />{'(Callers + Direct Leads)'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.profilesOfAssignedCallers).toLocaleString()}</span>
								<img className="number-icon" src="/images/leads.png" alt="" />
							</div>
							<div className="bottom">
								{'Total Profiles Created of Assigned Leads'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.pendingProfilesOfAssignedCallers).toLocaleString()}</span>
								<img className="number-icon" src="/images/leads.png" alt="" />
							</div>
							<div className="bottom">
								{'Assigned Leads pending to be profiled'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.walkInProfiles).toLocaleString()}</span>
								<img alt="" className="number-icon" src="/images/leads.png" />
							</div>
							<div className="bottom">
								{'Total Walk in Profiles'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.disqualifiedProfiles).toLocaleString()}</span>
								<img className="number-icon" src="/images/leads.png" alt="" />
							</div>
							<div className="bottom">
								{'Total Disqualified Profiles'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.totalProfiles).toLocaleString()}</span>
								<img className="number-icon" src="/images/leads.png" alt="" />
							</div>
							<div className="bottom">
								{'Total Profiles'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 0 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.enterprisesSetup).toLocaleString()}</span>
								<img className="number-icon" src="/images/enterprises.png" alt="" />
							</div>
							<div className="bottom">
								{'Enterprises Setup'}
							</div>
						</div>
						<div className="number-container" style={window.screen.width > 500 ? { width: `calc(${this.state.widthPercent}% - 20px)` } : { width: window.screen.width / 2 - 20, marginLeft: 5, marginRight: 5, marginBottom: 20 }}>
							<div className="top">
								<span className="value">{(this.state.totalCounts.enterprisesScaledup).toLocaleString()}</span>
								<img className="number-icon" src="/images/enterprises.png" alt="" />
							</div>
							<div className="bottom">
								{'Enterprises Scaled-up'}
							</div>
						</div>
					</div>
				) : (null)}
				{/* {
					JSON.parse(readCookie('userData')).type === 'partners' ?
						<Container>
						<div className="credit-progress-container">
							{/* <div className="credit-progress-container-title">
							Enterprise Set-up Progress	
								</div> */}

				{/* <Row>
									<p style={{ color: "red" }}> */}
				{/* <h6>Note:</h6> */}
				{/* <ul>
											<li>In this district, <strong>{this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0}</strong> enterprises have been created so far (<strong>{this.state.enterpriseProgressStats.creditLinkedCandidates || 0}</strong> with credit-linkage and <strong>{this.state.enterpriseProgressStats.selfInvestmentCandidates || 0}</strong> with 100% self-investment). {((35 - (this.state.enterpriseProgressStats.creditLinkedCandidates || 0)) > 0 ? 35 - (this.state.enterpriseProgressStats.creditLinkedCandidates || 0) : 0)!==0? <>To complete the mission, <strong>{70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0)}</strong> more startups need to be created, out of which at least	<strong>{35 - (this.state.enterpriseProgressStats.creditLinkedCandidates || 0)}</strong> need to be credit-linked (loan supported). The more, the better.</>: ((70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0)) > 0 ? 70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0) : 0) > 0 ? <>To complete the mission, <strong>{(70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0)) > 0 ? 70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0) : 0}</strong> more startups need to be created</> :
														null
												}
											</li>
											{getRatiosString(35 - (this.state.enterpriseProgressStats.creditLinkedCandidates || 0), 70 - (this.state.enterpriseProgressStats.enterprisesCreatedSoFar || 0) - (35 - (this.state.enterpriseProgressStats.creditLinkedCandidates || 0)))}
											{/* <li>Please ensure that the payment claims are raised only once a month and in the ratio (credit linked : 100% self investment) - 7:3 or 14:6 or 21:9 or 28:12 or 35:15.
											</li> */}
				{/* <li>Candidates who are marked successful, have undergone audit and haven’t been claimed yet are labelled <strong>“Ready for claim”</strong> and those candidate who have been claimed by you already are marked as <strong>"Claimed"</strong>.
											</li></ul>
									</p>
								</Row>
							<Row> */}
				{/* <Col md={3}>
									<div className="credit-progress-tile">
											<strong>Overall Creation</strong><br/>
											12 */}
				{/* {this.state.totalCounts ? (this.state.totalCounts.enterpriseCountForCredit || 0) : 0} */}
				{/* </div> */}
				{/* </Col> */}
				{/* <Col md={3}>
									<div className="credit-progress-tile">
											<strong>	Audit stats</strong>
											<br/>
											7 enterprises audited
											{/* {this.state.totalCounts ? (this.state.totalCounts.auditedCountForCredit || 0) : 0} */}
				{/* </div> */}
				{/* </Col> */}
				{/* <Col md={2}>
									<div className="credit-progress-tile">
											<strong>Enterprises with credit linkage</strong>
											<br />
											8
									</div>
									</Col>
									<Col md={2}>
										<div className="credit-progress-tile">
											<strong>Enterprises with self investment</strong>
											<br />
											20
										</div>
									</Col>
									<Col md={2}>
										<div className="credit-progress-tile">
											<strong>Remaining credit linkage enterprises to be created</strong>
											<br />
											35
										</div>
									</Col> 
									{/* <Col md={3}>
										<div className="credit-progress-tile">
											Credit linkage - 2
										</div>
									</Col> */}
				{/* </Row>
							</div>
						</Container>
					: null
				} */}
				{this.state.infographicData && window.outerWidth > 1024 ? (
					<><div className="infographic-container">

						<div className="profile-creation-stage stage-container">
							{this.state.infographicData.profileCreationStage.pendingAtSCK ? (
								<div className="pending-at-sck">{`Pending SCK action: ${this.state.infographicData.profileCreationStage.pendingAtSCK}`}</div>
							) : (null)}
							<div className="above-line-container flex-nowrap">
								<InfographicIcon type="on-hold" value={this.state.infographicData.profileCreationStage.pendingProfilesOfAssignedCallersCount} label="Pending Profiling" />
								<InfographicIcon type="in-progress" value={this.state.infographicData.profileCreationStage.incompleteCount} label="All Incomplete Profiles" />
							</div>
							<div className="line"></div>
							<div className="below-line-container">
								<InfographicIcon type="disqualified" value={this.state.infographicData.profileCreationStage.disqualifiedCount} label="Disqualified" />
							</div>
							<div className="stage-count">
								<div className="value">{`(${this.state.infographicData.profileCreationStage.stageCount.toLocaleString()})`}</div>
								<div className="label">Profile Creation Stage</div>
							</div>
						</div>
						<div className="due-diligence-stage stage-container">
							{this.state.infographicData.dueDiligenceStage.pendingAtSCK ? (
								<div className="pending-at-sck">{`Pending SCK action: ${this.state.infographicData.dueDiligenceStage.pendingAtSCK}`}</div>
							) : (null)}
							<InfographicIcon type="completed" value={this.state.infographicData.profileCreationStage.completedCount} label="Completed" />
							<div className="above-line-container">
								<InfographicIcon type="not-started" value={this.state.infographicData.dueDiligenceStage.notStartedCount} label="Not yet Started" />
								<InfographicIcon type="on-hold" value={this.state.infographicData.dueDiligenceStage.onHoldCount} label="On-hold" />
								<InfographicIcon type="in-progress" value={this.state.infographicData.dueDiligenceStage.inProgressCount} label="In Progress" />
							</div>
							<div className="line"></div>
							<div className="below-line-container">
								<InfographicIcon type="disqualified" value={this.state.infographicData.dueDiligenceStage.disqualifiedCount} label="Disqualified" />
							</div>
							<div className="stage-count">
								<div className="value">{`(${this.state.infographicData.dueDiligenceStage.stageCount.toLocaleString()})`}</div>
								<div className="label">Due-Diligence Stage</div>
							</div>
						</div>
						<div className="bplan-stage stage-container">
							{this.state.infographicData.bPlanStage.pendingAtSCK ? (
								<div className="pending-at-sck">{`Pending SCK action: ${this.state.infographicData.bPlanStage.pendingAtSCK}`}</div>
							) : (null)}
							<InfographicIcon type="completed" value={this.state.infographicData.dueDiligenceStage.completedCount} label="Completed" />
							<div className="above-line-container">
								<InfographicIcon type="not-started" value={this.state.infographicData.bPlanStage.notStartedCount} label="Not yet Started" />
								<InfographicIcon type="on-hold" value={this.state.infographicData.bPlanStage.onHoldCount} label="On-hold" />
								<InfographicIcon type="in-progress" value={this.state.infographicData.bPlanStage.inProgressCount} label="In Progress" />
							</div>
							<div className="line"></div>
							<div className="below-line-container">
								<InfographicIcon type="disqualified" value={this.state.infographicData.bPlanStage.disqualifiedCount} label="Disqualified" />
							</div>
							<div className="stage-count">
								<div className="value">{`(${this.state.infographicData.bPlanStage.stageCount.toLocaleString()})`}</div>
								<div className="label">B-Plan Stage</div>
							</div>
						</div>
						<div className="loan-application-stage stage-container">
							{this.state.infographicData.loanApplicationStage.pendingAtSCK ? (
								<div className="pending-at-sck">{`Pending SCK action: ${this.state.infographicData.loanApplicationStage.pendingAtSCK}`}</div>
							) : (null)}
							<div className="pointer" onClick={this.showLeads.bind(this, 'bPlanCompleted')}>
								<InfographicIcon type="completed" value={this.state.infographicData.bPlanStage.completedCount} label="Completed" />
							</div>
							<div className="above-line-container">
								{/* <InfographicIcon type="not-started" value={this.state.infographicData.loanApplicationStage.notStartedCount} label="Not yet Started" /> */}
								<InfographicIcon type="not-started" value={this.state.infographicData.loanApplicationStage.notStartedCount} label="Preparing for Loan Application" />
								{/* <InfographicIcon type="on-hold" value={this.state.infographicData.loanApplicationStage.pendingDisbursementCount} label="Pending Disbursement" /> */}
								<div className="pointer" style={{ fontSize: "10px" }} onClick={this.showLeads.bind(this, 'loanAppApplied')}>
									<InfographicIcon type="in-progress" value={this.state.infographicData.loanApplicationStage.appliedCount} label="Applications Submitted" />
								</div>
								<InfographicIcon type="on-hold" value={this.state.infographicData.loanApplicationStage.pendingDisbursementCount} label="Loans Sanctioned" />
							</div>
							<div className="line"></div>
							<div className="below-line-container">
								<InfographicIcon type="rejected" value={this.state.infographicData.loanApplicationStage.rejectedCount} label="Rejected" />
							</div>
							<div className="stage-count">
								<div className="value">{`(${this.state.infographicData.loanApplicationStage.stageCount.toLocaleString()})`}</div>
								<div className="label">Loan Application Stage</div>
							</div>
						</div>
						<div className="enterprise-setup-stage stage-container">
							{this.state.infographicData.enterpriseSetupStage.pendingAtSCK ? (
								<div className="pending-at-sck">{`Pending SCK action: ${this.state.infographicData.enterpriseSetupStage.pendingAtSCK}`}</div>
							) : (null)}
							<InfographicIcon type="completed" value={this.state.infographicData.loanApplicationStage.completedCount} label="Completed" />
							<div className="above-line-container">
								<div className="pointer" onClick={this.showLeads.bind(this, 'eSetupInProgress')}>
									{/* <InfographicIcon type="in-progress" value={this.state.infographicData.enterpriseSetupStage.inProgressCount} label="Not yet started/scaled-up" /> */}
									<InfographicIcon type="in-progress" value={this.state.infographicData.enterpriseSetupStage.inProgressCount} label="Ready to Start-up/Scale-up" />

								</div>
							</div>
							<div className="line"></div>
							<InfographicIcon type="completed" value={this.state.infographicData.enterpriseSetupStage.completedCount} label="Started-up/Scaled-up " enterpriseSetup={true} />
							<div className="stage-count">
								<div className="value">{`(${this.state.infographicData.enterpriseSetupStage.stageCount.toLocaleString()})`}</div>
								<div className="label">Enterprise Set-up / Scale-up Stage</div>
							</div>
						</div>
					</div></>
				) : (null)}

				<div className="leads-graphs-container">
					<div style={window.screen.width < 500 ? { width: "100%" } : null} className="leads-graph-container">
						<div className="title">{'Funnel'}</div>
						<div className="leads-graph">
							{this.state.leadsFunnelData.length ? (
								<div className="funnel">
									{this.state.leadsFunnelData.map((type, index) => {
										return (
											<div key={index} className={"trapezoid trapezoid-" + index} style={index < (this.state.leadsFunnelData.length - 1) ? { borderTopColor: type.fill } : { backgroundColor: type.fill }} title={this.state.leadsLoanFunnelData.length && type.name === 'Capital Fulfilled' ? `Self-investment: ${this.state.leadsLoanFunnelData[0].value}, Loans Disbursed: ${this.state.leadsLoanFunnelData[3].value}` : null}>
												<span style={window.screen.width < 500 ? { fontSize: 9 } : null}>{type.name + ' : ' + type.value}</span>
												{type["Male"] || type["Female"] || type["Other"] ? (
													<span style={window.screen.width < 500 ? { fontSize: 9 } : null} className="male-female">(
														{'M: ' + type["Male"]}
														{', F: ' + type["Female"]}
														{type["Other"] ? (', Other: ' + type["Other"]) : (null)}
														)</span>
												) : (null)}
											</div>
										)
									})}
								</div>
							) : (null)}
						</div>
					</div>
					{window.screen.width > 500 && <div className="leads-demography-container">
						<div className="title-type-container">
							<span>{'Demography'}</span>
							<Select style={{ minWidth: 200 }} placeholder="Select Type" value={this.state.selectedLeadsDemography} onChange={this.onLeadsDemographyChange}>
								<Option value="gender" title="Gender">Gender</Option>
								<Option value="phase" title="Phase">Phase</Option>
								<Option value="state" title="State">State</Option>
								<Option value="category" title="Category">Category</Option>
								<Option value="generatedThrough" title="Generated Through">Generated Through</Option>
							</Select>
						</div>
						<div className="leads-demography-graph-container">
							{this.state.leadsDemographyGraphData.length ? (
								<ResponsiveContainer>
									<PieChart>
										<Pie dataKey="value" data={this.state.leadsDemographyGraphData} label nameKey="_id">
											{this.state.leadsDemographyGraphData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
										</Pie>
										<Tooltip />
									</PieChart>
								</ResponsiveContainer>
							) : (null)}
							{!this.state.leadsDemographyGraphData.length ? (
								<div className="no-data-container">No Data Found</div>
							) : (null)}
						</div>
					</div>}
				</div>

				<div className="leads-graphs-container">
					{window.screen.width < 500 && <div style={{ width: "100%", marginLeft: 0, marginTop: 20 }} className="leads-demography-container">
						<div className="title-type-container">
							<span>{'Demography'}</span>
							<Select style={{ minWidth: 200 }} placeholder="Select Type" value={this.state.selectedLeadsDemography} onChange={this.onLeadsDemographyChange}>
								<Option value="gender" title="Gender">Gender</Option>
								<Option value="phase" title="Phase">Phase</Option>
								<Option value="state" title="State">State</Option>
								<Option value="category" title="Category">Category</Option>
								<Option value="generatedThrough" title="Generated Through">Generated Through</Option>
							</Select>
						</div>
						<div className="leads-demography-graph-container">
							{this.state.leadsDemographyGraphData.length ? (
								<ResponsiveContainer>
									<PieChart>
										<Pie dataKey="value" data={this.state.leadsDemographyGraphData} label nameKey="_id">
											{this.state.leadsDemographyGraphData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
										</Pie>
										<Tooltip />
									</PieChart>
								</ResponsiveContainer>
							) : (null)}
							{!this.state.leadsDemographyGraphData.length ? (
								<div className="no-data-container">No Data Found</div>
							) : (null)}
						</div>
					</div>}
				</div>

				<div className="leads-graphs-container">
					{/*<div className="leads-capital-fulfilled-container">
						<div className="title-type-container">
							<span>{'Loan Application Demography'}</span>
							<Select style={{width: '120'}} placeholder="Select Type" value={this.state.selectedLeadsLoanDemography} onChange={this.onLeadsLoanDemographyChange}>
								<Option value="capitalFulfilledStatus" title="Lead Capital Fulfilled Status">Lead Capital Fulfilled Status</Option>
								<Option value="loanApplicationStatus" title="Loan Application Status">Loan Application Status</Option>
							</Select>
						</div>
						<div className="leads-demography-graph-container">
							{this.state.leadsLoanDemographyGraphData.length ? (
								<ResponsiveContainer>
									<PieChart>
										<Pie dataKey="value" data={this.state.leadsLoanDemographyGraphData} label nameKey="_id">
											{this.state.leadsLoanDemographyGraphData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
										</Pie>
										<Tooltip />
									</PieChart>
								</ResponsiveContainer>
							) : (null)}
							{!this.state.leadsLoanDemographyGraphData.length ? (
								<div className="no-data-container">No Data Found</div>
							) : (null)}
						</div>
					</div>*/}
					<div style={window.screen.width < 500 ? { width: '100%' } : null} className="leads-loan-graph-container">
						<div className="title">{'Loan Application Funnel'}</div>
						<div className="leads-graph">
							{this.state.leadsLoanFunnelData.length ? (
								<div className="funnel">
									{this.state.leadsLoanFunnelData.map((type, index) => {
										return (
											<div key={index} className={"trapezoid trapezoid-" + index} style={index < (this.state.leadsLoanFunnelData.length - 1) ? { borderTopColor: type.fill } : { backgroundColor: type.fill }} onClick={this.showLeads.bind(this, type.type)}>
												<span>{type.name + ' : ' + type.value}</span>
											</div>
										)
									})}
								</div>
							) : (null)}
						</div>
					</div>
				</div>
				{JSON.parse(readCookie('userData')).type === 'le' && <Row>
					<note style={{ fontWeight: "900" }}><strong>
						Please note that we have started capturing the date of marking the enterprise as set-up/scaled-up from 28th September 2021. Hence, the infographic shows the correct figure for the number of "Started-up/Scaled-up" for dates after 28th September 2021.
					</strong></note>
				</Row>}
				{JSON.parse(readCookie('userData')).type !== 'partners' && false ? (
					<div className="top-districts-container">
						<div className="title">{'Top Performing Districts'}</div>
						<div className="top-districts-table-container">
							{this.state.topDistricts.length ? (
								<ToolkitProvider keyField="name" data={this.state.topDistricts} columns={this.state.topPerformingDistrictColumns}>
									{props => (
										<div className="table-container">
											<BootstrapTable id="top-districts-table" {...props.baseProps} ref={(table) => { this.topDistrictsTable = table; }} />
										</div>
									)}
								</ToolkitProvider>
							) : (
								<div className="no-data-container">No Data Found</div>
							)}
						</div>
					</div>
				) : (null)}
				{/*JSON.parse(readCookie('userData')).type !== 'partners' ? (*/}
				{/* <div className="top-fellows-container">
						<div className="title">{'Performance of SCKs'}</div>
						<div className="top-fellows-table-container">
							{this.state.topScks.length ? (
								<ToolkitProvider keyField="id" data={this.state.topScks} columns={this.state.topPerformingSCKColumns}>
									{props => (
										<div className="table-container">
											<BootstrapTable id="top-fellows-table" rowStyle={this.rowClassNameFormat} { ...props.baseProps } ref={(table) => { this.topScksTable = table; }} />
										</div>
									)}
								</ToolkitProvider>
							) : (
								<div className="no-data-container">No Data Found</div>
							)}
						</div>
					</div> */}
				{/*) : (null)*/}
				{JSON.parse(readCookie('userData')).type === 'le' && false ? (
					<div className="rewards-claim-container">
						<div className="title">{'Rewards & Claims'}</div>
						<div className="rewards-claim-table-container">
							{this.state.claimsData.length ? (
								<ToolkitProvider keyField="_id" data={this.state.claimsData} columns={this.state.claimsColumns}>
									{props => (
										<div className="table-container">
											<BootstrapTable id="rewards-claim-table" {...props.baseProps} ref={(table) => { this.claimsTable = table; }} />
										</div>
									)}
								</ToolkitProvider>
							) : (
								<div className="no-data-container">No Data Found</div>
							)}
						</div>
					</div>
				) : (null)}
			</div>
		);
	}
}
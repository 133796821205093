import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LinksTab from './SidebarTabs/LinksTab';

const readCookie = require('../cookie.js').readCookie;

export default class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			fullView: true
		};
	}

	componentDidMount() {
		let userData = JSON.parse(readCookie('userData'));
		this.setState({ data: userData });
	}

	render() {
		if (this.state.data) {
			return (
				<div className={this.props.sidebarMinimized ? (window.screen.width >= 500 && window.location.pathname === "/leads" ? "sidebar minimized" : "sidebar mob-minimized") : "sidebar"}>
					{window.screen.width >= 500 ? (
						<div className="user-section">
							<Row className="user-detail-row">
								<Col md={12} className="user-details">
									<Link to={'/'}>
										{readCookie("currentProjectDetails") && JSON.parse(readCookie("currentProjectDetails")).displayTitle  ? JSON.parse(readCookie("currentProjectDetails")).displayTitle : (window.location.hostname.includes('vcap.me') || window.location.hostname.includes('udyamita')) ? 'UDYAMITA' : 'SWATI'
									}</Link>
								</Col>
							</Row>
						</div>
					) : (null)}

					<div className="main-sidebar-sec">
						<Row>
							<LinksTab userData={this.props.userData} sidebarMinimized={this.props.sidebarMinimized} toggleSidebar={this.props.toggleSidebar}/>
						</Row>
					</div>

					<div
						// style={window.screen.width < 500 ? { marginTop: -50 } : null}
						className="logout-btn-container">
						<button className="btn logout-btn" onClick={this.props.logoutUser}>
							<i className="fa fa-sign-out mr5"></i>Logout
						</button>
					</div>

					<div className="powered-sec" title="Powered By LetsEndorse">
						<Row>
							<a href="https://www.letsendorse.com" target="_blank" rel="noopener noreferrer">
								{this.props.sidebarMinimized && window.location.pathname === "/leads" ? '' : 'Powered By'}
								<Image src="/images/le_black.png" width={this.props.sidebarMinimized && window.location.pathname === "/leads" ? "90" : "100"} />
							</a>
						</Row>
					</div>

					{window.location.pathname === "/leads" && window.screen.width >= 500 ? (
						<button className={this.props.sidebarMinimized ? "sidebar-retractor-btn open" : "sidebar-retractor-btn"} onClick={this.props.toggleSidebar}>
							<i className="fa fa-chevron-left" />
						</button>
					) : (null)}
				</div>
			);
		} else return null;
	}
}
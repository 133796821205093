import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import {
  ConfigProvider, DatePicker, Select, Switch, Button,
  // Spin,
  // Icon
} from "antd";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import React, { Component } from "react";
// import Swal from "sweetalert2";
import moment from "moment";

import enUS from "antd/es/locale/en_US";
import { Row, Col,  } from "react-bootstrap";

const readCookie = require("../../cookie.js").readCookie;
// const createCookie = require("../../cookie.js").createCookie;

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
// const antIcon = <Icon type="loading" style={{ fontSize: 750 }} spin />;
moment.locale("en");

const { ExportCSVButton } = CSVExport;

const durationOptions = ['Today', 'Yesterday', 'Last 7 days', 'Last 15 days', 'Last 30 days', 'Month till date', 'Custom Range'];
export default class LeadStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestones: [],
      milestone: "",
      page: 1,
      sizePerPage: 50,
      sizePerPageList: [50, 100, 150, 200],
      loading: true,
      leadsData: [],
      allStates: [],
      allClusters: [],
      allSources: [],
      clusters: [],
      phase: "",
      splitBySource: false,
      bankLoan: "",
      requiredState: "",
      clusterInfo: {},
      cluster: "",
      source: "",
      cloggingEnabled: false,
      fromDate: null, toDate: null,
      timeframeEnabled: false,
      duration: '',
      grandTotalRow: {},
    };
  }
  resetFilters = () => {
    this.setState({
      loading: true, cluster: "", source: "", requiredState: "", splitBySource: false,
      bankLoan: "", phase: "", clusterInfo: {}, cloggingEnabled: false, fromDate: null, toDate: null,
      duration: '', timeframeEnabled: false,
    });
    this.getDashboardData("");
  }
  changeMilestoneFilter = (e) => {
    this.setState({ milestone: e, loading: true });
    let { requiredState, splitBySource, clusterInfo, cluster, source, cloggingEnabled, phase } = this.state;

    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `milestone=${e}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    if (this.state.bankLoan !== "")
      query += `loanRequired=${this.state.bankLoan === 'Bank Loan required'}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  }
  changePhaseFilter = (e) => {
    this.setState({ phase: e, loading: true });
    let { requiredState, splitBySource, clusterInfo, cluster, source, cloggingEnabled, milestone } = this.state;

    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `enterpriseSetupStage=${e}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (this.state.bankLoan !== "")
      query += `loanRequired=${this.state.bankLoan === 'Bank Loan required'}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  };
  reportDownload = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/sidbi-reports/fortnigtly-report').then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Mission_Swavalamban_Report_${moment().format('DD-MM-YYYY')}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  changeBankLoanFilter = (e) => {
    this.setState({ bankLoan: e, loading: true });
    let { requiredState, splitBySource, clusterInfo, phase, cluster, source, cloggingEnabled, milestone } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `loanRequired=${e === 'Bank Loan required'}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  };
  changeSourceFilter = (e) => {
    this.setState({ source: e, loading: true });
    let { requiredState, splitBySource, clusterInfo, phase, cluster, bankLoan, cloggingEnabled, milestone } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `source=${e}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  };
  changeDuration = (e) => {
    let toChange = { duration: e, loading: true };
    let { requiredState, splitBySource, clusterInfo, phase, bankLoan, cluster, source, milestone } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${this.state.cloggingEnabled}&`;
    if (e === 'Custom Range') {
      toChange['toDate'] = null;
      toChange['fromDate'] = null;
      query += `fromDate=${null}&toDate=${null}&`;
    }
    else {
      let { fromDate = null, toDate = null } = this.getDateRange(e);
      query += `fromDate=${fromDate}&toDate=${toDate}&`;
    }
    this.setState(toChange);

    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}`;
    this.getDashboardData(query);
  }
  getDateRange(duration = this.state.duration) {
    let resultDuration = { fromDate: null, toDate: null };
    // durationOptions = ['Today', 'Yesterday', 'Last 7 days', 
    // 'Last 15 days', 'Last 30 days', 'Month till date', 'Custom Range'];

    switch (durationOptions.indexOf(duration)) {
      case 0: resultDuration = {
        fromDate: moment().format('DD-MM-YYYY'),
        toDate: moment().format('DD-MM-YYYY')
      };
        break;
      case 1: resultDuration = {
        fromDate: moment().subtract(1, 'days').format('DD-MM-YYYY'),
        toDate: moment().subtract(1, 'days').format('DD-MM-YYYY')
      };
        break;
      case 2: resultDuration = {
        fromDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
        toDate: moment().format('DD-MM-YYYY')
      };
        break;
      case 3: resultDuration = {
        fromDate: moment().subtract(15, 'days').format('DD-MM-YYYY'),
        toDate: moment().format('DD-MM-YYYY')
      };
        break;
      case 4: resultDuration = {
        fromDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
        toDate: moment().format('DD-MM-YYYY')
      };
        break;
      case 5: resultDuration = {
        fromDate: moment().startOf('month').format('DD-MM-YYYY'),
        toDate: moment().format('DD-MM-YYYY')
      };
        break;
      case 6: resultDuration = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate
      };
        break;
      default: resultDuration = { fromDate: null, toDate: null };
    }
    return resultDuration;
  }
  changeStateFilter = (e) => {
    let clusters = this.state.allClusters;
    let { splitBySource, phase, bankLoan, source, cloggingEnabled, milestone } = this.state;

    if (e !== '') {
      clusters = this.state.allClusters.filter(cluster => cluster.state.indexOf(e) > -1);
    }
    this.setState({ requiredState: e, clusters, cluster: '', clusterInfo: {}, loading: true });
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `requiredState=${e}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  };
  changeClusterFilter = (e) => {
    let clusterInfo = {};
    if (e !== '') {
      clusterInfo = this.state.allClusters.filter(cluster => cluster.fullName === e)[0];
    }
    this.setState({ cluster: e, clusterInfo, loading: true });
    let { requiredState, splitBySource, phase, bankLoan, source, cloggingEnabled, milestone } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&`;
    if (e !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  }
  toggleSplitBySource = (e) => {
    let { requiredState, clusterInfo, phase, bankLoan, cluster, source, cloggingEnabled, milestone } = this.state;
    this.setState({ splitBySource: e, loading: true });
    let query = `?splitBySource=${e}&cloggingEnabled=${cloggingEnabled}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}&`;
    let { fromDate = null, toDate = null } = this.getDateRange();
    query += `fromDate=${fromDate}&toDate=${toDate}&`;
    this.getDashboardData(query);
  }
  toggleClogging = (e) => {
    let { requiredState, splitBySource, clusterInfo, phase, bankLoan, cluster, source, milestone } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${e}&`;
    let stateToSet = { cloggingEnabled: e, loading: true };
    if (e) {
      stateToSet = {
        ...stateToSet, fromDate: null, toDate: null,
        timeframeEnabled: false,
        duration: ''
      };
      query += `fromDate=${null}&toDate=${null}&`;
    }
    else {
      let { fromDate = null, toDate = null } = this.getDateRange(this.state.duration);
      query += `fromDate=${fromDate}&toDate=${toDate}&`;
    }
    this.setState(stateToSet);
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (milestone !== "")
      query += `milestone=${milestone}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}`;
    this.getDashboardData(query);
  }

  toggleTimeFrame = (e) => {
    this.setState({ timeframeEnabled: e });
    if (!e) {
      this.resetFilters();
    }
  }
  amtFormatter = (cell) => {
    return (cell || 0).toLocaleString();
  }
  poFormatter = (cell,row) =>{
    if(cell){
      return <a target="_blank noopener noreferrer"  href={`/user-types/swavalamban-fellows/${row.poInfo._id}`}>{cell}</a>
    }
    else return null;
  }
  districtFormatter = (cell, row) => {

    if (cell.district && cell.source) return <span>{cell.district} - {cell.source}</span>
    else if (typeof cell === 'string') return < span >{cell}</span >;
    else return null;
  }
  stateFormatter = (cell, row) => {
    return <span>{cell[0]["state"]}</span>;
  };
  totalEnterprisesEnabledFormatter = (cell, row) => {
    
  }
  auditFormatter = (cell, row) => {
    if (row && row.id === 'Grand Total')
      return (
        <span>
          <span style={{ color: "blue" }}>
            {/* {row.unchangedStartedUpCount +
              row.unchangedScaledUpCount -
              row.auditedCount -
              row.auditFailedCount} */}
            {row.auditPendingCount}
          </span>{" "}
          : <span style={{ color: "red" }}> {row.auditFailedCount}</span> :{" "}
          <span style={{ color: "white" }}>{row.auditedCount}</span>{" "}
        </span>
      );

    if (row)
      return (
        <span>
          <span style={{ color: "blue" }}>
            {/* {row.unchangedStartedUpCount +
              row.unchangedScaledUpCount -
              row.auditedCount -
              row.auditFailedCount} */}
            {row.auditPendingCount}
          </span>{" "}
          : <span style={{ color: "red" }}> {row.auditFailedCount}</span> :{" "}
          <span style={{ color: "green" }}>{row.auditedCount}</span>{" "}
        </span>
      );
  };
  getDashboardData = (query = "", demographyQuery = "") => {
    let apiUrl = process.env.REACT_APP_API_URL; //+ '/api/v1/dashboard';
    fetch(apiUrl + "/api/v1/swati-leads/leadStats" + query, {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ leadsData: data.result || [], loading: false, grandTotalRow: data.grandTotalRow, milestones: data.milestones || [] });
        }
      });
  };




  rowClassNameFormat = (row, index) => {
    const style = { textAlign: "center" };
    const { scaledUpCount = 0, startedUpCount = 0 } = row;
    if (row.id === 'Grand Total') {
      style.backgroundColor = '#00cf00';
      style.fontWeight = 'bold';
      style.color = 'white';
    }

    else if ((scaledUpCount+startedUpCount)>=100) {
      style.backgroundColor = 'rgb(36, 215, 47)';
      style.color = 'rgb(66, 63, 15)';
    }

    return style;
  };

  getAllStates = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/location/states", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allStates: data.data.states });
        }
      });
  }
  getAllSources = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/lists/sources", {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allSources: data.sources });
        }
      });
  }
  getAllClusters = () => {
    // if (
    //   JSON.parse(readCookie("userData")).type === "le" &&
    //   (JSON.parse(readCookie("userData")).userType.name ===
    //     "Project Management Unit" ||
    //     JSON.parse(readCookie("userData")).userType.name === "Zonal Manager")
    // ) {
    fetch(
      process.env.REACT_APP_API_URL +
      "/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allClusters: data.users, clusters: data.users });
        }
      });
    // }
  }
  onDateChange = (value) => {
    let fromDate = null, toDate = null;
    if (value) {
      fromDate = moment(value[0]['_d']).format('DD-MM-YYYY');
      toDate = moment(value[1]['_d']).format('DD-MM-YYYY');
      // () => this.getDashboardData());
    }
    let { requiredState, splitBySource, clusterInfo, phase, bankLoan, cluster, source, cloggingEnabled } = this.state;
    let query = `?splitBySource=${splitBySource}&cloggingEnabled=${cloggingEnabled}&fromDate=${fromDate}&toDate=${toDate}&`;
    let stateToSet = { fromDate, toDate, loading: true };
    // query += ;

    this.setState(stateToSet);
    if (cluster !== '')
      query += `clusterDistricts=${clusterInfo['district']}&`;
    if (requiredState !== '')
      query += `requiredState=${requiredState}&`;
    if (source !== '')
      query += `source=${source}&`;
    if (bankLoan !== '')
      query += `loanRequired=${bankLoan === 'Bank Loan required'}&`;
    if (phase !== "")
      query += `enterpriseSetupStage=${phase}`;
    this.getDashboardData(query);
  }
  componentDidMount() {
    this.getDashboardData();
    this.getAllStates();
    this.getAllClusters();
    this.getAllSources();
  }
  render() {
    let columns = this.state.cloggingEnabled ? [
      {
        text: "District",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "district",
        headerStyle: () => {
          return {
            // width: "70px", textAlign: "center", wordWrap: "break-word",
            width: "120px", textAlign: "center", wordWrap: "break-word",
            background: "#20c997"
          };
        },
        footer: (this.state.grandTotalRow.district || "").toString()
        // formatter: this.districtFormatter,
      },
      {
        text: "Source",
        sort: true,
        hidden: !this.state.splitBySource,
        csvExport: true,
        export: this.state.splitBySource,
        searchable: true,
        dataField: "source",
        footer: (this.state.grandTotalRow.source || "").toString(),
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#20c997"
          };
        },
        // formatter: this.districtFormatter,
      },
      {
        text: "State",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "state",
        // formatter: this.stateFormatter,
        footer: (this.state.grandTotalRow.state || "").toString(),
        headerStyle: () => {
          return {
            // width: "70px", textAlign: "center", wordWrap: "break-word",
            width: "120px", textAlign: "center", wordWrap: "break-word",
            background: "#20c997"
          };
        },
      },
      {
        text: "Program Officer",
        sort: true,
        hidden: JSON.parse(readCookie('userData')).userType.name !== 'Project Management Unit',
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "poInfo.fullName",
        formatter: this.poFormatter,
        footer: () => {
          return '-';
        },
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            // width: "70px", textAlign: "center", wordWrap: "break-word",
            width: "120px", textAlign: "center", wordWrap: "break-word",
            // background: "#f6c1b9"
          };
        },
      },
      {
        text: "Pending Profiling",
        sort: true,
        hidden: true,
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "pendingProfiling",
        footer: (this.state.grandTotalRow.pendingProfiling || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f9d6d1"
          };
        },
      },
      {
        text: "Incomplete Profiles",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "incompleteProfilesCount",
        footer: (this.state.grandTotalRow.incompleteProfilesCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f9d6d1"
          };
        },
      },
      {
        text: "Disqualified in Profiling Stage",
        sort: true,
        hidden: true,
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "disqualifiedProfilesCount",
        footer: (this.state.grandTotalRow.disqualifiedProfilesCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f9d6d1"
          };
        },
      },
      {
        text: "In Profiling Stage",
        sort: true,
        hidden: true,
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "profilesCompletedCount",
        footer: (this.state.grandTotalRow.profilesCompletedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f9d6d1"
          };
        },
      },
      {
        text: "Profiling Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "profilesCompletedCount",
        footer: (this.state.grandTotalRow.profilesCompletedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f9d6d1"
          };
        },
      },
      {
        text: "Due Diligence (Not yet Started)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "dueDiligenceNotStartedCount",
        footer: (this.state.grandTotalRow.dueDiligenceNotStartedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "Due Diligence (On Hold)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "dueDiligenceOnHoldCount",
        footer: (this.state.grandTotalRow.dueDiligenceOnHoldCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "Due Diligence (In Progress)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "dueDiligenceInProgressCount",
        footer: (this.state.grandTotalRow.dueDiligenceInProgressCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "Due Diligence (Disqualified)",
        sort: true,
        hidden: true,
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "dueDiligenceDisqualifiedCount",
        footer: (this.state.grandTotalRow.dueDiligenceDisqualifiedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "In Due Diligence Stage",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "dueDiligenceStageCount",
        footer: (this.state.grandTotalRow.dueDiligenceStageCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "Due Diligence Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "dueDiligenceCompleted",
        footer: (this.state.grandTotalRow.dueDiligenceCompleted || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f8d959"
          };
        },
      },
      {
        text: "B-Plan (Not yet Started)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "bplanNotStartedCount",
        footer: (this.state.grandTotalRow.bplanNotStartedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "B-Plan (On Hold)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "bplanOnHoldCount",
        footer: (this.state.grandTotalRow.bplanOnHoldCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "B-Plan (In Progress)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "bplanInProgressCount",
        footer: (this.state.grandTotalRow.bplanInProgressCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "B-Plan (Disqualified)",
        sort: true,
        hidden: true,
        csvExport: false,
        export: false,
        searchable: true,
        dataField: "bplanDisqualifiedCount",
        footer: (this.state.grandTotalRow.bplanDisqualifiedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "B-Plan (Completed)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "bplanCompletedCount",
        footer: (this.state.grandTotalRow.bplanCompletedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "In B-Plan Stage",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "bplanStageCount",
        footer: (this.state.grandTotalRow.bplanStageCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#c8d866"
          };
        },
      },
      {
        text: "Loan Application Under Preparation",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationPreparingCount",
        // formatter: this.stateFormatter,
        footer: (this.state.grandTotalRow.loanApplicationPreparingCount || 0).toLocaleString(),
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "Loan Applications Submitted",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationSubmittedCount",
        footer: (this.state.grandTotalRow.loanApplicationSubmittedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "Loans Sanctioned",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationSanctionedCount",
        footer: (this.state.grandTotalRow.loanApplicationSanctionedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "Loan Applications Rejected",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationRejectedCount",
        footer: (this.state.grandTotalRow.loanApplicationRejectedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "In Loan Application Stage",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationStageCount",
        footer: (this.state.grandTotalRow.loanApplicationStageCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "Loan Applications Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanApplicationCompletedCount",
        footer: (this.state.grandTotalRow.loanApplicationCompletedCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#60d5e4"
          };
        },
      },
      {
        text: "Ready to Start/Scale-up",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "enterpriseCapitalReadyCount",
        footer: (this.state.grandTotalRow.enterpriseCapitalReadyCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f6c1b9"
          };
        },
      },
      {
        text: "In Enterprise Start/Scale-up state",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "enterpriseStageCount",
        footer: (this.state.grandTotalRow.enterpriseStageCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f6c1b9"
          };
        },
      },
      {
        text: "Start/Scale-up Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "enterpriseCompletedStageCount",
        footer: (this.state.grandTotalRow.enterpriseCompletedStageCount || 0).toLocaleString(),
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "90px", textAlign: "center", wordWrap: "break-word",
            background: "#f6c1b9"
          };
        },
      },
      {
        text: "Loan sum (INR) of successful enterprises",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanAmtSum",
        formatter: this.amtFormatter,
        footer: () => {
          return (this.state.grandTotalRow.loanAmtSum || 0).toLocaleString() +
            '\n(Avg:' + (this.state.grandTotalRow.loanAmtSum ? this.state.grandTotalRow.loanAmtSum / (this.state.grandTotalRow.loanCandidateSetUpCount || 1) : 0).toLocaleString() + ')'
        },
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "90px", textAlign: "center", wordWrap: "break-word",
            background: "#f6c1b9"
          };
        },
      },
      {
        text: "Self-investment sum (INR) of successful enterprises",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "selfInvestmentSum",
        formatter: this.amtFormatter,
        footer: () => {
          return (this.state.grandTotalRow.selfInvestmentSum || 0).toLocaleString() +
            '\n(Avg:' + (this.state.grandTotalRow.selfInvestmentSum ? this.state.grandTotalRow.selfInvestmentSum / (this.state.grandTotalRow.enterpriseCompletedStageCount || 1) : 0).toLocaleString() + ')'
        },
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "70px", textAlign: "center", wordWrap: "break-word",
            background: "#f6c1b9"
          };
        },
      },
    ] : [
      {
        text: "District",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "district",
        headerStyle: () => {
          return { width: "120px", textAlign: "center" };
          // return { width: "70px", textAlign: "center" };
        },
        footer: (this.state.grandTotalRow.district || 0).toLocaleString()

        // formatter: this.districtFormatter,
      },
      {
        text: "Source",
        sort: true,
        hidden: !this.state.splitBySource,
        csvExport: this.state.splitBySource,
        export: false,
        searchable: true,
        dataField: "source",
        headerStyle: () => {
          return { width: "70px", textAlign: "center" };
        },
        footer: (this.state.grandTotalRow.source || 0).toLocaleString()
        // formatter: this.districtFormatter,
      },
      {
        text: "State",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "state",
        // formatter: this.stateFormatter,
        footer: (this.state.grandTotalRow.state || "").toString(),
        headerStyle: () => {
          // return { width: "70px", textAlign: "center" };
          return { width: "120px", textAlign: "center" };
        },
        }, {
          text: "Program Officer",
          sort: true,
          hidden: JSON.parse(readCookie('userData')).userType.name !== 'Project Management Unit',
          csvExport: false,
          export: false,
          searchable: true,
          dataField: "poInfo.fullName",
          formatter: this.poFormatter,
          footer: () => {
            return '-';
          },
          // formatter: this.stateFormatter,
          headerStyle: () => {
            return {
              // width: "70px", textAlign: "center", wordWrap: "break-word",
              width: "120px", textAlign: "center", wordWrap: "break-word",
              // background: "#f6c1b9"
            };
          },
        },
      {
        text: "Profile Creation Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: true,
        dataField: "totalCount",
        searchable: true,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
        footer: (this.state.grandTotalRow.totalCount || 0).toLocaleString(),
      },
      {
        text: "Due Diligence Completed",
        sort: true,
        hidden: false,
        csvExport: true,
        export: true,
        dataField: "dueDiligenceCount",
        footer: (this.state.grandTotalRow.dueDiligenceCount || 0).toLocaleString(),
        searchable: true,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Business Plans Created",
        sort: true,
        hidden: false,
        csvExport: true,
        export: true,
        dataField: "bplanCount",
        searchable: true,
        footer: (this.state.grandTotalRow.bplanCount || 0).toLocaleString(),
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Loan Applications (in Submitted State)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        footer: (this.state.grandTotalRow.loanApplicationSubmittedCount || 0).toLocaleString(),
        dataField: "loanApplicationSubmittedCount",
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Loan Application (in Sanctioned State)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: true,
        dataField: "loanApplicationSanctionedCount",
        searchable: true,
        footer: (this.state.grandTotalRow.loanApplicationSanctionedCount || 0).toLocaleString(),
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Loan Applications (Disbursed)",
        sort: true,
        hidden: false,
        csvExport: true,
        export: true,
        dataField: "loanApplicationDisbursedCount",
        footer: (this.state.grandTotalRow.loanApplicationDisbursedCount || 0).toLocaleString(),
        searchable: true,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
        textAlign: "center",
      },
      {
        text: "Enterprises with credit fulfillment but yet to start/scale-up",
        sort: true,
        hidden: this.state.duration === '' ? false : true,
        csvExport: this.state.duration !== '' ? false : true,
        export: false,
        searchable: true,
        dataField: "enterpriseInProgressCount",
        footer: (this.state.grandTotalRow.enterpriseInProgressCount || 0).toLocaleString(),
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Enterprises Started",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "startedUpCount",
        footer: (this.state.grandTotalRow.startedUpCount || 0).toLocaleString(),
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        text: "Enterprises Scaled",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "scaledUpCount",
        footer: (this.state.grandTotalRow.scaledUpCount || 0).toLocaleString(),
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
        },
        {
          text: "Total Enterprises Enabled",
          sort: true,
          hidden: false,
          csvExport: false,
          export: false,
          searchable: true,
          dataField: "totalEnterprisesEnabled",
          // formatter: this.totalEnterprisesEnabledFormatter,
          footer: (this.state.grandTotalRow.totalEnterprisesEnabled || 0).toLocaleString(),

          // formatter: this.stateFormatter,
          headerStyle: () => {
            return {
              width: "70px", textAlign: "center", wordWrap: "break-word",
              // background: "#f6c1b9"
            };
          },
        },
      {
        text: "Audit Status (Pending : Failed : Successful)",
        sort: false,
        hidden: (this.state.duration === '' ? false : true) || (JSON.parse(readCookie("userData")).type !== "le"),
        csvExport: !((this.state.duration === '' ? false : true) || (JSON.parse(readCookie("userData")).type !== "le")),
        export: false,
        searchable: true,
        dataField: "auditedCount",
        formatter: this.auditFormatter,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
        // footer: ("-").toString(),
        footer: `${this.state.grandTotalRow.auditPendingCount} : ${this.state.grandTotalRow.auditFailedCount} : ${this.state.grandTotalRow.auditedCount}`

      },
      {
        text: "Loan sum (INR) of successful enterprises",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "loanAmtSum",
        formatter: this.amtFormatter,
        footer: () => {
          return (this.state.grandTotalRow.loanAmtSum || 0).toLocaleString() +
            '\n(Avg:' + (this.state.grandTotalRow.loanAmtSum ? this.state.grandTotalRow.loanAmtSum / (this.state.grandTotalRow.loanCandidateSetUpCount || 1) : 0).toLocaleString() + ')'
        },
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "90px", textAlign: "center", wordWrap: "break-word",
            // background: "#f6c1b9"
          };
        },
      },
      {
        text: "Self-investment sum (INR) of successful enterprises",
        sort: true,
        hidden: false,
        csvExport: true,
        export: false,
        searchable: true,
        dataField: "selfInvestmentSum",
        formatter: this.amtFormatter,
        footer: () => {
          return (this.state.grandTotalRow.selfInvestmentSum || 0).toLocaleString() +
            '\n(Avg:' + (this.state.grandTotalRow.selfInvestmentSum ? this.state.grandTotalRow.selfInvestmentSum / (this.state.grandTotalRow.sort_order || 1) : 0).toLocaleString() + ')'
        },
        // formatter: this.stateFormatter,
        headerStyle: () => {
          return {
            width: "90px", textAlign: "center", wordWrap: "break-word",
            // background: "#f6c1b9"
          };
        },
      },   
      
    ];
    return (
      <div className="page-container leads-stats-container">
        {this.state.loading ? (
          <div className="col-md-12 loader-container" style={{ top: "50%" }}>
            {/* <div className="loader-msg">Please wait while the data loads..!</div> */}
            <i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              style={{ fontSize: "60px", color: "#EF5A20" }}
            ></i>
            {/* <Spin indicator={antIcon} /> */}
          </div>
        ) : (
          <React.Fragment>
            <Row>
              <Col md={9}>
                <h3>LEAD STATS</h3>
              </Col>
              {JSON.parse(readCookie("userData")).type === "le" ?
                <Col md={3}>
                  <label className="control-label">
                    <Switch defaultChecked={this.state.cloggingEnabled}
                      checkedChildren="Enabled" unCheckedChildren="Disabled"
                      onChange={this.toggleClogging} />
                    &nbsp; Show Funnel Clogs
                  </label>
                </Col> : null}
            </Row>
            {JSON.parse(readCookie("userData")).type === "le" ? <>
              {/* <hr /> */}
              {!this.state.cloggingEnabled ? <Row>
                <Col md={3}>
                  <label className="control-label">
                    <Switch defaultChecked={this.state.timeframeEnabled}
                      checkedChildren="Enabled" unCheckedChildren="Disabled"
                      onChange={this.toggleTimeFrame}
                    />
                    &nbsp; Show Timeframe
                  </label>
                </Col>
                {this.state.timeframeEnabled ?
                  <React.Fragment>
                    <Col md={3}>
                      <div className="filter form-group">
                        <label className="control-label">{'Duration: '}</label>
                        <Select showSearch style={{ width: '150px' }} value={this.state.duration} onChange={this.changeDuration} filterOption={(input, option) => this.searchFilter(input, option)}>
                          <Option value="">None Selected</Option>
                          {durationOptions
                            .map((x, index) => {
                              return <Option value={x} key={index}>{x}</Option>
                            })}
                        </Select>
                      </div>
                    </Col>
                    {this.state.duration === 'Custom Range' && <Col md={3}>
                      <div className="filter form-group">
                        <label className="control-label">{'From - To date: '}</label>
                        <ConfigProvider locale={enUS}>
                          <RangePicker locale={enUS}
                            style={{ width: "200px" }}
                            format="DD-MM-YYYY"
                            value={[this.state.fromDate ? moment(this.state.fromDate, 'DD-MM-YYYY') : null,
                            this.state.toDate ? moment(this.state.toDate, 'DD-MM-YYYY') : null]}
                            type="date" onChange={this.onDateChange} />
                        </ConfigProvider>
                      </div>
                    </Col>}
                  </React.Fragment>
                  : null}
                <Col md={3}>

                </Col>
              </Row> : null}
              <Row>
                  <Col md={10}>
                    <>
                  <Row>
                    <Col md={2} sm={2} lg={2}>
                      <label className="control-label">Phase</label>
                      <Select
                        showSearch
                        style={{ width: "165px" }}
                        value={this.state.phase}
                        onChange={this.changePhaseFilter}
                      >
                        <Option value="">All</Option>
                        <OptGroup label="Phase">
                          <Option key="Scaled Up" value="Scaled up">
                            Scaled up
                          </Option>
                          <Option key="Started Up" value="Started up">
                            Started up
                          </Option>
                        </OptGroup>
                      </Select>
                        </Col>
                        {(JSON.parse(readCookie('userData')).type === 'sidbi' || JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit') ? <Col md={2}>
                          <label className="control-label">SIDBI Payment Milestone</label>
                          <Select
                            showSearch
                            style={{ width: "200px" }}
                            value={this.state.milestone}
                            onChange={this.changeMilestoneFilter}
                          >
                            <Option value="">All</Option>
                            <OptGroup label="Milestone">
                              <Option value="No milestone assigned">No milestone assigned</Option>
                              {
                                this.state.milestones.map(milestone => <Option key={milestone} value={milestone}>{milestone}</Option>)
                              }
                            </OptGroup>
                          </Select>
                        </Col>:null}
                    <Col md={2} sm={2} lg={2}>
                      <label className="control-label">Finance Type</label>
                      <Select
                        showSearch
                        style={{ width: "165px" }}
                        value={this.state.bankLoan}
                        onChange={this.changeBankLoanFilter}
                      >
                        <Option value="">All</Option>
                        <OptGroup label="Finance Type">
                          <Option value="Bank Loan required">
                            {/* Bank Loan required
                       */}
                            Loan/ Credit linked
                          </Option>
                          <Option value="100% self-financed">
                            100% self-financed
                          </Option>
                        </OptGroup>
                      </Select>
                    </Col>
                    <Col md={2} sm={2} lg={2}>
                      <label className="control-label">State</label>
                      <Select
                        showSearch
                        style={{ width: "165px" }}
                        value={this.state.requiredState}
                        onChange={this.changeStateFilter}
                      >
                        <Option value="">All</Option>
                        <OptGroup label="State">
                          {
                            this.state.allStates.map(state => <Option value={state.name} key={state._id}>{state.name}</Option>)
                          }
                        </OptGroup>
                      </Select>
                    </Col>
                    <Col md={2} sm={2} lg={2}>
                      <label className="control-label">Cluster</label>
                      <Select
                        showSearch
                        style={{ width: "165px" }}
                        value={this.state.cluster}
                        onChange={this.changeClusterFilter}
                      >
                        <Option value="">All</Option>
                        <OptGroup label="Cluster">
                          {
                            this.state.clusters.map(cluster => <Option value={cluster.fullName} key={cluster.id}>{cluster.fullName}</Option>)
                          }
                        </OptGroup>
                      </Select>
                    </Col>
                    <Col md={2} sm={2} lg={2}>
                      <label className="control-label">Source</label>
                      <Select
                        showSearch
                        style={{ width: "165px" }}
                        value={this.state.source}
                        onChange={this.changeSourceFilter}
                      >
                        <Option value="">All</Option>
                        <OptGroup label="Sources">
                          {
                            this.state.allSources.map(source => <Option value={source} key={source}>{source}</Option>)
                          }
                        </OptGroup>
                      </Select>
                    </Col>
                    
                      </Row>
                    </>
                  </Col><Col md={1} sm={1} lg={1}>
                    <Row className="justify-content-md-center">
                      <label
                        style={{ marginTop: 10, marginLeft: 12.5, minWidth: 100 }}
                        htmlFor="split-by-source"
                      ><Switch
                          size="small"
                          onClick={this.toggleSplitBySource}
                          checkedChildren="Y" unCheckedChildren="N"
                          defaultChecked={this.state.splitBySource} />
                        &nbsp;
                        Split By Source
                      </label>


                    </Row>
                    <Row className="justify-content-md-center">
                      <Button onClick={this.resetFilters} type="danger">Reset</Button>
                    </Row>
                  </Col>
                <Col md={1}>
                  <ExportCSVButton onClick={this.reportDownload}>Generate W-o-W Report</ExportCSVButton>
                </Col>
              </Row>
            </> :
              <>
                <hr />
                {!this.state.cloggingEnabled ? <Row>
                  <Col md={3}>
                    <label className="control-label">
                      <Switch defaultChecked={this.state.timeframeEnabled}
                        checkedChildren="Enabled" unCheckedChildren="Disabled"
                        onChange={this.toggleTimeFrame}
                      />
                      &nbsp; Show Timeframe
                    </label>
                  </Col>
                  {this.state.timeframeEnabled ?
                    <React.Fragment>
                      <Col md={3}>
                        <div className="filter form-group">
                          <label className="control-label">{'Duration: '}</label>
                          <Select showSearch style={{ width: '150px' }} value={this.state.duration} onChange={this.changeDuration} filterOption={(input, option) => this.searchFilter(input, option)}>
                            <Option value="">None Selected</Option>
                            {durationOptions
                              .map((x, index) => {
                                return <Option value={x} key={index}>{x}</Option>
                              })}
                          </Select>
                        </div>
                      </Col>
                      {this.state.duration === 'Custom Range' && <Col md={3}>
                        <div className="filter form-group">
                          <label className="control-label">{'From - To date: '}</label>
                          <ConfigProvider locale={enUS}>
                            <RangePicker locale={enUS}
                              style={{ width: "200px" }}
                              format="DD-MM-YYYY"
                              value={[this.state.fromDate ? moment(this.state.fromDate, 'DD-MM-YYYY') : null,
                              this.state.toDate ? moment(this.state.toDate, 'DD-MM-YYYY') : null]}
                              type="date" onChange={this.onDateChange} />
                          </ConfigProvider>
                        </div>
                      </Col>}
                    </React.Fragment>
                    : null}
                  <Col md={3}>

                  </Col>
                </Row> : null}
                  <Row>
                    <Col md={10}>
                      <Row>
                  <Col md={3}>
                    <label className="control-label">Phase</label>
                    <Select
                      showSearch
                      style={{ width: "200px" }}
                      value={this.state.phase}
                      onChange={this.changePhaseFilter}
                    >
                      <Option value="">All</Option>
                      <OptGroup label="Phase">
                        <Option key="Scaled Up" value="Scaled up">
                          Scaled up
                        </Option>
                        <Option key="Started Up" value="Started up">
                          Started up
                        </Option>
                      </OptGroup>
                    </Select>
                        </Col>
                     {(JSON.parse(readCookie('userData')).type === 'sidbi' || JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit') ?   <Col md={3}>
                          <label className="control-label">SIDBI Payment Milestone</label>
                          <Select
                            showSearch
                            style={{ width: "200px" }}
                            value={this.state.milestone}
                            onChange={this.changeMilestoneFilter}
                          >
                            <Option value="">All</Option>
                            <OptGroup label="Milestone">
                            <Option value="No milestone assigned">No milestone assigned</Option>
                              {
                                this.state.milestones.map(milestone => <Option key={milestone} value={milestone}>{ milestone}</Option>)
                              }
                            </OptGroup>
                          </Select>
                        </Col>:null}
                  <Col md={3}>
                    <label className="control-label">Finance Type</label>
                    <Select
                      showSearch
                      style={{ width: "200px" }}
                      value={this.state.bankLoan}
                      onChange={this.changeBankLoanFilter}
                    >
                      <Option value="">All</Option>
                      <OptGroup label="Finance Type">
                        <Option value="Bank Loan required">
                          {/* Bank Loan required
                       */}
                          Loan/ Credit linked
                        </Option>
                        <Option value="100% self-financed">
                          100% self-financed
                        </Option>
                      </OptGroup>
                    </Select>
                  </Col>
                  <Col md={3}>
                    <label className="control-label">State</label>
                    <Select
                      showSearch
                      style={{ width: "200px" }}
                      value={this.state.requiredState}
                      onChange={this.changeStateFilter}
                    >
                      <Option value="">All</Option>
                      <OptGroup label="State">
                        {
                          this.state.allStates.map(state => <Option value={state.name} key={state._id}>{state.name}</Option>)
                        }
                      </OptGroup>
                    </Select>
                  </Col>
                  <Col md={3} className="align-self-center">
                    <Button onClick={this.resetFilters} type="danger">Reset</Button>
                      </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <ExportCSVButton onClick={this.reportDownload}>Generate W-o-W Report</ExportCSVButton>
                    </Col>
                  {/* <Col md={2}>
                    {/* <Row className="justify-content-md-center">
                        {JSON.parse(readCookie("userData")).type === "le"?
                          <label
                        style={{ marginTop: 10, marginLeft: 12.5, minWidth: 100 }}
                        htmlFor="split-by-source"
                        >
                          <Switch
                          size="small"
                          onClick={this.toggleSplitBySource}
                          checkedChildren="Y" unCheckedChildren="N"
                          defaultChecked={this.state.splitBySource} />
                        &nbsp;
                        Split By Source
                      </label>: null}


                    </Row> */}
                  {/* <Row className="justify-content-md-center"> */}
                  {/* </Row> */}
                  {/* </Col> */}
                </Row>
              </>}
              {/* <br /> */}
              <Col md={12} className="lead-stats-table">

              {this.state.leadsData.length ? (
                  <Row className="mt10">
                    {/* <PaginationProvider
                      pagination={paginationFactory({
                        custom: true,
                        // page: this.state.page,
                        // sizePerPage: this.state.sizePerPage,
                        // totalSize: this.state.leadsData.length,
                        // sizePerPageList: this.state.sizePerPageList
                      })} keyField="_id" data={this.state.leadsData} columns={columns}>
                  {({ paginationProps, paginationTableProps })=>( */}
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.leadsData}
                      columns={columns}
                      exportCSV={{
                        fileName: `LE Lead_Stats_${moment().format('DD-MM-YYYY_hh-mm-ss_a')}.csv`
                      }}
                    >
                      {(props) => (
                        <React.Fragment>

                          {/* <Col md={10}>
                            <div className="title">{"District-wise Lead Stats"}</div>
                          </Col><Col md={2}><ExportCSVButton {...props.csvProps}>Export as CSV</ExportCSVButton></Col> */}


                            <div className="table-responsive">
                            {/* <hr /> */}
                            {/* <div {className = "table-container"}> */}
                              <div className="Rules-Container" style={{fontSize:"9px !important"}}><BootstrapTable
                                // classes="table table-fixed"
                              id="leads-stats-table"
                              rowStyle={this.rowClassNameFormat}
                              {...props.baseProps}
                              headerClasses="bootstrap-leads-table-header-class sticky tableHeader fixTableHead"
                              footerClasses="bootstrap-leads-table-footer-class"
                              ref={(table) => {
                                this.table = table;
                              }}
                            // remote={{ pagination: true }}
                            // onTableChange={this.onTableChange}

                            // {...paginationTableProps}
                            />
                              </div>

                            {/* <SizePerPageDropdownStandalone {...paginationProps} />
                                <PaginationListStandalone {...paginationProps} /> */}

                          </div>
                          </React.Fragment>)
                        }

                    </ToolkitProvider>
                    {/* )} */}
                    {/* </PaginationProvider> */}
                  </Row>
              ) : (
                <div className="no-data-container">No Data Found</div>
              )}</Col>
          </React.Fragment>
        )}
      </div>
    );
  }
}

import React ,{createContext,useReducer} from 'react';
import ProjectReducer from './ProjectReducer';

// const readCookie = require('../cookie.js').readCookie;


const INITIAL_PROJECT_OBJ = {project:''};

const ProjectContext = createContext(INITIAL_PROJECT_OBJ);

export const ProjectContextProvider = ({children})=>{
    const [state,dispatch] = useReducer(ProjectReducer,INITIAL_PROJECT_OBJ);
    const projectsHandler =(projectName)=>{
    //   let project_name =  readCookie(JSON.stringify('project_name'));
        dispatch({
            type: 'PROJECT_NAME',
            payload:projectName
        })
    } 

    return (
        <>
        <ProjectContext.Provider 
        value={{
            project :state.project,
            projectsHandler
        }}
        >
        {children}
        </ProjectContext.Provider> 
        </>  
        )
        
        }


export default ProjectContext;
import React from 'react';

const Label = (props) => {
  return (

    <label className="control-label">
      {props.name}
      <span className="mandatory">{"*"}</span>
    </label>

  );
};

export default Label;
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	//ConfigProvider, DatePicker, 
	Select,
	//Input, 
	Switch
} from 'antd';
import ToolkitProvider
	// , { Search } 
	from 'react-bootstrap-table2-toolkit';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
// import TimeAgo from 'react-timeago';
import Swal from 'sweetalert2';
import moment from 'moment';

import ViewSchemeModal from './ViewSchemeModal';
// import {allBanks} from '../../../commonConfig';
const readCookie = require('../cookie.js').readCookie;

const { Option, OptGroup } = Select;

export default class Schemes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allStates: [],
			allSchemes: [],
			viewScheme: null,
			allBusinessIdeas: [],
			selectedScheme: null,
			documents: ["Application of Intent (Covering letter)", "Filled Application form of the scheme", "Identity Proof (Self-attested Aadhar Card)", "Proof of residence (Self-attested)", "Recent Photographs (2 copies)", "Quotations of items/machinery being purchased, Details of suppliers and items being purchased", "Proof of category (SC/ST/OBC, if applicable)", "Proof of qualification", "Skill Training Completion Certificate", "EDP Completion Certificate", "Project Report or Business Plan (with projected Income and Expenses)", "Proof of business address (Rent/Lease deed/Ownership papers)", "Proof of business (Registration certificate, Udyog Aadhar, Relevant licenses secured)", "GST Certificate", "Statement of accounts (last 6 months)", "Statement of accounts (last 12 months)", "Last 2 years' balance sheet audited by a CA", "Income tax return", "Sales tax return", "Memorandum and Articles of association of the company", "Asset & Liability statements from the borrower including Directors & Partners", "Proof of ownership and valuation of land/property used as a collateral", "Fixed deposit of 50-90% value of the bank loan", "Bank consent letter", "Population Certificate", "DPR", "An active Current bank account", "PAN Card", "Guarantor's consent"],
			allBanks: [],
			// allBanks: allBanks,
			//["//Allahabad Bank", "Andhra Bank", "Andhra Pradesh Grameena Vikas Bank", "Aryavart Bank", "Axis Bank", "Bandhan Bank", "Bank of Bahrain and Kuwait", "Bank of Baroda", "Bank of India", "Bank of Maharashtra", "Baroda Uttar Pradesh Gramin Bank", "Canara Bank", "Central Bank of India", "City Union Bank", "Corporation Bank", "Dakshin Bihar Gramin Bank", "Dena Bank", "Deutsche Bank", "Development Credit Bank", "Dhanlaxmi Bank", "Federal Bank", "Fino Payments Bank", "Gramin Bank", "HDFC Bank", "ICICI Bank", "IDBI Bank", "Indian Bank", "Indian Post Payments Bank", "Indian Overseas Bank", "IndusInd Bank", "ING Vysya Bank", "Jammu and Kashmir Bank", "Karnataka Bank Ltd", "Karur Vysya Bank", "Kashi Gomti Samyut Gramin Bank", "Kotak Bank", "Laxmi Vilas Bank", "Oriental Bank of Commerce", "Prathama Bank", "Punjab National Bank - Corporate Banking", "Punjab National Bank - Retail Banking", "Punjab &amp; Sind Bank", "RBL Bank", "Shamrao Vitthal Co-operative Bank", "South Indian Bank", "State Bank of Bikaner &amp; Jaipur", "State Bank of Hyderabad", "State Bank of India", "State Bank of Mysore", "State Bank of Patiala", "State Bank of Travancore", "Syndicate Bank", "Tamilnad Mercantile Bank Ltd.", "UCO Bank", "Union Bank of India", "United Bank of India", "Uttar Bihar Gramin Bank", "Vijaya Bank", "Yes Bank Ltd"],
			schemeColumns: [
				{ text: '', sort: false, hidden: true, export: true, searchable: false, dataField: '_id' },
				{ text: 'Name', sort: true, hidden: false, export: true, dataField: 'name', searchable: true, headerStyle: () => { return { width: '200px' } }, formatter: this.formatColumn.bind(this, 'name') },
				{ text: 'Documents Required', sort: true, hidden: false, export: true, dataField: 'documentsRequired', searchable: true, formatter: this.formatColumn.bind(this, 'documentsRequired') },
				{ text: 'Created By', sort: true, hidden: false, export: true, dataField: 'createdBy', searchable: true, headerStyle: () => { return { width: '120px' } }, formatter: this.formatColumn.bind(this, 'createdBy') },
				{ text: 'Created At', sort: true, hidden: false, export: true, dataField: 'createdAt', searchable: true, headerStyle: () => { return { width: '100px' } }, formatter: this.formatColumn.bind(this, 'createdAt') },
				{ text: 'Updated By', sort: true, hidden: false, export: true, dataField: 'updatedBy', searchable: true, headerStyle: () => { return { width: '120px' } }, formatter: this.formatColumn.bind(this, 'updatedBy') },
				{ text: 'Updated At', sort: true, hidden: false, export: true, dataField: 'updatedAt', searchable: true, headerStyle: () => { return { width: '100px' } }, formatter: this.formatColumn.bind(this, 'updatedAt') },
				{ text: 'Edit/Delete', sort: false, hidden: (this.props.userData.type === 'le' && ["Swavalamban Fellows"].includes(this.props.userData.userType.name)), export: false, searchable: false, align: 'center', headerStyle: () => { return { width: '85px', textAlign: 'center' } }, dataField: 'edit', formatter: this.formatColumn.bind(this, 'editDelete') }
			]
		}
	}

	componentDidMount() {
		this.refreshSchemes();
		this.getAllBanks();
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allStates: data.data.states });
				}
			});

		fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/lead-business-ideas', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allBusinessIdeas: data.ideas });
				}
			});
	}
	getAllBanks = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/banks', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allBanks: data.allBanks });
				}
			});
	}
	refreshSchemes = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/schemes', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allSchemes: data.schemes });
				}
			});
	}

	formatColumn = (type, cell, row, index) => {
		if (type === 'createdBy' || type === 'updatedBy') {
			if (cell && cell._id && cell.fullName) {
				return (
					<a href={"/user-types/project-management-unit/" + cell._id}>{cell.fullName}</a>
				)
			} else return 'N/A';
		} else if (type === 'name') {
			return (
				<a onClick={this.viewScheme.bind(this, row)}>{cell}</a>
			)
		} else if (type === 'documentsRequired') {
			return (
				<span className="docs-reqd" title={cell.join(', ')}>{cell.join(', ')}</span>
			)
		} else if (type === 'createdAt' || type === 'updatedAt') {
			if (cell && moment(cell).isValid()) return moment(cell).format('DD/MM/YYYY hh:mm A');
			else if (cell) return cell;
			else return 'N/A';
		} else if (type === 'editDelete') {
			return (
				<div className="edit-delete-buttons">
					<button className="btn edit-button" onClick={this.editScheme.bind(this, row)}>
						<i className="fa fa-pencil"></i>
						Edit
					</button>
					<button className="btn delete-button" onClick={this.deleteScheme.bind(this, row._id)}>
						<i className="fa fa-trash"></i>
						Delete
					</button>
				</div>
			)
		}
	}

	updateEditingScheme = (type, value) => {
		let selectedScheme = this.state.selectedScheme;

		if (value.target) value = value.target.value;
		if (Array.isArray(value) && value.indexOf("Any") > -1) selectedScheme[type] = ["Any"];
		else if (Array.isArray(value) && value.indexOf("selectAll") > -1) {
			let allBusinessIdeas = this.state.allBusinessIdeas.map((idea) => { if (idea.name !== 'Null') return idea.combine; else return null; });
			selectedScheme[type] = allBusinessIdeas;
		} else if (Array.isArray(value) && value.indexOf("deselectAll") > -1) selectedScheme[type] = [];
		else selectedScheme[type] = value;
		this.setState({ selectedScheme });
	}

	editScheme = (scheme) => {
		let selectedScheme = scheme;
		if (selectedScheme.__v) delete selectedScheme.__v;
		if (selectedScheme.isDeleted) delete selectedScheme.isDeleted;
		if (selectedScheme.createdAt) delete selectedScheme.createdAt;
		if (selectedScheme.createdBy) delete selectedScheme.createdBy;
		if (selectedScheme.updatedAt) delete selectedScheme.updatedAt;
		if (selectedScheme.updatedBy) delete selectedScheme.updatedBy;

		this.setState({ selectedScheme });
	}

	hideEditingScheme = () => {
		this.setState({ selectedScheme: null });
	}

	viewScheme = (viewScheme) => {
		this.setState({ viewScheme });
	}

	hideViewScheme = () => {
		this.setState({ viewScheme: null });
	}

	addScheme = () => {
		let selectedScheme = {
			name: '',
			interestRate: '',
			loanTenure: '',
			moratorium: '',
			processingFee: '',
			margin: '',
			subsidy: '',
			geography: [],
			businessStage: [],
			businessType: [],
			region: [],
			minLoanLimit: 100,
			maxLoanLimit: 1000,
			requiresCollateral: false,
			minAgeLimit: 10,
			maxAgeLimit: 50,
			preferredBusinesses: [],
			preferredCategory: [],
			preferredGender: [],
			minimumQualification: [],
			forPWD: [],
			bankSpecificity: [],
			documentsRequired: [],
			goodToHaveDocuments: [],
			howToApply: ''
		};
		this.setState({ selectedScheme });
	}

	deleteScheme = (schemeId) => {
		if (schemeId) {
			Swal.fire({
				title: 'Are you sure?',
				html: 'This Scheme will be deleted permanently',
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: false,
				confirmButtonText: 'Yes, Delete',
				confirmButtonAriaLabel: 'Yes, Delete',
				cancelButtonText: 'No, Cancel',
				cancelButtonAriaLabel: 'No, Cancel'
			}).then((result) => {
				if (result.value) {
					fetch(process.env.REACT_APP_API_URL + '/api/v1/schemes/' + schemeId, {
						method: 'DELETE',
						headers: {
							'x-auth-token': JSON.parse(readCookie('access_token'))
						}
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								this.notifySuccess(data.messsage);
								this.refreshSchemes();
							} else {
								this.notifyError(data.messsage);
							}
						}).catch((e) => {
							this.notifyError('Scheme deletion failed!');
						});
				}
			});
		}
	}

	saveEditingScheme = () => {
		let selectedScheme = this.state.selectedScheme;
		if (selectedScheme.name && selectedScheme.interestRate && selectedScheme.loanTenure && selectedScheme.moratorium && selectedScheme.processingFee && selectedScheme.margin && selectedScheme.subsidy && selectedScheme.geography.length && selectedScheme.businessStage.length && selectedScheme.businessType.length && selectedScheme.region.length && selectedScheme.minLoanLimit && selectedScheme.maxLoanLimit && selectedScheme.minAgeLimit && selectedScheme.maxAgeLimit && selectedScheme.preferredBusinesses.length && selectedScheme.preferredCategory.length && selectedScheme.preferredGender.length && selectedScheme.minimumQualification.length && selectedScheme.forPWD.length && selectedScheme.bankSpecificity.length && selectedScheme.documentsRequired.length && selectedScheme.goodToHaveDocuments.length && selectedScheme.howToApply && selectedScheme.hasOwnProperty('multipleApplicationsAllowed')) {
			let selectedSchemeId = selectedScheme._id;
			if (selectedSchemeId) {
				delete selectedScheme._id;
				Swal.fire({
					title: 'Are you sure you want to update this credit scheme?',
					html: 'This will update the eligibility-based scheme recommendations for all candidates.',
					showCloseButton: true,
					showCancelButton: true,
					focusConfirm: false,
					confirmButtonText: 'Yes, Update',
					confirmButtonAriaLabel: 'Yes, Update',
					cancelButtonText: 'No, Cancel',
					cancelButtonAriaLabel: 'No, Cancel'
				}).then((result) => {
					if (result.value) {
						fetch(process.env.REACT_APP_API_URL + '/api/v1/schemes/' + selectedSchemeId, {
							method: 'PUT',
							headers: {
								'Content-Type': 'application/json',
								'x-auth-token': JSON.parse(readCookie('access_token'))
							},
							body: JSON.stringify(selectedScheme)
						}).then((response) => {
							return response.json();
						}).then((data) => {
							if (data.status) {
								this.refreshSchemes();
								this.hideEditingScheme();
								this.notifySuccess("Successfully updated scheme!");
							} else {
								this.notifyError(data.errorMsg);
							}
						});
					}
				});
			} else {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/schemes/new', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'x-auth-token': JSON.parse(readCookie('access_token'))
					},
					body: JSON.stringify(selectedScheme)
				}).then((response) => {
					return response.json();
				}).then((data) => {
					if (data.status) {
						this.refreshSchemes();
						this.hideEditingScheme();
						this.notifySuccess("Successfully added new scheme!");
					} else {
						this.notifyError(data.errorMsg);
					}
				});
			}
		} else {
			this.notifyError('Please fill the required values to save');
		}
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if (!Array.isArray(option.props.children)) {
			if (typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}

	notifySuccess = (text) => toast.success(text)
	notifyError = (text) => toast.error(text)

	render() {
		return (
			<div className={this.props.userData ? "page-container schemes-page" : "schemes-page"}>
				<div className="page-heading d-flex align-items-center">
					<h3>Schemes</h3>
					<div className="filter add-new-scheme-container" style={{ right: "40px", position: "absolute" }}>
						{["Swavalamban Fellows"].includes(this.props.userData.userType.name) ? null : <button className="btn add-new-scheme-btn" onClick={this.addScheme}>
							<i className="fa fa-plus mr5"></i>
							Add Scheme
						</button>}
					</div>
				</div>

				<div className="schemes-container">
					{this.state.allSchemes ? (
						<ToolkitProvider keyField="_id" data={this.state.allSchemes} columns={this.state.schemeColumns}>
							{props => (
								<div>
									<BootstrapTable id="schemes-table" {...props.baseProps} ref={(table) => { this.schemesTable = table; }} />
								</div>
							)}
						</ToolkitProvider>
					) : (null)}
					{!this.state.allSchemes.length ? (
						<div className="no-schemes">
							<h2>There are no Schemes currently.<br /><span>Please create a new one!</span></h2>
						</div>
					) : (null)}
				</div>

				{this.state.viewScheme ? (
					<ViewSchemeModal viewScheme={this.state.viewScheme} hideViewScheme={this.hideViewScheme} />
				) : (null)}

				{this.state.selectedScheme ? (
					<Modal className="edit-scheme-modal" size="lg" show={this.state.selectedScheme ? true : false} onHide={() => { }}>
						<Modal.Header>
							<Modal.Title className="modal-header-custom">
								{this.state.selectedScheme._id ? 'Edit Scheme' : 'New Scheme'}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mb20">
							<Row>
								<Col md={12}>
									<label className="control-label is-imp">Name</label>
									<input type="text" className="form-control" value={this.state.selectedScheme.name} onChange={this.updateEditingScheme.bind(this, 'name')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Interest Rate (Range)</label>
									<textarea className="form-control" placeholder="Interest Rate (Range)" value={this.state.selectedScheme.interestRate} onChange={this.updateEditingScheme.bind(this, 'interestRate')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Loan Tenure (in months)</label>
									<textarea className="form-control" placeholder="Loan Tenure (in months)" value={this.state.selectedScheme.loanTenure} onChange={this.updateEditingScheme.bind(this, 'loanTenure')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Moratorium (in months)</label>
									<textarea className="form-control" placeholder="Moratorium (in months)" value={this.state.selectedScheme.moratorium} onChange={this.updateEditingScheme.bind(this, 'moratorium')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Processing Fee</label>
									<textarea className="form-control" placeholder="Processing Fee" value={this.state.selectedScheme.processingFee} onChange={this.updateEditingScheme.bind(this, 'processingFee')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Margin (%)</label>
									<textarea className="form-control" placeholder="Margin (%)" value={this.state.selectedScheme.margin} onChange={this.updateEditingScheme.bind(this, 'margin')} />
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">Subsidy</label>
									<textarea className="form-control" placeholder="Subsidy" value={this.state.selectedScheme.subsidy} onChange={this.updateEditingScheme.bind(this, 'subsidy')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Geography</label>
									<Select mode="multiple" showSearch size="large" style={{ width: '100%' }} placeholder="Geography" onChange={this.updateEditingScheme.bind(this, 'geography')} value={this.state.selectedScheme.geography} filterOption={(input, option) => this.searchFilter(input, option)}>
										<Option value="Any">Any</Option>
										<OptGroup label="Focus States">
											{this.state.allStates.map((state, stateIndex) => {
												if (state.inFocusRegion) {
													if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
														return <Option key={stateIndex} value={state.name}>{state.name}</Option>
													} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
														return <Option key={stateIndex} value={state.name}>{state.name}</Option>
													} else return null;
												} else return null;
											})}
										</OptGroup>
										<OptGroup label="Not Focussed States">
											{this.state.allStates.map((state, stateIndex) => {
												if (!state.inFocusRegion) {
													if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
														return <Option key={stateIndex} value={state.name}>{state.name}</Option>
													} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
														return <Option key={stateIndex} value={state.name}>{state.name}</Option>
													} else return null;
												} else return null;
											})}
										</OptGroup>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Select Business Stage</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.businessStage} onChange={this.updateEditingScheme.bind(this, 'businessStage')}>
										<Option value="Any">Any</Option>
										<Option value="Start-up">Start-up</Option>
										<Option value="Scale-up">Scale-up</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Select Business Type</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.businessType} onChange={this.updateEditingScheme.bind(this, 'businessType')}>
										<Option value="Any">Any</Option>
										<Option value="Manufacturing">Manufacturing</Option>
										<Option value="Services">Services</Option>
										<Option value="Trading">Trading</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Select Region</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.region} onChange={this.updateEditingScheme.bind(this, 'region')}>
										<Option value="Any">Any</Option>
										<Option value="Rural">Rural</Option>
										<Option value="Urban">Urban</Option>
										<Option value="Tribal">Tribal</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Min loan limit (INR)</label>
									<input type="number" className="form-control" placeholder="Min loan limit (INR)" value={this.state.selectedScheme.minLoanLimit} min={1} onChange={this.updateEditingScheme.bind(this, 'minLoanLimit')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Max loan limit (INR)</label>
									<input type="number" className="form-control" placeholder="Max loan limit (INR)" value={this.state.selectedScheme.maxLoanLimit} min={1} onChange={this.updateEditingScheme.bind(this, 'maxLoanLimit')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Requires collateral</label>
									<Switch checked={this.state.selectedScheme.requiresCollateral} onChange={this.updateEditingScheme.bind(this, 'requiresCollateral')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Min age limit (in years)</label>
									<input type="number" className="form-control" placeholder="Min loan limit (INR)" value={this.state.selectedScheme.minAgeLimit} min={1} onChange={this.updateEditingScheme.bind(this, 'minAgeLimit')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Max age limit (in years)</label>
									<input type="number" className="form-control" placeholder="Max loan limit (INR)" value={this.state.selectedScheme.maxAgeLimit} min={1} onChange={this.updateEditingScheme.bind(this, 'maxAgeLimit')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Preferred Businesses</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.preferredBusinesses} onChange={this.updateEditingScheme.bind(this, 'preferredBusinesses')}>
										<Option value="Any">No Preferrence (All Business Types)</Option>
										<Option value="selectAll">Select All</Option>
										<Option value="deselectAll">Deselect All</Option>
										{this.state.allBusinessIdeas.map((businessIdea, index) => {
											if (businessIdea.name !== "Null") {
												return (
													<Option key={index} title={businessIdea.combine} value={businessIdea.combine}>{businessIdea.combine}</Option>
												)
											} else return null;
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Preferred Category</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.preferredCategory} onChange={this.updateEditingScheme.bind(this, 'preferredCategory')}>
										<Option value="Any">Any</Option>
										<Option value="General">General</Option>
										<Option value="SC">SC</Option>
										<Option value="ST">ST</Option>
										<Option value="OBC">OBC</Option>
										<Option value="Others">Others</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Preferred Gender</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.preferredGender} onChange={this.updateEditingScheme.bind(this, 'preferredGender')}>
										<Option value="Any">Any</Option>
										<Option value="Male">Male</Option>
										<Option value="Female">Female</Option>
										<Option value="Other">Other</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Minimum Qualification</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.minimumQualification} onChange={this.updateEditingScheme.bind(this, 'minimumQualification')}>
										<Option value="Any">Any</Option>
										<Option value="Uneducated">Uneducated</Option>
										<Option value="5th Pass">5th Pass</Option>
										<Option value="8th Pass">8th Pass</Option>
										<Option value="10th Pass">10th Pass</Option>
										<Option value="12th Pass">12th Pass</Option>
										<Option value="Diploma">Diploma</Option>
										<Option value="graduate">Graduate</Option>
										<Option value="post graduate">Post-graduate</Option>
										<Option value="Doctorate">Doctorate</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">For PWD</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.forPWD} onChange={this.updateEditingScheme.bind(this, 'forPWD')}>
										<Option value="Any">Any</Option>
										<Option value="Yes">Yes</Option>
										<Option value="No">No</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Bank specificity</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.bankSpecificity} onChange={this.updateEditingScheme.bind(this, 'bankSpecificity')}>
										<Option value="Any">Any</Option>
										{this.state.allBanks.map((bank, bankIndex) => {
											return (
												<Option key={bankIndex} value={bank}>{bank}</Option>
											)
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Documents required</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.documentsRequired} onChange={this.updateEditingScheme.bind(this, 'documentsRequired')}>
										{this.state.documents.map((doc, docIndex) => {
											return (
												<Option key={docIndex} value={doc}>{doc}</Option>
											)
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Good to have documents</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.selectedScheme.goodToHaveDocuments} onChange={this.updateEditingScheme.bind(this, 'goodToHaveDocuments')}>
										{this.state.documents.map((doc, docIndex) => {
											return (
												<Option key={docIndex} value={doc}>{doc}</Option>
											)
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Can make multiple applications</label>
									<div className="d-flex">
										<label for="multiple-applications-1">
											<input className="mr5" type="radio" name="multipleApplicationsAllowed" value={true} id="multiple-applications-1" onChange={this.updateEditingScheme.bind(this, 'multipleApplicationsAllowed', true)} checked={this.state.selectedScheme.multipleApplicationsAllowed === true ? true : false} />
											Yes
										</label>
										<label for="multiple-applications-2" className="ml15">
											<input className="mr5" type="radio" name="multipleApplicationsAllowed" value={false} id="multiple-applications-2" onChange={this.updateEditingScheme.bind(this, 'multipleApplicationsAllowed', false)} checked={this.state.selectedScheme.multipleApplicationsAllowed === false ? true : false} />
											No
										</label>
									</div>
								</Col>
								<Col md={12}>
									<label className="control-label is-imp">How to apply</label>
									<textarea className="form-control" placeholder="How to apply" value={this.state.selectedScheme.howToApply} onChange={this.updateEditingScheme.bind(this, 'howToApply')} />
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer className="footer-center">
							<Button className="btn brand-color-background" onClick={this.saveEditingScheme}>Save</Button>
							<Button className="btn btn-danger brand-color-background" onClick={this.hideEditingScheme}>Cancel</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}
			</div>
		);
	}
}

import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import {
	// ConfigProvider, DatePicker,
	Select,
	// Input,
	Switch, Collapse
} from 'antd';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import React, { Component } from 'react';
// import enUS from 'antd/es/locale/en_US';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

// import {allBanks} from '../../../commonConfig';
const readCookie = require('../cookie.js').readCookie;

const { Panel } = Collapse;
const { SearchBar } = Search;
const { Option, OptGroup } = Select;

export default class BankBranches extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			newBranch: {
			},
			allStates: [],
			sizePerPage: 200,
			bankBranches: [],
			allDistricts: [],
			totalBankBranches: 0,
			addNewBranch: false,
			sizePerPageList: [50, 100, 150, 200],
			filterData: {
				state: '',
				district: '',
				mainBranch: '',
				experience: '',
				contactDetails: '',
				verification: '',
				bankName: '',
				lendingBranch: '',
			},
			editBranch: null,
			ifscFound: false,
			selectedCities: [],
			selectedDistricts: [],
			focusStates: ["Bihar", "Jharkhand", "Odisha", "Telangana", "Uttar Pradesh"],
			// allBanks: allBanks,
			allBanks: [],
			accountHoldersCounts: {},
			applicantsCounts: {},
			sanctionedCounts: {},
			rejectedCounts: {},
			disbursedCounts: {}

			//["//Allahabad Bank", "Andhra Bank", "Andhra Pradesh Grameena Vikas Bank", "Aryavart Bank", "Axis Bank", "Bandhan Bank", "Bank of Bahrain and Kuwait", "Bank of Baroda", "Bank of India", "Bank of Maharashtra", "Baroda Uttar Pradesh Gramin Bank", "Canara Bank", "Central Bank of India", "City Union Bank", "Corporation Bank", "Dakshin Bihar Gramin Bank", "Dena Bank", "Deutsche Bank", "Development Credit Bank", "Dhanlaxmi Bank", "Federal Bank", "Fino Payments Bank", "Gramin Bank", "HDFC Bank", "ICICI Bank", "IDBI Bank", "Indian Bank", "Indian Post Payments Bank", "Indian Overseas Bank", "IndusInd Bank", "ING Vysya Bank", "Jammu and Kashmir Bank", "Karnataka Bank Ltd", "Karur Vysya Bank", "Kashi Gomti Samyut Gramin Bank", "Kotak Bank", "Laxmi Vilas Bank", "Oriental Bank of Commerce", "Prathama Bank", "Punjab National Bank - Corporate Banking", "Punjab National Bank - Retail Banking", "Punjab &amp; Sind Bank", "RBL Bank", "Shamrao Vitthal Co-operative Bank", "South Indian Bank", "State Bank of Bikaner &amp; Jaipur", "State Bank of Hyderabad", "State Bank of India", "State Bank of Mysore", "State Bank of Patiala", "State Bank of Travancore", "Syndicate Bank", "Tamilnad Mercantile Bank Ltd.", "UCO Bank", "Union Bank of India", "United Bank of India", "Uttar Bihar Gramin Bank", "Vijaya Bank", "Yes Bank Ltd"]
		}
		// this.accountHoldersCounts = this.accountHoldersCounts.bind(this);
	}

	componentDidMount() {
		this.getAllBanks();
		this.getAllBankBranches('');
		let userData = JSON.parse(readCookie('userData'));
		let userType = JSON.parse(readCookie('userData')).userType.name;
		if (userType === 'Swavalamban Fellows') {
			this.setState({
				allStates: userData.state.map(x => { return { name: x, inFocusRegion: true } }),
				filterData: { ...this.state.filterData, state: userData.state[0] },
				allDistricts: userData.district.map(x => { return { name: x } })
			});
		}
		else if (userType === 'Zonal Manager') {
			this.setState({
				allStates: userData.state.map(x => { return { name: x, inFocusRegion: true } }),
				// filterData: { ...this.state.filterData, state: userData.state[0] },
				// allDistricts: userData.district
			});
		}
		else if (userType === 'Project Management Unit') {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						this.setState({ allStates: data.data.states });
					}
				});
		}
	}
	getAllBanks = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/banks', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allBanks: data.allBanks });
				}
			});
	}
	getAllBankBranchesCounts = (query) => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/counts' + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					const {
						// bankBranches,
						totalBankBranches,
						// accountHoldersCounts,
						applicantsCounts,
						sanctionedCounts,
						disbursedCounts,
						rejectedCounts,
						// bankBranchesResult
					} = data;
					this.setState({
						// bankBranches: bankBranchesResult,
						totalBankBranches,
						// accountHoldersCounts,
						applicantsCounts,
						sanctionedCounts,
						disbursedCounts,
						rejectedCounts
					});
				}
			});
	}
	getAllBankBranches = (query) => {
		this.getAllBankBranchesCounts(query);

		fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches' + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					const {
						// bankBranches,
						// totalBankBranches,
						// accountHoldersCounts,
						// applicantsCounts,
						// sanctionedCounts,
						// disbursedCounts,
						// rejectedCounts,
						bankBranchesResult } = data;
					this.setState({
						bankBranches: bankBranchesResult,
						// totalBankBranches,
						// accountHoldersCounts,
						// applicantsCounts,
						// sanctionedCounts,
						// disbursedCounts,
						// rejectedCounts
					});
				}
			});
	}

	submitFilter = (page = this.state.page, sizePerPage = this.state.sizePerPage, searchText = this.state.searchText) => {
		let filterData = this.state.filterData, query = `?page=${page - 1}&limit=${sizePerPage}`;

		if (searchText) query += `&q=${searchText}`;
		if (filterData.state && filterData.state.length) query += `&state=${filterData.state}`;
		if (filterData.district && filterData.district.length) query += `&district=${filterData.district}`;
		if (filterData.mainBranch) query += `&mainBranch=${filterData.mainBranch}`;
		if (filterData.lendingBranch) query += `&lendingBranch=${filterData.lendingBranch}`;
		if (filterData.experience) query += `&experience=${filterData.experience}`;
		if (filterData.contactDetails) query += `&contactDetails=${filterData.contactDetails}`;
		if (filterData.verification) query += `&verification=${filterData.verification}`;
		if (filterData.bankName) query += `&bankName=${filterData.bankName}`;

		this.getAllBankBranches(query);
	}

	resetFilter = () => {
		let filterData = { state: '', district: '', mainBranch: '', lendingBranch: '', experience: '', contactDetails: '', verification: '', bankName: '' };
		let query = `?page=0&limit=${this.state.sizePerPage}`;
		this.setState({ filterData, page: 1 }, () => this.getAllBankBranches(query));
	}

	changeStateFilter = (state) => {
		let filterData = this.state.filterData;
		filterData['state'] = state;
		let userType = JSON.parse(readCookie('userData')).userType.name;
		if (state && userType !== 'Swavalamban Fellows') {
			filterData['district'] = '';
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?states=' + state, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						this.setState({ allDistricts: data.districts });
					}
				});
		}
		this.setState({ filterData }, () => this.submitFilter());
	}

	displayAccountHolders(cell, row) {
		if (cell) {
			return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{this.state.accountHoldersCounts[cell] || 0}
			</div>
		}
		else {
			return <span>0</span>
		}
	}
	displayAppliedLoans(cell, row) {
		if (cell) {
			return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{this.state.applicantsCounts[cell] || 0}
			</div>
		}
		else {
			return <span>0</span>
		}
	}
	displaySanctionedLoans(cell, row) {
		if (cell) {
			return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{this.state.sanctionedCounts[cell] || 0}
			</div>
		}
		else {
			return <span>0</span>
		}
	}
	displayDisbursedLoans(cell, row) {
		if (cell) {
			return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{this.state.disbursedCounts[cell] || 0}
			</div>
		}
		else {
			return <span>0</span>
		}
	}
	displayRejectedLoans(cell, row) {
		if (cell) {
			return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				{this.state.rejectedCounts[cell] || 0}
			</div>
		}
		else {
			return <span>0</span>
		}
	}
	changeFilterHandler = (type, e) => {
		let filterData = this.state.filterData, value = '';
		if (e && e.hasOwnProperty('target')) value = e.target.value;
		else value = e;
		filterData[type] = value;

		this.setState({ filterData }, () => this.submitFilter());
	}

	onTableChange = (type, obj) => {
		if (type === 'sort' && obj.sortOrder && obj.sortField) {
			// this.submitFilter(type, obj.page, obj.sizePerPage, obj.searchText, obj.sortField, obj.sortOrder);
		} else {
			// let changeType = '';
			// if(this.state.page !== obj.page) changeType = 'page';
			// else if(this.state.sizePerPage !== obj.sizePerPage) changeType = 'sizePerPage';
			// else if(this.state.searchText !== obj.searchText) changeType = 'searchText';

			this.setState({ page: obj.page, sizePerPage: obj.sizePerPage, searchText: obj.searchText });
			this.submitFilter(obj.page, obj.sizePerPage, obj.searchText);
		}
	}

	formatEdit = (cell, row) => {
		if (cell) {
			return (
				<button className="btn edit-lead-btn" onClick={this.toggleEditBranchModal.bind(this, row)}>Edit</button>
			)
		} else return null;
	}
	navigateToBankProfile = (ifsc) => {
		if (ifsc) {
			window.location = '/bank-branches/' + ifsc;
		}
	}
	formatIfsc = (cell, row) => {
		if (cell) {
			return (
				<a
					href={"/bank-branches/" + cell}
					target="_blank"
					rel="noopener noreferrer"
				// style={{ color: "--themeColor" }}
				>{cell}</a>
			)
		} else return null;
	}
	formatVerification = (cell, row) => {
		if (row.verifiedAt) {
			return (
				<span className="note">
					{moment(row.verifiedAt).format('DD/MM/YYYY hh:mm A') + ' by '}
					{row.verifiedBy.id ? (
						<a href={"/user-types/swavalamban-fellows/" + row.verifiedBy.id}>{row.verifiedBy.name}</a>
					) : row.verifiedBy.name}
				</span>
			)
		} else {
			return (
				<button className="btn edit-lead-btn" onClick={this.verifyBranch.bind(this, row)}>Verify</button>
			)
		}
	}

	verifyBranch = (branch) => {
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure about this?',
			html: 'You are about to mark this branch with IFSC - ' + branch.ifsc + ' as Verified!',
			focusConfirm: false,
			showCancelButton: true,
			cancelButtonText: 'No, Cancel',
			confirmButtonText: 'Yes, Mark Verified',
			cancelButtonAriaLabel: 'No, Cancel',
			confirmButtonAriaLabel: 'Yes, Mark Verified'
		}).then((result) => {
			if (result.value && branch._id) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/' + branch._id + '/verify', {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.submitFilter();
							this.notifySuccess('The branch has successfully been verified!');
						} else {
							this.notifyError('The branch could not be marked as verified!');
						}
					});
			}
		});
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if (!Array.isArray(option.props.children)) {
			if (typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}

	toggleEditBranchModal = (branch) => {
		if (this.state.editBranch) this.setState({ editBranch: null });
		else {
			this.setState({ editBranch: branch });
			if (branch.state) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + branch.state, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ selectedDistricts: data.districts });
						}
					});
			}
			if (branch.district) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?districtId=' + branch.district, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ selectedCities: data.cities });
						}
					});
			}
		}
	}

	updateEditingBranch = (type, value) => {
		let editBranch = this.state.editBranch;
		if (value.target) value = value.target.value;
		if (type === "status") {
			if (value) value = "ACTIVE";
			else value = "INACTIVE";
		}
		editBranch[type] = value;
		if (type === "state") {
			editBranch["district"] = "";
			editBranch["city"] = "";
			if (this.state.focusStates.indexOf(value) > -1) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + value, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ selectedDistricts: data.districts });
						}
					});
			} else this.setState({ selectedDistricts: [] });
		} else if (type === "district") {
			editBranch["city"] = "";
			if (this.state.focusStates.indexOf(value) > -1) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?districtId=' + value, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ selectedCities: data.cities });
						}
					});
			} else this.setState({ selectedCities: [] });
		}
		this.setState({ editBranch });
	}

	hideEditingBranch = () => {
		this.setState({ editBranch: null, ifscFound: false, selectedCities: [], selectedDistricts: [] });
	}

	saveEditingBranch = () => {
		let editBranch = this.state.editBranch, method = 'POST', path = '/api/v1/bank-branches';
		if (editBranch._id) {
			method = 'PUT';
			path = path + '/' + editBranch._id;
		}
		fetch(process.env.REACT_APP_API_URL + path, {
			method,
			headers: {
				'Content-Type': 'application/json',
				'x-auth-token': JSON.parse(readCookie('access_token'))
			},
			body: JSON.stringify(editBranch)
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.hideEditingBranch();
					this.submitFilter();
					const message = data.message || 'The branch has successfully been saved!';
					this.notifySuccess(message);
				} else {
					const message = data.errorMsg || 'The branch could not be updated!';
					this.notifyError(message);
				}
			});
	}

	notifySuccess = (text) => toast.success(text)
	notifyError = (text) => toast.error(text)

	render() {
		const columns = [
			{ text: '', sort: false, hidden: true, export: true, searchable: false, dataField: '_id' },
			{ text: 'IFSC Code', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'ifsc', formatter: this.formatIfsc },
			{ text: 'Name of Bank', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '200px' } }, dataField: 'bank' },
			{ text: 'State', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'state' },
			{ text: 'District', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'district' },
			{ text: 'City', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'city' },
			{ text: 'Pincode', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '100px' } }, dataField: 'pincode' },
			{ text: 'Branch Details', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '160px' } }, dataField: 'branch' },
			{ text: 'Experience', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'experience' },
			// { text: 'Status', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => {return {width: '120px'}}, dataField: 'status' },
			// { text: 'Verification', sort: false, hidden: false, export: true, searchable: false, headerStyle: () => {return {width: '160px'}}, dataField: 'verification', formatter: this.formatVerification },
			// { text: 'Edit', sort: false, hidden: false, export: false, searchable: false, headerStyle: () => { return { width: '60px' } }, dataField: '_id', formatter: this.formatEdit },
			{ text: 'Status', sort: false, hidden: true, export: true, searchable: false, headerStyle: () => { return { width: '120px' } }, dataField: 'status' },
			{ text: 'Verification', sort: false, hidden: true, export: true, searchable: false, headerStyle: () => { return { width: '160px' } }, dataField: 'verification', formatter: this.formatVerification },
			{ text: 'Edit', sort: false, hidden: true, export: false, searchable: false, headerStyle: () => { return { width: '60px' } }, dataField: '_id', formatter: this.formatEdit },
			{
				dataField: 'accountHoldersCount',
				text: 'No. of Account Holders',
				// formatter: this.displayAccountHolders.bind(this),
				headerStyle: () => ({ textAlign: 'center', width: '100px' }),
			},
			{
				dataField: 'ifsc',
				text: 'No. of Loans Applied',
				formatter: this.displayAppliedLoans.bind(this),
				headerStyle: () => ({ textAlign: 'center', width: '100px' }),
			},
			{
				dataField: 'ifsc',
				text: 'No. of Loans Sanctioned',
				formatter: this.displaySanctionedLoans.bind(this),
				headerStyle: () => ({ textAlign: 'center', width: '100px' }),
			},
			{
				dataField: 'ifsc',
				text: 'No. of Loans Disbursed',
				formatter: this.displayDisbursedLoans.bind(this),
				headerStyle: () => ({ textAlign: 'center', width: '100px' }),
			},
			{
				dataField: 'ifsc',
				text: 'No. of Loans Rejected',
				formatter: this.displayRejectedLoans.bind(this),
				headerStyle: () => ({ textAlign: 'center', width: '100px' }),
			},
		];

		return (
			<div className="page-container bank-branches-page">
				<div className="page-heading d-flex">
					<h3>Bank Branches</h3>
					{JSON.parse(readCookie('userData')).type === 'le' && JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' ? (
						<div className="filter add-new-prospect-container" style={{ right: "40px", position: "absolute" }}>
							<button className="btn add-new-prospect-btn" style={{ borderRadius: 0 }} onClick={this.toggleEditBranchModal.bind(this, {})} >
								<i className="fa fa-plus mr5"></i>
								Add New Bank
							</button>
						</div>
					) : (null)}
				</div>
				<div className="filters-container">
					<div className="filter form-group">
						<label className="control-label">{'State: '}</label>
						<Select showSearch style={{ width: '100%' }} value={this.state.filterData.state} onChange={this.changeStateFilter} filterOption={(input, option) => this.searchFilter(input, option)}>
							{['Project Management Unit', 'Zonal Manager'].indexOf(JSON.parse(readCookie('userData')).userType.name) > -1 &&
								<Option value="">All States</Option>}
							<OptGroup label="Focus States">
								{this.state.allStates.map((state, stateIndex) => {
									if (state.inFocusRegion) {
										return (
											<Option key={stateIndex} value={state.name}>{state.name}</Option>
										)
									} else return null;
								})}
							</OptGroup>
						</Select>
					</div>
					<div className="filter form-group">
						<label className="control-label">{'District: '}</label>
						<Select showSearch style={{ width: '100%' }} value={this.state.filterData.district} onChange={this.changeFilterHandler.bind(this, 'district')} filterOption={(input, option) => this.searchFilter(input, option)}>
							<Option value="">All Districts</Option>
							{this.state.filterData.state ? (
								this.state.allDistricts.map((district, districtIndex) => {
									// let districtName = district.name;
									// if(district.state) districtName += ' (' + district.state + ')';
									return (
										<Option value={district.name} title={district.name} key={districtIndex}>{district.name}</Option>
									)
								})
							) : (null)}
						</Select>
					</div>
					<div className="filter form-group">
						<label className="control-label">{'Main Branch: '}</label>
						<Select style={{ width: '100%' }} value={this.state.filterData.mainBranch} onChange={this.changeFilterHandler.bind(this, 'mainBranch')}>
							<Option value="">Select</Option>
							<Option value="Yes">Yes</Option>
							<Option value="No">No</Option>
						</Select>
					</div>
					<div className="filter form-group">
						<label className="control-label">{'Lending Branch: '}</label>
						<Select style={{ width: '100%' }} value={this.state.filterData.lendingBranch} onChange={this.changeFilterHandler.bind(this, 'lendingBranch')}>
							<Option value="">Select</Option>
							<Option value="Yes">Yes</Option>
							<Option value="No">No</Option>
						</Select>
					</div>
					<div className="filter form-group">
						<label className="control-label">{'Experience: '}</label>
						<Select style={{ width: '100%' }} value={this.state.filterData.experience} onChange={this.changeFilterHandler.bind(this, 'experience')}>
							<Option value="">Select</Option>
							<Option value="Good">Good</Option>
							<Option value="Average">Average</Option>
							<Option value="Poor">Poor</Option>
							<Option value="Unknown">Unknown</Option>
						</Select>
					</div>
					{/* <div className="filter form-group">
						<label className="control-label">{'Has Contact Details: '}</label>
						<Select style={{ width: '100%' }} value={this.state.filterData.contactDetails} onChange={this.changeFilterHandler.bind(this, 'contactDetails')}>
							<Option value="">Select</Option>
							<Option value="Verified">Yes - Verified</Option>
							<Option value="Unverified">No - Unverified</Option>
							<Option value="Not-available">Not-available</Option>
						</Select>
					</div> */}
					{/* <div className="filter form-group">
						<label className="control-label">{'Verification: '}</label>
						<Select style={{width: '100%'}} value={this.state.filterData.verification} onChange={this.changeFilterHandler.bind(this, 'verification')}>
							<Option value="">Select</Option>
							<Option value="Verfied">Verfied</Option>
							<Option value="Not Verified">Not Verified</Option>
						</Select>
					</div> */}
					<div className="filter form-group">
						<label className="control-label">{'Bank Name: '}</label>
						<Select style={{ width: '100%' }} value={this.state.filterData.bankName} onChange={this.changeFilterHandler.bind(this, 'bankName')}>
							<Option value="">Select Bank</Option>
							{
								this.state.allBanks.map((bank, index) => {
									return <Option key={index} value={bank}>{bank}</Option>
								})
							}
						</Select>
					</div>
					<div className="filter form-group reset">
						<button className="btn reset-filters" onClick={this.resetFilter}>Reset</button>
					</div>
				</div>
				<Col md={12} className="bank-branches-table-container">
					{this.state.bankBranches ? (
						<PaginationProvider pagination={paginationFactory({ custom: true, page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalBankBranches, sizePerPageList: this.state.sizePerPageList })} keyField="_id" data={this.state.bankBranches} columns={columns}>
							{({ paginationProps, paginationTableProps }) => (
								<ToolkitProvider keyField="ifsc" data={this.state.bankBranches} columns={columns} search={{ searchFormatted: true }}>
									{props => (
										<div>
											<Row className="mt10 mb5">
												<div className="total-bank-branches-count-container">
													<span className="total-bank-branches m0">
														{'Total Bank Branches: ' + this.state.totalBankBranches}
													</span>
												</div>
												<Col className="bank-branches-table-search-container">
													<SearchBar {...props.searchProps} placeholder="Search by Bank/State/District/City/Pincode/IFSC/Status" className="bank-branches-table-search" delay={1500} />
												</Col>
											</Row>

											<BootstrapTable {...props.baseProps} id="bank-branches-table" rowStyle={this.rowClassNameFormat} remote={{ pagination: true }} onTableChange={this.onTableChange} {...paginationTableProps} />
											<PaginationListStandalone {...paginationProps} />
											<SizePerPageDropdownStandalone {...paginationProps} />
										</div>
									)}
								</ToolkitProvider>
							)}
						</PaginationProvider>
					) : (null)}
				</Col>

				{this.state.editBranch ? (
					<Modal className="edit-branch-modal" size="lg" show={this.state.editBranch ? true : false} onHide={() => { }}>
						<Modal.Header>
							<Modal.Title className="modal-header-custom">
								{this.state.editBranch._id ? 'Edit Branch Details' : 'Add New Branch'}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mb20">
							<Row>
								<Col md={4}>
									<label className="control-label is-imp">Name of Bank</label>
									<Select size="large" style={{ width: '100%' }} value={this.state.editBranch.bank} onChange={this.updateEditingBranch.bind(this, 'bank')}>
										{this.state.allBanks.map((bank, index) => {
											return (
												<Option key={index} value={bank}>{bank}</Option>
											)
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">IFSC</label>
									<input type="text" className="form-control" placeholder="IFSC Code" value={this.state.editBranch.ifsc} onChange={this.updateEditingBranch.bind(this, 'ifsc')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Branch</label>
									<input type="text" className="form-control" placeholder="Branch Name" value={this.state.editBranch.branch} onChange={this.updateEditingBranch.bind(this, 'branch')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">State</label>
									<Select showSearch size="large" style={{ width: '100%' }} placeholder="State" onChange={this.updateEditingBranch.bind(this, 'state')} value={this.state.editBranch.state} filterOption={(input, option) => this.searchFilter(input, option)}>
										<OptGroup label="Focus States">
											{this.state.allStates.map((state, stateIndex) => {
												if (state.inFocusRegion) {
													return <Option key={stateIndex} value={state.name}>{state.name}</Option>
												} else return null;
											})}
										</OptGroup>
										<OptGroup label="Not Focussed States">
											{this.state.allStates.map((state, stateIndex) => {
												if (!state.inFocusRegion) {
													return <Option key={stateIndex} value={state.name}>{state.name}</Option>
												} else return null;
											})}
										</OptGroup>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">District</label>
									{this.state.selectedDistricts.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
										<Select showSearch size="large" style={{ width: '100%' }} placeholder="District" onChange={this.updateEditingBranch.bind(this, 'district')} value={this.state.editBranch.district} filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.selectedDistricts.map((district, districtIndex) => {
												return (
													<Option key={districtIndex} value={district.name}>{district.name}</Option>
												)
											})}
										</Select>
									) : (
										<input type="text" className="form-control" placeholder="District" value={this.state.editBranch.district} onChange={this.updateEditingBranch.bind(this, 'district')} />
									)}
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">City</label>
									{this.state.selectedCities.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
										<Select showSearch size="large" style={{ width: '100%' }} placeholder="City" onChange={this.updateEditingBranch.bind(this, 'city')} value={this.state.editBranch.city} filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.selectedCities.map((city, cityIndex) => {
												return (
													<Option key={cityIndex} value={city.name}>{city.name}</Option>
												)
											})}
										</Select>
									) : (
										<input type="text" className="form-control" placeholder="City" value={this.state.editBranch.city} onChange={this.updateEditingBranch.bind(this, 'city')} />
									)}
								</Col>
								<Col md={12}>
									<label className="control-label">Address</label>
									<textarea className="form-control" placeholder="Address" value={this.state.editBranch.address} onChange={this.updateEditingBranch.bind(this, 'address')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Pincode</label>
									<input type="text" className="form-control" placeholder="Pincode" value={this.state.editBranch.pincode} onChange={this.updateEditingBranch.bind(this, 'pincode')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Phone</label>
									<input type="text" className="form-control" placeholder="Phone (with STD code)" value={this.state.editBranch.phone} onChange={this.updateEditingBranch.bind(this, 'phone')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Email</label>
									<input type="text" className="form-control" placeholder="Email ID" value={this.state.editBranch.email} onChange={this.updateEditingBranch.bind(this, 'email')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Circle</label>
									<input type="text" className="form-control" placeholder="Circle" value={this.state.editBranch.circle} onChange={this.updateEditingBranch.bind(this, 'circle')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Module</label>
									<input type="text" className="form-control" placeholder="Module" value={this.state.editBranch.module} onChange={this.updateEditingBranch.bind(this, 'module')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Business Hours</label>
									<input type="text" className="form-control" placeholder="Business Hours" value={this.state.editBranch.hours} onChange={this.updateEditingBranch.bind(this, 'hours')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Latitude</label>
									<input type="text" className="form-control" placeholder="Latitude" value={this.state.editBranch.latitude} onChange={this.updateEditingBranch.bind(this, 'latitude')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Longitude</label>
									<input type="text" className="form-control" placeholder="Longitude" value={this.state.editBranch.longitude} onChange={this.updateEditingBranch.bind(this, 'longitude')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Location Accuracy</label>
									<Select size="large" style={{ width: '100%' }} value={this.state.editBranch.accuracy} onChange={this.updateEditingBranch.bind(this, 'accuracy')}>
										<Option value="approximate">Approximate</Option>
										<Option value="Exact(based on pincodes)">Exact(based on pincodes)</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Status</label>
									<Switch checked={this.state.editBranch.status === 'ACTIVE' ? true : false} onChange={this.updateEditingBranch.bind(this, 'status')} checkedChildren="Active" unCheckedChildren="Inactive" />
								</Col>
								<Col md={4}>
									<label className="control-label">Is a Main Branch</label>
									<Switch checked={this.state.editBranch.mainBranch} onChange={this.updateEditingBranch.bind(this, 'mainBranch')} checkedChildren="Yes" unCheckedChildren="No" />
								</Col>
								<Col md={4}>
									<label className="control-label">Is a Lending Branch</label>
									<Switch checked={this.state.editBranch.lendingBranch} onChange={this.updateEditingBranch.bind(this, 'lendingBranch')} checkedChildren="Yes" unCheckedChildren="No" />
								</Col>
								<Col md={4}>
									<label className="control-label">Branch Category</label>
									<Select size="large" style={{ width: '100%' }} value={this.state.editBranch.category} onChange={this.updateEditingBranch.bind(this, 'category')}>
										<Option value="Rural">Rural</Option>
										<Option value="Urban">Urban</Option>
										<Option value="Semi-urban">Semi-urban</Option>
										<Option value="Metro">Metro</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Contact Details</label>
									<Select size="large" style={{ width: '100%' }} value={this.state.editBranch.contactDetails} onChange={this.updateEditingBranch.bind(this, 'contactDetails')}>
										<Option value="Verified">Verified</Option>
										<Option value="Unverified">Unverified</Option>
										<Option value="Not-available">Not-available</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label">Mode of Payment</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.editBranch.modeOfPayment} onChange={this.updateEditingBranch.bind(this, 'modeOfPayment')}>
										<Option value="Cash">Cash</Option>
										<Option value="Cheque">Cheque</Option>
										<Option value="Demand Draft">Demand Draft</Option>
										<Option value="Net banking">Net banking</Option>
									</Select>
								</Col>
								<Collapse style={{ width: '100%', marginTop: 15 }}>
									<Panel header="Add Primary Details" key="primaryDetails">
										<Row>
											<Col md={4}>
												<label className="control-label">Contact Person Name</label>
												<input type="text" className="form-control" placeholder="Person Name" value={this.state.editBranch.primaryContactName} onChange={this.updateEditingBranch.bind(this, 'primaryContactName')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Mobile</label>
												<input type="text" className="form-control" placeholder="Person Mobile" value={this.state.editBranch.primaryContactMob} onChange={this.updateEditingBranch.bind(this, 'primaryContactMob')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Email ID</label>
												<input type="text" className="form-control" placeholder="Person Email ID" value={this.state.editBranch.primaryContactEmail} onChange={this.updateEditingBranch.bind(this, 'primaryContactEmail')} />
											</Col>
										</Row>
									</Panel>
									<Panel header="Add Secondary Details" key="secondaryDetails">
										<Row>
											<Col md={4}>
												<label className="control-label">Contact Person Name</label>
												<input type="text" className="form-control" placeholder="Person Name" value={this.state.editBranch.secondaryContactName} onChange={this.updateEditingBranch.bind(this, 'secondaryContactName')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Mobile</label>
												<input type="text" className="form-control" placeholder="Person Mobile" value={this.state.editBranch.secondaryContactMob} onChange={this.updateEditingBranch.bind(this, 'secondaryContactMob')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Email ID</label>
												<input type="text" className="form-control" placeholder="Person Email ID" value={this.state.editBranch.secondaryContactEmail} onChange={this.updateEditingBranch.bind(this, 'secondaryContactEmail')} />
											</Col>
										</Row>
									</Panel>
								</Collapse>
							</Row>
						</Modal.Body>
						<Modal.Footer className="footer-center">
							<Button className="btn brand-color-background" onClick={this.saveEditingBranch}>Save</Button>
							<Button className="btn btn-danger brand-color-background" onClick={this.hideEditingBranch}>Cancel</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}


				{/*  Add new branch - IFSC */}
				{this.state.addNewBranch ? (
					<Modal className="edit-branch-modal" size="lg" show={this.state.addNewBranch ? true : false} onHide={() => { }}>
						<Modal.Header>
							<Modal.Title className="modal-header-custom">
								{/* {this.state.editBranch._id ? 'Edit Branch Details' : 'Add New Branch'} */}
								Add New Branch
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mb20">
							<Row>
								<Col md={4}>
									<label className="control-label is-imp">Name of Bank</label>
									<Select size="large" style={{ width: '100%' }}
										// value={this.state.editBranch.bank}
										onChange={this.updateEditingBranch.bind(this, 'bank')}>
										{this.state.allBanks.map((bank, index) => {
											return (
												<Option key={index} value={bank}>{bank}</Option>
											)
										})}
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">IFSC</label>
									<input type="text" className="form-control" placeholder="IFSC Code"
										// value={this.state.editBranch.ifsc}
										onChange={this.updateEditingBranch.bind(this, 'ifsc')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">Branch</label>
									<input type="text" className="form-control" placeholder="Branch Name"
										// value={this.state.editBranch.branch}
										onChange={this.updateEditingBranch.bind(this, 'branch')} />
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">State</label>
									<Select showSearch size="large" style={{ width: '100%' }} placeholder="State" onChange={this.updateEditingBranch.bind(this, 'state')}
										// value={this.state.editBranch.state}
										filterOption={(input, option) => this.searchFilter(input, option)}>
										<OptGroup label="Focus States">
											{this.state.allStates.map((state, stateIndex) => {
												if (state.inFocusRegion) {
													return <Option key={stateIndex} value={state.name}>{state.name}</Option>
												} else return null;
											})}
										</OptGroup>
										<OptGroup label="Not Focussed States">
											{this.state.allStates.map((state, stateIndex) => {
												if (!state.inFocusRegion) {
													return <Option key={stateIndex} value={state.name}>{state.name}</Option>
												} else return null;
											})}
										</OptGroup>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">District</label>
									{this.state.selectedDistricts.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
										<Select showSearch size="large" style={{ width: '100%' }} placeholder="District" onChange={this.updateEditingBranch.bind(this, 'district')}
											// value={this.state.editBranch.district}
											filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.selectedDistricts.map((district, districtIndex) => {
												return (
													<Option key={districtIndex} value={district.name}>{district.name}</Option>
												)
											})}
										</Select>
									) : (
										<input type="text" className="form-control" placeholder="District"
											// value={this.state.editBranch.district}
											onChange={this.updateEditingBranch.bind(this, 'district')} />
									)}
								</Col>
								<Col md={4}>
									<label className="control-label is-imp">City</label>
									{this.state.selectedCities.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
										<Select showSearch size="large" style={{ width: '100%' }} placeholder="City" onChange={this.updateEditingBranch.bind(this, 'city')}
											// value={this.state.editBranch.city}
											filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.selectedCities.map((city, cityIndex) => {
												return (
													<Option key={cityIndex} value={city.name}>{city.name}</Option>
												)
											})}
										</Select>
									) : (
										<input type="text" className="form-control" placeholder="City"
											// value={this.state.editBranch.city}
											onChange={this.updateEditingBranch.bind(this, 'city')} />
									)}
								</Col>
								<Col md={12}>
									<label className="control-label">Address</label>
									<textarea className="form-control" placeholder="Address"
										// value={this.state.editBranch.address}
										onChange={this.updateEditingBranch.bind(this, 'address')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Pincode</label>
									<input type="text" className="form-control" placeholder="Pincode"
										// value={this.state.editBranch.pincode}
										onChange={this.updateEditingBranch.bind(this, 'pincode')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Phone</label>
									<input type="text" className="form-control" placeholder="Phone (with STD code)"
										// value={this.state.editBranch.phone} 
										onChange={this.updateEditingBranch.bind(this, 'phone')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Email</label>
									<input type="text" className="form-control" placeholder="Email ID"
										// value={this.state.editBranch.email}
										onChange={this.updateEditingBranch.bind(this, 'email')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Circle</label>
									<input type="text" className="form-control" placeholder="Circle"
										// value={this.state.editBranch.circle}
										onChange={this.updateEditingBranch.bind(this, 'circle')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Module</label>
									<input type="text" className="form-control" placeholder="Module"
										// value={this.state.editBranch.module}
										onChange={this.updateEditingBranch.bind(this, 'module')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Business Hours</label>
									<input type="text" className="form-control" placeholder="Business Hours"
										// value={this.state.editBranch.hours}
										onChange={this.updateEditingBranch.bind(this, 'hours')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Latitude</label>
									<input type="text" className="form-control" placeholder="Latitude"
										// value={this.state.editBranch.latitude}
										onChange={this.updateEditingBranch.bind(this, 'latitude')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Longitude</label>
									<input type="text" className="form-control" placeholder="Longitude"
										// value={this.state.editBranch.longitude}
										onChange={this.updateEditingBranch.bind(this, 'longitude')} />
								</Col>
								<Col md={4}>
									<label className="control-label">Location Accuracy</label>
									<Select size="large" style={{ width: '100%' }}
										// value={this.state.editBranch.accuracy}
										onChange={this.updateEditingBranch.bind(this, 'accuracy')}>
										<Option value="approximate">Approximate</Option>
										<Option value="Exact(based on pincodes)">Exact(based on pincodes)</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label">Status</label>
									<Switch
										// checked={this.state.editBranch.status === 'ACTIVE' ? true : false}
										onChange={this.updateEditingBranch.bind(this, 'status')} checkedChildren="Active" unCheckedChildren="Inactive" />
								</Col>
								<Col md={4}>
									<label className="control-label">Is a Main Branch</label>
									<Switch
										// checked={this.state.editBranch.mainBranch}
										onChange={this.updateEditingBranch.bind(this, 'mainBranch')} checkedChildren="Yes" unCheckedChildren="No" />
								</Col>
								<Col md={4}>
									<label className="control-label">Is a Lending Branch</label>
									<Switch
										// checked={this.state.editBranch.lendingBranch}
										onChange={this.updateEditingBranch.bind(this, 'lendingBranch')} checkedChildren="Yes" unCheckedChildren="No" />
								</Col>
								<Col md={4}>
									<label className="control-label">Branch Category</label>
									<Select size="large" style={{ width: '100%' }}
										// value={this.state.editBranch.category}
										onChange={this.updateEditingBranch.bind(this, 'category')}>
										<Option value="Rural">Rural</Option>
										<Option value="Urban">Urban</Option>
										<Option value="Semi-urban">Semi-urban</Option>
										<Option value="Metro">Metro</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label">Contact Details</label>
									<Select size="large" style={{ width: '100%' }}
										// value={this.state.editBranch.contactDetails}
										onChange={this.updateEditingBranch.bind(this, 'contactDetails')}>
										<Option value="Verified">Verified</Option>
										<Option value="Unverified">Unverified</Option>
										<Option value="Not-available">Not-available</Option>
									</Select>
								</Col>
								<Col md={4}>
									<label className="control-label">Mode of Payment</label>
									<Select mode="multiple" size="large" style={{ width: '100%' }}
										// value={this.state.editBranch.modeOfPayment}
										onChange={this.updateEditingBranch.bind(this, 'modeOfPayment')}>
										<Option value="Cash">Cash</Option>
										<Option value="Cheque">Cheque</Option>
										<Option value="Demand Draft">Demand Draft</Option>
										<Option value="Net banking">Net banking</Option>
									</Select>
								</Col>
								<Collapse style={{ width: '100%', marginTop: 15 }}>
									<Panel header="Add Primary Details" key="primaryDetails">
										<Row>
											<Col md={4}>
												<label className="control-label">Contact Person Name</label>
												<input type="text" className="form-control" placeholder="Person Name"
													// value={this.state.editBranch.primaryContactName}
													onChange={this.updateEditingBranch.bind(this, 'primaryContactName')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Mobile</label>
												<input type="text" className="form-control" placeholder="Person Mobile"
													// value={this.state.editBranch.primaryContactMob}
													onChange={this.updateEditingBranch.bind(this, 'primaryContactMob')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Email ID</label>
												<input type="text" className="form-control" placeholder="Person Email ID"
													// value={this.state.editBranch.primaryContactEmail}
													onChange={this.updateEditingBranch.bind(this, 'primaryContactEmail')} />
											</Col>
										</Row>
									</Panel>
									<Panel header="Add Secondary Details" key="secondaryDetails">
										<Row>
											<Col md={4}>
												<label className="control-label">Contact Person Name</label>
												<input type="text" className="form-control" placeholder="Person Name"
													// value={this.state.editBranch.secondaryContactName}
													onChange={this.updateEditingBranch.bind(this, 'secondaryContactName')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Mobile</label>
												<input type="text" className="form-control" placeholder="Person Mobile"
													// value={this.state.editBranch.secondaryContactMob}
													onChange={this.updateEditingBranch.bind(this, 'secondaryContactMob')} />
											</Col>
											<Col md={4}>
												<label className="control-label">Contact Person Email ID</label>
												<input type="text" className="form-control" placeholder="Person Email ID"
													// value={this.state.editBranch.secondaryContactEmail}
													onChange={this.updateEditingBranch.bind(this, 'secondaryContactEmail')} />
											</Col>
										</Row>
									</Panel>
								</Collapse>
							</Row>
						</Modal.Body>
						<Modal.Footer className="footer-center">
							<Button className="btn brand-color-background" onClick={this.saveEditingBranch}>Save</Button>
							<Button className="btn btn-danger brand-color-background" onClick={this.hideEditingBranch}>Cancel</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}

			</div>
		);
	}
}

import React, { useReducer, useEffect } from "react";
import { Select } from "antd";
import { validate } from "../../util/validators";
const { Option, OptGroup } = Select;
const readCookie = require("../../../cookie.js").readCookie;

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouch: true,
      };
    default:
      return state;
  }
};

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isValid: props.initialValid || false,
    isTouch: false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const touchHandler = (event) => {
    dispatch({ type: "TOUCH" });
  };

  const changeHandler = (event) => {
    // console.log(event.target.value);
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
  };

  const selectChangeHandler = (value) => {
    // console.log(`selected ${value}`);
    dispatch({ type: "CHANGE", val: value, validators: props.validators });
  };

  const element =
    props.element === "textarea" ? (
      <textarea
        id={props.id}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : props.element === "Select" ? (
      <Select
        id={props.id}
        style={{ width: 200 }}
        defaultValue={inputState.value}
        placeholder="Select a person"
        onChange={selectChangeHandler}
        onBlur={touchHandler}
        optionFilterProp="children"
      >
        {props.option && props.name === "state" && (
          <OptGroup label="Focus States">
            {props.option.map((state, stateIndex) => {
              if (state.inFocusRegion) {
                if (
                  JSON.parse(readCookie("userData")).userType.name ===
                  "Swavalamban Fellows" &&
                  JSON.parse(readCookie("userData")).address.state.includes(
                    state.name
                  )
                ) {
                  return (
                    <Option key={stateIndex} value={state.name}>
                      {state.name}
                    </Option>
                  );
                } else if (
                  JSON.parse(readCookie("userData")).userType.name !==
                  "Swavalamban Fellows"
                ) {
                  return (
                    <Option key={stateIndex} value={state.name}>
                      {state.name}
                    </Option>
                  );
                } else return null;
              } else return null;
            })}
          </OptGroup>
        )}
        {props.option && props.name === "state" && (
          <OptGroup label="Not Focussed States">
            {props.option &&
              props.option.map((state, stateIndex) => {
                if (!state.inFocusRegion) {
                  if (
                    JSON.parse(readCookie("userData")).userType.name ===
                    "Swavalamban Fellows" &&
                    JSON.parse(readCookie("userData")).address.state.includes(
                      state.name
                    )
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    );
                  } else if (
                    JSON.parse(readCookie("userData")).userType.name !==
                    "Swavalamban Fellows"
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    );
                  } else return null;
                } else return null;
              })}
          </OptGroup>
        )}
        {props.option && props.name === "district" && (
          <OptGroup label="District">
            {props.option.map((district, districtIndex) => {
              if (
                JSON.parse(readCookie("userData")).userType.name ===
                "Swavalamban Fellows" &&
                JSON.parse(readCookie("userData")).address.district.includes(
                  district.name
                )
              ) {
                return (
                  <Option key={districtIndex} value={district.name}>
                    {district.name}
                  </Option>
                );
              } else if (
                JSON.parse(readCookie("userData")).userType.name !==
                "Swavalamban Fellows"
              ) {
                return (
                  <Option key={districtIndex} value={district.name}>
                    {district.name}
                  </Option>
                );
              } else return null;
            })}
          </OptGroup>
        )}
        {props.option && props.name === "type" && (
          <OptGroup label="Type">
            {props.option.map((type) => {
              return (
                <Option key={type.toString()} value={type}>
                  {type}
                </Option>
              );
            })}
          </OptGroup>
        )}
        {props.option && props.name === "rating/database" && (
          <OptGroup label="Type">
            {props.option.map((type) => {
              return (
                <Option key={type.toString()} value={type}>
                  {type}
                </Option>
              );
            })}
          </OptGroup>
        )}
        {props.option && props.name === "trainingname" && (
          <OptGroup label="Type">
            {props.option.map((type) => {
              return (
                <Option key={type.toString()} value={type}>
                  {type}
                </Option>
              );
            })}
          </OptGroup>
        )}
        {props.option && props.name === "contactType" && (
          <OptGroup label="Type">
            {props.option.map((type) => {
              return (
                <Option key={type.toString()} value={type}>
                  {type}
                </Option>
              );
            })}
          </OptGroup>

        )}
      </Select>
    ) : props.type === "input" ? (
      <input
        id={props.id}
        type={props.type}
        value={inputState.value}
        onChange={changeHandler}
        onBlur={touchHandler}
      />
    ) : (
      <input
        id={props.id}
        type={props.type}
        value={inputState.value}
        onChange={changeHandler}
        onBlur={touchHandler}
        min={props.min}
        max={props.max}
      />
    );

  return (
    <div
      className={`remove-error ${!inputState.isValid && inputState.isTouch && "error-message"
        }`}
    >
      {element}
      {!inputState.isValid && inputState.isTouch && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row, Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

const readCookie = require('../../partials/cookie.js').readCookie;
const { SearchBar } = Search;

export default class Members extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: [],
			allRepo: [],
			members: [{
				id: 'sad876sdfs876fsd76f8',
				fullName: 'Monika Shukla',
				mobileNo: '7204021821',
				registeredOn: '2019-07-04T01:39:26.876Z',
				email: 'monika@letsendorse.com'
			}, {
				id: '78sd6d6fsd87f67fs78s',
				fullName: 'Subham Chatterjee',
				mobileNo: '8906758008',
				registeredOn: '2019-08-11T05:03:26.876Z',
				email: 'subham.b.chatterjee@gmail.com'
			}, {
				id: '12321c127y7dscgd8cs3',
				fullName: 'Sayan Ghosh',
				mobileNo: '9679646588',
				registeredOn: '2019-08-12T06:12:26.876Z',
				email: 'sayanghsh3@gmail.com'
			}, {
				id: '7as6d5as67d5as67d5as',
				fullName: 'Vishal Gangwar',
				mobileNo: '9823782942',
				registeredOn: '2019-09-16T02:56:26.876Z',
				email: 'gangwarvishal655@gmail.com'
			}],
			searchVal: '',
			currentPage: 1,
			sizePerPage: 20,
			filteredRepo: [],
			totalDataSize: 0,
			importUsers: null,
			deletableMembers: [],
			selectedRowCount: 0,
			addMemberModal: false,
			importMemberModal: false,
			deleteButtonDisabled: true,
			addUser: {
				fullName: '',
				email: '',
				mobileNo: ''
			},
			selectRowProp: {
				mode: 'checkbox',
				onSelect: this.onRowSelect.bind(this),
				onSelectAll: this.onAllRowSelect.bind(this)
			},
		}
	}

	componentWillMount() {
	}

	onPageChange = (page, sizePerPage) => {
	}

	onSizePerPageList = (sizePerPage) => {
	}

	openAddMember = () => {
		this.setState({ addMemberModal: true });
	}

	closeAddMember = () => {
		this.setState({ addMemberModal: false, addUser: { fullName: '', email: '', mobileNo: '' }, error: [] });
	}

	openImportMember = () => {
		this.setState({ importMemberModal: true });
	}

	closeImportMember = () => {
		this.setState({ importMemberModal: false, importUsers: null }, () => { this.getUsers(); });
	}

	createCustomButtonGroup = (props, onClick) => {
		return (
			<div className='my-custom-class' sizeClass='btn-group-md'>
				<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.getUsers}>
					<span>
						<i className="glyphicon glyphicon-refresh"></i>
						Refresh
					</span>
				</button>
				<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
					style={{ marginLeft: '5px' }} onClick={this.openAddMember}>
					<span>
						<i className="fa fa-user-plus" aria-hidden="true"></i>
						Add Member
					</span>
				</button>
				<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
					style={{ marginLeft: '5px' }} onClick={this.deleteMultipleUser} disabled={this.state.deleteButtonDisabled}>
					<span>
						<i className="fa fa-trash" aria-hidden="true"></i>
						Remove Users
					</span>
				</button>
			</div>
		);
	}

	createCustomClearButton = (onClick) => {
		return (
			<span onClick={onClick}>
				<i className="clear-table-search glyphicon glyphicon-remove"></i>
			</span>
		);
	}

	formatDate(cell, row) {
		return moment(cell).format('DD/MM/YYYY hh:mm A')
	}

	dataFormat(cell, row) {
		if (cell) {
			return (
				<div title={cell}>{cell.toString()}</div>
			)
		}
	}

	addUserOnChangeHandler = (type, e) => {
		let addUser = this.state.addUser, error = this.state.error;
		addUser[type] = e.target.value;
		if (e.target.value !== '') {
			if (error.length && error.indexOf(type) > -1) error.splice(error.indexOf(type), 1);
		} else {
			if (error.indexOf(type) === -1) error.push(type);
		}
		this.setState({ addUser, error });
	}

	submitUserHandler = () => {
		let dataObj = this.state.addUser;
		if (dataObj.fullName && dataObj.mobileNo && dataObj.email) {
			fetch(process.env.REACT_APP_API_URL + '/add-user', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token'))
				},
				body: JSON.stringify(dataObj)
			}).then(data => data.json())
				.then(data => {
					if (data.status === 'ok') {
						if (data.showMessage) this.notifySuccess(data.message);
						else this.notifySuccess('User has been added.');
						this.closeAddMember();
					} else {
						this.notifyError('User addition failed');
						this.closeAddMember();
					}
				});
		} else {
			this.notifyError('Please fill up the mandatory fields.');
		}
	}

	notifyError = (text) => toast.error(text);

	notifySuccess = (text) => toast.success(text);

	importOnChangeHandler = (e) => {
		var files = e.target.files;
		if (files.length) this.setState({ importUsers: files[0] });
		else this.setState({ importUsers: null });
	}

	importMemberHandler = (e) => {
		var file = this.state.importUsers, formData = new FormData();
		if (file !== null) {
			formData.append("data_sheet", file)
			fetch(process.env.REACT_APP_API_URL + '/add-users', {
				method: 'POST',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				},
				body: formData
			}).then(data => data.json())
				.then(data => {
					if (data.status === 'ok') {
						if (data.showMessage) this.notifySuccess(data.message);
						else this.notifySuccess('User(s) have been added.');
						this.closeImportMember();
					} else {
						this.notifyError('User(s) addition failed');
						this.closeImportMember();
					}
				})
		} else {
			this.notifyError('Please select a supported file to upload');
		}
	}

	onRowSelect(row, isSelected) {
		let deletableMembers = this.state.deletableMembers, deleteButtonDisabled = true;
		let id = row['id'];
		if (isSelected) {
			deletableMembers.push(id);
			this.setState({ selectedRowCount: this.state.selectedRowCount + 1, deletableMembers, deleteButtonDisabled: false });
		} else {
			deletableMembers.splice(deletableMembers.indexOf(id), 1);
			if (this.state.selectedRowCount - 1) deleteButtonDisabled = false;
			this.setState({ selectedRowCount: this.state.selectedRowCount - 1, deletableMembers, deleteButtonDisabled });
		}
	}

	onAllRowSelect(isSelected, row) {
		if (isSelected) {
			let members = this.state.members;
			let deletableMembers = [];
			for (var i = 0; i < members.length; i++) {
				deletableMembers.push(members[i]['id']);
			}
			let deleteButtonDisabled = true;
			if (deletableMembers.length) deleteButtonDisabled = false
			this.setState({
				deletableMembers,
				deleteButtonDisabled,
				selectedRowCount: this.state.members.length
			});
		} else {
			this.setState({
				selectedRowCount: 0,
				deletableMembers: [],
				deleteButtonDisabled: true
			});
		}
	}

	deleteMultipleUser = () => {
		if (this.state.deletableMembers.length > 0) {
			Swal.fire({
				title: 'Are you sure?',
				text: `You won't be able to revert this action.`,
				type: 'warning',
				showCancelButton: true,
				cancelButtonColor: '#EF1313',
				confirmButtonText: 'Yes, I am sure!',
				cancelButtonText: "No, cancel it!",
				closeOnConfirm: true,
				closeOnCancel: true
			}).then((result) => {
				if (result.value) {
					let dataObj = {};
					dataObj['userIds'] = this.state.deletableMembers;
					fetch(process.env.REACT_APP_API_URL + '/delete-org-users', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Auth': JSON.parse(readCookie('access_token'))
						},
						body: JSON.stringify(dataObj)
					}).then(data => data.json())
						.then(data => {
							if (data.status === 'ok') {
								this.setState({ selectedRowCount: 0, deleteButtonDisabled: true, deletableMembers: [] }, () => {
									this.notifySuccess('User has been removed.');
									this.getUsers();
								})
							} else {
								this.notifyError('Request failed.');
							}
						})
				}
			});
		}
	}

	deleteUserHandler(cell) {
		if (cell) {
			let dataObj = {};
			dataObj['userIds'] = [cell];
			fetch(process.env.REACT_APP_API_URL + '/delete-org-users', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Auth': JSON.parse(readCookie('access_token'))
				},
				body: JSON.stringify(dataObj)
			}).then(data => data.json())
				.then(data => {
					if (data.status === 'ok') {
						this.notifySuccess('User has been removed.');
						this.getUsers();
					} else {
						this.notifyError('Request failed.');
					}
				});
		}
	}

	deleteUserFormatter = (cell, row) => {
		if (cell) {
			return (
				<button className="btn btn-danger btn-remove-user" onClick={this.deleteUserHandler.bind(this, cell)}>Remove</button>
			)
		} else return null;
	}

	render() {
		const columns = [{
			dataField: 'id',
			text: '',
			width: '0',
			sort: false,
			hidden: true,
			export: true,
			searchable: false
		}, {
			dataField: 'fullName',
			text: 'Full Name',
			width: '0',
			sort: true,
			hidden: false,
			export: true,
			searchable: true
		}, {
			dataField: 'registeredOn',
			text: 'Registered On',
			width: '0',
			sort: true,
			hidden: false,
			export: true,
			formatter: this.formatDate.bind(this),
			searchable: true
		}, {
			dataField: 'mobileNo',
			text: 'Mobile',
			width: '0',
			sort: true,
			hidden: false,
			export: true,
			searchable: true
		}, {
			dataField: 'email',
			text: 'Email',
			width: '0',
			sort: true,
			hidden: false,
			export: true,
			searchable: true
		}];

		return (
			<div className="user-settings-container">
				<Row className="user-settings-content-container">
					<Col md={12} className="user-settings-table">
						<ToolkitProvider keyField="id" data={this.state.members} columns={columns} search={{ searchFormatted: true }}>
							{props => (
								<div>
									<Row className="mt10">
										<Col>
											<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'} onClick={this.getUsers}>
												<span>
													<i className="glyphicon glyphicon-refresh"></i>
													Refresh
												</span>
											</button>
											<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
												style={{ marginLeft: '5px' }} onClick={this.openAddMember}>
												<span>
													<i className="fa fa-user-plus" aria-hidden="true"></i>
													Add Member
												</span>
											</button>
											<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
												style={{ marginLeft: '5px' }} onClick={this.deleteMultipleUser} disabled={this.state.deleteButtonDisabled}>
												<span>
													<i className="fa fa-trash" aria-hidden="true"></i>
													Remove Users
												</span>
											</button>
										</Col>
										<Col className="members-table-search-container">
											<SearchBar {...props.searchProps} placeholder="Search by Name/Registration Date/Mobile/Email" className="members-table-search" delay={500} />
										</Col>
									</Row>
									<BootstrapTable	{...props.baseProps} selectRow={this.state.selectRowProp} ref={(table) => { this.table = table; }} />
								</div>
							)}
						</ToolkitProvider>
					</Col>
				</Row>
				<Modal className="add-user-modal" show={this.state.addMemberModal} onHide={this.closeAddMember}>
					<Modal.Header className="user-modal-header">
						<Modal.Title className="modal-header-custom">Add user</Modal.Title>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<Row className="body-container">
							<Col md={12}>
								<label className="add-user-name-text control-label is-imp">Enter user name</label>
								<input type="text" placeholder={this.state.error.indexOf('fullName') > -1 ? "Full name cannot be empty" : "Full name"}
									className={this.state.error.indexOf('fullName') > -1 ? 'add-user-name-error form-control' : 'add-user-name form-control'}
									value={this.state.addUser.fullName} onChange={this.addUserOnChangeHandler.bind(this, 'fullName')}
								/>
							</Col>
							<Col md={12}>
								<label className="add-user-email-text control-label is-imp">Enter user email</label>
								<input type="email" placeholder={this.state.error.indexOf('email') > -1 ? "Email cannot be empty" : "Email"}
									className={this.state.error.indexOf('email') > -1 ? 'add-user-email-error form-control' : 'add-user-email form-control'}
									value={this.state.addUser.email} onChange={this.addUserOnChangeHandler.bind(this, 'email')}
								/>
							</Col>
							<Col md={12}>
								<label className="add-user-number-text control-label is-imp">Enter user mobile number</label>
								<input type="text" placeholder={this.state.error.indexOf('mobileNo') > -1 ? "Mobile number cannot be empty" : "Mobile number"}
									className={this.state.error.indexOf('mobileNo') > -1 ? 'add-user-number-error form-control' : 'add-user-number form-control'}
									value={this.state.addUser.mobileNo} onChange={this.addUserOnChangeHandler.bind(this, 'mobileNo')}
								/>
							</Col>
						</Row>
						<div className="modal-footer mt10">
							<div className="add-user-btn-container">
								<button className="btn btn-default add-user-btn mr10" disabled={!this.state.addUser.email || !this.state.addUser.fullName || !this.state.addUser.mobileNo} onClick={this.submitUserHandler}>Submit</button>
								<button className="btn btn-danger" onClick={this.closeAddMember}>Cancel</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal className="import-user-modal" show={this.state.importMemberModal} onHide={this.closeImportMember}>
					<Modal.Header className="import-modal-header">
						<Modal.Title className="modal-header-custom">Import Users</Modal.Title>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<Row md={12} className="body-conatiner text-center">
							<label className="import-user-file control-label">Import user(s) file</label>
							<note>Note: Maximum file size 5MB, only .csv and .xlsx files supported.</note>
							<input type='file' accept='.csv,.xlsx' className="form-control mb10 mt10 ml10" style={{ width: '97%' }} onChange={this.importOnChangeHandler} />
						</Row>
						<div className="modal-footer mt10">
							<div className="add-user-btn-container">
								<button className="btn btn-default add-user-btn mr10" disabled={this.state.importUsers === null} onClick={this.importMemberHandler}>Submit</button>
								<button className="btn btn-danger" onClick={this.closeImportMember}>Cancel</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

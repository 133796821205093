import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const readCookie = require('../cookie.js').readCookie;

export default class ModuleManagement extends Component {
	state = {
		modules: [],
		moduleModalType: '',
		moduleModalData: null,
		moduleModalHeading: '',
		moduleModalDataError: {},
		moduleModalVisibility: false,
	}

	componentDidMount() {
		this.getModules();
	}

	getModules = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/module', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ modules: data.modules });
				}
			});
	}

	changeModuleModalVisibility = (visibility) => {
		this.setState({ moduleModalVisibility: visibility });
	}

	openModuleModal = (type, module) => {
		let moduleModalData = {}, moduleModalHeading = '', moduleModalType = type;
		if (type === 'add') {
			moduleModalHeading = 'Add New Module';
			moduleModalData = {
				name: '',
				key: '',
				permissions: [{ name: '', key: '' }]
			};
		} else if (type === 'edit' && module) {
			moduleModalHeading = 'Edit Module';
			module = JSON.parse(module);
			moduleModalData = {
				id: module.id,
				name: module.name,
				key: module.key,
				permissions: module.permissions
			};
		}
		this.setState({ moduleModalData, moduleModalHeading, moduleModalType, moduleModalVisibility: true });
	}

	changeModuleDataHandler = (type, x, y) => {
		let moduleModalData = this.state.moduleModalData;
		if (type === 'name' || type === 'key') {
			moduleModalData[type] = x.target.value;
		} else if (type === 'permissionName' || type === 'permissionKey') {
			type = type.split('permission')[1].toLowerCase();
			moduleModalData.permissions[x][type] = y.target.value;
		} else if (type === 'permissionAdd') {
			moduleModalData.permissions.push({ name: '', key: '' });
		} else if (type === 'permissionDelete') {
			moduleModalData.permissions.splice(x, 1);
		}
		this.setState({ moduleModalData });
	}

	checkModuleModalData = (cb) => {
		let moduleModalData = this.state.moduleModalData;
		if (moduleModalData.name === '' || moduleModalData.key === '') cb('Please enter required data');
		else if (!moduleModalData.permissions.length) cb('Please enter permissions');
		else {
			let error = false;
			for (var i = 0; i < moduleModalData.permissions.length; i++) {
				if (moduleModalData.permissions[i].name === '' || moduleModalData.permissions[i].key === '') {
					error = true;
				}
			}
			if (error) cb('Please enter required data');
			else cb(false);
		}
	}

	submitModuleModal = () => {
		this.checkModuleModalData((err) => {
			if (err) this.notifyError(err);
			else {
				let moduleModalData = this.state.moduleModalData,
					moduleModalType = this.state.moduleModalType;
				if (moduleModalType === 'add') {
					fetch(process.env.REACT_APP_API_URL + '/api/v1/module', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'x-auth-token': JSON.parse(readCookie('access_token'))
						},
						body: JSON.stringify(moduleModalData)
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								this.notifySuccess(data.message);
								this.getModules();
								this.changeModuleModalVisibility(false);
							} else {
								this.notifyError(data.message);
							}
						});
				} else if (moduleModalType === 'edit') {
					console.log(moduleModalData)
					fetch(process.env.REACT_APP_API_URL + '/api/v1/module/' + moduleModalData.id, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							'x-auth-token': JSON.parse(readCookie('access_token'))
						},
						body: JSON.stringify(moduleModalData)
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								this.notifySuccess(data.message);
								this.getModules();
								this.changeModuleModalVisibility(false);
							} else {
								this.notifyError(data.message);
							}
						});
				}
			}
		});
	}

	notifyError = (text) => toast.error(text);

	notifySuccess = (text) => toast.success(text);

	render() {
		return (
			<div className="module-management-page page-container">
				<h3>Module Management</h3>
				<div className="d-flex">
					<button className={'btn btn-success'} style={{ marginLeft: '5px' }} onClick={() => this.openModuleModal('add')}>
						<span>
							<i className="fa fa-plus" aria-hidden="true"></i>
							Add Module
						</span>
					</button>
				</div>
				<div className="modules-container">
					{this.state.modules.map((module, index) => {
						return (
							<div key={index} className="module-container">
								<div className="d-flex module-name-container">
									<span className="module-name">{module.name}</span>
									<button className="btn btn-success edit-module-btn" onClick={() => this.openModuleModal('edit', JSON.stringify(module))}>
										<span>
											<i className="fa fa-pencil" aria-hidden="true"></i>
											Edit
										</span>
									</button>
								</div>
								<div className="d-flex module-permissions-container">
									{module.permissions.map((permission, permissionIndex) => {
										return (
											<div key={permissionIndex} className="module-permission">{permission.name}</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>

				{this.state.moduleModalData !== null ? (
					<Modal show={this.state.moduleModalVisibility} onHide={() => this.changeModuleModalVisibility(false)} size='lg' className="module-modal-container">
						<Modal.Header closeButton>
							<Modal.Title>{this.state.moduleModalHeading}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row>
								<Col>
									<label className="control-label is-imp">Module Name</label>
									<input type="text" placeholder="Enter Module Name" value={this.state.moduleModalData.name} className="form-control" onChange={this.changeModuleDataHandler.bind(this, 'name')} style={this.state.moduleModalData.nameError ? { borderColor: 'red' } : {}} />
								</Col>
								<Col>
									<label className="control-label is-imp">Module Key</label>
									<input type="text" placeholder="Enter Module Key" value={this.state.moduleModalData.key} className="form-control" onChange={this.changeModuleDataHandler.bind(this, 'key')} style={this.state.moduleModalData.keyError ? { borderColor: 'red' } : {}} />
								</Col>
							</Row>
							<div className="module-modal-permission-heading">Permissions</div>
							{this.state.moduleModalData.permissions.map((permissionA, permissionIndex) => {
								return (
									<Row key={permissionIndex + 'asd'} className="mb10 permission-container">
										<Col>
											<label className="control-label is-imp">Permission Name</label>
											<input type="text" placeholder="Enter Permission Name" value={permissionA.name} className="form-control" onChange={this.changeModuleDataHandler.bind(this, 'permissionName', permissionIndex)} style={permissionA.nameError ? { borderColor: 'red' } : {}} />
										</Col>
										<Col>
											<label className="control-label is-imp">Permission Key</label>
											<input type="text" placeholder="Enter Permission Key" value={permissionA.key} className="form-control" onChange={this.changeModuleDataHandler.bind(this, 'permissionKey', permissionIndex)} style={permissionA.keyError ? { borderColor: 'red' } : {}} />
										</Col>
										<div>
											<Button variant="secondary" onClick={this.changeModuleDataHandler.bind(this, 'permissionDelete', permissionIndex)}>
												Delete
											</Button>
										</div>
									</Row>
								)
							})}
							<div className="add-module-permission-btn-container text-center">
								<button className="btn btn-success add-module-permission-btn" onClick={this.changeModuleDataHandler.bind(this, 'permissionAdd')}>
									<i className="fa fa-plus" aria-hidden="true"></i>
									Add New Permission
								</button>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => this.changeModuleModalVisibility(false)}>
								Cancel
							</Button>
							<Button variant="primary" onClick={this.submitModuleModal}>
								Save Changes
							</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}
			</div>
		);
	}
}

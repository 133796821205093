/* eslint-disable jsx-a11y/anchor-is-valid */
import $ from 'jquery';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import React, { Component } from 'react';
import {
	Col, Row,
	Button,
	// Modal
} from 'react-bootstrap';
import {
	// DatePicker,
	Steps, Select, Icon,
	// Switch
} from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import NewLeadAdvancedForm from './AddNewLeadForms/NewLeadAdvancedForm';
import NewLeadBackgroundForm from './AddNewLeadForms/NewLeadBackgroundForm';
import NewLeadFinancialForm from './AddNewLeadForms/NewLeadFinancialForm';
// import NewLeadCommentsForm from './AddNewLeadForms/NewLeadCommentsForm';

const readCookie = require('../cookie.js').readCookie;
const specializationsOfEducation = ["Agriculture", "Architecture", "Arts", "Business", "Commerce", "Computer Application", "Dentistry", "Design", "Economics", "Education", "Engineering", "Environment", "Language", "Law", "Liberal Arts", "Medicine", "Multimedia", "Nursing", "Nutrition", "Performing Arts", "Pharmacy", "Physiotherapy", "Sciences", "Social Work", "Tourism"];

const { Step } = Steps,
	mobileRegex = /^[6-9]\d{9}$/,
	fullNameRegex = /^[a-zA-Z0-9()\/\s\.]+$/,
	altMobileRegex = /^[0-9]*$/;
const { Option, OptGroup } = Select;

export default class CreateNewLead extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorObj: {},
			allUsers: [],
			isIvrs: false,
			allStates: [],
			allCities: [],
			allBlocks: [],
			leadData: null,
			allDistricts: [],
			allUserTypes: [],
			allNativeCities: [],
			allNativeDistricts: [],
			mobileNumberStatus: '',
		};
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allStates: data.data.states });
				}
			});

		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-types', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allUserTypes: data.userTypes });
				}
			});

		let leadData = {
			leadInfo: {
				userType: '',
				assignTo: ''
			},
			basic: {
				mobileNumber: '',
				name: '',
				address: {
					city: '',
					line1: '',
					line2: '',
					state: '',
					block: '',
					district: '',
					country: 'India'
				},
				nativeOf: {
					city: '',
					state: '',
					district: ''
				},
				// creditLinked: '',
				gender: '',
				pincode: '',
				source: 'Agent',
				altMobileNumber: '',
				generatedThrough: '',
				numberOfFamilyMember: "",
				numberOfDependents: '',
			},
			background: {
				educationalQualification: '',
				specializationOfEducation: '',
				religion: '',
				category: '',
				pwd: '',
				employmentStatus: '',
				employer: '',
				currentAnnualIncome: '',
				experience: '',
				// numberOfFamilyMember: '',
				// numberOfDependents: '',
				backgroundAndStability: '',
				dob: '',
				identityType: '',
				identityNumber: ''
			},
			advanced: {
				hasLand: '',
				relationshipWithLandOwner: '',
				landOwnerName: '',
				area: { value: '', unit: '' },
				hasOtherAssets: [],
				collateral: '',
				hasBankAccount: '',
				bankAccounts: [],
				proximityToBank: '',
				receivedBankLoan: '',
				receivedLoanProviderType: '',
				loanType: '',
				loansInstallmentStatus: '',
				loanBanks: [],
				genuinenessAndHumility: '',
				confidenceAsSwavalambi: ''
			},
			financial: {
				hasABusinessIdea: '',
				financeType: '',
				canSelfFinanceTheCapitalRequiredUpToINR: '',
				loanAmountRequired: '',
				businessIdeas: [],
				appliedForEnterpriseLoanBefore: '',
				loanProviderType: '',
				bankName: [],
				statusOfEnterpriseLoan: '',
				loanSizeCategory: '',
				ancestralHistoryOfWorkingInWhichSector: '',
				familyBackground: '',
				experienceInSector: '',
				phase: '',
				creditLinked: '',
			},
			comments: {
				overallSentiment: '',
				tentativeHorizonToCloseThis: ''
			},
			leadMaturity: {
				skillTrainedStatus: '',
				skillTrainingInstituteType: '',
				skillTrainingInstituteName: '',
				skillTrainingInstituteLocation: { label: '' },
				enterpriseTrainedStatus: '',
				enterpriseTrainingInstituteType: '',
				enterpriseTrainingInstituteName: '',
				enterpriseTrainingInstituteLocation: { label: '' }
			}
		}

		if (JSON.parse(readCookie('userData')).type === 'partners') {
			leadData.leadInfo = {
				userType: 'swavalamban-connect-kendra',
				// assignTo: JSON.parse(readCookie('userData')).ngo._id
				assignTo: JSON.parse(readCookie('userData')).udaan ? JSON.parse(readCookie('userData')).udaan : JSON.parse(readCookie('userData')).ngo._id,
			}
		}

		if (this.props.match.params.ivrsId) {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/ivrs/' + this.props.match.params.ivrsId, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						let ivrsData = data.ivrs, mobileNumberStatus = '', errorObj = this.state.errorObj;
						if (ivrsData.mobile.match(/^0/) !== null) ivrsData.mobile = ivrsData.mobile.slice(1);
						leadData.basic.mobileNumber = ivrsData.mobile;
						if (ivrsData.pincode) leadData.basic.pincode = ivrsData.pincode;

						if (ivrsData.mobile.match(mobileRegex) === null) {
							errorObj['mobileNumber'] = 'Invalid Mobile Number!';
							this.setState({ errorObj, leadData });
						} else {
							fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/check-mobile?mobileNumber=' + ivrsData.mobile, {
								method: 'GET',
								headers: {
									'x-auth-token': JSON.parse(readCookie('access_token'))
								}
							}).then(data => data.json())
								.then(data => {
									if (data.status) {
										mobileNumberStatus = 'ok';
										delete errorObj.mobileNumber;
										if (ivrsData.isTrained) leadData.basic.generatedThrough = 'Already trained candidate';
										else leadData.basic.generatedThrough = 'Toll-free call';
										this.setState({ mobileNumberStatus, errorObj, leadData, isIvrs: true });
									} else {
										mobileNumberStatus = 'error';
										errorObj['mobileNumber'] = data.errorMsg;
										this.setState({ mobileNumberStatus, errorObj });
										Swal.fire({
											'title': 'Oops!',
											'text': 'The mobile number already has a Lead.',
											'type': 'error'
										}).then((res) => {
											window.location.pathname = "/ivrs/leads";
										});
									}
								});
						}
					} else {
						Swal.fire({
							'title': 'Oops!',
							'text': 'The mobile number already has a Lead.',
							'type': 'error'
						}).then((res) => {
							window.location.pathname = "/ivrs/leads";
						});
					}
				});
		} else {
			this.setState({ leadData });
		}
	}

	changeHandler = (x, y, e) => {
		let leadData = this.state.leadData, errorObj = this.state.errorObj;
		if (x === 'basic' && y === 'city') {
			leadData[x]['address'][y] = e;
			if (!leadData[x]['nativeOf'][y]) leadData[x]['nativeOf'][y] = e;
		} else if (x === 'basic' && y === 'block') {
			leadData[x]['address'][y] = e;
			// if(!leadData[x]['nativeOf'][y]) leadData[x]['nativeOf'][y] = e;
		}
		else if (x === 'basic' && y === 'addressLine1') {
			leadData[x]['address']['line1'] = e.target.value;
		} else if (x === 'basic' && y === 'addressLine2') {
			leadData[x]['address']['line2'] = e.target.value;
		} else if (x === 'basic' && (y === 'generatedThrough' || y === 'gender')) {
			leadData[x][y] = e;
		} else if (x === 'financial' && y === 'phase') {
			leadData[x][y] = e;
		} else if (x === 'leadInfo' && y === 'userType') {
			leadData[x]['userType'] = e;
			leadData['leadInfo']['assignTo'] = '';
			if (e === 'swavalamban-connect-kendra') {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) this.setState({ allUsers: data.ngos });
					});
			} else {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/' + e, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) this.setState({ allUsers: data.users });
					});
			}
		} else if (y === 'dob') {
			leadData[x][y] = e;
		} else if (x === 'basic' && y === 'nativeCity') {
			leadData[x]['nativeOf']['city'] = e;
		} else if (y === 'areaValue') {
			leadData[x]['area']['value'] = e.target.value;
		} else if (y === 'areaUnit') {
			leadData[x]['area']['unit'] = e;
		} else if (y === 'hasABusinessIdea') {
			let value = e;
			if (e.hasOwnProperty('target')) value = e.target.value;
			leadData[x][y] = value;
			if (y === 'hasABusinessIdea' && !leadData.financial.businessIdeas.length && value !== 'None') this.addNewBusinessIdea();
		} else if (e.hasOwnProperty('target')) {
			if (x === 'financial' && y === 'canSelfFinanceTheCapitalRequiredUpToINR') {
				leadData[x][y] = parseInt(e.target.value); // restricting to an integer
			}
			else {
				leadData[x][y] = e.target.value;
			}
		} else if (x === 'leadMaturity' && (y === 'skillTrainingInstituteLocation' || y === 'enterpriseTrainingInstituteLocation')) {
			leadData[x][y] = { label: e };
			let x1 = x, y1 = y;
			geocodeByAddress(e)
				.then(results => getLatLng(results[0]))
				.then((latLng) => {
					leadData[x1][y1]['latitude'] = latLng.lat;
					leadData[x1][y1]['longitude'] = latLng.lng;
					if (errorObj.hasOwnProperty(y)) delete errorObj[y];
					this.setState({ leadData, errorObj });
				}).catch(error => console.error('Error', error));
		} else {
			leadData[x][y] = e;
		}

		if (x === 'financial' && y === 'financeType' && leadData[x][y] === '100% self-financed') leadData.financial.loanAmountRequired = 0;
		if (errorObj.hasOwnProperty(y)) delete errorObj[y];
		this.setState({ leadData, errorObj });
	}

	checkMobileNumber = () => {
		let mobileNumber = this.state.leadData.basic.mobileNumber, mobileNumberStatus = '', errorObj = this.state.errorObj;
		if (mobileNumber.match(mobileRegex) !== null) {
			delete errorObj.mobileNumber;
			this.setState({ mobileNumberStatus: 'loading', errorObj });
			fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/check-mobile?mobileNumber=' + this.state.leadData.basic.mobileNumber, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						if (data.ivrsId) window.location.pathname = "/ivrs-to-lead/" + data.ivrsId;
						else {
							mobileNumberStatus = 'ok';
							delete errorObj.mobileNumber;
							this.setState({ mobileNumberStatus, errorObj });
						}
					} else {
						mobileNumberStatus = 'error';
						errorObj['mobileNumber'] = data.errorMsg;
						this.setState({ mobileNumberStatus, errorObj });
					}
				});
		} else {
			mobileNumberStatus = 'error';
			errorObj['mobileNumber'] = 'Invalid Mobile';
			this.setState({ mobileNumberStatus, errorObj });
		}
	}

	handleStateDistrictChange = (type, value) => {
		var query = '', leadData = this.state.leadData, errorObj = this.state.errorObj;
		if (type === 'district' && this.state.leadData.basic.address.state && value) query = `?stateId=${this.state.leadData.basic.address.state}&districtId=${value}`;
		else if (type === 'state' && value) query = `?stateId=${value}`;

		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address' + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					if (type === 'state') {
						leadData.basic.address.city = '';
						leadData.basic.address.block = '';
						leadData.basic.address.district = '';
						leadData.basic.address.state = value;
						if (!leadData.basic.nativeOf.state) {
							leadData.basic.nativeOf.city = '';
							leadData.basic.nativeOf.district = '';
							leadData.basic.nativeOf.state = value;
							this.setState({ allNativeDistricts: data.districts });
						}
						this.setState({ allDistricts: data.districts, leadData, allCities: [], allBlocks: [] });
					} else if (type === 'district') {
						leadData.basic.address.city = '';
						leadData.basic.address.block = '';
						leadData.basic.address.district = value;
						if (!leadData.basic.nativeOf.district) {
							leadData.basic.nativeOf.city = '';
							leadData.basic.nativeOf.district = value;
							this.setState({ allNativeCities: data.cities });
						}
						this.setState({ allCities: data.cities, leadData, allBlocks: data.blocks });
					}
				}
			});
		if (errorObj.hasOwnProperty(type)) delete errorObj[type];
		this.setState({ errorObj });
	}

	handleNativeStateDistrictChange = (type, value) => {
		var query = '', leadData = this.state.leadData, errorObj = this.state.errorObj;
		if (type === 'district' && this.state.leadData.basic.nativeOf.state && value) query = `?stateId=${this.state.leadData.basic.nativeOf.state}&districtId=${value}`;
		else if (type === 'state' && value) query = `?stateId=${value}`;

		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address' + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					if (type === 'state') {
						leadData.basic.nativeOf.city = '';
						leadData.basic.nativeOf.district = '';
						leadData.basic.nativeOf.state = value;
						this.setState({ allNativeDistricts: data.districts, leadData, allNativeCities: [] });
					} else if (type === 'district') {
						leadData.basic.nativeOf.city = '';
						leadData.basic.nativeOf.district = value;
						this.setState({ allNativeCities: data.cities, leadData });
					}
				}
			});
		if (type === 'district' && errorObj.hasOwnProperty('nativeDistrict')) delete errorObj['nativeDistrict'];
		else if (type === 'state' && errorObj.hasOwnProperty('nativeState')) delete errorObj['nativeState'];
		this.setState({ errorObj });
	}

	addNewBusinessIdea = (leadData) => {
		let heroIdea = 'false';
		if (leadData) {
			if (!leadData.hasOwnProperty('financial')) leadData = this.state.leadData;
		} else leadData = this.state.leadData;
		if (!leadData.financial.businessIdeas.length) heroIdea = 'true';
		leadData.financial.businessIdeas.push({ _id: leadData.financial.businessIdeas.length + 1, sector: '', subSector: '', enterpriseIdea: '', ideaDetails: '', heroIdea, easeOfIdeaExecution: '', confidenceOfDemand: '', confidenceOfSupply: '', requiresLand: 'false', requiresSkills: 'false', knowledgeLevel: '', supplyFiguredOut: 'false', marketAccessFiguredOut: 'false' });
		this.setState({ leadData });
	}

	deleteIdeaRow = (id, type) => {
		let leadData = this.state.leadData;
		if (type === 'businessIdeas') {
			for (let i = 0; i < leadData.financial.businessIdeas.length; i++) {
				if (leadData.financial.businessIdeas[i]._id === id) {
					leadData.financial.businessIdeas.splice(i, 1);
					break;
				}
			}
		}
		this.setState({ leadData });
	}

	changeBusinessIdeaHandler = (type, data, inKey, e) => {
		let value = e;
		if (e.hasOwnProperty('target')) value = e.target.value;

		let leadData = this.state.leadData;
		if (type === 'businessIdea') {
			for (let i = 0; i < leadData.financial.businessIdeas.length; i++) {
				if (leadData.financial.businessIdeas[i]._id === data._id) {
					leadData.financial.businessIdeas[i][inKey] = value;
				}
			}
		}
		this.setState({ leadData });
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if (!Array.isArray(option.props.children)) {
			if (typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}

	notifySuccess = (text) => toast.success(text)

	notifyError = (text) => toast.error(text)

	save = (type, cb) => {
		this.checkErrors((error) => {
			if (error) {
				if (cb && typeof cb === "function") cb(false);
			} else {
				let leadData = {
					basic: {
						...JSON.parse(JSON.stringify(this.state.leadData)).basic,
						...JSON.parse(JSON.stringify(this.state.leadData)).background
					},
					leadInfo: JSON.parse(JSON.stringify(this.state.leadData)).leadInfo,
					enterpriseStatus: JSON.parse(JSON.stringify(this.state.leadData)).financial,
					familyAndAsset: {
						...JSON.parse(JSON.stringify(this.state.leadData)).advanced,
						ancestralHistoryOfWorkingInWhichSector: JSON.parse(JSON.stringify(this.state.leadData)).financial.ancestralHistoryOfWorkingInWhichSector,
						familyBackground: JSON.parse(JSON.stringify(this.state.leadData)).financial.familyBackground,
						experienceInSector: JSON.parse(JSON.stringify(this.state.leadData)).financial.experienceInSector,
						// numberOfFamilyMember: JSON.parse(JSON.stringify(this.state.leadData)).background.numberOfFamilyMember,
						// numberOfDependents: JSON.parse(JSON.stringify(this.state.leadData)).background.numberOfDependents,
						backgroundAndStability: JSON.parse(JSON.stringify(this.state.leadData)).background.backgroundAndStability
					},
					leadScoreAndSentiment: JSON.parse(JSON.stringify(this.state.leadData)).comments,
					leadMaturity: {
						skillTrained: {
							skillTrainedStatus: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.skillTrainedStatus,
							skillTrainingInstituteType: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteType,
							skillTrainingInstituteName: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteName,
							skillTrainingInstituteLocation: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteLocation
						},
						enterpriseTrained: {
							enterpriseTrainedStatus: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.enterpriseTrainedStatus,
							enterpriseTrainingInstituteType: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteType,
							enterpriseTrainingInstituteName: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteName,
							enterpriseTrainingInstituteLocation: JSON.parse(JSON.stringify(this.state.leadData)).leadMaturity.instituteLocation
						}
					}
				};
				delete leadData.basic.pincode;
				delete leadData.basic.mobileNumber;
				// delete leadData.basic.numberOfDependents;
				// delete leadData.basic.numberOfFamilyMember;
				delete leadData.basic.backgroundAndStability;
				if (leadData.enterpriseStatus.creditLinked === 'Yes') leadData.enterpriseStatus.creditLinked = true;
				else leadData.enterpriseStatus.creditLinked = false;

				if (leadData.enterpriseStatus.businessIdeas.length) {
					for (let i = 0; i < leadData.enterpriseStatus.businessIdeas.length; i++) {
						leadData.enterpriseStatus.businessIdeas[i].heroIdea = leadData.enterpriseStatus.businessIdeas[i].heroIdea === 'true' ? true : false;
						leadData.enterpriseStatus.businessIdeas[i].requiresLand = leadData.enterpriseStatus.businessIdeas[i].requiresLand === 'true' ? true : false;
						leadData.enterpriseStatus.businessIdeas[i].requiresSkills = leadData.enterpriseStatus.businessIdeas[i].requiresSkills === 'true' ? true : false;
						leadData.enterpriseStatus.businessIdeas[i].supplyFiguredOut = leadData.enterpriseStatus.businessIdeas[i].supplyFiguredOut === 'true' ? true : false;
						leadData.enterpriseStatus.businessIdeas[i].marketAccessFiguredOut = leadData.enterpriseStatus.businessIdeas[i].marketAccessFiguredOut === 'true' ? true : false;
					}
				}
				if (leadData['enterpriseStatus']['appliedForEnterpriseLoanBefore'] === 'Yes') leadData['enterpriseStatus']['appliedForEnterpriseLoanBefore'] = true;
				else leadData['enterpriseStatus']['appliedForEnterpriseLoanBefore'] = false;
				delete leadData['enterpriseStatus']['familyBackground'];
				delete leadData['enterpriseStatus']['experienceInSector'];
				delete leadData['enterpriseStatus']['ancestralHistoryOfWorkingInWhichSector'];
				// if (leadData.familyAndAsset.numberOfFamilyMember) leadData.familyAndAsset.numberOfFamilyMember = parseInt(leadData.familyAndAsset.numberOfFamilyMember);
				if (leadData.basic.numberOfFamilyMember) leadData.basic.numberOfFamilyMember = parseInt(leadData.basic.numberOfFamilyMember);
				// if (leadData.familyAndAsset.numberOfDependents) leadData.familyAndAsset.numberOfDependents = parseInt(leadData.familyAndAsset.numberOfDependents);
				if (leadData.basic.numberOfDependents) leadData.basic.numberOfDependents = parseInt(leadData.basic.numberOfDependents);

				if (leadData.familyAndAsset.collateral === 'Yes') leadData.familyAndAsset.collateral = true;
				else leadData.familyAndAsset.collateral = false;

				if (leadData.familyAndAsset.hasLand === 'Yes') leadData.familyAndAsset.hasLand = true;
				else leadData.familyAndAsset.hasLand = false;

				if (leadData.familyAndAsset.hasBankAccount === 'Yes') leadData.familyAndAsset.hasBankAccount = true;
				else leadData.familyAndAsset.hasBankAccount = false;

				this.updateLead(leadData, type, cb);
			}
		});
	}

	updateLead = (data, type, cb) => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/' + this.props.match.params.leadId, {
			method: "PATCH",
			headers: {
				'Content-Type': 'application/json',
				'x-auth-token': JSON.parse(readCookie('access_token'))
			},
			body: JSON.stringify(data)
		}).then((response) => {
			return response.json();
		}).then((data) => {
			if (data.status) {
				if (type === 'notify') this.notifySuccess("Successfully updated Lead!");
				if (cb && typeof cb === "function") cb(true);
			} else {
				this.notifyError("Update failed!");
				if (cb && typeof cb === "function") cb(false);
			}
		});
	}

	checkErrors = (cb) => {
		let errorObj = {}, leadData = this.state.leadData, error = false;

		if (this.state.mobileNumberStatus !== 'ok') { errorObj['mobileNumber'] = 'Invalid Mobile Number!'; error = true; }
		if (leadData.basic.altMobileNumber) {
			if (leadData.basic.altMobileNumber.match(altMobileRegex) === null) { errorObj['altMobileNumber'] = 'Invalid Alt-Mobile Number!'; error = true; }
		}
		if (!leadData.basic.name  || !fullNameRegex.test(leadData.basic.name)) { errorObj['name'] = 'Invalid Full Name!'; error = true; }
		if (!leadData.basic.address.state) { errorObj['state'] = 'Invalid State!'; error = true; }
		if (this.state.allDistricts.length && !leadData.basic.address.district) { errorObj['district'] = 'Invalid District!'; error = true; }
		if (this.state.allCities.length && !leadData.basic.address.city) { errorObj['city'] = 'Invalid City!'; error = true; }
		if (this.state.allBlocks.length && !leadData.basic.address.block) { errorObj['block'] = 'Invalid Block!'; error = true; }
		if (!leadData.basic.address.line1) { errorObj['addressLine1'] = 'Invalid Address Line 1!'; error = true; }
		if (!leadData.financial.phase) { errorObj['phase'] = 'Invalid Phase!'; error = true; }
		else if (leadData.financial.phase === 'Scale-up') {
			if (!leadData.enterpriseStatus.creditLinked) { errorObj['creditLinked'] = 'Please select a correct value!'; error = true; }
		}
		if (!leadData.basic.gender) { errorObj['gender'] = 'Invalid Gender!'; error = true; }
		if (!leadData.basic.pincode || leadData.basic.pincode.toString().match(/^\d{6}$/) === null) { errorObj['pincode'] = 'Invalid Pincode!'; error = true; }
		if (!leadData.basic.generatedThrough) { errorObj['generatedThrough'] = 'Invalid Generated Through!'; error = true; }
		if (JSON.parse(readCookie('userData')).type !== 'partners') {
			if (!leadData.leadInfo.userType) { errorObj['userType'] = 'Invalid User Type!'; error = true; }
			if (!leadData.leadInfo.assignTo) { errorObj['assignTo'] = 'Invalid Assigned To!'; error = true; }
		}

		if (!leadData.financial.hasABusinessIdea) { errorObj['hasABusinessIdea'] = 'Invalid Business Idea selected!'; error = true; }
		if (!leadData.financial.financeType) { errorObj['financeType'] = 'Invalid Finance Status selected!'; error = true; }
		if (leadData.basic.altMobileNumber === leadData.basic.mobileNumber)
		{
			errorObj['altMobileNumber'] = 'Alternate mobile no. can\'t be same as the primary number!';
			error = true;
		} else if (leadData.basic.altMobileNumber.length && leadData.basic.altMobileNumber.length !== 10) {
			errorObj['altMobileNumber'] = 'Alternate mobile number should be a valid 10 digit mobile number!';
			error = true;
		}
		if (leadData.financial.canSelfFinanceTheCapitalRequiredUpToINR === '') { errorObj['canSelfFinanceTheCapitalRequiredUpToINR'] = 'Invalid self finance amount!'; error = true; }
		else if (parseInt(leadData.financial.canSelfFinanceTheCapitalRequiredUpToINR) < 1000) { errorObj['canSelfFinanceTheCapitalRequiredUpToINR'] = 'Minimum Self finance amount is 1000 INR!'; error = true; }
		if (leadData.financial.financeType === "Bank Loan required" && !leadData.financial.loanAmountRequired) { errorObj['loanAmountRequired'] = 'Invalid loan amount!'; error = true; }
		if (leadData.financial.loanAmountRequired) {
			if (parseInt(leadData.financial.loanAmountRequired) < 1000) { errorObj['loanAmountRequired'] = 'Minimum loan amount is 1000 INR!'; error = true; }
		}
		if (leadData.financial.hasABusinessIdea && leadData.financial.hasABusinessIdea !== "None") {
			if (!leadData.financial.businessIdeas.length) { errorObj['businessIdeas'] = 'Invalid business ideas enlisted!'; error = true; }
			else {
				let businessIdeasError = false;
				for (let i = 0; i < leadData.financial.businessIdeas.length; i++) {
					if (!leadData.financial.businessIdeas[i].enterpriseIdea || !leadData.financial.businessIdeas[i].ideaDetails || !leadData.financial.businessIdeas[i].easeOfIdeaExecution || !leadData.financial.businessIdeas[i].confidenceOfDemand || !leadData.financial.businessIdeas[i].confidenceOfSupply) businessIdeasError = true;
				}
				if (businessIdeasError) { errorObj['businessIdeas'] = 'Please fill the mandatory fields for the business ideas enlisted!'; error = true; }
			}
		}
		if (!leadData.financial.appliedForEnterpriseLoanBefore) { errorObj['appliedForEnterpriseLoanBefore'] = 'Please select a correct value!'; error = true; }
		if (!leadData.leadMaturity.skillTrainedStatus) { errorObj['skillTrainedStatus'] = 'Please select a skill training status!'; error = true; }
		else {
			if (["Skill training ongoing", "Skill training completed and has certificate", "Skill training completed but no certificate"].indexOf(leadData.leadMaturity.skillTrainedStatus) > -1) {
				if (!leadData.leadMaturity.skillTrainingInstituteType) { errorObj['skillTrainingInstituteType'] = 'Invalid skill training institute type!'; error = true; }
				if (!leadData.leadMaturity.skillTrainingInstituteName) { errorObj['skillTrainingInstituteName'] = 'Invalid skill training institute name!'; error = true; }
				if (!leadData.leadMaturity.skillTrainingInstituteLocation.label || !leadData.leadMaturity.skillTrainingInstituteLocation.latitude || !leadData.leadMaturity.skillTrainingInstituteLocation.longitude) { errorObj['skillTrainingInstituteLocation'] = 'Invalid skill training institute location!'; error = true; }
			}
		}
		if (!leadData.leadMaturity.enterpriseTrainedStatus) { errorObj['enterpriseTrainedStatus'] = 'Please select a EDP training status!'; error = true; }
		else {
			if (["EDP ongoing", "Completed EDP with certificate", "Completed EDP without certificate"].indexOf(leadData.leadMaturity.enterpriseTrainedStatus) > -1) {
				if (!leadData.leadMaturity.enterpriseTrainingInstituteType) { errorObj['enterpriseTrainingInstituteType'] = 'Invalid EDP training institute type!'; error = true; }
				if (!leadData.leadMaturity.enterpriseTrainingInstituteName) { errorObj['enterpriseTrainingInstituteName'] = 'Invalid EDP training institute name!'; error = true; }
				if (!leadData.leadMaturity.enterpriseTrainingInstituteLocation.label || !leadData.leadMaturity.enterpriseTrainingInstituteLocation.latitude || !leadData.leadMaturity.enterpriseTrainingInstituteLocation.longitude) { errorObj['enterpriseTrainingInstituteLocation'] = 'Invalid EDP training institute location!'; error = true; }
			}
		}
		if (!leadData.financial.familyBackground) { errorObj['familyBackground'] = 'Invalid Family Background selected!'; error = true; }
		if (leadData.financial.experienceInSector === '') { errorObj['experienceInSector'] = 'Invalid experience in sector value!'; error = true; }

		if (!leadData.background.educationalQualification) { errorObj['educationalQualification'] = 'Invalid educational qualification selected!'; error = true; }
		else if (["Diploma", "graduate", "post graduate", "Doctorate"].indexOf(leadData.background.educationalQualification) > -1) {
			if (!leadData.background.specializationOfEducation) { errorObj['specializationOfEducation'] = 'Invalid specialization of education selected!'; error = true; }
		}
		if (!leadData.background.category) { errorObj['category'] = 'Invalid category selected!'; error = true; }
		if (!leadData.background.pwd) { errorObj['pwd'] = 'Invalid selection!'; error = true; }
		if (!leadData.background.employmentStatus) { errorObj['employmentStatus'] = 'Invalid employment status selected!'; error = true; }
		if (!leadData.background.currentAnnualIncome) { errorObj['currentAnnualIncome'] = 'Invalid current annual income selected!'; error = true; }
		if (!leadData.background.experience && parseInt(leadData.background.experience) !== 0) { errorObj['experience'] = 'Invalid total work experience!'; error = true; }
		// if (leadData.background.numberOfFamilyMember === '') { errorObj['numberOfFamilyMember'] = 'Invalid total number of family members!'; error = true; }
		if (leadData.basic.numberOfFamilyMember === '') { errorObj['numberOfFamilyMember'] = 'Invalid total number of family members!'; error = true; }
		// if (leadData.background.numberOfDependents === '') { errorObj['numberOfDependents'] = 'Invalid total number of dependents!'; error = true; }
		if (leadData.basic.numberOfDependents === '') { errorObj['numberOfDependents'] = 'Invalid total number of dependents!'; error = true; }
		if (!leadData.background.backgroundAndStability) { errorObj['backgroundAndStability'] = 'Invalid option selected!'; error = true; }
		if (!leadData.background.dob || !moment(leadData.background.dob).isValid()) { errorObj['dob'] = 'Invalid date of birth selected!'; error = true; }
		else if (moment(leadData.background.dob).isValid()) {
			if (moment().diff(moment(leadData.background.dob), 'years') < 14) { errorObj['dob'] = 'Invalid date of birth selected!'; error = true; }
		}
		if (!leadData.background.identityType.length) { errorObj['identityType'] = 'No options were selected!'; error = true; }

		if (!leadData.advanced.hasLand || typeof leadData.advanced.hasLand !== 'string') { errorObj['hasLand'] = 'Invalid selection!'; error = true; }
		else if (leadData.advanced.hasLand === 'Yes') {
			if (!leadData.advanced.relationshipWithLandOwner) { errorObj['relationshipWithLandOwner'] = 'Invalid relationship selected!'; error = true; }
		}
		if (!leadData.advanced.collateral) { errorObj['collateral'] = 'Invalid selection!'; error = true; }
		if (!leadData.advanced.hasBankAccount) { errorObj['hasBankAccount'] = 'Invalid selection!'; error = true; }
		else if (leadData.advanced.hasBankAccount === 'Yes') {
			if (!leadData.advanced.bankAccounts.length) { errorObj['bankAccounts'] = 'Please select atleast one Bank!'; error = true; }
		}
		if (!leadData.advanced.receivedBankLoan) { errorObj['receivedBankLoan'] = 'Invalid selection!'; error = true; }
		else if (leadData.advanced.receivedBankLoan !== 'Never taken a loan') {
			if (!leadData.advanced.loanType) { errorObj['loanType'] = 'Invalid type of loan selected!'; error = true; }
			if (!leadData.advanced.receivedLoanProviderType) { errorObj['receivedLoanProviderType'] = 'Invalid type of loan provider selected!'; error = true; }
			if (leadData.advanced.receivedBankLoan === 'Loan taken and ongoing' && !leadData.advanced.outstandingLoanAmount) { errorObj['outstandingLoanAmount'] = 'Invalid outstanding loan amount!'; error = true; }
			else if (leadData.advanced.receivedBankLoan === 'Loan taken and ongoing' && leadData.advanced.outstandingLoanAmount) {
				if (parseInt(leadData.advanced.outstandingLoanAmount) < 1000) { errorObj['outstandingLoanAmount'] = 'Minimum outstanding loan amount is 1000 INR!'; error = true; }
			}
		}
		if (!leadData.advanced.genuinenessAndHumility) { errorObj['genuinenessAndHumility'] = 'Invalid value selected!'; error = true; }
		if (!leadData.advanced.confidenceAsSwavalambi) { errorObj['confidenceAsSwavalambi'] = 'Invalid value selected!'; error = true; }
		if (!leadData.comments.overallSentiment) { errorObj['overallSentiment'] = 'Invalid value selected!'; error = true; }
		if (!leadData.comments.tentativeHorizonToCloseThis) { errorObj['tentativeHorizonToCloseThis'] = 'Invalid value selected!'; error = true; }
		this.setState({ errorObj });
		cb(error);
	}

	gotoStep = (hash) => {
		$('.page-container.edit-lead-page').animate({
			scrollTop: $(hash).offset().top - 55
		}, 800);
	}

	goBackToLeads = () => {
		window.location.pathname = "/lead/" + this.props.match.params.leadId;
	}

	render() {
		if (this.state.leadData !== null) {
			return (
				<div className="page-container edit-lead-page">
					<h3 className="mb15 text-center">
						<span className="back" onClick={this.goBackToLeads}>
							<i className="fa fa-chevron-left"></i>
							Back to Lead
						</span>
						Edit Lead
						<span className="lead-name">
							{this.state.leadData.basic.name}
						</span>
					</h3>
					<Steps size="small" current={this.state.currentStep - 1}>
						<Step title={<a onClick={this.gotoStep.bind(this, '#basic')}>Basic</a>} icon={<Icon type="idcard" />} />
						<Step title={<a onClick={this.gotoStep.bind(this, '#businessIdea')}>Business Idea</a>} icon={<Icon type="shop" />} />
						<Step title={<a onClick={this.gotoStep.bind(this, '#individualFamilyDetails')}>Individual/Family Details</a>} icon={<Icon type="file-text" />} />
						<Step title={<a onClick={this.gotoStep.bind(this, '#generalDetails')}>Other General Details</a>} icon={<Icon type="bank" />} />
					</Steps>

					<h2 id="basic" className="step-heading">BASIC</h2>
					<Row className="step-container step-0">
						<Col md={3}>
							<label className="control-label is-imp">Mobile Number</label>
							<div className="mobile-number-input-container">
								<input type="text" placeholder="Enter Mobile Number" value={this.state.leadData.basic.mobileNumber} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'mobileNumber')} onBlur={this.checkMobileNumber} style={this.state.errorObj.hasOwnProperty('mobileNumber') ? { borderColor: '#F68787' } : {}} disabled={this.props.match.params.ivrsId ? true : false} />
								{this.state.mobileNumberStatus ? (
									<div className="mobile-number-status-container">
										{this.state.mobileNumberStatus === 'loading' ? (
											<Icon type="loading" style={{ color: '#FBE633' }} />
										) : (null)}

										{this.state.mobileNumberStatus === 'ok' ? (
											<Icon type="check" style={{ color: '#78D245' }} />
										) : (null)}

										{this.state.mobileNumberStatus === 'error' ? (
											<Icon type="exclamation" style={{ color: '#F68787' }} />
										) : (null)}
									</div>
								) : (null)}
							</div>
							{this.state.errorObj.hasOwnProperty('mobileNumber') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.mobileNumber}</div>
							) : (null)}
						</Col>

						<Col md={3}>
							<label className="control-label is-imp">Full Name</label>
							<input type="text" placeholder="Enter Name" value={this.state.leadData.basic.name} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'name')} disabled={JSON.parse(readCookie('userData')).type === 'partners' ? true : false} />
						</Col>

						<Col md={3}>
							<label className="control-label is-imp">State</label>
							<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.address.state} onChange={this.handleStateDistrictChange.bind(this, 'state')} filterOption={(input, option) => this.searchFilter(input, option)}>
								<Option value="" disabled>Select State</Option>
								<OptGroup label="Focus States">
									{this.state.allStates.map((state, stateIndex) => {
										if (state.inFocusRegion) {
											return (
												<Option key={stateIndex} value={state.name}>{state.name}</Option>
											)
										} else return null;
									})}
								</OptGroup>
								<OptGroup label="Not Focussed States">
									{this.state.allStates.map((state, stateIndex) => {
										if (!state.inFocusRegion) {
											return (
												<Option key={stateIndex} value={state.name}>{state.name}</Option>
											)
										} else return null;
									})}
								</OptGroup>
							</Select>
							{this.state.errorObj.hasOwnProperty('state') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.state}</div>
							) : (null)}
						</Col>

						{this.state.allDistricts.length ? (
							<Col md={3}>
								<label className="control-label is-imp">District</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.address.district} onChange={this.handleStateDistrictChange.bind(this, 'district')} disabled={!this.state.leadData.basic.address.state} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select District</Option>
									{this.state.allDistricts.map((district, districtIndex) => {
										return (
											<Option key={districtIndex} value={district.name}>{district.name}</Option>
										)
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('district') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.district}</div>
								) : (null)}
							</Col>
						) : (null)}
						{this.state.allBlocks.length ? (
							<Col md={3}>
								<label className="control-label is-imp">Block</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.address.block} onChange={this.changeHandler.bind(this, 'basic', 'block')} disabled={!this.state.leadData.basic.address.district} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select Block</Option>
									{this.state.allBlocks.map((block, blockIndex) => {
										return (
											<Option key={blockIndex} value={block.name}>{block.name}</Option>
										)
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('block') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.block}</div>
								) : (null)}
							</Col>
						) : (null)}
						{this.state.allCities.length ? (
							<Col md={3}>
								<label className="control-label is-imp">City</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.address.city} onChange={this.changeHandler.bind(this, 'basic', 'city')} disabled={!this.state.leadData.basic.address.district} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select City</Option>
									{this.state.allCities.map((city, cityIndex) => {
										return (
											<Option key={cityIndex} value={city.name}>{city.name}</Option>
										)
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('city') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.city}</div>
								) : (null)}
							</Col>
						) : (null)}

						<Col md={this.state.allCities.length ? 9 : 12}>
							<label className="control-label is-imp">Address Line 1</label>
							<input type="text" placeholder="Enter Address Line 1" value={this.state.leadData.basic.address.line1} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'addressLine1')} style={this.state.errorObj.hasOwnProperty('addressLine1') ? { borderColor: '#F68787' } : {}} />
							{this.state.errorObj.hasOwnProperty('addressLine1') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.addressLine1}</div>
							) : (null)}
						</Col>

						<Col md={9}>
							<label className="control-label">Address Line 2</label>
							<input type="text" placeholder="Enter Address Line 2" value={this.state.leadData.basic.address.line2} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'addressLine2')} style={this.state.errorObj.hasOwnProperty('addressLine2') ? { borderColor: '#F68787' } : {}} />
							{this.state.errorObj.hasOwnProperty('addressLine2') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.addressLine2}</div>
							) : (null)}
						</Col>

						<Col md={3}>
							<label className="control-label is-imp">Pincode</label>
							<input type="text" placeholder="Pincode" value={this.state.leadData.basic.pincode} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'pincode')} style={this.state.errorObj.hasOwnProperty('pincode') ? { borderColor: '#F68787' } : {}} />
							{this.state.errorObj.hasOwnProperty('pincode') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.pincode}</div>
							) : (null)}
						</Col>

						<Col md={3}>
							<label className="control-label is-imp">Phase</label>
							<Select size="large" style={{ width: '100%' }} value={this.state.leadData.financial.phase} onChange={this.changeHandler.bind(this, 'financial', 'phase')}>
								<Option value="" disabled>Select Phase</Option>
								<Option value="Start-up">Wants to start a new business</Option>
								<Option value="Scale-up">Runs a business, Wants to grow it</Option>
							</Select>
							{this.state.errorObj.hasOwnProperty('phase') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.phase}</div>
							) : (null)}
						</Col>

						{this.state.leadData.financial.phase === 'Scale-up' ? (
							<Col md={3}>
								<label className="control-label is-imp">Taken business loan before</label>
								<Select size="large" style={{ width: '100%' }} value={this.state.leadData.enterpriseStatus.creditLinked || ""} onChange={this.changeHandler.bind(this, 'enterpriseStatus', 'creditLinked')}>
									<Option value="" disabled>Select Yes/No</Option>
									<Option value="Yes">Yes</Option>
									<Option value="No">No</Option>
								</Select>
								{this.state.errorObj.hasOwnProperty('creditLinked') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.creditLinked}</div>
								) : (null)}
							</Col>
						) : (null)}

						<Col md={3}>
							<label className="control-label is-imp">Generated Through</label>
							<Select size="large" style={{ width: '100%' }} value={this.state.leadData.basic.generatedThrough} onChange={this.changeHandler.bind(this, 'basic', 'generatedThrough')} disabled={this.state.isIvrs ? true : false}>
								<Option value="" disabled>Select Lead Generation Channel</Option>
								<Option value="Call">Call</Option>
								<Option value="Visit">Visit</Option>
								<Option value="Referral">Referral</Option>
								<Option value="Activations">Activations</Option>
								<Option value="Toll-free call">Toll-free call</Option>
								<Option value="DIC" title="DIC">DIC</Option>
								{/* KVIC
																	KVIB
																	Coir Board
																	Municipality
																	SLBC */}
								<Option value="KVIB" title="KVIB">KVIB</Option>
								<Option value="KVIC" title="KVIC">KVIC</Option>
								<Option value="Coir Board" title="Coir Board">Coir Board</Option>
								<Option value="Municipality" title="Municipality">Municipality</Option>
								<Option value="SLBC" title="SLBC">SLBC</Option>
								<Option value="SBI PM SVANidhi" title="SBI PM SVANidhi">SBI PM SVANidhi</Option>
								<Option value="NULM-Sanctioned" title="NULM-Sanctioned">NULM-Sanctioned</Option>
								<Option value="NULM- Yet to be sanctioned" title="NULM- Yet to be sanctioned">NULM- Yet to be sanctioned</Option>
								{/* <Option value="PMEGP- yet to be sanctioned" title="PMEGP- yet to be sanctioned">PMEGP- yet to be sanctioned</Option>	 */}
								<Option value="Social Media" title="Social Media">Social Media</Option>
								<Option value="Bihar CM Udyami-Yojana" title="Bihar CM Udyami-Yojana">Bihar CM Udyami-Yojana</Option>
								<Option value="PMEGP- Pending" title="PMEGP- Pending">PMEGP- Pending</Option>
								<Option value="PMEGP- Sanctioned" title="PMEGP- Sanctioned">PMEGP- Sanctioned</Option>
								<Option value="PMEGP- Rejected" title="PMEGP- Rejected">PMEGP- Rejected</Option>
								<Option value="Government Departments" title="Government Departments">Government Departments</Option>
								<Option value="Customer Service Point (CSP)" title="Customer Service Point (CSP)">Customer Service Point (CSP)</Option>
								<Option value="Volunteer" title="Volunteer">Volunteer</Option>
								{/* <Option value="Others" title="Others">Others</Option>
								
								{this.state.leadData.basic.generatedThrough === 'Already trained candidate' ? (
									<Option value="Already trained candidate">Already trained candidate</Option>
								) : (null)}
								<Option value="Others">Others</Option> */}
							</Select>
							{this.state.errorObj.hasOwnProperty('generatedThrough') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.generatedThrough}</div>
							) : (null)}
						</Col>

						<Col md={3}>
							<label className="control-label is-imp">Gender</label>
							<Select size="large" style={{ width: '100%' }} value={this.state.leadData.basic.gender} onChange={this.changeHandler.bind(this, 'basic', 'gender')}>
								<Option value="" disabled>Select Gender</Option>
								<Option value="Male">Male</Option>
								<Option value="Female">Female</Option>
								<Option value="Other">Other</Option>
							</Select>
							{this.state.errorObj.hasOwnProperty('gender') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.gender}</div>
							) : (null)}
						</Col>

						<Col md={3}>
							<label className="control-label">Alternate Mobile Number</label>
							<input type="text" placeholder="8976543210" value={this.state.leadData.basic.altMobileNumber} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'altMobileNumber')} style={this.state.errorObj.hasOwnProperty('altMobileNumber') ? { borderColor: '#F68787' } : {}} />
							{this.state.errorObj.hasOwnProperty('altMobileNumber') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.altMobileNumber}</div>
							) : (null)}
						</Col>

						{JSON.parse(readCookie('userData')).type !== 'partners' ? (
							<Col md={3}>
								<label className="control-label is-imp">Select User Type</label>
								<Select size="large" style={{ width: '100%' }} value={this.state.leadData.leadInfo.userType} onChange={this.changeHandler.bind(this, 'leadInfo', 'userType')}>
									<Option value="" disabled>Select User Type</Option>
									{this.state.allUserTypes.map((userType, userTypeIndex) => {
										if (userType.customUrl !== 'sidbi') {
											return (
												<Option key={userTypeIndex} value={userType.customUrl}>{userType.name}</Option>
											)
										} else return null;
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('userType') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.userType}</div>
								) : (null)}
							</Col>
						) : (null)}

						{JSON.parse(readCookie('userData')).type !== 'partners' && this.state.allUsers.length ? (
							<Col md={3}>
								<label className="control-label is-imp">Assign To</label>
								<Select size="large" showSearch style={{ width: '100%' }} placeholder="Assign To" value={this.state.leadData.leadInfo.assignTo} onChange={this.changeHandler.bind(this, 'leadInfo', 'assignTo')} filterOption={(input, option) => this.searchFilter(input, option)}>
									{this.state.allUsers.map((user, userIndex) => {
										if (this.state.leadData.leadInfo.userType === 'swavalamban-connect-kendra') {
											return (
												<Option key={userIndex} value={user._id}>{user.ngoName}</Option>
											)
										} else {
											return (
												<Option key={userIndex} value={user._id}>{user.fullName + ' (' + user.email + ')'}</Option>
											)
										}
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('assignTo') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.assignTo}</div>
								) : (null)}
							</Col>
						) : (null)}

						{this.state.leadData.basic.address.state ? (
							<Col md={3}>
								<label className="control-label">Native State</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.nativeOf.state} onChange={this.handleNativeStateDistrictChange.bind(this, 'state')} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select Native State</Option>
									<OptGroup label="Focus States">
										{this.state.allStates.map((state, stateIndex) => {
											if (state.inFocusRegion) {
												return (
													<Option key={stateIndex} value={state.name}>{state.name}</Option>
												)
											} else return null;
										})}
									</OptGroup>
									<OptGroup label="Not Focussed States">
										{this.state.allStates.map((state, stateIndex) => {
											if (!state.inFocusRegion) {
												return (
													<Option key={stateIndex} value={state.name}>{state.name}</Option>
												)
											} else return null;
										})}
									</OptGroup>
								</Select>
								{this.state.errorObj.hasOwnProperty('nativeState') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.nativeState}</div>
								) : (null)}
							</Col>
						) : (null)}

						{this.state.allNativeDistricts.length && this.state.leadData.basic.address.district ? (
							<Col md={3}>
								<label className="control-label">Native District</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.nativeOf.district} onChange={this.handleNativeStateDistrictChange.bind(this, 'district')} disabled={!this.state.leadData.basic.nativeOf.state} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select District</Option>
									{this.state.allNativeDistricts.map((district, districtIndex) => {
										return (
											<Option key={districtIndex} value={district.name}>{district.name}</Option>
										)
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('nativeDistrict') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.nativeDistrict}</div>
								) : (null)}
							</Col>
						) : (null)}

						{this.state.allNativeCities.length && this.state.leadData.basic.address.city ? (
							<Col md={3}>
								<label className="control-label">Native City</label>
								<Select size="large" showSearch style={{ width: '100%' }} value={this.state.leadData.basic.nativeOf.city} onChange={this.changeHandler.bind(this, 'basic', 'nativeCity')} disabled={!this.state.leadData.basic.nativeOf.district} filterOption={(input, option) => this.searchFilter(input, option)}>
									<Option value="" disabled>Select City</Option>
									{this.state.allNativeCities.map((city, cityIndex) => {
										return (
											<Option key={cityIndex} value={city.name}>{city.name}</Option>
										)
									})}
								</Select>
								{this.state.errorObj.hasOwnProperty('nativeCity') ? (
									<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.nativeCity}</div>
								) : (null)}
							</Col>
						) : (null)}
					</Row>
					<Col md={4}>
						<label className="control-label is-imp">Educational Qualification</label>
						<Select size="large" style={{ width: '100%' }} value={this.state.leadData.background.educationalQualification} onChange={this.props.changeHandler.bind(this, 'background', 'educationalQualification')}>
							<Option value="">Select Educational Qualification</Option>
							<Option value="Uneducated">Uneducated</Option>
							<Option value="5th Pass">5th Pass</Option>
							<Option value="8th Pass">8th Pass</Option>
							<Option value="10th Pass">10th Pass</Option>
							<Option value="12th Pass">12th Pass</Option>
							<Option value="Diploma">Diploma</Option>
							<Option value="graduate">Graduate</Option>
							<Option value="post graduate">Post-graduate</Option>
							<Option value="Doctorate">Doctorate</Option>
						</Select>
						{this.state.errorObj.hasOwnProperty('educationalQualification') ? (
							<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.educationalQualification}</div>
						) : (null)}
					</Col>

					{["Diploma", "graduate", "post graduate", "Doctorate"].indexOf(this.state.leadData.background.educationalQualification) > -1 ? (
						<Col md={4}>
							<label className="control-label is-imp">Specialization Of Education</label>
							<Select size="large" style={{ width: '100%' }} value={this.props.background.specializationOfEducation} onChange={this.props.changeHandler.bind(this, 'background', 'specializationOfEducation')}>
								<Option value="">Select Specialization Of Education</Option>
								{specializationsOfEducation.map((item, index) => {
									return (
										<Option value={item} key={index}>{item}</Option>
									)
								})}
							</Select>
							{this.state.errorObj.hasOwnProperty('specializationOfEducation') ? (
								<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.specializationOfEducation}</div>
							) : (null)}
						</Col>
					) : (null)}

					<Col md={4}>
						<label className="control-label is-imp">Religion</label>
						<Select size="large" style={{ width: '100%' }} value={this.props.background.religion} onChange={this.props.changeHandler.bind(this, 'background', 'religion')}>
							<Option value="">Select Religion</Option>
							<Option value="Christianity">Christianity</Option>
							<Option value="Islam">Islam</Option>
							<Option value="Hinduism">Hinduism</Option>
							<Option value="Buddhism">Buddhism</Option>
							<Option value="Sikhism">Sikhism</Option>
							<Option value="Jainism">Jainism</Option>
							<Option value="Judaism">Judaism</Option>
							<Option value="Nonreligious">Nonreligious</Option>
						</Select>
					</Col>

					<Col md={4}>
						<label className="control-label is-imp">Category</label>
						<Select size="large" style={{ width: '100%' }} value={this.props.background.category} onChange={this.props.changeHandler.bind(this, 'background', 'category')}>
							<Option value="">Select Category</Option>
							<Option value="General">General</Option>
							<Option value="SC">SC</Option>
							<Option value="ST">ST</Option>
							<Option value="OBC">OBC</Option>
							<Option value="Others">Others</Option>
						</Select>
						{this.state.errorObj.hasOwnProperty('category') ? (
							<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.category}</div>
						) : (null)}
					</Col>

					<Col md={4}>
						<label className="control-label is-imp">Is a person with disability (PWD)</label>
						<Select size="large" style={{ width: '100%' }} value={this.props.background.pwd} onChange={this.props.changeHandler.bind(this, 'background', 'pwd')}>
							<Option value="">Select Yes/No</Option>
							<Option value="Yes">Yes</Option>
							<Option value="No">No</Option>
						</Select>
						{this.state.errorObj.hasOwnProperty('pwd') ? (
							<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.pwd}</div>
						) : (null)}
					</Col>

					<h2 id="businessIdea" className="step-heading">BUSINESS IDEA</h2>
					<NewLeadFinancialForm basic={this.state.leadData.basic} financial={this.state.leadData.financial} leadMaturity={this.state.leadData.leadMaturity} changeHandler={this.changeHandler} errorObj={this.state.errorObj} addNewBusinessIdea={this.addNewBusinessIdea} deleteIdeaRow={this.deleteIdeaRow} changeBusinessIdeaHandler={this.changeBusinessIdeaHandler} />

					<h2 id="individualFamilyDetails" className="step-heading">INDIVIDUAL/FAMILY DETAILS</h2>
					<NewLeadBackgroundForm background={this.state.leadData.background} changeHandler={this.changeHandler} errorObj={this.state.errorObj} />

					<h2 id="generalDetails" className="step-heading">OTHER GENERAL DETAILS</h2>
					<NewLeadAdvancedForm advanced={this.state.leadData.advanced} comments={this.state.leadData.comments} changeHandler={this.changeHandler} errorObj={this.state.errorObj} handleNativeStateDistrictChange={this.handleNativeStateDistrictChange} allStates={this.state.allStates} allNativeDistricts={this.state.allNativeDistricts} allNativeCities={this.state.allNativeCities} />

					<div className="d-flex justify-content-center mt20">
						<Button variant='primary' style={{ marginLeft: '20px', marginRight: '20px', float: 'right' }} onClick={this.save.bind(this, 'notify')}>
							<i className="fa fa-floppy-o fa-2x"></i>
							Save
						</Button>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

/* eslint-disable jsx-a11y/anchor-is-valid */

import {
	// Modal,
	Row, Col, Button
} from "react-bootstrap";
// import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
// import debounce from 'lodash/debounce';
import {
	Select,
	// Spin
} from 'antd';
// import { toPlainObject } from "lodash";
// import Swal from 'sweetalert2';

const { Option,
	// OptGroup
} = Select;

// const ValidateData = require('../../../ValidateData.js');
const readCookie = require('../cookie.js').readCookie;

export default class FocusStates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorObj: {},
			allCities: [],
			allStates: [],
			allDistricts: [],
			selectedDistricts: [],
			selectedStates: [],
			defaultSelected: [],
			editMode: false,
			focusDistricts: [],
			focusStates: [],
			focusedDistrictsCount: 0,
			defaultSelectedStates: [],
			districtsToList: []
		}
		this.lastFetchId = 0;
		// this.fetchSaarthiLeads = debounce(this.fetchSaarthiLeads, 800);
	}

	fetchFocusRegions = () => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-focus-regions', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				console.log(data, 'Data');
				this.setState({
					allStates: data.allStates || [],
					allDistricts: data.allDistricts || [],
					focusStates:
						data.focusStates ||
						[],
					focusDistricts: data.focusDistricts || [],
					selectedStates:
						data.focusStates ||
						[],
					selectedDistricts: data.focusDistricts || [],
					focusedDistrictsCount: data.focusedDistrictsCount || 0,
					districtsToList: data.allDistricts.filter(x=>data.focusStateNames.includes(x.state)),
				});
			});
	}
	componentDidMount() {
		// this.fetchStates();	
		this.fetchFocusRegions();
	}


	handleState = (val) => {
		let states = this.state.allStates.filter(x => val.includes(x._id));
		states = states.map(x => x.name);
		const districtsToList = this.state.allDistricts.filter(x => states.includes(x.state));
		const districtIds = districtsToList.map(x => x._id);
		const selectedDistricts = this.state.selectedDistricts.filter(x => districtIds.includes(x));
		this.setState({ selectedStates: val, districtsToList, selectedDistricts });
	}

	declareFocused = () => {
		let errorObj = {}
		if (this.state.selectedDistricts.length < 1) {
			errorObj.district = 'Please select at least 1 district';
		}
		if (this.state.selectedStates.length < 1) {
			errorObj.state = 'Please select at least 1 state';
		}
		this.setState({errorObj})
		if (Object.keys(errorObj).length > 0) {
			return;
		}
		const data = {
			districts : this.state.selectedDistricts,
			// districtsToRemove: removeIds,
			states: this.state.selectedStates,
		}
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/declare-focused', {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'x-auth-token': JSON.parse(readCookie('access_token'))
					},
					body: JSON.stringify(data)
				}).then((response) => {
					return response.json();
				}).then((data) => {
					if (data.status) {
						this.setState({ editMode: false })
						this.fetchFocusRegions();
						this.notifySuccess("Successfully updated focus Districts");
						// this.fetchStates();
					}
				})
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	onSearch = (val) => {
		console.log('search:', val);
	}

	render() {
		return (
			<div className="add-platform-user">
				<Row>
				<Col md={8} className="d-flex mb20 align-items-center">
					<h3 className="header-custom">DECLARE FOCUSED LOCATIONS</h3>
				</Col>
				<Col md={4}>
				  <div align='right'>
					<h4 className='header-custom'>{this.state.focusedDistrictsCount} Focused Districts</h4>
				  </div>
				</Col>
				</Row>
				<div align='right'>
					{!this.state.editMode && <a 
					  style={{backgroundColor: '#EF5A20', width: 70, color: 'white', paddingLeft: 10, paddingRight: 10, height: 30, borderRadius: 3}}
					  class="go-back-btn d-flex align-items-center"
					  onClick={() => this.setState({editMode: true})}
					>
					  <i class="fa fa-pencil mr5" />
					  Edit
					</a>}
				</div>
				<Row className="body-conatiner">
					<Col md={6} className="elem-containers">
							<label className="control-label is-imp">State</label>
							<Select
							size='large'
							mode='multiple'
							defaultValue={this.state.selectedStates}
							key={this.state.selectedStates}
							disabled={!this.state.editMode}
							style={{ width: '100%', height: "38px" }} placeholder="Select State"  
							onChange={this.handleState}>
								{this.state.editMode && <Option disabled value="">Select State</Option>}
								{/* {this.state.allStates.length ? (
									<OptGroup label="Focus States"> */}
										{this.state.allStates.length && this.state.allStates.map((state, stateIndex) => {
											// if (state.inFocusRegion) {
												return (
													<Option key={stateIndex} value={state._id}>{state.name}</Option>
												)
											// } else return null;
										})}
									{/* </OptGroup>
								) : (null)} */}
							</Select>
							{this.state.errorObj.hasOwnProperty('state') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.state}</div>
							) : (null)}
						</Col>
						<Col md={12} className="elem-containers">
							<label className="control-label is-imp">District</label>
							<Select
							  size='large'	
							  mode='multiple'
							  defaultValue={this.state.selectedDistricts}
							onSearch={this.onSearch}
							
							  showSearch
							  key={this.state.selectedDistricts}
							  optionFilterProp="children"
							  disabled={!this.state.editMode}
							  filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							  style={{ width: '100%'}} placeholder="Select District"
							  onChange={(value) => this.setState({selectedDistricts: value})}
							  >
								<Option disabled value="">Select District</Option>
							{this.state.districtsToList.length ? (
									this.state.districtsToList.map((district, districtIndex) => {
										return (
											<Option key={districtIndex} value={district._id} >{`${district.name || ''}(${district.state || ''})`}</Option>
										)
									})
								) : (null)}
							</Select>
					</Col>
					{this.state.errorObj.hasOwnProperty('district') ? (
						<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.district}</div>
					) : (null)}
				</Row>
				{this.state.editMode ? <Col className="add-platform-user-footer mt20">
					<Button variant='primary' style={{ marginRight: '20px' }} onClick={this.declareFocused}>Submit</Button>
					{/*<Button variant='secondary' onClick={this.cancelSubmit}>Cancel</Button>*/}
				</Col> : null}
			</div>
		);
	}
}

import React, { useState, useEffect } from 'react';
import './Interactions.css';

import {
  // Table,
  Row, Col
} from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import swal from 'sweetalert';
// import axios from 'axios';
import moment from 'moment';
import {
  EditFilled,
  FileAddFilled,
} from '@ant-design/icons';
import {
  // ConfigProvider/*, DatePicker*/,
  // Select,
  // Input,
  Switch,
  // Collapse
} from 'antd';

// const { Option, OptGroup } = Select;

const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\s-]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/;
const readCookie = require('../../cookie').readCookie;
// const AUTH_TOKEN = `${JSON.parse(readCookie('access_token'))}`;
// console.log(AUTH_TOKEN)

function BankInteractions(props) {
  const INITIAL_MANAGER_DETAILS = { name: '', primaryMobile: '', secondaryMobile: '' };

  const { bankDetails, managerDetails, userData, modalOpen } = props;
  const [modalShow, setModalShow] = useState(modalOpen || false);
  const [interactionDetails, setinteractionDetails] = useState(false);
  const { SearchBar } = Search;
  const [candidates, setCandidates] = useState([]);
  const [editingManager, setEditingManager] = useState(false);
  const [editManagerDetails, setEditManagerDetails] = useState(INITIAL_MANAGER_DETAILS);
  const [interactions, setinteractions] = useState([]);

  const getCandidateDetails = (ifsc = bankDetails.ifsc) => {

    let query = "?";



    fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-branches/candidates/" + ifsc + query, {
      method: "GET",
      headers: {
        "x-auth-token": JSON.parse(readCookie("access_token")),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status && data.simpleLeadInfo) {
          // this.setState({ bankDetails: data.branch });
          setCandidates(data.simpleLeadInfo);
          // setPaginationParticulars({ ...paginationParticulars, totalLeads: data.totalLeads });
          // setCounts(data.counts);
        }
        // else {
        //     window.location = '/bank-branches';
        // }
      });
  };
  function editButton(cell, row, rowIndex) {
    return (
      <div
        onClick={() => {
          setModalShow(true);
          setinteractionDetails(rowIndex);
        }}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <EditFilled />
      </div>
    );
  }
  function displayDate(cell, row) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {moment(row.date).format('DD/MM/YYYY HH:mm')}
      </div>
    );
  }

  function displayFollowUp(cell, row) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {moment(row.followUp).isValid()
          ? moment(row.followUp).format("DD/MM/YYYY")
          : "No Follow Up Required"}      </div>
    );
  }
  const columns = [
    {
      dataField: 'Edit',
      isDummyField: true,
      formatter: editButton,
      text: 'Update',
      headerStyle: () => ({ textAlign: 'center', width: '100px' })
    },
    {
      dataField: 'date',
      text: 'Date of Interaction',
      formatter: displayDate,
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'interactor.name',
      text: 'Initiated by',
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'mode',
      text: 'Mode',
      headerStyle: () => ({ textAlign: 'center', width: '100px' }),
    },
    {
      dataField: 'connectionStatus',
      text: 'Connection Status',
      headerStyle: () => ({ textAlign: 'center', width: '100px' }),
    },
    {
      dataField: 'sentiment',
      text: 'Overall Sentiment',

      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'comments',
      text: 'Comments',
      headerStyle: () => ({ textAlign: 'center', width: '250px' }),
    },
    {
      dataField: 'nextAction',
      text: 'Next Action',

      headerStyle: () => ({ textAlign: 'center', width: '250px' }),
    },
    {
      dataField: 'toTakeAction.name',
      text: 'Action Pending on',

      headerStyle: () => ({ textAlign: 'center', width: '150px' }),
    },
    {
      dataField: 'followUp',
      text: 'Follow up date',
      formatter: displayFollowUp,
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },

  ];

  async function fetchData() {
    if (bankDetails.ifsc) {
      const url = process.env.REACT_APP_API_URL + "/api/v1/bank-interactions/" + bankDetails.ifsc;
      fetch(url, {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      })
        .then((data) => data.json())
        .then((response) => {
          if (!response.error) {
            setinteractions(response.interactions);
          }
        });
    }
  }
  useEffect(() => {

    fetchData();
    getCandidateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function MyVerticallyCenteredModal(props) {
    const { onHide } = props;
    const TODAY = new Date();
    const [fields, setFields] = useState(interactions); // Input Fields
    const [startDate, setStartDate] = useState(new Date()); // Date For Forms
    const [checkBox, setcheckBox] = useState(false);

    const [followUpDate, setFollowUpDateDate] = useState(new Date());
    const [newInteraction, setnewInteraction] = useState({
      // userId: 4865437856294,
      date: new Date(),
      interactor: false,
      mode: false,
      connectionStatus: false,
      sentiment: false,
      comments: false,
      nextAction: false,
      toTakeAction: false,
      followUp: new Date(),
      ifsc: bankDetails.ifsc
    });
    const [validity, setValidity] = useState(false);

    function updateUser() {
      let managerDetailsToAdd = false;
      if (editingManager) {
        if (editManagerDetails.name && phoneRegex.test(editManagerDetails.primaryMobile)
          && (editManagerDetails.secondaryMobile === '' || phoneRegex.test(editManagerDetails.secondaryMobile))) { managerDetailsToAdd = editManagerDetails; }
        else {
          swal('Please fill in the valid manager details', '', 'error');
          return;
        }
      }
      if (interactionDetails !== false) {
        setinteractions([...fields]); // Updating the changes from Modal
        const { _id } = interactions[interactionDetails];
        interactions[interactionDetails].managerDetailsToAdd = managerDetailsToAdd;
        interactions[interactionDetails].ifsc = bankDetails.ifsc;
        // { ...interactions[interactionDetails], managerDetailsToAdd, ifsc: bankDetails.ifsc }
        fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-interactions/" + _id, {
          method: "PUT",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(interactions[interactionDetails])
        })
          .then((data) => data.json())
          .then((data) => {
            if (!data.error) {
              swal('Successfully Updated', '', 'success');
              fetchData();
              onHide();
              if (managerDetailsToAdd) { window.location.reload(); }
            }
            else {
              swal(data.errorMessage || "Couldn't update", '', 'error');
            }
          }).catch(err => {
            swal(err.errorMessage || "Couldn't update", '', 'error');
          });
      } else {
        interactions.push(newInteraction);
        newInteraction.managerDetailsToAdd = managerDetailsToAdd;
        fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-interactions", {
          method: "POST",
          headers: {
            "x-auth-token": JSON.parse(readCookie("access_token")),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newInteraction)
        })
          .then((data) => data.json())
          .then((data) => {
            if (!data.error) {
              swal('Successfully Added', '', 'success');
              fetchData();
              onHide();
              if (managerDetailsToAdd) { window.location.reload(); }
            }
            else {
              swal(data.errorMessage || "Couldn't update", '', 'error');
            }
          }).catch(err => {
            swal(err.errorMessage || "Couldn't update", '', 'error');
          });
      }
    }

    useEffect(() => {
      let disable = Object.values(newInteraction).every(isTrue);
      if (editingManager) {
        disable = disable && editManagerDetails.name && editManagerDetails.primaryMobile
          && phoneRegex.test(editManagerDetails.primaryMobile)
          && (!editManagerDetails.secondaryMobile || phoneRegex.test(editManagerDetails.secondaryMobile))
      }
      setValidity(!disable);
    }, [newInteraction]);

    function handleInputChange(e, field) {
      // console.log(e.target.value, field);
      if (interactionDetails !== false) {
        const values = [...interactions];
        values[interactionDetails][field] = e.target.value;
        setFields(values);
      } else {
        const tempInteraction = { ...newInteraction };
        tempInteraction[field] = e.target.value;
        setnewInteraction(tempInteraction);
      }
    }

    function handleDropdownSelectChange(e, changeSource) {
      // console.log(e.target.value, changeSource);

      if (interactionDetails !== false) {
        const values = [...interactions];
        values[interactionDetails][changeSource] = e.target.value;
        if (changeSource === 'connectionStatus' && e.target.value === 'Unsuccessful Attempt') {
          values[interactionDetails]['sentiment'] = 'Cannot Say';
        }
        setFields(values);
      } else {
        const tempInteraction = { ...newInteraction };
        tempInteraction[changeSource] = e.target.value;
        if (changeSource === 'connectionStatus' && e.target.value === 'Unsuccessful Attempt') {
          tempInteraction['sentiment'] = 'Cannot Say';
          setnewInteraction(tempInteraction);
        }
        setnewInteraction(tempInteraction);
      }
    }
    const handleManagerChange = (e, type) => {
      let obj = editManagerDetails;
      obj[type] = e.target.value;
      setEditManagerDetails(obj);
    }
    function handleDateChange(e, field) {
      // console.log(e, field);
      if (field === 'date')
        setStartDate(new Date(e));
      else
        setFollowUpDateDate(new Date(e));
      // console.log(e.toString(), field);
      if (interactionDetails !== false) {
        const values = [...interactions];
        values[interactionDetails][field] = new Date(e); // change
        setFields(values);
      } else {
        const tempInteraction = { ...newInteraction };
        tempInteraction[field] = new Date(e); // change
        setnewInteraction(tempInteraction);
      }
    }

    function isTrue(value) {
      return value !== false;
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {interactionDetails !== false
              ? 'Edit Interaction'
              : 'Add New Interaction'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div>
              {
                !managerDetails ?
                  <div>
                    <Row>
                      <Col md={10}>
                        <strong>
                          This branch does not have a manager tagged. Do you have the details of the manager?
                        </strong>
                      </Col>
                      <Col md={2}>
                        <Switch defaultChecked={editingManager}
                          checkedChildren="Yes" unCheckedChildren="No"
                          onChange={(e) => { setEditingManager(e); }} />
                      </Col>
                    </Row>
                  </div> :
                  <div>
                    <Row>
                      <Col md={10}>
                        <strong>
                          This branch has {managerDetails.name} ({managerDetails.primaryMobile}) tagged as manager. Does this branch now have a new manager?
                        </strong>
                      </Col>
                      <Col md={2}>
                        <Switch defaultChecked={editingManager}
                          checkedChildren="Yes" unCheckedChildren="No"
                          onChange={(e) => { setEditingManager(e) }} />
                      </Col>
                    </Row>
                  </div>
              }
              {
                editingManager ?
                  <React.Fragment>
                    <div className="dynamicInputFields">
                      <div
                        style={{
                          textAlign: 'right',
                          width: '30%',
                        }}
                      >
                        {'Name'}*
                      </div>
                      <input
                        type="text"
                        placeholder={'Enter Name'}
                        onChange={(e) => {
                          // // setEditManagerDetails({ ...editManagerDetails, name: e.target.value })
                          // let obj = { ...editManagerDetails, name: e.target.value };
                          // setEditManagerDetails(obj);
                          handleManagerChange(e, 'name')
                        }}
                        style={{
                          width: '60%',
                          height: '100%',
                          borderRadius: 5,
                          borderColor: 'transparent',
                          borderBottomColor: 'lightgrey',
                        }}
                        required
                      />
                    </div>
                    <div className="dynamicInputFields">
                      <div
                        style={{
                          textAlign: 'right',
                          width: '30%',
                        }}
                      >
                        {'Primary Mobile Number'}*
                      </div>
                      <input
                        type="text"
                        placeholder={'Enter Primary Mobile Number'}
                        pattern={phoneRegex}
                        onChange={(e) => {
                          // // setEditManagerDetails({ ...editManagerDetails, name: e.target.value })
                          // let obj = { ...editManagerDetails, name: e.target.value };
                          // setEditManagerDetails(obj);
                          handleManagerChange(e, 'primaryMobile')
                        }}
                        style={{
                          width: '60%',
                          height: '100%',
                          borderRadius: 5,
                          borderColor: 'transparent',
                          borderBottomColor: 'lightgrey',
                        }}
                        required
                      />
                    </div>
                    <div className="dynamicInputFields">
                      <div
                        style={{
                          textAlign: 'right',
                          width: '30%',
                        }}
                      >
                        {'Secondary Mobile Number'}
                      </div>
                      <input
                        type="text"
                        placeholder={'Enter Secondary Mobile Number'}
                        pattern={phoneRegex}
                        onChange={(e) => {
                          // // setEditManagerDetails({ ...editManagerDetails, name: e.target.value })
                          // let obj = { ...editManagerDetails, name: e.target.value };
                          // setEditManagerDetails(obj);
                          handleManagerChange(e, 'secondaryMobile')
                        }} style={{
                          width: '60%',
                          height: '100%',
                          borderRadius: 5,
                          borderColor: 'transparent',
                          borderBottomColor: 'lightgrey',
                        }}
                      // required
                      />
                    </div>
                  </React.Fragment> :
                  null
              }
              <hr />
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: "right",
                    width: "30%",
                  }}
                >
                  {"Date"}*
                </div>
                <div
                  style={{
                    height: "100%",
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    selected={interactionDetails !== false ? new Date(interactions[interactionDetails].date) : startDate}
                    className="datePicker"
                    maxDate={TODAY}
                    onChange={(e) => handleDateChange(e, "date")}
                  />
                </div>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Interactor'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].interactor.id
                      : newInteraction.interactor || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={(e) => handleDropdownSelectChange(e, 'interactor')}
                >
                  <option disabled value={-1}>Select Interactor</option>
                  <option value={userData.id}> {userData ? `${userData.fullName}` : null} (Me)</option>
                  {/* <option>User</option>
                   */}
                  {candidates.map((candidate, candidateIndex) => {
                    return <option key={candidateIndex} value={candidate.id}>{candidate.name}</option>
                  })}
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Mode Of Interaction'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].mode
                      : newInteraction.mode || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={(e) => handleDropdownSelectChange(e, 'mode')}
                >
                  <option disabled value={-1}>Select Mode of Interaction</option>
                  <option>Phone Call</option>
                  <option>Visit/Meeting</option>
                  <option>Conference Call</option>
                  <option>Video Call</option>
                  <option>Seminar</option>
                  <option>Fair</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Connection Status'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].connectionStatus
                      : newInteraction.connectionStatus || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={(e) => handleDropdownSelectChange(e, 'connectionStatus')
                  }
                >
                  <option disabled value={-1}>Select Connection Status</option>
                  <option>Connected</option>
                  <option>Unsuccessful Attempt</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Overall Sentiments'}*
                </div>
                <Form.Control
                  disabled={interactionDetails !== false
                    ? interactions[interactionDetails].connectionStatus === 'Unsuccessful Attempt'
                    : newInteraction.connectionStatus === 'Unsuccessful Attempt'}
                  value={
                    (interactionDetails !== false
                      ? interactions[interactionDetails].connectionStatus === 'Unsuccessful Attempt'
                      : newInteraction.connectionStatus === 'Unsuccessful Attempt') ?
                      'Cannot Say' :
                      interactionDetails !== false
                        ? interactions[interactionDetails].sentiment
                        : newInteraction.sentiment || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={(e) => handleDropdownSelectChange(e, 'sentiment')}
                >
                  <option disabled value={-1}>Select Overall Sentiment</option>
                  <option>Positive</option>
                  <option>Neutral</option>
                  <option>Negative</option>
                  <option>Cannot Say</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Comments'}*
                </div>
                {/* <input
                  type="text"
                  placeholder={'Comments'}
                  onChange={(e) => handleInputChange(e, 'comments')}
                  style={{
                    width: '60%',
                    height: '100%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                  }}
                  required
                /> */}
                <textarea
                  type="text"
                  placeholder={'Comments'}
                  onChange={(e) => handleInputChange(e, 'comments')}
                  style={{
                    width: '60%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                    resize: 'none',
                  }}
                  defaultValue={interactionDetails !== false ? interactions[interactionDetails].comments : ""}
                  required
                />
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Next Action To Be Taken'}*
                </div>
                {/* <input
                  type="text"
                  placeholder={'Next Action To Be Taken'}
                  onChange={(e) => handleInputChange(e, 'nextAction')}
                  style={{
                    width: '60%',
                    height: '100%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                  }}
                  required
                /> */}
                <textarea
                  type="text"
                  placeholder={'Next Action To Be Taken'}
                  onChange={(e) => handleInputChange(e, 'nextAction')}
                  style={{
                    width: '60%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                    resize: 'none',
                  }}
                  defaultValue={interactionDetails !== false ? interactions[interactionDetails].nextAction : ""}
                  required
                />
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Action To Be Taken By'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].toTakeAction.id
                      : newInteraction.toTakeAction || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={(e) => handleDropdownSelectChange(e, 'toTakeAction')
                  }
                >
                  <option disabled value={-1}>Choose who has to take next action</option>
                  <option value={userData.id}> {userData ? `${userData.fullName}` : null} (Me)</option>
                  {/* <option>User</option>
                   */}
                  {candidates.map((candidate, candidateIndex) => {
                    return <option key={candidateIndex} value={candidate.id}>{candidate.name}</option>
                  })}
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: "right",
                    width: "30%",
                  }}
                >
                  {"Follow-Up on"} {checkBox ? null : "*"}
                </div>
                {interactionDetails !== false ? (
                  <div
                    style={{
                      height: "100%",
                      width: "60%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {interactions[interactionDetails].followUp !== 'No Follow Up' && (
                      <DatePicker
                        selected={new Date(interactions[interactionDetails].followUp)}
                        className="datePicker"
                        minDate={TODAY}
                        onChange={(e) => handleDateChange(e, "followUp")}
                      />
                    )}

                    <Form>
                      <div key={"default-checkbox"}>
                        <Form.Check
                          checked={interactions[interactionDetails].followUp === 'No Follow Up'}
                          type={"checkbox"}
                          id={"default-checkbox"}
                          label={"No scope of following up."}
                          onChange={() => {
                            setcheckBox(!checkBox);
                            if (interactionDetails !== false) {
                              interactions[interactionDetails].followUp =
                                checkBox ? moment().toDate() : "No Follow Up";
                            } else {
                              newInteraction.followUp = checkBox
                                ? moment().toDate()
                                : "No Follow Up";
                            }
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "60%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {checkBox === false && (
                      <DatePicker
                        selected={followUpDate}
                        className="datePicker"
                        minDate={TODAY}
                        onChange={(e) => handleDateChange(e, "followUp")}
                      />
                    )}
                    {/* {checkBox === false &&
                    {(
                    <DatePicker
                      selected={followUpDate}
                      className="datePicker"
                      minDate={moment().toDate()}
                      onChange={(e) => handleDateChange(e, 'followUp')}
                    />
                  )} */}
                    <Form>
                      <div key={"default-checkbox"}>
                        <Form.Check
                          type={"checkbox"}
                          id={"default-checkbox"}
                          label={"No scope of following up."}
                          onChange={() => {
                            setcheckBox(!checkBox);
                            if (interactionDetails !== false) {
                              interactions[interactionDetails].followUp =
                                checkBox ? moment().toDate() : "No Follow Up";
                            } else {
                              newInteraction.followUp = checkBox
                                ? moment().toDate()
                                : "No Follow Up";
                            }
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {interactionDetails !== false ? (
            // <Button onClick={updateUser}>Edit Interaction</Button>
            <Button
              onClick={() => {
                swal('Do you really want to edit this interaction?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                })
                  .then((value) => {
                    switch (value) {
                      case 'Yes':
                        updateUser()
                        break;

                      default:
                    }
                  });
              }}
            >
              Edit Interaction
            </Button>
          ) : (
            <Button
              // onClick={updateUser}
              onClick={() => {
                swal('Do you really want to create a new interaction?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                })
                  .then((value) => {
                    switch (value) {
                      case 'Yes':
                        updateUser()
                        break;

                      default:
                    }
                  });
              }}

              disabled={validity}
            >
              Add Interaction
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
  MyVerticallyCenteredModal.propTypes = {
    onHide: PropTypes.func,
  };

  return (
    <div className="Tab2Container">
      <div className="Tab2EditButtonContainer">
        <div className="Tab2EditButton">
          <div
            className="Tab2EditButtonText"
            onClick={() => {
              setModalShow(true);
            }}
          >
            <FileAddFilled />
            Add New Interaction
          </div>
        </div>
      </div>
      <div className="Tab2Table">

        <ToolkitProvider
          bootstrap4
          keyField="_id"
          data={interactions}
          columns={columns}
          search
        >
          {
            (props) => (
              <div className='homeTableContainer'>
                <hr />
                <div style={{ paddingLeft: 20 }}>
                  Search: <SearchBar {...props.searchProps} />
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory({ sizePerPage: 50, sizePerPageList: [50, 100, 150, 200] })}
                // filter={ filterFactory() }
                />
              </div>
            )
          }
        </ToolkitProvider>

        {/* <Table striped hover variant="light" size="lr">
          <thead>
            <tr>
              <th className='tdStyle'>S. N0.</th>
              <th className='tdStyle'>Edit</th>
              <th className='tdStyle'>Date of Interaction</th>
              <th className='tdStyle'>Initiated by</th>
              <th className='tdStyle'>Mode</th>
              <th className='tdStyle'>Connection Status</th>
              <th className='tdStyle'>Overall Sentiment</th>
              <th className='tdStyle'>Comments</th>
              <th className='tdStyle'>Next Action</th>
              <th className='tdStyle'>Action Pending on</th>
              <th className='tdStyle'>Follow up date</th>
            </tr>
          </thead>
          <tbody>
            {interactions.map((item, index) => (
              <tr key={index}>
                <td className='tdStyle'>{interactions.length - index}</td>
                <td
                  style={{ cursor: 'pointer' }}
                  // onClick={() => {
                  //   swal('Do you really want to edit this interaction?', {
                  //     buttons: {
                  //       catch: {
                  //         text: 'Yes',
                  //         value: 'Yes',
                  //       },
                  //       No: true,
                  //     },
                  //   })
                  //   .then((value) => {
                  //     switch (value) {
                  //       case 'Yes':
                  //         setModalShow(true);
                  //         setinteractionDetails(index);
                  //         break;

                  //       default:
                  //     }
                  //   });
                  // }}
                  onClick={() => {
                    setModalShow(true);
                    setinteractionDetails(index);
                  }}
                >
                  <EditFilled/>
                </td>
                <td className='tdStyle'>{moment(item.date).format('DD/MM/YYYY HH:mm')}</td>
                <td className='tdStyle'>{item.interactor}</td>
                <td className='tdStyle'>{item.mode}</td>
                <td className='tdStyle'>{item.connectionStatus}</td>
                <td className='tdStyle'>{item.sentiment}</td>
                <td className='tdStyle'>{item.comments}</td>
                <td className='tdStyle'>{item.nextAction}</td>
                <td className='tdStyle'>{item.toTakeAction}</td>
                <td className='tdStyle'>{moment(item.followUp).format('DD/MM/YYYY HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setEditingManager(false);
          setEditManagerDetails(INITIAL_MANAGER_DETAILS);
          setinteractionDetails(false);
        }}
      />
    </div>
  );
}

export default BankInteractions;

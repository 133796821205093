/** @format */

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Select } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Button, Modal, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import CompleteServiceDelivery from "./CompleteServiceDelivery.js";

const readCookie = require("../cookie.js").readCookie;

const { Option, OptGroup } = Select;

var currentDate = new Date();
const afterTwoDays = new Date();
afterTwoDays.setDate(afterTwoDays.getDate() + 2);



const INITIAL_MODAL_VALUE = {
  _id: "",
  selectedService: "",
  status: "Pending",
  paidAmount: 0,
  outstandingAmount: 0,
  requestedOn: currentDate,
  assignedTo: "",
  committedDeliveryDate: afterTwoDays,
  paymentType: "",
  serviceValue: "",
  discountInRupees: 0,
  netServiceValue: "",
  disablePaymentType: false,
};

export default class Enterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalAllServiceData: 0,
      sizePerPage: 50,
      filterData: {
        status: "",
      },
      errorObj: {},
      selectedCandidate: null,
      editModalVisibility: false,
      sizePerPageList: [50, 100, 150, 200],
      selectedIds: [],
      selectMode: true,
      allIds: [],
      viewType: "table",
      rowDetails: {},
      userData: props.userData,
      leadData: props.leadData,
      refreshLeadData: props.refreshLeadData,
      allOptedServiceData: [],
      allServices: [],
      selectedService: {},
      allActiveAssignTo: [],
      totalAmountPaid: 0,
      totalAmountOutstanding: 0,
      allFieldsFilled: false,
      posting: false,
      CDSeditModal: false,
      filteredData: [],
      groupedServicesToShow: [],
      groupedServices: [],
      groupedAssignTo: [],
      s3Urls: [],
      disablePaymentType: false,
    };
    this.getAllServiceDetails = this.getAllServiceDetails.bind(this);
  }

  getServiceRequestDetails = () => {
    this.setState({ loading: true });
    fetch(
      process.env.REACT_APP_API_URL +
        "/api/v1/service-delivery/service-log/" +
        this.props.leadData.leadId +
        "/" +
        this.state.rowDetails._id,
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ rowDetails: data.requestDetails, loading: false });
        }
      });
  };

  getAllServiceDetails = (isUpdate) => {
    fetch(
      process.env.REACT_APP_API_URL +
        "/api/v1/service-delivery/service-log/" +
        this.props.leadData.leadId,
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          if (isUpdate) {
            let updatedRow = data.serviceRequests.filter(
              (serviceObj) => this.state.rowDetails._id === serviceObj._id
            );
            if (updatedRow.length > 0) {
              this.setState({ rowDetails: updatedRow[0] });
            }
          }
          this.setState({
            allOptedServiceData: data.serviceRequests,
            filteredData: data.serviceRequests,
            totalAllServiceData: data.serviceRequests.length,
            totalAmountPaid: data.totalAmountPaid,
            totalAmountOutstanding: data.totalAmountOutstanding,
            allIds: data.serviceRequests.map((item) => item._id),
          });
        }
      });
  };

  async componentDidMount() {
    this.getAllServiceDetails();
    this.getAssignedTo();

    await this.getServicesData();

    setTimeout(() => {
      const groupedServices = this.state.allServices.reduce(
        (groups, service) => {
          const { category, serviceTitle, _id, showedServiceId } = service;
          const index = groups.findIndex(
            (group) => group.category === category
          );
          if (index === -1) {
            groups.push({
              category,
              services: [
                { _id, name: serviceTitle, showedServiceId: showedServiceId },
              ],
            });
          } else {
            groups[index].services.push({
              _id,
              name: serviceTitle,
              showedServiceId: showedServiceId,
            });
          }
          return groups;
        },
        []
      );
      this.setState({
        groupedServices,
        groupedServicesToShow: groupedServices,
      });

      const groupedAssignTo = this.state.allActiveAssignTo.reduce(
        (groups, assignTo) => {
          const { userType, fullName, _id } = assignTo;
          const index = groups.findIndex(
            (group) => group.userType.name === userType.name
          );
          if (index === -1) {
            groups.push({ userType, assignTo: [{ _id, name: fullName }] });
          } else {
            groups[index].assignTo.push({ _id, name: fullName });
          }
          return groups;
        },
        []
      );
      this.setState({ groupedAssignTo });
    }, 2000);
  }

  // formattings
  formatOptionalColumn = (cell, row) => {
    if (cell || cell === 0) return cell;
    else return "N/A";
  };

  formatTatColumn = (cell, row) => {
    if (cell || cell === 0) {
      let minutes = Math.floor(cell / 60000);
      let hours = Math.round(minutes / 60);
      let days = Math.round(hours / 24);
      return days;
    } else return "N/A";
  };

  formatDeleteColumn = (cell, row) => {
    let disabled = (row.orderDetails && row.orderDetails.length && row.orderDetails[0].paymentDetails && row.orderDetails[0].paymentDetails && ["captured", "authorized"].includes(row.orderDetails[0].paymentDetails.status));
    return cell ? <Button
      disabled={
        disabled
      }
      onClick={() => {
        this.deleteService(cell);
      }}
      style={{ marginLeft: "10px" }}
    >
      <i className="fa fa-trash" />
    </Button> : null;
      
  }

  formatDate = (cell, row) => {
    if (cell || cell === 0) {
      return moment(cell).format("DD/MM/YYYY");
    } else if (row.addedAt) {
      return moment(row.addedAt).format("DD/MM/YYYY");
    }
    return null;
  };

  // change halders
  changeHandler = (type, e, eventObj) => {

    let selectedCandidate = this.state.selectedCandidate;

    let selectedService = {};

    // selectedCandidate["assignedTo"] = this.props.userData._id;
    if (type === "selectedService" && e) {
      selectedCandidate["selectedService"] = e;
      selectedService = this.state.allServices.filter(
        (name) => name._id === e
      )[0];

      if (
        selectedService.exceptions &&
        Array.isArray(selectedService.exceptions)
      ) {
        // Iterate over the exception array
        selectedService.exceptions.forEach((exception, i) => {
          if (
            exception.amount &&
            exception.amount !== selectedService.basePrice &&
            exception.state &&
            exception.state.includes(this.state.leadData.basic.address.state)
            && exception.district &&
              exception.district.includes(this.state.leadData.basic.address.district)
            // exception.amount < selectedService.basePrice
          ) {
            selectedService.basePrice = exception.amount; // Update the service value if the exception amount is less than the current value
          }
        });
      }

      selectedCandidate["serviceValue"] = selectedService.basePrice;
      selectedCandidate["netServiceValue"] = selectedService.basePrice;
      selectedCandidate["paymentType"] = selectedService.preferredPaymentType;
      this.setState({ selectedService });
    } else if (type === "assignedTo" && e) {
      selectedCandidate["assignedTo"] = e;
    } else if (type === "serviceValue") {
      selectedCandidate["serviceValue"] = e.target.value;
      selectedCandidate["netServiceValue"] =
        e.target.value - this.state.selectedCandidate.discountInRupees;
    } else if (type === "discountInRupees") {
      const percentage = this.state.selectedService.maxDiscountPercentage;
      const percentageValue =
        (this.state.selectedCandidate.serviceValue * percentage) / 100;
      if (e.target.value <= percentageValue) {
        selectedCandidate[type] = e.target.value;
        type = "netServiceValue";
        selectedCandidate[type] =
          this.state.selectedCandidate.serviceValue -
          this.state.selectedCandidate.discountInRupees;
      } else return;
    } else if (["requestedOn", "committedDeliveryDate"].includes(type)) {
      if (!e) {
        return;
      } else {
        selectedCandidate[type] = e;
      }
    } else if (type === "paymentType") {
      selectedCandidate[type] = e.target.value;
      if (e.target.value === "Pro-bono") {
        selectedCandidate["paidAmount"] = 0;
        selectedCandidate["outstandingAmount"] = 0;
      } else if (e.target.value === "Pre-paid") {
        selectedCandidate["paidAmount"] = selectedCandidate["netServiceValue"];
        selectedCandidate["outstandingAmount"] = 0;
      } else if (e.target.value === "Post-paid") {
        selectedCandidate["paidAmount"] = 0;
        selectedCandidate["outstandingAmount"] =
          selectedCandidate["serviceValue"];
      } else if (e.target.value === "50% Advance") {
        selectedCandidate["paidAmount"] =
          selectedCandidate["netServiceValue"] / 2;
        selectedCandidate["outstandingAmount"] =
          selectedCandidate["netServiceValue"] / 2;
      }
    } else if (type === "comments") {
      selectedCandidate["comments"] = e.target.value;
    }

    if (
      this.state.selectedCandidate.selectedService &&
      this.state.selectedCandidate.requestedOn &&
      this.state.selectedCandidate.assignedTo &&
      this.state.selectedCandidate.committedDeliveryDate &&
      this.state.selectedCandidate.paymentType
    ) {
      this.setState({ allFieldsFilled: true });
    }
    this.setState({ selectedCandidate });
  };

  changeFilterHandler = (type, e) => {
    let filterData = this.state.filterData;
    filterData[type] = e;

    let filteredData = [];
    if (type === "status") {
      if (filterData.status === "Pending") {
        filteredData = this.state.allOptedServiceData.filter(
          (service) => service.status === "Pending"
        );
      } else if (filterData.status === "Delivered") {
        filteredData = this.state.allOptedServiceData.filter(
          (service) => service.status === "Delivered"
        );
      } else {
        filteredData = this.state.allOptedServiceData;
      }
    }
    this.setState({ filteredData });
  };

  deleteService = (serviceId) => {
    Swal.fire({
      title: 'Are you sure you want to delete this service request?',
      text: `You wont be able to revert this.`,
      type: 'warning',
      showCancelButton: true,
    }).then(res => {
      if (res.value) {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/service-delivery/' + serviceId, {
          method: 'DELETE',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token'))
          }
        }).then(data => data.json())
          .then(data => {
            if (data.status) {
              // this.getServiceRequestDetails()
              this.getAllServiceDetails();
              this.notifySuccess('Successfully deleted the service request!');
            } else {
              this.notifyError('Deletion of the service request failed');
            }
          }).catch(err => {
            this.notifyError('Deletion of the service request failed');
            console.log(err)
          });
      }
    });  
  }

  showEditModal = (isEdit) => {
    let selectedCandidate = this.state.selectedCandidate;

    let serviceData = {};

    if (isEdit) {
      const orderDetails = this.state.rowDetails.orderDetails;
      
      const disablePaymentType = (["captured", "authorized"].includes(orderDetails[0]['status']));
      this.setState({ disablePaymentType });
      serviceData = {
        _id: this.state.rowDetails._id,
        selectedService: this.state.rowDetails.serviceId._id || "",
        status: this.state.rowDetails.status || "",
        paidAmount: this.state.rowDetails.paidAmount || 0,
        outstandingAmount: this.state.rowDetails.outstandingAmount || 0,
        requestedOn: new Date(this.state.rowDetails.requestedOn),
        assignedTo: this.state.rowDetails.assignedTo._id,
        committedDeliveryDate: new Date(
          this.state.rowDetails.committedDeliveryDate || ""
        ),
        paymentType: this.state.rowDetails.paymentType,
        serviceValue: this.state.rowDetails.serviceValue || "",
        discountInRupees: this.state.rowDetails.discountInRupees || 0,
        netServiceValue: this.state.rowDetails.netServiceValue || "",
      };
    } else {
      this.setState({ disablePaymentType: false });
      selectedCandidate = INITIAL_MODAL_VALUE;
      serviceData = selectedCandidate
        ? {
            _id: this.props.leadData.leadId,
            selectedService: selectedCandidate.selectedService || "",
            status: selectedCandidate.status || "",
            paidAmount: selectedCandidate.paidAmount || 0,
            outstandingAmount: selectedCandidate.outstandingAmount || 0,
            requestedOn: selectedCandidate.requestedOn || currentDate,
            assignedTo: selectedCandidate.assignedTo || this.props.userData._id,
            committedDeliveryDate:
              selectedCandidate.committedDeliveryDate || afterTwoDays,
            paymentType: selectedCandidate.paymentType || "",
            serviceValue: selectedCandidate.serviceValue || "",
            discountInRupees: selectedCandidate.discountInRupees || 0,
            netServiceValue: selectedCandidate.netServiceValue || "",
          }
        : INITIAL_MODAL_VALUE;
    }

    this.setState({
      editModalVisibility: true,
      selectedCandidate: serviceData,
    });
  };

  hideEditModal = () => {
    this.setState({
      editModalVisibility: false,
      selectedCandidate: INITIAL_MODAL_VALUE,
      // selectedCandidate: null
    });
  };

  getServicesData = async () => {
    fetch(
      process.env.REACT_APP_API_URL + "/api/v1/service-delivery/service-list",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allServices: data.services });
        }
      });
  };

  getAssignedTo = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        "/api/v1/service-delivery/auxiliary/active-users",
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ allActiveAssignTo: data.data });
        }
      });
  };

  updateFromCDS = () => {
    let selectedCandidate = this.state.selectedCandidate,
      errorObj = this.state.errorObj;
    if (selectedCandidate) {
      let serviceData = {
        serviceId: selectedCandidate.selectedService,
        status: "Pending",
        requestedOn: selectedCandidate.requestedOn,
        assignedTo: selectedCandidate.assignedTo,
        paymentType: selectedCandidate.paymentType,
        serviceValue: selectedCandidate.serviceValue,
        paidAmount: selectedCandidate.paidAmount,
        outstandingAmount: selectedCandidate.outstandingAmount,
        discountInRupees: selectedCandidate.discountInRupees,
        netServiceValue: selectedCandidate.netServiceValue,
        committedDeliveryDate: selectedCandidate.committedDeliveryDate,
        leadId: this.props.leadData.leadId,
      };
      if (Object.keys(errorObj).length) {
        this.setState({ errorObj });
      } else {
        Swal.fire({
          title: "Are you sure you want update?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Update!",
          cancelButtonText: "No, Cancel!",
        }).then((res) => {
          if (res.value) {
            this.setState({ CSDposting: true });
            fetch(
              process.env.REACT_APP_API_URL +
                "/api/v1/service-delivery/service-log/" +
                this.state.rowDetails._id,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": JSON.parse(readCookie("access_token")),
                },
                body: JSON.stringify(serviceData),
              }
            )
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                if (data.status) {
                  this.notifySuccess("Successfully updated the service!");
                  // this.getServiceRequestDetails();
                  this.hideEditModal();
                  this.getServiceRequestDetails();
                  this.setState({ posting: false });
                  this.getAllServiceDetails(true);
                } else {
                  this.setState({ posting: false });
                  this.notifyError(data.errorMessage);
                }
              });
          }
        });
      }
    }
  };
  updateRowDetails = (rowDetails) => {
    this.setState({ rowDetails });
  };
  updateCandidate = () => {
    if (this.state.CDSeditModal) {
      this.updateFromCDS();
    } else {
      let selectedCandidate = this.state.selectedCandidate,
        errorObj = this.state.errorObj;
      let serviceData = {
        serviceId: selectedCandidate.selectedService,
        status: "Pending",
        requestedOn: selectedCandidate.requestedOn,
        assignedTo: selectedCandidate.assignedTo,
        paymentType: selectedCandidate.paymentType,
        serviceValue: selectedCandidate.serviceValue,
        paidAmount: selectedCandidate.paidAmount,
        outstandingAmount: selectedCandidate.outstandingAmount,
        discountInRupees: selectedCandidate.discountInRupees,
        netServiceValue: selectedCandidate.netServiceValue,
        committedDeliveryDate: selectedCandidate.committedDeliveryDate,
        leadId: this.props.leadData.leadId,
      };
      if (Object.keys(errorObj).length) {
        this.setState({ errorObj });
      } else {
        Swal.fire({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes!",
          cancelButtonText: "No, Cancel!",
        }).then((res) => {
          if (res.value) {
            this.setState({ posting: true });
            fetch(
              process.env.REACT_APP_API_URL +
                "/api/v1/service-delivery/service-log",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": JSON.parse(readCookie("access_token")),
                },
                body: JSON.stringify(serviceData),
              }
            )
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                if (data.status) {
                  this.notifySuccess("Successfully added the new service!");
                  this.hideEditModal();
                  this.setState({ posting: false });
                  this.getAllServiceDetails();
                } else {
                  this.setState({ posting: false });
                  this.notifyError(data.errorMessage);
                }
              });
          }
        });
      }
    }
  };

  handleSelectAllChange = (selected) => {
    if (selected) {
      this.setState({ selectedIds: this.state.allIds });
    } else {
      this.setState({ selectedIds: [] });
    }
  };

  notifySuccess = (text) => toast.success(text);
  notifyError = (text) => toast.error(text);

  handleSelectChange = (row) => {
    const FILTERED_ROWS = this.state.allServices.filter(
      (service) => service._id === row.serviceId._id
    );
    const selectedService = FILTERED_ROWS.length ? FILTERED_ROWS[0] : {};
    this.setState({ rowDetails: row, selectedService, viewType: "detail" });
    // this.state.viewType = ;
    let selectedIds = this.state.selectedIds;
    if (!selectedIds.includes(row._id)) {
      selectedIds.push(row._id);
    } else {
      const removeIndex = selectedIds.map((item) => item._id).indexOf(row._id);
      removeIndex >= 0 && selectedIds.splice(removeIndex, 1);
    }
    this.setState({ selectedIds });
  };

  onTableChange = (type, obj) => {
    let changeType = "";
    if (this.state.page !== obj.page) changeType = "page";
    else if (this.state.sizePerPage !== obj.sizePerPage)
      changeType = "sizePerPage";
    else if (this.state.searchText !== obj.searchText)
      changeType = "searchText";

    this.setState({
      page: obj.page,
      sizePerPage: obj.sizePerPage,
      searchText: obj.searchText,
    });
    this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText);
  };

  handleAllServicesClick = () => {
    this.setState({
      viewType: "table",
      selectedService: {},
      selectedCandidate: {},
    });
  };

  handelCDSEditModalClick = () => {
    this.setState({ CDSeditModal: true });
    this.showEditModal(true);
  };


  render() {
    const selectRow = {
      mode: "radio",
      clickToSelect:
        // JSON.parse(readCookie("userData")).userType.name ===
        // "Project Management Unit" &&
        this.state.selectMode,
      selected: this.state.selectedIds,
      onSelect: this.handleSelectChange,
      onSelectAll: this.handleSelectAllChange,
      // hideSelectColumn: !(
      //   // JSON.parse(readCookie("userData")).userType.name ===
      //   // "Project Management Unit" &&
      //   this.state.selectMode
      // ),
      hideSelectColumn: true,
    };

    let EnterpriseColumns = [
      {
        text: "",
        sort: false,
        hidden: true,
        export: true,
        dataField: "_id",
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "Service",
        align: "center",
        dataField: "serviceId.serviceTitle",
        headerStyle: () => {
          return { width: "330px", textAlign: "center" };
        },
        formatter: (cell, row) => {
          return <a onClick={()=>this.handleSelectChange(row)} href="#">{row.serviceId.serviceTitle}</a>;
        },
      },

      {
        sort: false,
        export: true,
        hidden: false,
        text: "Status",
        align: "center",
        dataField: "status",
        headerStyle: () => {
          return { width: "130px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        align: "center",
        text: "Requested On",
        dataField: "requestedOn",
        formatter: this.formatDate,
        headerStyle: () => {
          return { width: "150px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        align: "center",
        text: "Assigned To",
        dataField: "assignedTo.fullName",
        headerStyle: () => {
          return { width: "180px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "Payment Type",
        align: "center",
        dataField: "paymentType",
        headerStyle: () => {
          return { width: "180px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "Service Value",
        align: "center",
        dataField: "netServiceValue",
        formatter: this.formatOptionalColumn,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "Paid",
        align: "center",
        dataField: "paidAmount",
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "Outstanding",
        align: "center",
        dataField: "outstandingAmount",
        formatter: this.formatOptionalColumn,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        text: "TAT/Ageing",
        align: "center",
        dataField: "turnAroundTimeOrAgeing",
        formatter: this.formatTatColumn,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
      {
        sort: false,
        export: false,
        hidden: JSON.parse(readCookie("userData")).userType.name !== "Project Management Unit",
        text: "Delete Service",
        align: "center",
        dataField: "_id",
        formatter: this.formatDeleteColumn,
        headerStyle: () => {
          return { width: "100px", textAlign: "center" };
        },
      },
    ];

    return (
      <div className='tcacr-page' style={{ marginTop: "30px" }}>
        {this.state.viewType === "detail" && this.state.rowDetails ? (
          <CompleteServiceDelivery
            userData={this.props.userData}
            leadData={this.props.leadData}
            updateRowDetails={this.updateRowDetails}
            getServiceRequestDetails={this.getServiceRequestDetails}
            rowDetails={this.state.rowDetails}
            rowId={this.state.rowDetails._id}
            viewType={this.state.viewType}
            allActiveAssignTo={this.state.allActiveAssignTo}
            handleAllServicesClick={this.handleAllServicesClick}
            handelCDSEditModalClick={this.handelCDSEditModalClick}
            selectedService={this.state.selectedService}
            getAllServiceDetails={this.getAllServiceDetails}
          />
        ) : (
          <div className='d-flex flex-column '>
            <Row className='justify-content-end'>
              <div className='filter-class d-flex align-items-center'>
                <div style={{ marginRight: "10px" }}>Filter by Status:</div>
                <Select
                  style={{ minWidth: "150px" }}
                  placeholder='Select Status'
                  onChange={this.changeFilterHandler.bind(this, "status")}
                  value={this.state.filterData.status}
                >
                  <Option disabled value=''>
                    All
                  </Option>
                  <Option value='Pending'>Pending</Option>
                  <Option value='Delivered'>Delivered</Option>
                </Select>
                <Button
                  onClick={() => {
                    this.showEditModal();
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Add New Service
                </Button>
              </div>
            </Row>

            <div
              className='d-flex font-weight-bold'
              style={{ marginLeft: "16px" }}
            >
              <div
                className='tcacr-heading'
                style={{ borderRight: "1px solid black", paddingRight: "7px" }}
              >
                Enterprise Support Services ({this.state.totalAllServiceData})
              </div>
              <div
                className='tcacr-heading'
                style={{
                  color: "#00dd00",
                  paddingLeft: "10px",
                  borderRight: "1px solid black",
                  paddingRight: "7px",
                }}
              >
                Paid: INR {this.state.totalAmountPaid}
              </div>
              <div className='tcacr-heading' style={{ paddingLeft: "10px" }}>
                Outstanding: INR {this.state.totalAmountOutstanding}
              </div>
            </div>

            <Col md={12} className='tcacr-table' style={{ marginTop: "10px" }}>
              {this.state.filteredData ? (
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: this.state.page,
                    sizePerPage: this.state.sizePerPage,
                    totalSize: this.state.totalAllServiceData,
                    sizePerPageList: this.state.sizePerPageList,
                  })}
                  keyField='_id'
                  data={this.state.filteredData}
                  columns={EnterpriseColumns}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='_id'
                      data={this.state.filteredData}
                      columns={EnterpriseColumns}
                      search={{ searchFormatted: true }}
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            {...props.baseProps}
                            ref={(table) => {
                              this.table = table;
                            }}
                            remote={{ pagination: true }}
                            {...paginationTableProps}
                            onTableChange={this.onTableChange}
                            // selectRow={selectRow}
                          />

                          <PaginationListStandalone {...paginationProps} />
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              ) : null}
            </Col>
          </div>
        )}

        {this.state.editModalVisibility ? (
          <Modal
            className='reassign-lead-modal'
            show={this.state.editModalVisibility}
            size='lg'
          >
            <Modal.Header>
              <Modal.Title className='modal-header-custom'>
                Add/Edit Service
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label
                    className='control-label is-imp'
                    style={{ width: "40%" }}
                  >
                    Select Service
                  </label>

                  <Select
                    disabled={this.state.disablePaymentType}
                    size='large'
                    placeholder='Select Service'
                    showSearch
                    filterOption={false}
                    onSearch={(e) => {
                      let groupedServicesOptions = this.state.groupedServices;
                      let optionsToShow = [];
                      let reg = new RegExp(e, "ig");
                      for (let i = 0; i < groupedServicesOptions.length; i++) {
                        let option = { ...groupedServicesOptions[i] };
                        let services = option.services.filter((op) => {
                          return (
                            reg.test(op.name) ||
                            reg.test(op.showedServiceId) ||
                            reg.test(option.category)
                          );
                        });
                        if (services.length > 0) {
                          option.services = services;
                          optionsToShow.push(option);
                          console.log("925 optionsToShow: ", optionsToShow);
                        }
                      }
                      this.setState({ groupedServicesToShow: optionsToShow });
                      console.log("929 aftersetting: ", optionsToShow);
                    }}
                    onChange={this.changeHandler.bind(this, "selectedService")}
                    value={this.state.selectedCandidate.selectedService}
                  >
                    <Option value='' disabled>
                      All
                    </Option>

                    {this.state.groupedServicesToShow.map((item, index) => {
                      return (
                        <OptGroup label={item.category} key={index}>
                          {item.services.map((service) => (
                            <Option key={service._id} value={service._id}>
                              {service.showedServiceId
                                ? `(${service.showedServiceId}) `
                                : ""}
                              &nbsp; [{item.category}] &nbsp;{service.name}
                            </Option>
                          ))}
                        </OptGroup>
                      );
                    })}
                  </Select>
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label
                    className='control-label is-imp'
                    style={{ width: "40%" }}
                  >
                    Requested on{" "}
                  </label>

                  <DatePicker
                    className='datePicker border'
                    onChange={this.changeHandler.bind(this, "requestedOn")}
                    selected={this.state.selectedCandidate.requestedOn}
                    dateFormat={"dd/MM/yyyy"}
                    maxDate={currentDate}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label
                    className='control-label is-imp'
                    style={{ width: "40%" }}
                  >
                    Assign To
                  </label>
                  <Select
                    size='large'
                    placeholder='Select Status'
                    onChange={this.changeHandler.bind(this, "assignedTo")}
                    value={this.state.selectedCandidate.assignedTo}
                  >
                    <Option value='' disabled>
                      All
                    </Option>
                    {this.state.groupedAssignTo.map((item, index) => {
                      return (
                        <OptGroup label={item.userType.name}>
                          {item.assignTo.map((assign) => (
                            <Option key={assign._id} value={assign._id}>
                              {assign.name}
                            </Option>
                          ))}
                        </OptGroup>
                      );
                    })}
                  </Select>
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label
                    className='control-label is-imp'
                    style={{ width: "40%" }}
                  >
                    Committed Delivery by:{" "}
                  </label>

                  <DatePicker
                    className='datePicker border'
                    onChange={this.changeHandler.bind(
                      this,
                      "committedDeliveryDate"
                    )}
                    selected={
                      this.state.selectedCandidate.committedDeliveryDate
                    }
                    dateFormat={"dd/MM/yyyy"}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label
                    className='control-label is-imp'
                    style={{ width: "40%" }}
                  >
                    Payment Type:{" "}
                  </label>

                  <form
                    onChange={this.changeHandler.bind(this, "paymentType")}
                    value={this.state.selectedCandidate.paymentType}
                    // defaultValue={this.state.selectedCandidate.paymentType}
                    // defaultChecked={this.state.selectedCandidate.paymentType}
                    className='d-flex justify-content-between container'
                  >
                    <label>
                      <input
                        type='radio'
                        name='paymentType'
                        checked={
                          this.state.selectedCandidate.paymentType ===
                          "Pro-bono"
                        }
                        value='Pro-bono'
                        disabled={this.state.disablePaymentType}
                      />{" "}
                      Pro-bono
                    </label>

                    <label>
                      <input
                        type='radio'
                        name='paymentType'
                        checked={
                          this.state.selectedCandidate.paymentType ===
                          "Pre-paid"
                        }
                        value='Pre-paid'
                        disabled={this.state.disablePaymentType}
                      />{" "}
                      Pre-Paid
                    </label>
                    <label>
                      <input
                        type='radio'
                        checked={
                          this.state.selectedCandidate.paymentType ===
                          "50% Advance"
                        }
                        name='paymentType'
                        value='50% Advance'
                        disabled={this.state.disablePaymentType}
                      />{" "}
                      50% Advance
                    </label>
                    <label>
                      <input
                        type='radio'
                        checked={
                          this.state.selectedCandidate.paymentType ===
                          "Post-paid"
                        }
                        name='paymentType'
                        value='Post-paid'
                        disabled={this.state.disablePaymentType}
                      />{" "}
                      Post-Paid
                    </label>
                  </form>
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label className='control-label' style={{ width: "40%" }}>
                    Service Value (INR):
                  </label>
                  <input
                    disabled={
                      this.state.selectedService
                        ? this.state.selectedService.basePrice
                        : true
                    }
                    className='form-control'
                    type='number'
                    placeholder='Service Value'
                    value={this.state.selectedCandidate.serviceValue}
                    onChange={this.changeHandler.bind(this, "serviceValue")}
                  />
                </Col>
                {this.state.selectedCandidate.selectedService &&
                !this.state.selectedService.basePrice ? (
                  <Container fluid>
                    <note>{this.state.selectedService.description}</note>
                  </Container>
                ) : null}

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label className='control-label' style={{ width: "40%" }}>
                    Discount (INR):
                  </label>
                  <input
                    className='form-control'
                    type='number'
                    disabled={this.state.disablePaymentType}
                    placeholder='Discount'
                    value={this.state.selectedCandidate.discountInRupees}
                    onChange={this.changeHandler.bind(this, "discountInRupees")}
                  />
                </Col>

                <Col className='d-flex' style={{ alignItems: "center" }}>
                  <label className='control-label' style={{ width: "40%" }}>
                    Net Service Value (INR):
                  </label>
                  <input
                    disabled
                    className='form-control'
                    type='number'
                    placeholder='Net Service Value'
                    value={this.state.selectedCandidate.netServiceValue}
                    onChange={this.changeHandler.bind(this, "netServiceValue")}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='footer-center'>
              <Button
                className='btn btn-warning brand-color-background'
                onClick={this.updateCandidate}
                disabled={!this.state.allFieldsFilled || this.state.posting}
              >
                {this.state.posting ? "Posting" : "Save"}
              </Button>
              <Button className='btn btn-danger' onClick={this.hideEditModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ProjectContextProvider} from "./components/partials/context/ProjectContext";
const subdomainIsValid = require('./checkSubdomain.js').subdomainIsValid;

var url = '',
	subdomain = '',
	// eslint-disable-next-line no-unused-vars
	appName = 'Swati',
	logo = '/images/s-logo.png',
	manifest = '%PUBLIC_URL%/manifest-s.json',
	title = 'SWATI | Swavalamban Technology Interface',
	description = 'A platform to power and manage creation and growth of MSMEs.';

if (window.location.hostname.split('.').length === 3 && window.location.hostname.split('.')[0] !== 'www') {
	subdomain = window.location.hostname.split('.')[0];
}
if (subdomain && subdomainIsValid(subdomain)) {
	url = window.location.origin + '/themes/' + subdomain + '.theme.css';
	document.body.innerHTML = document.body.innerHTML + "<style> @import '" + url + "'; </style>";
} else {
	url = window.location.origin + '/themes/theme.css';
	document.body.innerHTML = document.body.innerHTML + "<style> @import '" + url + "'; </style>";
}
if (window.location.hostname.includes('vcap.me') || window.location.hostname.includes('udyamita')) {
	appName = 'Udyamita';
	logo = '/images/u-logo.png';
	title = 'Udyamita Technology Interface';
	manifest = '%PUBLIC_URL%/manifest-u.json';
}

ReactDOM.render(
	<DndProvider backend={Backend}>
		<ProjectContextProvider>
		<ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={true} closeOnClick={false} newestOnTop={false} pauseOnHover={true} />
		<Helmet>
			<title>{title}</title>
			<link rel="manifest" href={manifest} />
			<link rel="apple-touch-icon" href={logo} />
			<meta name="description" content={description} />
			<link rel="icon" type="image/png" href={logo} sizes="16x16" />
			<link rel="icon" type="image/png" href={logo} sizes="32x32" />

			<meta property="og:image" content={logo} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
		</Helmet>
		<Routes />
		</ProjectContextProvider>
	</DndProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
require('dotenv').config();
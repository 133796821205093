import Swal from 'sweetalert2';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
	Col,
	// Row, Button, Modal
} from 'react-bootstrap';

const readCookie = require('../cookie.js').readCookie;

export default class SingleLeadStrengthRating extends Component {
	constructor(props) {
		super(props);
		this.state = {
			answers: {},
			questions: [],
			responseData: null
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/lead-rating/questions', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					let questions = data.questions, answers = {};
					for (let i = 0; i < questions.length; i++) {
						answers[questions[i].id] = { answer: '', comment: '' };
					}

					fetch(process.env.REACT_APP_API_URL + '/api/v1/lead-rating/' + this.props.leadId, {
						method: 'GET',
						headers: {
							'x-auth-token': JSON.parse(readCookie('access_token'))
						}
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								answers = data.response.response;
								this.setState({ responseData: data.response, answers, questions });
							} else {
								this.setState({ answers, questions });
							}
						}).catch((err) => {
							console.log(err);
							this.setState({ answers, questions });
						});
				}
			});
	}

	answerHandler = (qId, e) => {
		let answers = this.state.answers;
		if (answers.hasOwnProperty(qId)) answers[qId]['answer'] = e.target.value;
		else {
			answers[qId] = {
				answer: e.target.value
			};
		}
		this.setState({ answers });
	}

	commentHandler = (qId, e) => {
		let answers = this.state.answers;
		if (answers.hasOwnProperty(qId)) answers[qId]['comment'] = e.target.value;
		else {
			answers[qId] = {
				comment: e.target.value
			};
		}
		this.setState({ answers });
	}

	submit = () => {
		let answers = this.state.answers,
			// questions = this.state.questions,
			hasError = false;
		for (let i = 0; i < Object.values(answers).length; i++) {
			if (Object.values(answers)[i].answer === '' && !hasError) hasError = Object.keys(answers)[i];
		}

		if (!hasError) {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/lead-rating/submit/' + this.props.leadId, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'x-auth-token': JSON.parse(readCookie('access_token'))
				},
				body: JSON.stringify(answers)
			}).then((response) => {
				return response.json();
			}).then((data) => {
				if (data.status) {
					Swal.fire("The lead score is " + data.score + "%", "", "success").then(() => {
						window.location.pathname = '/lead/' + this.props.leadId + '/rating';
					});
				} else {
					this.notifyError("Submission failed!");
				}
			});
		} else {
			this.notifyError('Please answer all questions!');
		}
	}

	notifySuccess = (text) => toast.success(text)
	notifyError = (text) => toast.error(text)

	render() {
		return (
			<Col md={12} className="tab-container lead-strength-rating-container">
				{this.state.responseData !== null ? (
					<div className="lead-current-rating d-flex">
						<Col>
							{this.state.responseData.respondedBy ? (
								this.state.responseData.respondedBy.fullName ? (
									<h3>Responded By: <span>{this.state.responseData.respondedBy.fullName}</span></h3>
								) : (null)
							) : (null)}
							<h3>Responded At: <span>{this.state.responseData.respondedAt}</span></h3>
						</Col>
						<Col className="score">
							<h3>Score: <span>{this.state.responseData.totalPoints + '%'}</span></h3>
						</Col>
					</div>
				) : (null)}
				<Col className="questions-container">
					{this.state.questions.length ? (
						this.state.questions.map((question, questionIndex) => {
							return (
								<Col md={12} className="questionare-container mb30" key={questionIndex}>
									<label className="control-label">{(questionIndex + 1) + '. ' + question.text}</label>
									<div className="option-container d-flex align-items-center">
										{question.answers.length ? (
											question.answers.map((answer, answerIndex) => {
												if (answer) {
													return (
														<div key={answerIndex} className="d-flex justify-content-center align-items-center options" style={{ marginRight: '30px' }}>
															{this.state.answers[question.id] ? (
																<input type="radio" style={{ marginRight: '5px', cursor: 'pointer' }} value={answer} name={'question' + questionIndex} onChange={this.answerHandler.bind(this, question.id)} checked={this.state.answers[question.id].answer === answer ? true : false} />
															) : (
																<input type="radio" style={{ marginRight: '5px', cursor: 'pointer' }} value={answer} name={'question' + questionIndex} onChange={this.answerHandler.bind(this, question.id)} defaultChecked={false} />
															)}
															<label style={{ marginTop: 3, marginBottom: 3 }} className="control-label">{answer}</label>
														</div>
													)
												}
												else return null;
											})
										) : (null)}
									</div>
									<div className="mb5 mt5 comments-container">
										{question.commentType === 'text' ? (
											this.state.answers[question.id] ? (
												<textarea onChange={this.commentHandler.bind(this, question.id)} style={{ width: "100%", minHeight: 60 }} placeholder="Comments" className="form-control" defaultValue={this.state.answers[question.id].comment}></textarea>
											) : (
												<textarea onChange={this.commentHandler.bind(this, question.id)} style={{ width: "100%", minHeight: 60 }} placeholder="Comments" className="form-control" defaultValue=""></textarea>
											)
										) : (
											this.state.answers[question.id] ? (
												<input type="number" placeholder="Enter amount in INR" onChange={this.commentHandler.bind(this, question.id)} className="form-control" defaultValue={this.state.answers[question.id].comment} />
											) : (
												<input type="number" placeholder="Enter amount in INR" onChange={this.commentHandler.bind(this, question.id)} className="form-control" defaultValue="" />
											)
										)}
									</div>
								</Col>
							)
						})
					) : (null)}
					<Col md={12} className="text-center mt20 mb20">
						<button className="btn submit-rating-btn" onClick={this.submit}>Generate Lead Rating</button>
					</Col>
				</Col>
			</Col>
		);
	}
}

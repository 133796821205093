import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import Leads from '../2-leads/Leads';
import PlatformUsers from './PlatformUsers';
import AllActivities from '../6-activities/AllActivities';
import SckClaims from './SckClaimsTab';

const readCookie = require('../cookie.js').readCookie;

export default class PlatformOrg extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userType: null,
			orgData: null
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-type/' + this.props.match.params.type, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ userType: data.data });
				}
			});

		fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/' + this.props.match.params.orgId, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ orgData: data.ngo });
				}
			});
	}

	changeStatus = () => {
		let orgData = this.state.orgData;
		Swal.fire({
			title: 'Select to update status',
			input: 'select',
			inputOptions: {
				'Active': 'Active',
				'Inactive': 'Inactive'
			},
			inputPlaceholder: 'Select to update status',
			showCancelButton: true,}
			// inputValidator: (value) => {
		).then((x)=>{
			const value=x.value;
		if (value && (value !== orgData.status || value==='Inactive')) {
				
		
			if(value==='Inactive'){
								Swal.fire({
			title: 'Select a reason to deactivate',
			input: 'select',
			inputOptions: {
				"Poor performance, not able to meet desired outcomes":"Poor performance, not able to meet desired outcomes",
"Passiveness despite numerous online and in-person training":"Passiveness despite numerous online and in-person training",
"Did not start the mission at all (<15 profiles)":"Did not start the mission at all (<15 profiles)",
"Organization adversely affected by COVID 19":"Organization adversely affected by COVID 19",
"Took the advance and left the mission":"Took the advance and left the mission",
"Got bigger opportunity in livelihood space":"Got bigger opportunity in livelihood space",
"Got bigger opportunity in livelihood space owing to Mission Swavalamban":"Got bigger opportunity in livelihood space owing to Mission Swavalamban",
"SCK's internal resource crunch":"SCK's internal resource crunch",
"Fraudulent or misleading activities":"Fraudulent or misleading activities",
"Left the mission after taking initial payment":"Left the mission after taking initial payment",
"District discontinued": "District discontinued"
			},
			inputPlaceholder: 'Select a reason to deactivate',
			showCancelButton: true,
			inputValidator: (reasonValue) => {
				if(reasonValue){
orgData.status = value;
					orgData.reasonForDeboarding = reasonValue;
					orgData.deactivatedOn = moment();
					fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/' + this.props.match.params.orgId, {
						method: "PATCH",
						headers: {
							'Content-Type': 'application/json',
							'x-auth-token': JSON.parse(readCookie('access_token'))
						},
						body: JSON.stringify({ status: value, reasonForDeboarding:reasonValue  })
					}).then((response) => {
						return response.json();
					}).then((data) => {
						if (data.status) {
							this.notifySuccess('Successfully updated the status of the organisation!');
							this.setState({ orgData });
						} else {
							this.notifyError("Update NGO status failed!");
						}
					});
				}
					
				
			}
		});}
					
					else if(value && value!=='Inactive'){
					orgData.status = value;
					fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/' + this.props.match.params.orgId, {
						method: "PATCH",
						headers: {
							'Content-Type': 'application/json',
							'x-auth-token': JSON.parse(readCookie('access_token'))
						},
						body: JSON.stringify({ status: value })
					}).then((response) => {
						return response.json();
					}).then((data) => {
						if (data.status) {
							this.notifySuccess('Successfully updated the status of the organisation!');
							this.setState({ orgData });
						} else {
							this.notifyError("Update NGO status failed!");
						}
					});
					}
			
				}
			
		});
	}

	notifySuccess = (text) => toast.success(text);

	notifyError = (text) => toast.error(text);

	render() {
		if (this.state.userType !== null) {
			return (
				<div className="org-details-page page-container">
					{this.state.orgData !== null ? (
						<Col style={{ padding: '0px', marginBottom: '20px' }} className="d-flex align-items-center">
							<a className="go-back-btn d-flex align-items-center" href={'/org-types/' + this.props.match.params.type}><i className="fas fa-chevron-left mr5"></i>Back</a>
							<h3 style={{ margin: '0px' }}>{this.state.orgData.ngoName}</h3>
							{this.state.orgData.logo ? (
								<img alt="" src={this.state.orgData.logo} onError={(event) => event.target.setAttribute("src", "/images/user_1.png")} />
							) : (null)}
						</Col>
					) : (null)}
					<Tabs defaultActiveKey={1} id="org-details-tab">
						<Tab eventKey={1} title={<div className="organisation-tab-header"><i className='far fa-newspaper'></i><span> Overview</span></div>}>
							{JSON.parse(readCookie('userData')).type !== 'sidbi' ? (
								<div className="edit-btn-container d-flex justify-content-end">
									<Button variant='primary' onClick={() => { window.location.pathname = 'org-types/' + this.props.match.params.type + '/' + this.props.match.params.orgId + '/edit' }}>Edit/Manage</Button>
								</div>
							) : (null)}
							{this.state.orgData  ? (
								<Row className="org-details-overview-container">
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">Admin Full Name : </span>
										<span className="value">{this.state.orgData.adminId.fullName}</span>
									</Col>
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">Email : </span>
										<span className="value">{this.state.orgData.email}</span>
									</Col>
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">Mobile Number : </span>
										<span className="value">{this.state.orgData.mobileNumber}</span>
									</Col>
									<Col md={4} className="d-flex align-items-center justify-content-between">
										<div>
											<span className="heading">STATUS : </span>
											<span className="value">{this.state.orgData.status.toUpperCase()}</span>
										</div>
										{JSON.parse(readCookie('userData')).type !== 'sidbi' && false ? (
											<div>
												<span className="org-change-status" onClick={this.changeStatus}>Change</span>
											</div>
										) : (null)}
									</Col>
{this.state.orgData.status.toLowerCase()==='inactive' && this.state.orgData.reasonForDeboarding ?									<Col md={4} className="d-flex align-items-center justify-content-between">
									<div>
											<span className="heading">REASON FOR DEBOARDING : </span>
											<span className="value">{this.state.orgData.reasonForDeboarding}</span>
										</div>
									</Col> : null}
									{this.state.orgData.status.toLowerCase()==='inactive' && this.state.orgData.deactivatedOn && JSON.parse(readCookie('userData')).type !== 'sidbi' ?									<Col md={4} className="d-flex align-items-center justify-content-between">
									<div>
											<span className="heading">DATE OF DEBOARDING : </span>
											<span className="value">{moment(this.state.orgData.deactivatedOn).format("DD/MM/YYYY")}</span>
										</div>
									</Col> : null}
									<Col md={4}>
										<span className="heading">IS NGO PARTNER : </span>
										<span className="value">{this.state.orgData.isNgoPartner && this.state.orgData.leProfileUrl ? 'Yes' : 'No'}</span>
										{this.state.orgData.isNgoPartner && this.state.orgData.leProfileUrl ? (<span className="value"> [<a href={this.state.orgData.leProfileUrl} rel="noopener noreferrer" target="_blank">{this.state.orgData.ngoName}</a>]</span>) : (null)}
									</Col>
									{this.state.orgData.sckType ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">SCK TYPE : </span>
											<span className="value">{this.state.orgData.sckType.toUpperCase()}</span>
										</Col>
									) : (null)}
									{this.state.orgData.bankIfsc ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">IFSC: </span>
											<span className="value">{this.state.orgData.bankIfsc.toUpperCase()}</span>
										</Col>
									) : (null)}

									{this.state.orgData.bankAccNo ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">ACCOUNT NUMBER: </span>
											<span className="value">{this.state.orgData.bankAccNo}</span>
										</Col>
									) : (null)}
									{this.state.orgData.bankBranchAddress ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">BANK BRANCH ADDRESS: </span>
											<span className="value">{this.state.orgData.bankBranchAddress.toUpperCase()}</span>
										</Col>
									) : (null)}
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">State : </span>
										<span className="value">{this.state.orgData.state}</span>
									</Col>
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">District : </span>
										<span className="value">{this.state.orgData.district}</span>
									</Col>
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">City : </span>
										<span className="value">{this.state.orgData.city}</span>
									</Col>
									<Col md={this.state.orgData.address2 ? 6 : 12} className="d-flex align-items-center">
										<span className="heading">Address Line 1 : </span>
										<span className="value">{this.state.orgData.address1}</span>
									</Col>
									{this.state.orgData.address2 ? (
										<Col md={6} className="d-flex align-items-center">
											<span className="heading">Address Line 2 : </span>
											<span className="value">{this.state.orgData.address2}</span>
										</Col>
									) : (null)}
									{this.state.orgData.location ? (
										<Col md={9} className="d-flex align-items-center">
											<span className="heading">OFFICE LOCATION : </span>
											<span className="value"><a href={`https://maps.google.com/?q=${this.state.orgData.location.latitude},${this.state.orgData.location.longitude}`} rel="noopener noreferrer" target="_blank">{this.state.orgData.location.label}</a></span>
											&emsp;{this.state.orgData.locationUrl !== undefined && this.state.orgData.locationUrl !== "" && <span className="heading"><a href={this.state.orgData.locationUrl} rel="noopener noreferrer" target="_blank">Verified Location</a></span>}
										</Col>
									) : (null)}
									<Col md={3} className="d-flex align-items-center">
										<span className="heading">Pincode : </span>
										<span className="value">{this.state.orgData.pincode}</span>
									</Col>

									{this.state.orgData.ngoUrl ? (
										<Col md={6} className="d-flex align-items-center">
											<span className="heading">NGO Website Url : </span>
											<span className="value ml5"><a className="btn" href={`${this.state.orgData.ngoUrl}`} rel="noopener noreferrer" target="_blank">Click here</a></span>
										</Col>
									) : (null)}

									{this.state.orgData.swavalambanFellow ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">TAGGED TO : </span>
											<span className="value">{this.state.orgData.swavalambanFellow}</span>
										</Col>
									) : (null)}
									<Col md={4} className="d-flex align-items-center">
										<span className="heading">ADDED ON : </span>
										<span className="value">{moment(this.state.orgData.addedOn).format('DD/MM/YYYY hh:mm A')}</span>
									</Col>
									{this.state.orgData.addedBy ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">ADDED BY : </span>
											<span className="value">{this.state.orgData.addedBy.fullName}</span>
										</Col>
									) : (null)}
									{this.state.orgData.deactivatedOn ? <Col md={4} className="d-flex align-items-center">
										<span className="heading">DEACTIVATED ON : </span>
										<span className="value">{moment(this.state.orgData.deactivatedOn).format('DD/MM/YYYY hh:mm A')}</span>
									</Col>:(null)}
									{this.state.orgData.deactivatedBy ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">DEACTIVATED BY : </span>
											<span className="value">{this.state.orgData.deactivatedBy.fullName}</span>
										</Col>
									) : (null)}
									{this.state.orgData.estd ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">ESTD : </span>
											<span className="value">{this.state.orgData.estd}</span>
										</Col>
									) : (null)}
									{this.state.orgData.causes.length ? (
										<Col md={4}>
											<span className="heading">CAUSES : </span>
											<span className="value">{this.state.orgData.causes.map((cause, index) => {
												if (this.state.orgData.causes.length > 1) {
													return cause + ', ';
												} else {
													return cause
												}
											})}
											</span>
										</Col>
									) : (null)}
									{this.state.orgData.cachmentPopulation ? (
										<Col md={4} className="d-flex align-items-center">
											<span className="heading">CACHMENT POPULATION : </span>
											<span className="value">{this.state.orgData.cachmentPopulation}</span>
										</Col>
									) : (null)}
									{this.state.orgData.officePhotos.length !== 0 &&
										this.state.orgData.officePhotos.map(image => {
											return <Col md={4}>
												<img src={image} alt="" height="180" width="250" style={{ textAlign: "center", borderWidth: 1, borderColor: '#eee', padding: '5px' }} />
											</Col>
										})
									}
								</Row>
							) : (null)}
						</Tab>
						<Tab eventKey={2} title={<div className="organisation-tab-header"><i className='fas fa-users'></i><span> Users</span></div>}>
							<PlatformUsers type={this.props.match.params.type} orgId={this.props.match.params.orgId} />
						</Tab>
						<Tab eventKey={3} title={<div className="organisation-tab-header"><i className='fas fa-chart-pie'></i><span> Leads</span></div>}>
							<Leads type={this.props.match.params.type} orgId={this.props.match.params.orgId} view="tab" />
						</Tab>
						<Tab eventKey={4} title={<div className="organisation-tab-header"><i className='fas fa-calendar-alt'></i><span> Activities</span></div>}>
							{this.state.orgData ? (
								<AllActivities type={this.props.match.params.type} orgId={this.props.match.params.orgId} orgName={this.state.orgData.ngoName} view="tab" />
							) : (null)}
						</Tab>

						{JSON.parse(readCookie("userData")).type !== "sidbi" && <Tab eventKey={5} title={<div className="organisation-tab-header"><i className='fas fa-receipt'></i><span> Claims</span></div>}>
							{/* {this.state.orgData ? ( */}
							{/* // <AllActivities type={this.props.match.params.type} orgId={this.props.match.params.orgId} orgName={this.state.orgData.ngoName} view="tab" /> */}
							{/* <div>ALL data comes here</div>
							 */}
							<SckClaims orgId={this.props.match.params.orgId} />
							{/* ): (null)} */}
						</Tab>}
					</Tabs>
				</div>
			);
		} else return null;
	}
}

import React
    // , { useState, useEffect }
    from 'react';
import { Table } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import PropTypes from 'prop-types';
// import Form from 'react-bootstrap/Form';
// import swal from 'sweetalert';
// import axios from 'axios';
// import { State, City } from 'country-state-city';
// import {
//     SaveFilled,
//     EditFilled,
// } from '@ant-design/icons';
import moment from 'moment';

// const readCookie = require('../../cookie').readCookie;
// const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`;

function GeneralDetailsTab(props) {
    const { bankDetails } = props;
    const dataToRender = [{
        heading: 'Basic Details',
        rows: [
            { label: 'Bank Name', field: 'bank' },
            { label: 'Branch', field: 'branch' },
            { label: 'IFSC', field: 'ifsc' },
            { label: 'MICR', field: 'micr' },
            { label: 'Main Branch', field: 'mainBranch' },
            { label: 'Lending Branch', field: 'lendingBranch' },

            { label: 'Experience', field: 'experience' },
        ]
    },
    {
        heading: 'Address & Location',
        rows: [
            { label: 'Address', field: 'address' },

            { label: 'City', field: 'city' },
            { label: 'Circle', field: 'circle' },
            { label: 'District', field: 'district' },
            { label: 'State', field: 'state' },
            { label: 'Pincode', field: 'pincode' },

            { label: 'Latitude', field: 'latitude' },
            { label: 'Longitude', field: 'longitude' },
        ]
    },
    {
        heading: 'Contact Info',
        rows: [
            { label: 'Email', field: 'email' },
            { label: 'Visiting Hours', field: 'hours' },

            { label: 'Phone', field: 'phone' },

            { label: 'Primary Contact Name', field: 'primaryContactName' },
            { label: 'Primary Contact Number', field: 'primaryContactMob' },
            { label: 'Primary Contact Email', field: 'primaryContactEmail' },

            { label: 'Secondary Contact Name', field: 'secondaryContactName' },
            { label: 'Secondary Contact Number', field: 'secondaryContactMob' },
            { label: 'Secondary Contact Email', field: 'secondaryContactEmail' },
        ]
    },
    {
        heading: 'Meta Info',
        rows: [
            { label: 'Added By', field: 'addedBy', nested: true },
            { label: 'Added At', field: 'addedAt', isTime: true },
            { label: 'Updated By', field: 'updatedBy', nested: true },
            { label: 'Updated At', field: 'updatedAt', isTime: true },
            { label: 'Verified By', field: 'verifiedBy', nested: true },
            { label: 'Verified At', field: 'verifiedAt', isTime: true },

        ]
    }
    ]
    return (<div className='Tab1Container'>
        <div className='Tab1Table'>
            {
                bankDetails
                && <div>
                    {dataToRender.map((division, index) => {
                        return <div className='Tab1TableElements' key={index}><h3> {division.heading} </h3>
                            <Table striped hover variant="light" size="lr">

                                <tbody >
                                    {division.rows.map((row, index) => {
                                        return <tr key={index} style={{ display: 'flex' }}>
                                            <td style={{ textAlign: 'right', width: '40%', fontWeight: "900" }}>{row.label}</td>
                                            <td style={{ textAlign: 'left', width: '60%' }}>

                                                {(row.nested ? bankDetails[row.field]['name'] : typeof bankDetails[row.field] === 'boolean' ? bankDetails[row.field] ? 'Yes' : 'No' : row.isTime && bankDetails[row.field] ? moment(bankDetails[row.field]).format('DD/MM/YYYY hh:mm A') : bankDetails[row.field]) || '-'}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                            <br /></div>

                    })}
                    {/* <h3> Address </h3>

                    <Table striped hover variant="light" size="lr">

                        <tbody >
                            {[{ label: 'Bank Name', field: 'bank' },
                            { label: 'Branch', field: 'branch' },
                            { label: 'IFSC', field: 'ifsc' },
                            { label: 'MICR', field: 'micr' },
                            { label: 'Main Branch', field: 'mainBranch' },


                            ].map((row, index) => {
                                return <tr key={index} style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>{row.label}</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {typeof bankDetails[row.field] === 'boolean' ? bankDetails[row.field] ? 'Yes' : 'No' : bankDetails[row.field] || '-'}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table> */}
                    {/* <br />
                    <h3> Contact Details</h3> */}
                </div>
            }

        </div>
        {/* < MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
            /> */}
    </div >

    )
}

export default GeneralDetailsTab;
/** @format */

import React from "react";
import Swal from "sweetalert2";
import { Select } from "antd";
import {
  Col,
  Row,
  // Button, Modal
} from "react-bootstrap";
const { OptGroup, Option } = Select;
const Eligibility = ({
  leadData,
  projectData,
  globalDDFormState,
  setGlobalDDFormState,
  dueDiligenceChange,
  isEditable,
  saveToProfile,
  showHoldPrompt,
  showDisqualificationPrompt,
}) => {
  return (
    <>
      <h3>ELIGIBILITY (MANDATORY)</h3>
      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          What type of set-up do you have?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.setUp || ""}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "setUp"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Commercial", "Home-based unit", "Agriculture land"].map(
            (option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            }
          )}
        </Select>
      </Col>
      {globalDDFormState.eligibility.setUp === "Home-based unit" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.setUp === "Agriculture land" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Is there any other person from the family taken PM FME loan?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.familyTakenPmfLoan}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "familyTakenPmfLoan"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["No", "Yes"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.familyTakenPmfLoan === "Yes" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
      <div className='d-flex flex-column'>
        <label className='control-label'>
          What is your business idea? 
          </label>
          <note>
          (Should not be hotel, restaurant, vending
          cart, kirana, veg/fruit shop, poultry, cattle, fishery, only packing,
          etc.)
          </note>
          </div>
      
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.businessIdea}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "businessIdea"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Eligible", "Ineligible"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.businessIdea === "Ineligible" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      {/* <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          "Do you have your educational certificate? (You must be at least 8th
          standard pass to apply.)"
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.educationalCertificate}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "educationalCertificate"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.educationalCertificate === "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null} */}


      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>Select the purpose of loan</label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.purposeOfLoan}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "purposeOfLoan"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Machinery",
            "Machinery and Working capital",
            "Working capital only",
            "Construction",
            "Working capital",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.purposeOfLoan ===
      "Working capital only" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.purposeOfLoan === "Construction" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.purposeOfLoan === "Working capital" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
      <div className='d-flex flex-column'>
        <label className='control-label'>
          What is your CIBIL score?
          </label>
          <note>
          (Ask the candidate to share the latest CIBIL
          report)
          </note>
          </div> 
        
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.cibilScore}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "cibilScore"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Unknown", "0,-1", "<650", "650-700", "700-750", "750+"].map(
            (option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            }
          )}
        </Select>
      </Col>
      {globalDDFormState.eligibility.cibilScore === "<650" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      <Col md={6} className='due-diligence-question'>
        <label className='control-label'>Upload CIBIL Report</label>
      </Col>
      <Col md={6} className='due-diligence-question'>
        <div
          // className='d-flex'
        >

          {globalDDFormState.eligibility.cibilReportDocument ?
            <div>
              <a href={globalDDFormState.eligibility.cibilReportDocument} target="_blank" rel="noreferrer noopener"><strong> View File</strong></a></div> : <input
            className='form-control'
            type='file'
            onChange={(e)=>dueDiligenceChange(e,"eligibility","cibilReportDocument")}
            accept='.jpg,.png,.jpeg,.pdf'
            disabled={!isEditable}
          />}
       
        </div>
        {/* upload is pending */}
        {/** Label for key - cibilReportDocument */}
      </Col>
      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>Proposed Loan amount</label>
        <input
          type='text'
          defaultValue={parseInt(leadData.enterpriseStatus.loanAmountRequired)}
          className={"form-control"}
          disabled
        />
      </Col>
      {leadData.enterpriseStatus.phase === "Scale-up" ? (
        <Col md={12} className='due-diligence-question same-line'>
          <label className='control-label'>
            Mention your last year turnover 
          </label>

          <div className='inr-input'>
        <span className='inr-symbol'>₹</span>
        <input
          type='number'
          placeholder='Last year turnover (INR)'
          // value={
          //   globalDDFormState.eligibility.lastYearTurnOver
          //     ? String(globalDDFormState.eligibility.lastYearTurnOver)
          //         .replace(/\D/g, "")
          //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          //     : ""
          // }
          value={globalDDFormState.eligibility.lastYearTurnOver}
          className={"form-control"}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "lastYearTurnOver"
            )
          }
        />
      </div>
        </Col>
      ) : null}

   

      {leadData.enterpriseStatus.loanAmountRequired >
      globalDDFormState.eligibility.lastYearTurnOver
        ? <Col md={12}>
          <div className='d-flex flex-column'>
          <note>Loan amount is more than last year's Turnover. Reduce the same.</note>
        </div>
        </Col>
        : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Do you have any ongoing loans or have taken loans previously ?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.loanHistory}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "loanHistory"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "No loan history",
            "Past loan repaid - no ongoing loan",
            "Another loan ongoning",
            "Already applied for PMFME",
            "Past loan defaulted",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.loanHistory === "Another loan ongoning" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD Till IT IS REPAID
          </button>
        </Col>
      ) : null}

      {globalDDFormState.eligibility.loanHistory ===
      "Already applied for PMFME" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.loanHistory === "Past loan defaulted" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Are you willing to give 10% margin to the bank?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.WillingToGiveMargin}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "WillingToGiveMargin"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.WillingToGiveMargin === "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {leadData.enterpriseStatus.loanAmountRequired > 1000000 ? (
        <>
          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              What kind of collateral can you provide to the bank if the banker
              asks for it? (For loans above INR 10 lacs)
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.eligibility.whatKindOfCollateral}
              disabled={!isEditable}
              onChange={(e) =>
                dueDiligenceChange(
                  e,
                  "eligibility",
                  // "questions",
                  "whatKindOfCollateral"
                )
              }
            >
              <Option value='' disabled>Select Option</Option>
              {[
                "Personal Investment",
                "Land (own name)",
                "Land (Parents/family)",
                "No collateral",
              ].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {globalDDFormState.eligibility.whatKindOfCollateral ===
          "No collateral" ? (
            <Col md={12}>
              <button
                className='btn btn-default mark-as-disqualified-btn'
                disabled={!isEditable}
                onClick={showDisqualificationPrompt}
              >
                MARK AS DISQUALIFIED
              </button>
            </Col>
          ) : null}
        </>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Calculate EMI using{" "}
          <note>
            <a
              href={"https://groww.in/calculators/emi-calculator"}
              rel='noopener noreferrer'
              target='_blank'
            >https://groww.in/calculators/emi-calculator</a>
          </note>
          &nbsp; and share the EMI with them. Use 11% as interest rate. Will you make
          enough profit to pay this EMI?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.CalculateEmi}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "CalculateEmi"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Confident", "Not confident"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.CalculateEmi === "Not confident" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Do you have quotation for machinery ready? (Upload quotations in the
          next stage)
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.quotationForMachinery}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "quotationForMachinery"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Have the quotations",
            "Will arrange quotation in a week",
            "Need help in getting quotation",
            "Cannot arrange for quotation",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.quotationForMachinery ===
      "Cannot arrange for quotation" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.quotationForMachinery ===
      "Will arrange quotation in a week" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.quotationForMachinery ===
      "Need help in getting quotation" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
      <Col md={12} className='due-diligence-question same-line'>
      <div className='d-flex flex-column'>
        <label className='control-label'>
          Status of the place of unit: 
          </label>
          <note>
          Does it have the necessary
          infrastructure? Like water, plumbing, electricity, proper drainage, no
          foul smell, safety, security, cleanliness etc.
          </note>
         
        
        </div>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.StatusOfPlaceOfUnit}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "StatusOfPlaceOfUnit"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Necessary infrastructure is available",
            "Some upgrade is needed",
            "No facilities (might fail field visit)",
            "Location yet to finalize",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.StatusOfPlaceOfUnit ===
      "Some upgrade is needed" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.StatusOfPlaceOfUnit ===
      "No facilities (might fail field visit)" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.StatusOfPlaceOfUnit ===
      "Location yet to finalize" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>Mention the first bank's IFSC</label>
        <input
          type='text'
          placeholder='Please specify'
          value={globalDDFormState.eligibility.firstBankIFSC}
          className={"form-control"}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "firstBankIFSC"
            )
          }
        />
      </Col>

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>Mention the second bank's IFSC</label>
        <input
          type='text'
          placeholder='Please specify'
          value={globalDDFormState.eligibility.secondBankIFSC}
          className={"form-control"}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "secondBankIFSC"
            )
          }
        />
      </Col>

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Is your bank account active since the last 6 months?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.bankAccountIsActive}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "bankAccountIsActive"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.bankAccountIsActive === "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          How far is your bank branch from your (proposed) unit location?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.bankDistanceFromLocation}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "bankDistanceFromLocation"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["<5 km", "5 km - 10 km", "10 km - 15 km", ">15 km"].map(
            (option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            }
          )}
        </Select>
      </Col>
      {globalDDFormState.eligibility.bankDistanceFromLocation === ">15 km" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      {globalDDFormState.eligibility.bankDistanceFromLocation ===
      "10 km - 15 km" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          How is your relation with your primary bank
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.relationWithPrimaryBank}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "relationWithPrimaryBank"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Good", "Neutral", "Bad Relation"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.relationWithPrimaryBank ===
      "Bad Relation" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
      <div className='d-flex flex-column'>
        <label className='control-label'>

          Would you visit the bank when required and asked to?
          </label>
          <note>
            (The candidate should know that banking procedures take time and
            they would need to be patient while visiting the bank. Multiple
            visits to the bank shall be required and the candidate should be
            ready for the same.)
          </note>
       </div>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={
            globalDDFormState.eligibility.candidateVisitingBankWhenRequired
          }
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "candidateVisitingBankWhenRequired"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.candidateVisitingBankWhenRequired ===
      "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
      <div className='d-flex flex-column'>
        <label className='control-label'>
          Are you comfortable with bank visiting your enterprise set-up for
          inspection?
          </label>
          <note>
            (Candidate must be ready for field visit by the bank as without
            field vist and unit inspection, bank will not approve the loan.)
          </note>
       </div>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={
            globalDDFormState.eligibility.comfortableWithBankVisit
          }
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "comfortableWithBankVisit"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.comfortableWithBankVisit ===
      "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Do you have an active FSSAI license?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.eligibility.haveActiveFSSAI}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(
              e,
              "eligibility",
              // "questions",
              "haveActiveFSSAI"
            )
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Yes",
            "No, I will arrange for it within a week",
            "No, I will take more time",
            "Yes, I will arrange once the unit starts",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.eligibility.haveActiveFSSAI ===
      "No, I will arrange for it within a week" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
      {globalDDFormState.eligibility.haveActiveFSSAI ===
      "No, I will take more time" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e) => showHoldPrompt("dueDiligence")}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
    </>
  );
};

export default Eligibility;

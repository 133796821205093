import React, { useState, useEffect } from "react";
import { Select, } from 'antd';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Col, Row, Button, Container, } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const readCookie = require('../cookie.js').readCookie;

const { Option } = Select;
const notifySuccess = (text) => toast.success(text);
const notifyError = (text) => toast.error(text);

const PaymentClaim = (compProps) => {
    const { userData, leadData } = compProps;
    const TODAY = new Date();

    const initialClaim = {
        claimantId: "",
        leadId: leadData.id,
        billId: "",
        dateOfPayment: TODAY,
        comments: undefined,
        status: ''
    };
    const canEdit = userData.userType.name === 'Project Management Unit';

    const [claim, setClaim] = useState({});
    const [meta, setMeta] = useState({});

    const [editingClaim, setEditingClaim] = useState({});
    const [scks, setScks] = useState([]);
    const [errorsList, setErrors] = useState([]);
    const [errorsMessages, setErrorMessages] = useState([]);
    const [editing, setEditing] = useState(false);

    const fetchClaimDetails = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/lead/' + leadData.id, {
            method: 'GET',
            headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    setClaim(data.claimDetails);
                    setMeta(data.metaDetails);
                    if (data.claimDetails.claimantId) {
                        getBills(data.claimDetails.claimantId);
                    }
                }
            }).catch(err => {
                setClaim(initialClaim);
            });
    }
    const getScksList = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo?districts=' + leadData.basic.address.district, {
            method: 'GET',
            headers: {
                'x-auth-token': JSON.parse(readCookie('access_token'))
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    setScks(data.ngos || []);
                }
            });
    }

    // make claim calls - update or get call
    const makeClaimCall = () => {
        // let formDataBody = getDataForm();
        // const errors =

        if ([
            'Claimed and Settled'].indexOf(editingClaim.status) === -1) {
            setEditingClaim({ ...editingClaim, dateOfPayment: null });
        }

        validateBill();
        if (errorsList.length) {
            notifyError('Please make sure you\'ve filled all the fields correctly!');
            return;
        }

        if (editingClaim._id) {
            Swal.fire({
                icon: 'warning',
                title: 'Are you sure about this?',
                html: 'You are about override existing claim. This is an irreversible action!',
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Please update',
                cancelButtonAriaLabel: 'No, Cancel',
                confirmButtonAriaLabel: 'Yes, Please update'
            }).then((result) => {
                if (result.value) {
                    setUpdating(true);
                    fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/lead/' + editingClaim._id, {
                        method: 'PUT',
                        headers: {
                            'x-auth-token': JSON.parse(readCookie('access_token')),
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(editingClaim)
                    }).then(data => data.json())
                        .then(data => {
                            if (data.status) {
                                notifySuccess(data.message);
                                fetchClaimDetails();
                                stopEditing();
                                setUpdating(false);
                            }
                        }).catch(err => {
                            console.error(err, err.errorMsg);
                            notifyError(err.errorMsg);
                            setUpdating(false);
                        });
                }
            });

        }
        else {
            setUpdating(true);
            fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/lead/', {
                method: 'POST',
                headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingClaim)
            }).then(data => data.json())
                .then(data => {
                    if (data.status) {
                        notifySuccess(data.message);
                        fetchClaimDetails();
                        stopEditing();
                        setUpdating(false);
                    }
                }).catch(err => {
                    console.error(err, err.errorMsg);
                    notifyError(err.errorMsg);
                    setUpdating(false);
                });
        }
    }


    const validateBill = (submittingForm = false) => {
        let { status, claimantId, dateOfPayment, billId } = editingClaim;
        let errorMsgs = {};
        let errors = [];

        if (status === '') {
            errorMsgs['status'] = 'Status can\'t be empty';
            errors.push('Status can\'t be empty');
        }
        if (claimantId === '' && [
            'Claimed but Audit Failed',
            'Claimed and Payment Pending',
            'Claimed and Settled'].indexOf(status) > -1) {
            errorMsgs['claimantId'] = 'Claimant should be selected';
            errors.push('Claimant should be selected');
        }
        if (billId === '' && [
            // 'Claimed but Audit Failed',
            'Claimed and Payment Pending',
            'Claimed and Settled'].indexOf(status) > -1) {
            errorMsgs['billId'] = 'Bill must be selected';
            errors.push('Bill must be selected');
        }
        if (dateOfPayment === '' && [
            'Claimed and Settled'].indexOf(dateOfPayment) > -1) {
            errorMsgs['dateOfPayment'] = 'Date of Payment must be selected';
            errors.push('Date of Payment must be selected');
        }
        setErrorMessages(errorMsgs);
        setErrors(errors);
    }
    useEffect(() => {
        fetchClaimDetails();
        getScksList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateBill();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingClaim]);
    //Show claim modal - set modal object to show details
    const [bills, setBills] = useState([]);
    const [updating, setUpdating] = useState(false);

    const getBills = (id) => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/sck/bills/' + id, {
            method: 'GET',
            headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    setBills(data.bills);
                }
            }).catch(err => {
                setBills([]);
            });
    }


    const stopEditing = () => { setEditingClaim({}); setErrors([]); setErrorMessages({}); setEditing(false); }
    return <div className="manage-claims">

        {
            (claim._id || editing) ?
                <>
                    <Container>
                        <Row className="d-flex align-items-end justify-content-between" style={{ margin: "auto", fontSize: 18 }}>
                            {/* <span style={{marginTop: 10}}> */}
                            {claim?.status && <Col md={5}>
                                <h6>
                                    Current Status: {claim.status}
                                </h6></Col>}
                            {meta?.updater && <Col md={5} style={{ color: "green", textAlign: "right" }}>
                                Last updated by {meta.updater} on {moment(claim.updatedAt).format('DD-MM-YYYY, hh:mm a')}
                            </Col>}
                            <Row>
                                <Col md={2}>
                                    {(canEdit && !editing) ? <Button onClick={() => {
                                        setEditing(true);
                                        setEditingClaim(claim);
                                    }} variant="primary">Edit</Button>
                                        : null} </Col></Row>
                            {/* </span> */}
                        </Row>
                    </Container>
                    <hr />
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <label className="control-label is-imp">    Select Claim Status
                                </label>   </Col>
                            <Col sm={6}>
                                <Row>
                                    <Select
                                        showSearch
                                        // className="form-control"
                                        style={{ width: 250 }}
                                        // placeholder="Select Status"
                                        onChange={(e) => {
                                            let claimObj = editingClaim;
                                            claimObj.status = e;
                                            // if ([
                                            //     'In-house-intervention-Cannot be claimed',
                                            //     'Unclaimed'].indexOf(e) > -1) {
                                            //     claimObj.claimantId = "";
                                            //     claimObj.billId = "";
                                            //     claimObj.dateOfPayment = TODAY;
                                            // }
                                            // else if ([
                                            //     'Claimed but Audit Failed',
                                            // ].indexOf(e) > -1) {
                                            //     claimObj.billId = undefined;
                                            //     claimObj.dateOfPayment = undefined;
                                            // }
                                            // else if ([
                                            //     'Claimed and Payment Pending',
                                            // ].indexOf(e) > -1) {
                                            //     claimObj.dateOfPayment = undefined;
                                            // }
                                            // console.log(claimObj)
                                            // setEditingClaim(claimObj);
                                            // onChange = {(e) => {
                                            setEditingClaim({ ...editingClaim, status: e });
                                            // }}

                                        }}
                                        disabled={!editing}
                                        value={editing ? editingClaim.status || "" : claim.status || ""}>
                                        <Option value="">Select Claim Status</Option>
                                        {[
                                            'In-house-intervention-Cannot be claimed',
                                            'Unclaimed',
                                            'Claimed but Audit Failed',
                                            'Claimed and Payment Pending',
                                            'Claimed and Settled'].map((x, index) => {
                                                return <Option key={index} value={x}>{x}</Option>
                                            })}

                                    </Select>
                                </Row>
                                <Row>
                                    <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }}
                                        className="has-error">{errorsMessages['status'] || ''}
                                    </small>
                                </Row>
                            </Col>
                        </Row>
                        {[
                            // 'In-house-intervention-Cannot be claimed',
                            // 'Unclaimed',
                            'Claimed but Audit Failed',
                            'Claimed and Payment Pending',
                            'Claimed and Settled'].indexOf(editing ? editingClaim.status : claim.status) > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <label className="control-label is-imp">
                                        Claimant
                                    </label>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Select
                                            disabled={!editing}
                                            showSearch
                                            // className="form-control"
                                            style={{ width: 250 }}
                                            // placeholder="Select Status"
                                            onChange={(e) => { getBills(e); setEditingClaim({ ...editingClaim, claimantId: e }); }}
                                            value={editing ? editingClaim.claimantId || "" : claim.claimantId || ""}>
                                            <Option value="">Select Claimant</Option>
                                            {scks.map((x, index) => {
                                                return <Option key={index} value={x._id}>{x.ngoName}</Option>
                                            })}

                                        </Select>
                                    </Row>
                                    <Row>
                                        <small
                                            style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }}
                                            className="has-error">{errorsMessages['claimantId'] || ''}
                                        </small>
                                    </Row>
                                </Col>
                            </Row>}

                        {[
                            // 'In-house-intervention-Cannot be claimed',
                            // 'Unclaimed',
                            // 'Claimed but Audit Failed',
                            'Claimed and Payment Pending',
                            'Claimed and Settled'].indexOf(editing ? editingClaim.status : claim.status) > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <label className="control-label is-imp">
                                        Select the final claim bill for payment
                                    </label>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Select
                                            disabled={!editing}
                                            showSearch
                                            // className="form-control"
                                            style={{ width: 250 }}
                                            // placeholder="Select Status"
                                            onChange={(e) => { setEditingClaim({ ...editingClaim, billId: e }); }}
                                            value={editing ? editingClaim.billId === null ? "" : editingClaim.billId || "" : claim.billId === null ? "" : claim.billId || ""}>
                                            <Option value={""}>Select Claim Bill</Option>
                                            {bills.map((x, index) => {
                                                return <Option key={index} value={x._id}>{x.billLabel}</Option>
                                            })}

                                        </Select>
                                    </Row>
                                    <Row>
                                        <small
                                            style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }}
                                            className="has-error">{errorsMessages['billId'] || ''}
                                        </small>
                                    </Row>
                                </Col>
                            </Row>}


                        {/* Select the final claim bill for payment */}
                        {[
                            // 'In-house-intervention-Cannot be claimed',
                            // 'Unclaimed',
                            // 'Claimed but Audit Failed',
                            // 'Claimed and Payment Pending',
                            'Claimed and Settled'].indexOf(editing ? editingClaim.status : claim.status) > -1 &&
                            <Row>
                                <Col sm={6}>
                                    <label className="control-label is-imp">    Date of Payment
                                    </label>   </Col>
                                <Col sm={6}>
                                    <Row>
                                        <DatePicker
                                            disabled={!editing}
                                            placeholder="Select Final Bill Date"
                                            selected={editing ? (editingClaim._id ?
                                                new Date(editingClaim.dateOfPayment || TODAY) :
                                                editingClaim.dateOfPayment) :
                                                new Date(claim.dateOfPayment || TODAY)}
                                            // format={'DD/MM/YYYY'}
                                            dateFormat={'dd-MM-yyyy'}
                                            maxDate={TODAY}
                                            className="form-control"
                                            required
                                            onChange={(e) => { setEditingClaim({ ...editingClaim, 'dateOfPayment': new Date(e) }); }} />
                                    </Row>
                                    <Row>
                                        <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }}
                                            className="has-error">{errorsMessages['dateOfPayment'] || ''}
                                        </small>
                                    </Row>
                                </Col>
                            </Row>}
                        <Row>
                            <Col sm={6}>
                                <label className="control-label">
                                    Comments
                                </label>   </Col>
                            <Col sm={6}>
                                <Row>
                                    <textarea
                                        disabled={!editing}
                                        style={{
                                            width: '60%',
                                            borderRadius: 5,
                                            // borderColor: 'transparent',
                                            borderBottomColor: 'lightgrey',
                                            resize: 'none',
                                        }}
                                        value={editing ? editingClaim.comments : claim.comments}
                                        onChange={(e) => {
                                            setEditingClaim({
                                                ...editingClaim,
                                                'comments': e.target.value
                                            });
                                        }} />

                                </Row>
                                <Row>
                                    <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }}
                                        className="has-error">{errorsMessages['comments'] || ''}
                                    </small>
                                </Row>
                            </Col>
                        </Row>
                        {editing && <Row>
                            <Col md={6} style={{ textAlign: "right" }}>  <Button
                                variant={updating ? "secondary" : "primary"}
                                disabled={errorsList.length > 0 || updating}
                                onClick={() => {
                                    makeClaimCall()
                                }}
                            >
                                {updating ? (editingClaim._id ? 'Updating' : 'Adding')
                                    : (editingClaim._id ? 'Update' : 'Add')}

                            </Button></Col><Col md={6}>
                                <Button variant="secondary" onClick={() => { setEditing(false) }}>
                                    Cancel
                                </Button></Col>
                        </Row>}
                    </Container>
                </> :
                <div style={{ top: '50%', left: '40%', position: 'absolute', textAlign: 'center' }}>
                    <Row> <span style={{ textAlign: 'center' }}>Payment claim details not available yet!</span>
                    </Row >{canEdit ?
                        <Row style={{ textAlign: 'center' }}>
                            <Button style={{ margin: 'auto' }} variant="primary"
                                onClick={() => {
                                    setEditing(true);
                                    setEditingClaim(initialClaim);
                                }}>
                                Add Claim Details Now
                            </Button></Row> : null}
                </div>
        }
    </div>
};

export default PaymentClaim;
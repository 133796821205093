import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
    Switch,
	// ConfigProvider, DatePicker, Switch,
	Select
} from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import {
	Col, Row, Button, Modal, Dropdown,
	DropdownButton, } from 'react-bootstrap';
import React, { Component } from 'react';
// import enUS from 'antd/es/locale/en_US';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import AddCandidatesToProjectModal from '../AddCandidatesToProjectModal.js';

const readCookie = require('../cookie.js').readCookie;

const { SearchBar } = Search;
const { Option, OptGroup } = Select;
const knowlarity_emails = require('../../../emails_knowlarity.json');

export default class TCACR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			tcacr: [],
			totalTCACR: 0,
			sizePerPage: 50,
			filterData: {
				phase: '',
				status: [],
				hasIVRS: '',
				hasLead: '',
				trainingCenter: '',
				state: '',
				district: '',
			},
			errorObj: {},
			allStates: [],
			allDistricts: [],
			filterStates: [],
			filterDistricts: [],
			allUserTypes: [],
			allAssignedTo: [],
			showFilter: false,
			selectedCandidate: null,
			uploadCandidateObj: null,
			uploadCandidateStates: [],
			editModalVisibility: false,
			uploadCandidateDistricts: [],
			uploadCandidatesVisibility: false,
			sizePerPageList: [50, 100, 150, 200],
			allPOs: [],
			modalPos: [],
			selectedIds: [],
			selectMode: false,
			showAddIdsToProjectModal: false,
			allIds: [],
			projectMoveAction: '',
		}
	}
	hideAddIdsToProjectModal = (addedToProject = false) => {
		this.setState({ showAddIdsToProjectModal: false });
		if (addedToProject) {
			this.setState({
				selectedIds: [],
				selectMode: false,
			});
			this.submitFilter('page');
		}
	}
	getPos = () => {
		fetch(
			process.env.REACT_APP_API_URL +
			"/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full",
			{
				method: "GET",
				headers: {
					"x-auth-token": JSON.parse(readCookie("access_token")),
				},
			}
		)
			.then((data) => data.json())
			.then((data) => {
				if (data.status) {
					this.setState({ allPOs: data.users });
				}
			});
	}

	componentDidMount() {
		this.getPos();
		this.getTCACR();

		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-types', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allUserTypes: data.userTypes });
				}
			});

		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allStates: data.data.states, filterStates: data.data.states });
					if (JSON.parse(readCookie('userData')).userType.name === "Project Management Unit") {
						let states = [];
						for (let i = 0; i < data.data.states.length; i++) {
							if (data.data.states[i].inFocusRegion) states.push(data.data.states[i].name);
						}
						if (data.data.states.length === 1) this.handleStateChange(data.data.states[0].name);
						this.setState({ uploadCandidateStates: states });
					}
				}
			});

		if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') {
			let uploadCandidateStates = JSON.parse(readCookie('userData')).address.state,
				uploadCandidateDistricts = JSON.parse(readCookie('userData')).address.district;
			if (uploadCandidateStates && Array.isArray(uploadCandidateStates) && uploadCandidateDistricts && Array.isArray(uploadCandidateDistricts)) {
				uploadCandidateStates = uploadCandidateStates.sort();
				uploadCandidateDistricts = uploadCandidateDistricts.sort();
				this.setState({ uploadCandidateStates, uploadCandidateDistricts });
			}
		}
	}

	getTCACR = (query) => {
		if (!query) query = `?page=${this.state.page}&perPage=${this.state.sizePerPage}`;
		fetch(process.env.REACT_APP_API_URL + '/api/v1/tcacr' + query, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ tcacr: data.tcacr, totalTCACR: data.tcacrLength, allIds: (data.allTcacrIds||[]) }, () => {
					});
				}
			});
	}

	handleStateChange = (value) => {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?state=' + value, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allDistricts: data.districts })
					let districts = [];
					for (let i = 0; i < data.districts.length; i++) districts.push(data.districts[i].name);
					this.setState({ uploadCandidateDistricts: districts });
				}
			});
	}

	formatStatus = (cell, row) => {
		if (row.hasLead) return 'LEAD PROFILE CREATED';
		else if (row.hasIVRS) return 'IVRS ASSIGNED';
		else {
			if (cell === "CREATED") return 'Unassigned / Uncalled';
			else if (cell === "Unresponsive") return 'Unresponsive';
			else if (cell === "Wants to become Swavalambi") return 'New Business Aspirant';
			else if (cell === "Not interested in current business") return 'Not interested in business';
			else if (cell === "Business loan taken") return 'SCALE-UP Candidate- Has availed business loan';
			else if (cell === "No loan taken") return 'SCALE-UP Candidate- Never availed business loan';
			else return null;
		}
	}

	formatOptionalColumn = (cell, row) => {
		if (cell) return cell;
		else return 'N/A';
	}

	formatDate = (cell, row) => {
		if (cell) {
			return moment(cell).format('DD/MM/YYYY hh:mm A');
		} else if (row.addedAt) {
			return moment(row.addedAt).format('DD/MM/YYYY hh:mm A');
		} return null;
	}

	formatStateDistrict = (cell, row) => {
		if (cell && cell.name) return cell.name;
		else return null;
	}

	formatAssignedTo = (cell, row) => {
		if (cell && cell.hasOwnProperty('id')) {
			if (cell.name && row.inModel === 'Ngo') {
				return (
					<a href={"/org-types/swavalamban-connect-kendra/" + cell.id} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>{cell.name}</a>
				)
			} else if (cell.name && row.inModel === 'User') {
				return (
					<a href={"/user-types/swavalamban-fellows/" + cell.id} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>{cell.name}</a>
				)
			}
		} else return 'Not Assigned';
	}

	formatEdit = (cell, row) => {
		if (row._id && !row.hasLead && !row.hasIVRS) {
			return (
				<Button variant='primary' onClick={this.showEditModal.bind(this, row)}>Edit</Button>
			)
		} else return 'N/A';
	}

	showEditModal = (selectedCandidate) => {
		let leadData = {
			_id: selectedCandidate._id,
			mobile: selectedCandidate.mobile,
			status: selectedCandidate.status,
			pincode: selectedCandidate.pincode,
			state: selectedCandidate.state._id || '',
			inModel: selectedCandidate.inModel || 'Ngo',
			district: selectedCandidate.district._id || '',
			assignedTo: selectedCandidate.assignedTo.id || '',
			isArchived: selectedCandidate.isArchived || false,
			comments: selectedCandidate.comments || '',
		};
		let modalPos = this.state.allPOs;
		if (selectedCandidate.district) {
			// if (districtObj && districtObj.length) {
			modalPos = this.state.allPOs.filter(x => x.district.indexOf(selectedCandidate.district.name) > -1);
			// }
			// console.log(modalPos, 'modal')
			this.setState({ modalPos });
		}
		else if (selectedCandidate.state) {
			// let stateObj = this.state.allStates.filter(x => x.id === selectedCandidate.state);
			// if (stateObj && stateObj.length) {
			modalPos = this.state.allPOs.filter(x => x.state.indexOf(selectedCandidate.state.name) > -1);
			// }
			// console.log('STATE OBJ', stateObj);

			this.setState(modalPos);
		}
		else {
			this.setState(modalPos);
		}
		var promises = [], allNGOs = [], allDistricts = [];
		if (leadData.state) {
			var promise0 = fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + selectedCandidate.state._id, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						allDistricts = data.districts;
					}
				});
			promises.push(promise0);
		}
		if (leadData['inModel'] === 'Ngo') {
			var promise1 = fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						allNGOs = data.ngos;
					}
				});
			promises.push(promise1);
		}
		else if (leadData['inModel']) {
			var promise2 = fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/swavalamban-fellows', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						allNGOs = data.users;
					}
				});
			promises.push(promise2);
		}

		Promise.all(promises).then(() => {
			this.setState({ allNGOs, editModalVisibility: true, selectedCandidate: leadData, allDistricts, errorObj: {} });
		});
	}

	hideEditModal = () => {
		this.setState({ editModalVisibility: false, selectedCandidate: null });
	}

	changeHandler = (type, e, eventObj) => {
		let selectedCandidate = this.state.selectedCandidate, errorObj = this.state.errorObj;
		if (type === "assignedTo" && e) {
			selectedCandidate['assignedTo'] = e;
			selectedCandidate['inModel'] = eventObj.props.label;
		}
		else if (type === 'isArchived' || type === 'status' || type === 'assignedTo' || type === 'state' || type === 'district') {
			if (type === 'state' && e) {
				selectedCandidate['district'] = '';
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + e, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ allDistricts: data.districts });
						}
					});
			}
			selectedCandidate[type] = e;
		} else {
			if (type === 'pincode') {
				if (e.target.value.length <= 6) selectedCandidate[type] = e.target.value;
			} else selectedCandidate[type] = e.target.value;
		}
		if (type === "comments") {
			selectedCandidate['comments'] = e.target.value;
		}
		if (type === "status") {
			delete errorObj['assignedTo'];
			delete errorObj['inModel'];
		}
		if (errorObj.hasOwnProperty(type)) delete errorObj[type];
		this.setState({ selectedCandidate, errorObj });
	}

	callNumber(number) {
		if (number.length === 11) { number = number.slice(1, 11); }
		Swal.fire({
			'title': 'Are you sure?',
			'text': `Do you want to place a call to ${number}?`,
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText: 'Yes, Please do',
			confirmButtonAriaLabel: 'Yes, Please do',
			cancelButtonText: 'No, Cancel',
			cancelButtonAriaLabel: 'No, Cancel'
		}).then((res) => {
			if (res.value) {
				this.placeCall(number);
			}
		});
	}
	placeCall(number) {
		let knowlarity_makecall__url = 'https://kpi.knowlarity.com/Basic/v1/account/call/makecall';
		fetch(knowlarity_makecall__url, {
			method: 'POST',
			headers: {
				// 'x-api-key': 'qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY',
				// 'Authorization': 'f925251f-c0aa-4b89-bdf9-60f1b06ec111',
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				// "k_number": "+918047225386",
				// "customer_number": "+91" + number,
				// "agent_number": "+91" + this.props.userData.mobileNumber,
				// "caller_id": "+918047225386"
				to: "+91" + number,
				from: "+91" + this.props.userData.mobileNumber,
			})
		}).then(data => data.json())
			.then(data => {
				console.log(data);
				if (!data.error) {
					this.notifySuccess(data.message);
				} else {
					this.notifyError(`Couldn't make call!`);
				}
			});

	}

	updateCandidate = () => {
		let selectedCandidate = this.state.selectedCandidate, errorObj = this.state.errorObj;
		if (selectedCandidate) {
			let leadData = {
				state: selectedCandidate.state,
				status: selectedCandidate.status,
				pincode: selectedCandidate.pincode,
				inModel: selectedCandidate.inModel,
				district: selectedCandidate.district,
				assignedTo: selectedCandidate.assignedTo,
				comments: selectedCandidate.comments,
				// added for udyamitra assignment
				application: window.location.hostname.includes('udyamita') ? 'Udyamita' : undefined
			};

			if (["Wants to become Swavalambi", "No loan taken"].indexOf(leadData.status) > -1) {
				if (!leadData.assignedTo) errorObj['assignedTo'] = 'The candidate must be assigned to an SCK or a PO';
				if (!leadData.inModel) errorObj['inModel'] = 'Please select a correct user type';
				if (!leadData.state) errorObj['state'] = 'Please select a correct state';
				if (!leadData.district) errorObj['district'] = 'Please select a correct district';
				if (!leadData.pincode) errorObj['pincode'] = 'Please enter a correct pincode';
			}

			if (Object.keys(errorObj).length) {
				this.setState({ errorObj });
			} else {
				Swal.fire({
					title: 'Are you sure?',
					text: 'The Offline Aspirant will be updated.',
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, Update!',
					cancelButtonText: 'No, Cancel!'
				}).then((res) => {
					if (res.value) {
						fetch(process.env.REACT_APP_API_URL + '/api/v1/tcacr/' + selectedCandidate._id, {
							method: "PUT",
							headers: {
								'Content-Type': 'application/json',
								'x-auth-token': JSON.parse(readCookie('access_token'))
							},
							body: JSON.stringify(leadData)
						}).then((response) => {
							return response.json();
						}).then((data) => {
							if (data.status) {
								this.notifySuccess("Successfully updated Offline Aspirant!");
								this.submitFilter('page');
								this.setState({ editModalVisibility: false, selectedCandidate: null });
							} else {
								this.notifyError(data.errorMsg);
							}
						});
					}
				});
			}
		}
	}

	changeFilterHandler = (type, value) => {
		let filterData = this.state.filterData;
		if (type === 'state') {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?state=' + value, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						this.setState({ filterDistricts: data.districts });
						filterData['district'] = '';
					}
				});
		}
		filterData[type] = value;
		this.setState({ filterData });
	}

	showFilter = () => {
		this.setState({ showFilter: !this.state.showFilter }, () => {
			if (this.state.showFilter) {
				$('.filter-container').css('display', 'block');
			} else {
				$('.filter-container').css('display', 'none');
			}
		})
	}

	showUploadCandidates = () => {
		let uploadCandidateObj = {
			file: '',
			state: '',
			district: ''
		};
		if (this.state.uploadCandidateStates.length === 1) uploadCandidateObj['state'] = this.state.uploadCandidateStates[0];
		if (this.state.uploadCandidateDistricts.length === 1) uploadCandidateObj['district'] = this.state.uploadCandidateDistricts[0];
		this.setState({ uploadCandidatesVisibility: true, uploadCandidateObj });
	}

	hideUploadCandidates = () => {
		this.setState({ uploadCandidatesVisibility: false, uploadCandidateObj: null });
	}

	changeUploadHandler = (type, value) => {
		let uploadCandidateObj = this.state.uploadCandidateObj;
		if (type === 'file') value = value.target.files[0];
		else if (value.target) value = value.target.value;
		// else if(type === 'state' && value) this.handleStateChange(value);
		uploadCandidateObj[type] = value;
		this.setState({ uploadCandidateObj });
	}

	submitCandidates = () => {
		let uploadCandidateObj = this.state.uploadCandidateObj, error = {};

		if (uploadCandidateObj.state &&
			// uploadCandidateObj.district &&
			uploadCandidateObj.file) {
			let formData = new FormData();
			formData.append('file', uploadCandidateObj.file);
			formData.append('state', uploadCandidateObj.state);
			// formData.append('district', uploadCandidateObj.district);

			fetch(process.env.REACT_APP_API_URL + '/api/v1/tcacr/upload', {
				method: 'POST',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				},
				body: formData
			}).then(data => data.json())
				.then(data => {
					Swal.fire({
						title: data.title,
						html: data.html,
						type: data.type
					}).then((res) => {
						if (data.status) {
							this.getTCACR();
							this.setState({ uploadCandidatesVisibility: false, uploadCandidateObj: null });
						}
					});
				});
		} else {
			if (uploadCandidateObj.state) error['state'] = 'Please select a state';
			if (uploadCandidateObj.file) error['file'] = 'Please select a proper file';
			if (uploadCandidateObj.district) error['district'] = 'Please select a district';

			this.setState({ uploadCandidateObj });
		}
	}

	submitFilter = (type = '', page = this.state.page, sizePerPage = this.state.sizePerPage, searchText = this.state.searchText) => {
		let filterData = this.state.filterData, query = `?page=${page}&perPage=${sizePerPage}`;

		if (filterData.trainingCenter !== '') query += `&trainingCenter=${filterData.trainingCenter}`;
		if (filterData.hasLead !== '') query += `&hasLead=${filterData.hasLead}`;
		if (filterData.hasIVRS !== '') query += `&hasIVRS=${filterData.hasIVRS}`;
		// if (filterData.status !== '') query += `&status=${filterData.status}`;
		if (filterData.status.join(',')) query += `&status=${filterData.status.join(',')}`;
		if (filterData.phase !== '') query += `&phase=${filterData.phase}`;

		if (filterData.state !== '') query += `&state=${filterData.state}`;
		if (filterData.district !== '') query += `&district=${filterData.district}`;

		if (searchText) query += `&q=${searchText}`;

		if (type !== 'page' && type !== 'sizePerPage' && type !== 'searchText') this.showFilter();

		this.getTCACR(query);
	}

	resetFilter = () => {
		let filterData = { phase: '', status: [], hasIVRS: '', hasLead: '', trainingCenter: '', state: '', district: '' };
		let query = `?page=${this.state.page}&perPage=${this.state.sizePerPage}`;
		this.setState({ filterData }, () => this.getTCACR(query));
		this.showFilter();
	}

	onTableChange = (type, obj) => {
		let changeType = '';
		if (this.state.page !== obj.page) changeType = 'page';
		else if (this.state.sizePerPage !== obj.sizePerPage) changeType = 'sizePerPage';
		else if (this.state.searchText !== obj.searchText) changeType = 'searchText';

		this.setState({ page: obj.page, sizePerPage: obj.sizePerPage, searchText: obj.searchText });
		this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText);
	}

	searchFilter = (input, option) => {
		input = input.toLowerCase();
		if (!Array.isArray(option.props.children)) {
			if (typeof option.props.children === 'string') {
				return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
		}
	}
	handleSelectChange = (row) => {
			let selectedIds = this.state.selectedIds;
			if (!selectedIds.includes(row._id)) {
				selectedIds.push(row._id);
			} else {
				const removeIndex = selectedIds.map(item => item._id).indexOf(row._id);
				removeIndex >= 0 && selectedIds.splice(removeIndex, 1);
			}
			this.setState({ selectedIds });
	
	}
	handleSelectAllChange = (selected) => {
		if (selected) {
			this.setState({selectedIds:this.state.allIds})
		} else {
			this.setState({ selectedIds: []})
		}
	}

	mobileFormatter = (cell, row) => {
		if (cell) {
			return <div className="d-flex ">{(/*this.props.userData.userType.name==='Project Management Unit' ||*/
				// ['sibbupatel@gmail.com', "riteshroshan12@gmail.com", "divyanshu@letsendorse.com", "cheraddysujatha08@gmail.com", "mounikakelam2421@gmail.com", "mudirajmanmohan8@gmail.com"]
				knowlarity_emails.includes(this.props.userData.email) /*|| this.props.userData.userType.name === 'Zonal Manager'*/ || (this.props.userData.userType.name === 'Swavalamban Fellows'
					// && this.props.userData.fellowAccessType === 'Full'
				)) && <span style={{ cursor: "pointer", marginLeft: "10px", marginRight: "2px", }} className="call-icon" onClick={this.callNumber.bind(this, cell)}>
					<i style={{ fontSize: '20px', color: "#00dd00" }} title="Call this number" className="fa fa-phone fa-lg" /> &nbsp;
				</span>}<span>{cell}</span></div>;
		}
		else return null;
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);


	// selectFormatter = (cell, row, index) => {
		
	// 	return this.state.selectedIds.includes(cell) ? <Checkbox onChange={(e) => this.handleSelectChange(e, cell, row, index)} defaultChecked={this.state.selectedIds.includes(cell)} checked={this.state.selectedIds.includes(cell)} /> : 
	// 		<><Checkbox onChange={(e) => this.handleSelectChange(e, cell, row, index)} defaultChecked={this.state.selectedIds.includes(cell)} checked={this.state.selectedIds.includes(cell)} /> lll</>
	// }
	render() {
		const selectRow = {
			mode: 'checkbox',
			clickToSelect: JSON.parse(readCookie('userData')).userType.name === "Project Management Unit" && this.state.selectMode,
			selected: this.state.selectedIds,
			onSelect: this.handleSelectChange,
			onSelectAll: this.handleSelectAllChange,
			hideSelectColumn: !(JSON.parse(readCookie('userData')).userType.name === "Project Management Unit" && this.state.selectMode)
		};

		let tcacrColumns = [
			{ text: '', sort: false, hidden: true, export: true, dataField: '_id', searchable: false },
			// { sort: false, export: true, hidden: !this.state.selectMode, text: 'Select All', searchable: true, align: 'center', dataField: '_id', headerStyle: () => { return { width: '60px', textAlign: 'center' } }, formatter: this.selectFormatter },
			{ sort: false, export: true, hidden: false, text: 'Mobile', searchable: true, align: 'center', dataField: 'mobile', headerStyle: () => { return { width: '120px', textAlign: 'center' } }, formatter: this.mobileFormatter },
			{ sort: false, export: true, hidden: false, text: 'Full Name', searchable: true, align: 'center', dataField: 'fullName', formatter: this.formatOptionalColumn, headerStyle: () => { return { width: '100px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'State', searchable: true, align: 'center', dataField: 'state', formatter: this.formatStateDistrict, headerStyle: () => { return { width: '150px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'District', searchable: true, align: 'center', dataField: 'district', formatter: this.formatStateDistrict, headerStyle: () => { return { width: '150px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'Status', searchable: true, align: 'center', dataField: 'status', formatter: this.formatStatus, headerStyle: () => { return { width: '130px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'Phase', searchable: true, align: 'center', dataField: 'phase', formatter: this.formatOptionalColumn, headerStyle: () => { return { width: '80px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'Source', searchable: true, align: 'center', dataField: 'trainingCenter', headerStyle: () => { return { width: '120px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'Skills', searchable: true, align: 'center', dataField: 'skills', headerStyle: () => { return { width: '180px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, text: 'Pincode', searchable: true, align: 'center', dataField: 'pincode', formatter: this.formatOptionalColumn, headerStyle: () => { return { width: '100px', textAlign: 'center' } } },
			{ sort: false, export: false, hidden: false, searchable: false, text: 'Edit', dataField: 'edit', align: "center", headerStyle: () => { return { width: '80px', textAlign: 'center' } }, formatter: this.formatEdit },
			{ sort: false, export: true, hidden: false, searchable: true, align: 'center', text: 'Updated At', dataField: 'updatedAt', formatter: this.formatDate, headerStyle: () => { return { width: '150px', textAlign: 'center' } } },
			{ sort: false, export: true, hidden: false, searchable: true, align: 'center', text: 'Assigned To', dataField: 'assignedTo', formatter: this.formatAssignedTo, headerStyle: () => { return { width: '180px', textAlign: 'center' } } }
		];


		return (
			<div className="tcacr-page page-container">
				<h3>Trained Candidates Automated Call Records</h3>
				{
					JSON.parse(readCookie("userData")).userType.name === 'Project Management Unit' &&
						this.state.selectMode && this.state.selectedIds.length !== 0 ?
						<AddCandidatesToProjectModal
							showModal={this.state.showAddIdsToProjectModal && this.state.projectMoveAction}
							leadIds={this.state.selectedIds}
							leadsType={'tcacrs'}
							hideModal={this.hideAddIdsToProjectModal}
							action={this.state.projectMoveAction}
						/>
						: null
				}
				<Row>
					<Col md={3} />
					<Col md={9}>
					{false && JSON.parse(readCookie("userData")).userType.name === 'Project Management Unit' && <div align='end' className='row' style={{ justifyContent: 'flex-end', paddingRight: 150 }}>
						{this.state.selectMode && <h5 style={{ color: '#ef5a20', fontWeight: 'bold', marginRight: 10, paddingTop: 5 }}>{this.state.selectedIds.length} Selected</h5>}
						{this.state.selectMode > 0 &&
							<DropdownButton style={{ marginRight: 30 }} disabled={this.state.selectedIds.length === 0} id="dropdown-basic-button" title="&emsp;Take Action&emsp;" onChange={() => console.log('SELECTED LEADS')}>
								<Dropdown.Item onClick={() => {
                                  this.setState({ showAddIdsToProjectModal: true, projectMoveAction: 'add' });
                                }
                                }>
                                  Add to another project
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  this.setState({ showAddIdsToProjectModal: true, projectMoveAction: 'move' });
                                }
                                }>
                                  Move to another project
                                </Dropdown.Item>
								{/* <Dropdown.Item onClick={() => {
                                      localStorage.setItem('selectedLeads', JSON.stringify(this.state.selectedIds));
                                      window.open(process.env.REACT_APP_CAMPAIGN_URL+"?token=" + JSON.parse(readCookie("access_token")), "_blank")
                                    }
                                  }>
                                  Trigger Campaign
                                </Dropdown.Item> */}
							</DropdownButton>}
						<h5 style={{ paddingTop: 2 }}>Selection Mode</h5>
						<Switch checked={this.state.selectMode} style={{ transform: 'scale(1.3)', width: 80, marginLeft: 30 }} checkedChildren="On" unCheckedChildren="Off" onChange={(e) => this.setState({ selectedIds: [], selectMode: e })} />
						</div>}
					</Col>
				</Row>
				<Col md={12} className="tcacr-table">
					{this.state.tcacr ? (
						<PaginationProvider pagination={paginationFactory({ custom: true, page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalTCACR, sizePerPageList: this.state.sizePerPageList })} keyField="_id" data={this.state.tcacr} columns={tcacrColumns}>
							{({ paginationProps, paginationTableProps }) => (
								<ToolkitProvider keyField="_id" data={this.state.tcacr} columns={tcacrColumns} search={{ searchFormatted: true }}>
									{props => (
										<div>
											<Row className="mt10">
												<Col className="d-flex" style={{ alignItems: 'center' }}>
													<span className="total-tcacr m0">
														{'Total Offline Aspirants: ' + this.state.totalTCACR}
													</span>
													{this.state.uploadCandidateStates.length ? (
														<span className="btn upload-tcacr-btn m0 ml5" onClick={this.showUploadCandidates}>
															<i className="fas fa-plus mr5"></i>
															Upload Candidates
														</span>
													) : (null)}
												</Col>
												<Col className="tcacr-table-search-container">
													<SearchBar {...props.searchProps} placeholder="Search by Mobile/District" className="tcacr-table-search" delay={2000} />
												</Col>
												<div className="filter-class d-flex align-items-center justify-content-center" onClick={this.showFilter}>Filter<i className="fas fa-filter"></i></div>
											</Row>
											<div className="filter-container">
												<div className="close-filter" onClick={this.showFilter}>X</div>
												<div className="status-filter">
													<label className="control-label">Status</label>
													<Select style={{ width: '100%' }} mode='multiple' placeholder="Select Status" onChange={this.changeFilterHandler.bind(this, 'status')} value={this.state.filterData.status}>
														{/* <Option value="">All</Option> */}
														<Option value="CREATED" title="Unassigned / Uncalled">Unassigned / Uncalled</Option>
														<Option value="Unresponsive" title="Unresponsive">Unresponsive</Option>
														{/* <Option value="IVRS ASSIGNED" title="IVRS Assigned">IVRS Assigned</Option>
														<Option value="LEAD PROFILE CREATED" title="Lead Profile Created">Lead Profile Created</Option> */}
														<Option value="Wants to become Swavalambi" title="New Business Aspirant">New Business Aspirant</Option>
														<Option value="Not interested in current business" title="Not interested in business">Not interested in business</Option>
														<Option value="Business loan taken" title="SCALE-UP Candidate- Has availed business loan">SCALE-UP Candidate- Has availed business loan</Option>
														<Option value="No loan taken" title="SCALE-UP Candidate- Never availed business loan">SCALE-UP Candidate- Never availed business loan</Option>
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">State</label>
													<Select style={{ width: '100%' }} onChange={this.changeFilterHandler.bind(this, 'state')}
														value={this.state.filterData.state}>
														<Option value="">All</Option>
														<OptGroup label="Focus States">
															{this.state.filterStates.map((state, stateIndex) => {
																if (state.inFocusRegion) {
																	return (
																		<Option key={stateIndex} value={state.name}>{state.name}</Option>
																	)
																} else return null;
															})}
														</OptGroup>
														<OptGroup label="Not Focussed States">
															{this.state.filterStates.map((state, stateIndex) => {
																if (!state.inFocusRegion) {
																	return (
																		<Option key={stateIndex} value={state.name}>{state.name}</Option>
																	)
																} else return null;
															})}
														</OptGroup>
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">District</label>
													<Select style={{ width: '100%' }} onChange={this.changeFilterHandler.bind(this, 'district')}
														value={this.state.filterData.district}>
														<Option value="">All</Option>
														{this.state.filterDistricts.map((district, districtIndex) => {
															return (
																<Option key={districtIndex} value={district.name}>{district.name}</Option>
															)
														})}
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">Converted to IVRS</label>
													<Select style={{ width: '100%' }} placeholder="Converted to IVRS" onChange={this.changeFilterHandler.bind(this, 'hasIVRS')} value={this.state.filterData.hasIVRS}>
														<Option value="">All</Option>
														<Option value="true">Yes</Option>
														<Option value="false">No</Option>
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">Converted to Lead</label>
													<Select style={{ width: '100%' }} placeholder="Converted to Lead" onChange={this.changeFilterHandler.bind(this, 'hasLead')} value={this.state.filterData.hasLead}>
														<Option value="">All</Option>
														<Option value="true">Yes</Option>
														<Option value="false">No</Option>
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">Phase of Business</label>
													<Select style={{ width: '100%' }} placeholder="Phase of Business" onChange={this.changeFilterHandler.bind(this, 'phase')} value={this.state.filterData.phase}>
														<Option value="">All</Option>
														<Option value="Start-up">Start-up</Option>
														<Option value="Scale-up">Scale-up</Option>
													</Select>
												</div>
												<div className="status-filter">
													<label className="control-label">Source</label>
													<Select style={{ width: '100%' }} placeholder="Source" onChange={this.changeFilterHandler.bind(this, 'trainingCenter')} value={this.state.filterData.trainingCenter}>
														<Option value="">All</Option>
														<Option value="JSS" title="JSS">JSS</Option>
														<Option value="PMKK" title="PMKK">PMKK</Option>
														<Option value="RSETI" title="RSETI">RSETI</Option>
														<Option value="DDU-GKY" title="DDU-GKY">DDU-GKY</Option>
														<Option value="DIC" title="DIC">DIC</Option>
														{/* KVIC
																	KVIB
																	Coir Board
																	Municipality
																	SLBC */}
														<Option value="KVIB" title="KVIB">KVIB</Option>
														<Option value="KVIC" title="KVIC">KVIC</Option>
														<Option value="Coir Board" title="Coir Board">Coir Board</Option>
														<Option value="Municipality" title="Municipality">Municipality</Option>
														<Option value="SLBC" title="SLBC">SLBC</Option>
														<Option value="SBI PM SVANidhi" title="SBI PM SVANidhi">SBI PM SVANidhi</Option>
														<Option value="NULM-Sanctioned" title="NULM-Sanctioned">NULM-Sanctioned</Option>
														<Option value="NULM- Yet to be sanctioned" title="NULM- Yet to be sanctioned">NULM- Yet to be sanctioned</Option>
														{/* <Option value="PMEGP- yet to be sanctioned" title="PMEGP- yet to be sanctioned">PMEGP- yet to be sanctioned</Option>	 */}
														<Option value="Social Media" title="Social Media">Social Media</Option>
														<Option value="Bihar CM Udyami-Yojana" title="Bihar CM Udyami-Yojana">Bihar CM Udyami-Yojana</Option>
														<Option value="PMEGP- Pending" title="PMEGP- Pending">PMEGP- Pending</Option>
														<Option value="PMEGP- Sanctioned" title="PMEGP- Sanctioned">PMEGP- Sanctioned</Option>
														<Option value="PMEGP- Rejected" title="PMEGP- Rejected">PMEGP- Rejected</Option>
														<Option value="Government Departments" title="Government Departments">Government Departments</Option>
														<Option value="Customer Service Point (CSP)" title="Customer Service Point (CSP)">Customer Service Point (CSP)</Option>
														<Option value="Volunteer" title="Volunteer">Volunteer</Option>
														<Option value="Others" title="Others">Others</Option>
													</Select>
												</div>

												<div className="d-flex filter-btn-container mt10">
													<div className="reset-btn mr10" onClick={this.resetFilter}>Reset</div>
													<div className="submit-btn" onClick={this.submitFilter}>Submit</div>
												</div>
											</div>
											<BootstrapTable	{...props.baseProps} ref={(table) => { this.table = table; }} remote={{ pagination: true }} onTableChange={this.onTableChange} {...paginationTableProps} selectRow={selectRow} />
											<PaginationListStandalone {...paginationProps} />
											<SizePerPageDropdownStandalone {...paginationProps} />
										</div>
									)}
								</ToolkitProvider>
							)}
						</PaginationProvider>
					) : (null)}
				</Col>

				{this.state.editModalVisibility && this.state.selectedCandidate ? (
					<Modal className="reassign-lead-modal" show={this.state.editModalVisibility} size="lg">
						<Modal.Header>
							<Modal.Title className="modal-header-custom">
								Edit Offline Aspirant
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mb20">
							<Row>
								<Col md={6}>
									<label className="control-label is-imp">Mobile</label>
									<input className="form-control" type="text" style={{ width: '100%' }} placeholder="Mobile" value={this.state.selectedCandidate.mobile} disabled={true} />
									{this.state.errorObj.hasOwnProperty('mobile') ? (
										<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.mobile}</div>
									) : (null)}
								</Col>

								<Col md={6}>
									<label className="control-label">Pincode</label>
									<input className="form-control" type="text" style={{ width: '100%' }} placeholder="Pincode" value={this.state.selectedCandidate.pincode} onChange={this.changeHandler.bind(this, 'pincode')} />
									{this.state.errorObj.hasOwnProperty('pincode') ? (
										<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.pincode}</div>
									) : (null)}
								</Col>

								<Col md={6}>
									<label className="control-label is-imp">Status</label>
									<Select size="large" style={{ width: '100%' }} placeholder="Select Status" onChange={this.changeHandler.bind(this, 'status')} value={this.state.selectedCandidate.status}>
										<Option value="CREATED">Unassigned / Uncalled</Option>
										<Option value="Unresponsive">Unresponsive</Option>
										<Option value="Wants to become Swavalambi">New Business Aspirant</Option>
										<Option value="Not interested in current business">Not interested in business</Option>
										<Option value="Business loan taken">SCALE-UP Candidate- Has availed business loan</Option>
										<Option value="No loan taken">SCALE-UP Candidate- Never availed business loan</Option>
									</Select>
									{this.state.errorObj.hasOwnProperty('status') ? (
										<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.status}</div>
									) : (null)}
								</Col>

								{["Wants to become Swavalambi", "No loan taken"].indexOf(this.state.selectedCandidate.status) > -1 && (this.state.allNGOs.length || this.state.modalPos.length) ? (
									<Col md={6}>
										<label className="control-label is-imp">Assign To</label>
										<Select size="large" showSearch style={{ width: '100%' }} placeholder="Assign To" onChange={this.changeHandler.bind(this, 'assignedTo')} value={this.state.selectedCandidate.assignedTo} filterOption={(input, option) => this.searchFilter(input, option)}>
											<Option value="">Select an assignee</Option>
											<OptGroup label="Swavalamban Fellows">
												{this.state.modalPos.map((po, poIndex) => {
													return (
														<Option label={'User'} key={poIndex} value={po._id}>{po.fullName}</Option>
													)
												})}
											</OptGroup>
											<OptGroup label="Swavalamban Connect Kendras">
												{this.state.allNGOs.map((ngo, ngoIndex) => {
													// if (this.state.ngo.inModel === 'Ngo') {
													if (ngo.ngoName) {
														return (
															<Option key={ngoIndex} label={'Ngo'} value={ngo._id}>{ngo.ngoName}</Option>
														)
													} else {
														return (
															<Option key={ngoIndex} label={'Ngo'} value={ngo._id}>{ngo.fullName + ' (' + ngo.email + ')'}</Option>
														)
													}
												})}
											</OptGroup>
										</Select>
										{this.state.errorObj.hasOwnProperty('assignedTo') ? (
											<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.assignedTo}</div>
										) : (null)}
									</Col>
								) : (null)}

								<Col md={6}>
									<label className="control-label is-imp">State</label>
									<Select size="large" showSearch style={{ width: '100%' }} placeholder="Select State" onChange={this.changeHandler.bind(this, 'state')} value={this.state.selectedCandidate.state} filterOption={(input, option) => this.searchFilter(input, option)}>
										<OptGroup label="Focus States">
											{this.state.allStates.map((state, stateIndex) => {
												if (state.inFocusRegion) {
													return (
														<Option key={stateIndex} value={state._id}>{state.name}</Option>
													)
												} else return null;
											})}
										</OptGroup>
										<OptGroup label="Not Focussed States">
											{this.state.allStates.map((state, stateIndex) => {
												if (!state.inFocusRegion) {
													return (
														<Option key={stateIndex} value={state._id}>{state.name}</Option>
													)
												} else return null;
											})}
										</OptGroup>
									</Select>
									{this.state.errorObj.hasOwnProperty('state') ? (
										<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.state}</div>
									) : (null)}
								</Col>

								{this.state.allDistricts.length ? (
									<Col md={6}>
										<label className="control-label is-imp">District</label>
										<Select size="large" showSearch style={{ width: '100%' }} placeholder="Select District" onChange={this.changeHandler.bind(this, 'district')} value={this.state.selectedCandidate.district} filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.allDistricts.map((district, districtIndex) => {
												return (
													<Option key={districtIndex} value={district._id}>{district.name}</Option>
												)
											})}
										</Select>
										{this.state.errorObj.hasOwnProperty('district') ? (
											<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.district}</div>
										) : (null)}
									</Col>
								) : (null)}
							</Row>
							<Row>
								<Col md={12}>
									<label className="control-label is-imp">Comments</label>
									<textarea style={{ width: "100%", padding: 10, color: '#495057' }} placeholder="Comments" onChange={this.changeHandler.bind(this, 'comments')} value={this.state.selectedCandidate.comments} />
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer className="footer-center">
							<Button className="btn btn-danger brand-color-background" onClick={this.hideEditModal}>Cancel</Button>
							<Button className="btn btn-warning brand-color-background" onClick={this.updateCandidate}>Save</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}

				{this.state.uploadCandidatesVisibility && this.state.uploadCandidateObj ? (
					<Modal className="upload-candidates-modal" show={this.state.uploadCandidatesVisibility} size="lg">
						<Modal.Header>
							<Modal.Title className="modal-header-custom">
								Upload Trained Candidates Sheet
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mb20">
							<Row>
								<Col md={9}>
									<label className="control-label is-imp">Select State</label>
									<Select size="large" style={{ width: '100%' }} placeholder="Select State" onChange={this.changeUploadHandler.bind(this, 'state')} value={this.state.uploadCandidateObj.state}>
										{this.state.uploadCandidateStates.map((state, index) => {
											return (
												<Option key={index} value={state}>{state}</Option>
											)
										})}
									</Select>
								</Col>

								{/* <Col md={6}>
									<label className="control-label is-imp">Select District</label>
									<Select size="large" style={{ width: '100%' }} placeholder="Select District" onChange={this.changeUploadHandler.bind(this, 'district')} value={this.state.uploadCandidateObj.district}>
										{this.state.uploadCandidateDistricts.map((district, index) => {
											return (
												<Option key={index} value={district}>{district}</Option>
											)
										})}
									</Select>
								</Col> */}

								<Col md={12}>
									<label className="control-label is-imp">Upload Candidates File (Only csv, xlsx accepted)</label>
									<input className="form-control upload-file-input" type="file" style={{ width: '100%' }} placeholder="Upload File" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls" onChange={this.changeUploadHandler.bind(this, 'file')} />
									{/* <note className="note">Note: Kindly upload the csv with the following header rows- Name, Mobile*, Source*, Skills*, Pincode (Mandatory rows are marked with *)</note> */}
									<note className="note">Note: Kindly upload the csv file with the following header rows- Name, Mobile*, District*, Source*, Skills*, Pincode (Mandatory rows are marked with *). Please note that the District name is case sensitive and should be exactly same as what is recognized by the platform. To know the possible values for Source, please look at the Source dropdown in the filters here.</note>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer className="footer-center">
							<Button className="btn btn-danger brand-color-background" onClick={this.hideUploadCandidates}>Cancel</Button>
							<Button className="btn btn-warning brand-color-background" onClick={this.submitCandidates}>Save</Button>
						</Modal.Footer>
					</Modal>
				) : (null)}
			</div>
		);
	}
}

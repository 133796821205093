/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    // Table,
    Col, Row,
    // ToggleButton
} from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider
    // , { Search }
    from "react-bootstrap-table2-toolkit";
import { Switch } from 'antd';

import moment from 'moment';

const readCookie = require('../../cookie').readCookie;
// const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`;

function CandidatesTab(props) {
    const starredStyle = (cell, row) => {
        if (cell) return { backgroundColor: "#FFF2C9" };
    };
    const addressFormatter = (cell, row) => {
        if (cell) return cell;
        else return null;
    };
    const nameFormatter = (cell, row) => {
        if (cell) {
            if (row.id) {
                return (
                    <a
                        href={"/lead/" + row.id}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={row.enterpriseSetup ? { color: "#fff" } : null}
                    >
                        {cell}{" "}
                        {row.audit !== undefined && (JSON.parse(readCookie("userData")).type !== "sidbi"
                        ) ? row.audit.status === "Audited" ? (
                            <img src="/images/check.png" width="12" alt="" />
                        ) : row.audit !== undefined && row.audit.status === "Audit Failed" ? (
                            <img src="/images/cancel.png" width="12" alt="" />
                        ) : null : null }
                    </a>
                );
            } else return cell;
        } else return null;
    };
    // const starFormatter = (cell, row, index) => {
    //     if (cell) {
    //         if (row.enterpriseSetup)
    //             return (
    //                 <i
    //                     className="fa fa-star"
    //                     style={{ fontSize: 20, color: "#fff" }}
    //                     onClick={toggleStar.bind(this, row.id, !cell, index)}
    //                 ></i>
    //             );
    //         else
    //             return (
    //                 <i
    //                     className="fa fa-star"
    //                     style={{ fontSize: 20, color: "var(--themeColor)" }}
    //                     onClick={toggleStar.bind(this, row.id, !cell, index)}
    //                 ></i>
    //             );
    //     } else {
    //         return (
    //             <i
    //                 className="fa fa-star-o"
    //                 style={{ fontSize: 20 }}
    //                 onClick={toggleStar.bind(this, row.id, !cell, index)}
    //             ></i>
    //         );
    //     }
    // };

    const assignToFormatter = (cell, row) => {
        if (cell && cell.hasOwnProperty("_id")) {
            if (cell && cell.fullName) {
                if (
                    row.userType &&
                    (JSON.parse(readCookie("userData")).type === "le" ||
                        JSON.parse(readCookie("userData")).type === "sidbi")
                ) {
                    return (
                        <a
                            href={"/user-types/" + row.userType + "/" + cell._id}
                            style={row.enterpriseSetup ? { color: "#fff" } : null}
                        >
                            {cell.fullName}
                        </a>
                    );
                } else return cell.fullName;
            } else if (cell && cell.ngoName) {
                return (
                    <a
                        href={
                            JSON.parse(readCookie("userData")).type === "partners"
                                ? "/settings"
                                : "/org-types/swavalamban-connect-kendra/" + cell._id
                        }
                        style={row.enterpriseSetup ? { color: "#fff" } : null}
                    >
                        {cell.ngoName}
                    </a>
                );
            } else return null;
        } else return null;
    };

    const yesnoFormatter = (cell, row) => {
        if (cell) return "Yes";
        else if (cell === false) return "No";
        else return null;
    };

    const formatTime = (cell, row) => {
        if (cell) {
            return moment(cell).format("DD/MM/YYYY hh:mm A");
        } else return <span>-</span>;
    };

    const formatSaarthis = (cell, row) => {
        if (cell) {
            return (
                <span>
                    <a
                        className="saarthi-name"
                        href={"/user-types/saarthis/" + cell._id}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {cell.fullName}
                    </a>
                </span>
            );
        } else return "N/A";
    };

    // const formatEdit = (cell, row) => {
    //     if (row.id && JSON.parse(readCookie("userData")).type !== "sidbi") {
    //         return (
    //             <button
    //                 className="btn edit-lead-btn"
    //                 onClick={() => (window.location.pathname = "/edit-lead/" + row.id)}
    //             >
    //                 Edit
    //             </button>
    //         );
    //     } else return null;
    // };

    const formatRating = (cell, row) => {
        if (cell) {
            return (
                <span className="note" style={{ fontSize: 18 }}>
                    {cell}
                </span>
            );
        } else return null;
    };

    // const toggleStar = (leadId, starred, index) => {
    //     let title = "Are you sure you want to prioritize this lead?";
    //     if (!starred) title = "Are you sure you want to deprioritize this lead?";
    //     Swal.fire({
    //         title,
    //         focusConfirm: false,
    //         showCancelButton: true,
    //         cancelButtonText: "No, Cancel",
    //         confirmButtonText: "Yes, Update",
    //         cancelButtonAriaLabel: "No, Cancel",
    //         confirmButtonAriaLabel: "Yes, Update",
    //     }).then((result) => {
    //         if (result.value && leadId) {
    //             fetch(process.env.REACT_APP_API_URL + "/api/v1/swati-leads/" + leadId, {
    //                 method: "PATCH",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "x-auth-token": JSON.parse(readCookie("access_token")),
    //                 },
    //                 body: JSON.stringify({ leadInfo: { starred } }),
    //             })
    //                 .then((data) => data.json())
    //                 .then((data) => {
    //                     if (data.status) {
    //                         let allLeads = this.state.allLeads;
    //                         allLeads[index]["starred"] = starred;
    //                         this.setState({ allLeads });
    //                     } else {
    //                         this.notifyError("Could not update");
    //                     }
    //                 });
    //         }
    //     });
    // };

    let columns = [
        {
            text: "",
            sort: false,
            hidden: true,
            export: true,
            searchable: false,
            dataField: "id",
        },
        {
            text: "Starred",
            sort: true,
            // hidden: false,
            hidden: true,

            export: false,
            searchable: false,
            align: "center",
            style: starredStyle,
            headerStyle: () => {
                return { width: "60px", textAlign: "center" };
            },
            dataField: "starred",
            // formatter: starFormatter,
        },
        {
            text: "Name",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "200px" };
            },
            dataField: "name",
            formatter: nameFormatter,
        },
        {
            text: "Mobile No.",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "110px" };
            },
            dataField: "mobileNumber",
        },

        {
            text: "State",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "state",
            formatter: addressFormatter,
        },
        {
            text: "District",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "district",
            formatter: addressFormatter,
        },
        {
            text: "Block",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "block",
            formatter: addressFormatter,
        },
        {
            text: "City",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "city",
            formatter: addressFormatter,
        },
        {
            text: "Pincode",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "70px" };
            },
            dataField: "pincode",
        },
        {
            text: "Assigned To",
            sort: false,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px" };
            },
            dataField: "assignTo",
            formatter: assignToFormatter,
        },
        {
            text: "Added By",
            sort: false,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px" };
            },
            dataField: "addedBy",
            formatter: assignToFormatter,
        },
        {
            text: "Phase",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "110px" };
            },
            dataField: "stage",
        },
        {
            text: "Generated Through",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "generatedThrough",
        },
        {
            text: "Self Finance Amount (INR)",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "canSelfFinanceTheCapitalRequiredUpToINR",
        },
        {
            text: "Loan Amount (INR)",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "130px" };
            },
            dataField: "loanAmountRequired",
        },
        {
            text: "Status",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px" };
            },
            dataField: "status",
        },
        {
            text: "Finance Type",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "160px" };
            },
            dataField: "financeType",
        },
        {
            text: "Has Business Idea",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "140px" };
            },
            dataField: "hasBusinessIdea",
        },
        {
            text: "Has Business Plan",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "140px" };
            },
            dataField: "hasABusinessPlanAlready",
            formatter: yesnoFormatter,
        },
        {
            text: "Overall Sentiment",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "170px" };
            },
            dataField: "overallSentiment",
        },
        {
            text: "Created On",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "createdAt",
            formatter: formatTime,
        },
        {
            text: "Last Updated At",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "updatedAt",
            formatter: formatTime,
        },
        {
            text: "Due-diligence done at",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "ddCompletedAt",
            formatter: formatTime,
        }, {
            text: "Bplan done At",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "bplanCompletedAt",
            formatter: formatTime,
        }, {
            text: "Loan Application Done At",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "loanApplicationCompletedAt",
            formatter: formatTime,
        }, {
            text: "Loan Application Status",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "loanApplicationStatus",
        }, {
            text: "Started/Scaled Up on(in)",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "enterpriseSetUpAt",
            // formatter: this.formatTime,
        },
        {
            text: "Marked Start/Scale Up on",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px", textAlign: "center" };
            },
            dataField: "enterpriseUpdatedOn",
            formatter: formatTime,
        },
    ];

    if (JSON.parse(readCookie("userData")).type === "le") {
        columns.splice(7, 0, {
            text: "Profile Completed (%)",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "90px" };
            },
            dataField: "profileCompletion",
        });
        columns.splice(8, 0, {
            text: "Strength Rating",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "70px", textAlign: "center" };
            },
            align: "center",
            dataField: "strengthRating",
            formatter: formatRating,
        });
        if (JSON.parse(readCookie("userData")).accountType !== "saarthi") {
            columns.splice(15, 0, {
                text: "SAARTHIs",
                sort: true,
                hidden: false,
                export: true,
                searchable: true,
                headerStyle: () => {
                    return { width: "160px" };
                },
                dataField: "saarthis",
                formatter: formatSaarthis,
            });
        }
        // columns.splice(9, 0, { text: 'Edit', sort: false, hidden: false, export: false, searchable: false, align: 'center', headerStyle: () => {return {width: '75px', textAlign: 'center'}}, dataField: 'edit', formatter: this.formatEdit });
    } else {
        columns.splice(14, 0, {
            text: "Profile Completed (%)",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "150px" };
            },
            dataField: "profileCompletion",
        });
        columns.splice(19, 0, {
            text: "Strength Rating",
            sort: true,
            hidden: false,
            export: true,
            searchable: true,
            headerStyle: () => {
                return { width: "140px", textAlign: "center" };
            },
            align: "center",
            dataField: "strengthRating",
            formatter: formatRating,
        });
        // if(JSON.parse(readCookie('userData')).type !== 'sidbi') {
        // 	columns.splice(7, 0, { text: 'Edit', sort: false, hidden: false, export: false, searchable: false, align: 'center', headerStyle: () => {return {width: '75px', textAlign: 'center'}}, dataField: 'edit', formatter: this.formatEdit });
        // }
    }
    const { bankDetails } = props;
    const [candidates, setCandidates] = useState([]);
    const [counts, setCounts] = useState({
        applied: 0,
        accountHolders: 0,
        sanctioned: 0,
        disbursed: 0,
        rejected: 0,
    });
    const [candidatesFilter, setCandidatesFilter] = useState({
        accounts: false,
        applied: false,
        sanctioned: false,
        disbursed: false,
        rejected: false,
    });
    const [paginationParticulars, setPaginationParticulars] = useState({
        page: 1,
        totalLeads: 0,
        searchText: "",
        // leadCounts: {},
        // allUserTypes: [],
        sizePerPage: 100,
        // allAssignedTo: [],
        // showFilter: false,
        sizePerPageList: [50, 100, 150, 200],
    });
    const getCandidateDetails = (ifsc = bankDetails.ifsc) => {

        let query = "?";
        if (candidatesFilter.accounts) query += 'includeAccounts=Yes&';
        if (candidatesFilter.applied) query += 'includeApplied=Yes&';
        if (candidatesFilter.sanctioned) query += 'includeSanctioned=Yes&';
        if (candidatesFilter.disbursed) query += 'includeDisbursed=Yes&';
        if (candidatesFilter.rejected) query += 'includeRejected=Yes';


        fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-branches/candidates/" + ifsc + query, {
            method: "GET",
            headers: {
                "x-auth-token": JSON.parse(readCookie("access_token")),
            },
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status && data.simpleLeadInfo) {
                    // this.setState({ bankDetails: data.branch });
                    setCandidates(data.simpleLeadInfo);
                    setPaginationParticulars({ ...paginationParticulars, totalLeads: data.totalLeads });
                    setCounts(data.counts);
                }
                // else {
                //     window.location = '/bank-branches';
                // }
            });
    };
    const rowClassNameFormat = (row, index) => {
        // coloring for follow-ups
        // if (this.state.followUpDues) {
        //     if (
        //         this.state.followUpDues.dueToday &&
        //         this.state.followUpDues.dueToday.indexOf(row.id) > -1
        //     )
        //         return { backgroundColor: "#faec2f" };
        //     if (
        //         this.state.followUpDues.overDue &&
        //         this.state.followUpDues.overDue.indexOf(row.id) > -1
        //     )
        //         return { backgroundColor: "#a60000" };
        //     if (
        //         this.state.followUpDues.dueThisWeek &&
        //         this.state.followUpDues.dueThisWeek.indexOf(row.id) > -1
        //     )
        //         return { backgroundColor: "#499aff" };
        // }
        if (row.enterpriseSetup) {
            return { backgroundColor: "#78D245" };
        } else if (row.saarthis && row.saarthis.status === "Active") {
            if (row.saarthis.saarthiAccess === "dueDiligence")
                return { backgroundColor: "#FFE8FE" };
            else return { backgroundColor: "#D4FFFF" };
        }
    };
    const onTableChange = (type, obj) => {
        // if (type === "sort" && obj.sortOrder && obj.sortField) {
        //     this.submitFilter(
        //         type,
        //         obj.page,
        //         obj.sizePerPage,
        //         obj.searchText,
        //         obj.sortField,
        //         obj.sortOrder
        //     );
        // } else {
        // let changeType = "";
        // if (paginationParticulars.page !== obj.page) changeType = "page";
        // else if (paginationParticulars.sizePerPage !== obj.sizePerPage)
        //     changeType = "sizePerPage";
        // else if (paginationParticulars.searchText !== obj.searchText)
        //     changeType = "searchText";

        setPaginationParticulars({
            ...paginationParticulars,
            page: obj.page,
            sizePerPage: obj.sizePerPage,
            searchText: obj.searchText,
        });
        // this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText);
        // }
    };

    useEffect(() => { getCandidateDetails() }, [candidatesFilter]);
    return (<div className='Tab1Container'>
        <Col md={12} className="leads-table-container">
            <Row className="mt10 mb5">
                <Col md={3}>
                    <div className="total-leads-count-container" style={{ backgroundColor: "var(--themeColor)", textAlign: "center", borderRadius: "10px" }}>
                        <span className="total-leads m0" style={{ fontWeight: 900, fontSize: 22, color: "#fff" }}>
                            {"Total leads: " + paginationParticulars.totalLeads}
                        </span>
                    </div>
                </Col>
                <Col md={9}>
                    <Row className="mt10 mb5">
                        <Col md={4}>
                            <div className="total-leads-count-container">
                                <Switch defaultChecked={candidatesFilter.accounts} onChange={(e) => { setCandidatesFilter({ ...candidatesFilter, accounts: e }); }} />
                                {" "} Account Holders [{counts.accountHolders}]
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="total-leads-count-container">
                                <Switch defaultChecked={candidatesFilter.applied} onChange={(e) => { setCandidatesFilter({ ...candidatesFilter, applied: e }); }} />
                                {" "} Loans Applied [{counts.applied}]
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="total-leads-count-container">
                                <Switch defaultChecked={candidatesFilter.sanctioned} onChange={(e) => { setCandidatesFilter({ ...candidatesFilter, sanctioned: e }); }} />
                                {" "} Loans Sanctioned [{counts.sanctioned}]
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="total-leads-count-container">
                                <Switch defaultChecked={candidatesFilter.disbursed} onChange={(e) => { setCandidatesFilter({ ...candidatesFilter, disbursed: e }); }} />
                                {" "} Loans Disbursed [{counts.disbursed}]
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="total-leads-count-container">
                                <Switch defaultChecked={candidatesFilter.rejected} onChange={(e) => { setCandidatesFilter({ ...candidatesFilter, rejected: e }); }} />
                                {" "} Loans Rejected [{counts.rejected}]
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {candidates ? (
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        page: paginationParticulars.page,
                        sizePerPage: paginationParticulars.sizePerPage,
                        totalSize: paginationParticulars.totalLeads,
                        sizePerPageList: paginationParticulars.sizePerPageList,
                    })}
                    keyField="_id"
                    data={candidates}
                    columns={columns}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            data={candidates}
                            columns={columns}
                            search={{ searchFormatted: true }}
                        >
                            {(props) => (
                                <div>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        id="leads-page-table"
                                        rowStyle={rowClassNameFormat}
                                        remote={{ pagination: true }}
                                        onTableChange={onTableChange}
                                        {...paginationTableProps}
                                    />
                                    <PaginationListStandalone {...paginationProps} />
                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
            ) : null}
        </Col>
    </div >

    )
}

export default CandidatesTab;
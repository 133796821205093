import React from "react";
import { Button, Table } from "react-bootstrap";
import Contactretrieve from "./contactRetrival";

const Contactlist = (props) => {
  if (props.items === undefined) {
    return (
      <div
        style={{
          position: "absolute",
          left: "40rem",
          top: "30rem",
          backgroundColor: "gray",
          padding: "20px",
          borderRadius: "25px",
        }}
      >
        <h4>No Contact found. Maybe create one?</h4>
        <Button onClick={props.onShow}>Create contact</Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Button>Total Member : {props.items.length}</Button>{" "}
      {/*come from database*/}
      <Button onClick={props.onShow}>Create contact</Button>
      <Table striped responsive="2rem" bordered hover size="sm">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Added on</th>
            <th>Last Update</th>
            <th> Edit </th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((contact) => (
            <Contactretrieve
              key={contact.id}
              id={contact.id}
              type={contact.type}
              designation={contact.designation}
              name={contact.name}
              email={contact.email}
              phone={contact.phone}
              addedon={contact.addedon}
              lastupdate={contact.lastupdate}
              onDelete={props.onDeleteContact}
            />
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default Contactlist;

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ConfigProvider, DatePicker, Select } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';


import enUS from 'antd/es/locale/en_US';

const readCookie = require('../../cookie.js').readCookie;
const createCookie = require('../../cookie.js').createCookie;

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

moment.locale('en');

export default class SckAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			topScks: [],
			state: '',
			cluster: [],
			district: [],
			toDate: null,
			allStates: [],
			fromDate: null,
			claimsData: [],
			allDistricts: [],
			topDistricts: [],
			widthPercent: 16,
			totalCounts: null,
			ivrsLeadRatio: null,
			swavalambanFellows: [],
			topPerformingSCKColumns: [
				{ text: '', sort: false, hidden: true, export: false, searchable: false, dataField: 'id' },
				{ text: 'NGO', sort: true, hidden: false, export: true, dataField: 'ngoName', searchable: true, formatter: this.formatNGOName, headerStyle: () => { return { width: '160px' } } },
				{ text: 'State', sort: true, hidden: false, export: true, dataField: 'state', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'District', sort: true, hidden: false, export: true, dataField: 'district', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Total assigned callers (A)', sort: true, hidden: false, export: true, dataField: 'districtIVRS', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Callers assigned to SCK (B)', sort: true, hidden: false, export: true, dataField: 'ivrs', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total Caller profiles created by SCK (C)', sort: true, hidden: false, export: true, dataField: 'leads', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Caller profiles disqualified (D)', sort: true, hidden: false, export: true, dataField: 'disqualifiedLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller profiles pending (E)', sort: true, hidden: false, export: true, dataField: 'pendingIVRS', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller Profile Creation % (C / (C + E))', sort: true, hidden: false, export: true, dataField: 'profileCreationRatio', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '90px' } }, sortFunc: this.ratioSort },
				{ text: 'Direct Profiles created by SCK (F)', sort: true, hidden: false, export: true, dataField: 'directLeads', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Direct profiles disqualified (G)', sort: true, hidden: false, export: true, dataField: 'directDisqualifiedLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total profiles created (H = C + F)', sort: true, hidden: false, export: true, dataField: 'totalLeads', searchable: true, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Total profiles not disqualified (J = H - G - D)', sort: true, hidden: false, export: true, dataField: 'totalLeadsNotDisqualified', searchable: true, headerStyle: () => { return { width: '100px' } } },
				{ text: 'Disqualification % ((D + G) / H)', sort: true, hidden: false, export: true, dataField: 'disqualificationPercent', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '105px' } } },
				{ text: 'Complete profiles % (Total 100% complete profiles out of J / J)', sort: true, hidden: false, export: true, dataField: 'completedProfPercent', searchable: true, formatter: this.formatPercentage, headerStyle: () => { return { width: '90px' } } },
				{ text: 'Caller profiles conversion status', sort: true, hidden: false, export: true, dataField: 'profileCreationRatio', searchable: true, formatter: this.leadConversion, headerStyle: () => { return { width: '130px' } } },
			]
		}
	}

	getDashboardData = (query = '', demographyQuery = '') => {
		let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/dashboard';
		fetch(apiUrl + '/top-performers/sck' + query /*? '?fromDate=28-10-2019' : query + '&fromDate=28-10-2019'*/, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					if (data.hasOwnProperty('ivrsLeadRatio')) this.setState({ ivrsLeadRatio: data.ivrsLeadRatio });
					this.setState({ topScks: data.topScks || [], loading: false });
				}
			});
	}
	submitFilter = () => {
		this.setState({ loading: true });
		let { fromDate, toDate, state, district } = this.state, query = '', demographyQuery = '';
		if (state) {
			if (query) query += `&state=${state}`;
			else query = `?state=${state}`;
			if (demographyQuery) demographyQuery += `&state=${state}`;
			else demographyQuery = `?state=${state}`;
		}
		if (district) {
			if (query) query += `&district=${district}`;
			else query = `?district=${district}`;
			if (demographyQuery) demographyQuery += `&district=${district}`;
			else demographyQuery = `?district=${district}`;
		}
		if (toDate) {
			if (query) query += `&toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			else query = `?toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			if (demographyQuery) demographyQuery += `&toDate=${moment(toDate).format('DD-MM-YYYY')}`;
			else demographyQuery = `?toDate=${moment(toDate).format('DD-MM-YYYY')}`;
		}
		if (fromDate) {
			if (query) query += `&fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			else query = `?fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			if (demographyQuery) demographyQuery += `&fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
			else demographyQuery = `?fromDate=${moment(fromDate).format('DD-MM-YYYY')}`;
		}

		this.getDashboardData(query, demographyQuery);
	}

	resetFilter = () => {
		// let state = '', district = '', fromDate = null, toDate = null;
		this.setState({ state: '', district: [], cluster: [], fromDate: null, toDate: null, allDistricts: [], loading: true }, () => this.getDashboardData());
	}
	rowClassNameFormat = (row, index) => {
		if (parseInt(row.profileCreationRatio) >= 80) return { color: '#fff', backgroundColor: '#78D245' };
		else if (parseInt(row.profileCreationRatio) < 80 && parseInt(row.profileCreationRatio) >= 65) return { backgroundColor: '#FFDF80' };
		else if (parseInt(row.profileCreationRatio) < 65) return { color: '#fff', backgroundColor: '#ff4f4f' };
	}
	componentDidMount() {
		this.getDashboardData();
		if (JSON.parse(readCookie('userData')).type !== 'partners' || JSON.parse(readCookie('userData')).accountType === 'localGovernment') {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) this.setState({ allStates: data.data.states });

					if (JSON.parse(readCookie('userData')).type === 'le') {
						fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full', {
							method: 'GET',
							headers: {
								'x-auth-token': JSON.parse(readCookie('access_token'))
							}
						}).then(data => data.json())
							.then(data => {
								if (data.status) {
									this.setState({ swavalambanFellows: data.users });
								}
								if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
							});
					} else {
						if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
					}
				});
		} else {
			if (!readCookie('noticed') && this.state.ivrsLeadRatio) {
				Swal.fire({
					type: 'warning',
					title: 'URGENT NOTICE!',
					text: '',
					focusConfirm: false,
					showCloseButton: true,
					confirmButtonText: 'Ok',
					showCancelButton: false,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						Swal.showLoading();
						this.checkIfGotValue = setInterval(() => {
							if (this.state.ivrsLeadRatio) {
								clearInterval(this.checkIfGotValue);
								if (parseInt(this.state.ivrsLeadRatio) < 80) {
									Swal.update({
										text: 'Please note that your caller profile completion rate is only ' + this.state.ivrsLeadRatio + '%. This means that you are not proactive enough in calling the interested aspirants and many candidates are awaiting your call. You can find them in the "IVRS callers" section.'
									});
									Swal.hideLoading();
								} else {
									Swal.close();
									createCookie('noticed', true, 7);
								}
							}
						}, 100);
					}
				}).then((result) => {
					if (result.value) {
						createCookie('noticed', true, 7);
					}
				});
			}
			if (JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' || JSON.parse(readCookie('userData')).userType.name === 'Zonal Manager' || JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows') this.setState({ showFollowUpModule: true });
		}
	}
	onChange = (type, value) => {
		if (type === 'state') {
			if (value) {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + value, {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ allDistricts: data.districts, district: [], state: value }, () => this.submitFilter());
						}
					});
			} else {
				this.setState({ allDistricts: [], district: [], state: value }, () => this.submitFilter());
			}
		} else if (type === 'cluster') {
			for (let i = 0; i < this.state.swavalambanFellows.length; i++) {
				if (value.includes(this.state.swavalambanFellows[i]._id)) {
					fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + this.state.swavalambanFellows[i].state[0], {
						method: 'GET',
						headers: {
							'x-auth-token': JSON.parse(readCookie('access_token'))
						}
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								this.setState({ allDistricts: data.districts, district: this.state.swavalambanFellows[i].district, state: this.state.swavalambanFellows[i].state[0], cluster: value }, () => this.submitFilter());
							}
						});
				}
			}
		} else {
			if (value.hasOwnProperty('target')) this.setState({ [type]: value.target.value }, () => this.submitFilter());
			else this.setState({ [type]: value }, () => this.submitFilter());
		}
	}
	onDateChange = (value) => {
		if (value) {
			let fromDate = value[0], toDate = value[1];
			this.setState({ fromDate, toDate }, () => this.submitFilter());
		} else {
			this.setState({ fromDate: null, toDate: null }, () => this.submitFilter());
		}
	}

	render() {
		return <div className="page-container top-scks-container">
			{this.state.loading ? <div className="col-md-12 loader-container" style={{ top: '50%' }}>
				{/* <div className="loader-msg">Please wait while the data loads..!</div> */}
				<i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ fontSize: '60px' }}></i>
			</div> : <React.Fragment> <div className={this.state.showFollowUpModule ? "filters-container follow-up-margin" : "filters-container"}>
				<div className="filters-container"><div className="filter form-group">
					<label className="control-label">{'From - To date: '}</label>
					<ConfigProvider locale={enUS}>
						<RangePicker locale={enUS} style={{ width: "200px" }} format="DD/MM/YYYY" value={[this.state.fromDate, this.state.toDate]} type="date" onChange={this.onDateChange} />
					</ConfigProvider>
				</div>
					{JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Connect Kendra' ? (
						<div className="filter form-group">
							<label className="control-label">{'State: '}</label>
							<Select showSearch style={{ width: '120px' }} value={this.state.state} onChange={this.onChange.bind(this, 'state')} filterOption={(input, option) => this.searchFilter(input, option)}>
								<Option value="">All States</Option>
								<OptGroup label="Focus States">
									{this.state.allStates.map((state, stateIndex) => {
										if (state.inFocusRegion) {
											if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else return null;
										} else return null;
									})}
								</OptGroup>
								<OptGroup label="Not Focussed States">
									{this.state.allStates.map((state, stateIndex) => {
										if (!state.inFocusRegion) {
											if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.state.includes(state.name)) {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
												return <Option key={stateIndex} value={state.name}>{state.name}</Option>
											} else return null;
										} else return null;
									})}
								</OptGroup>
							</Select>
						</div>
					) : (null)}
					{JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Connect Kendra' ? (
						<div className="filter form-group">
							<label className="control-label">{'District: '}</label>
							<Select mode="multiple" showSearch style={{ width: '130px' }} value={this.state.district} onChange={this.onChange.bind(this, 'district')} filterOption={(input, option) => this.searchFilter(input, option)} placeholder="All Districts">
								{this.state.state ? (
									this.state.allDistricts.map((district, districtIndex) => {
										if (JSON.parse(readCookie('userData')).userType.name === 'Swavalamban Fellows' && JSON.parse(readCookie('userData')).address.district.includes(district.name)) {
											return <Option key={districtIndex} value={district.name}>{district.name}</Option>
										} else if (JSON.parse(readCookie('userData')).userType.name !== 'Swavalamban Fellows') {
											return <Option key={districtIndex} value={district.name}>{district.name}</Option>
										} else return null;
									})
								) : (null)}

							</Select>
						</div>
					) : (null)}
					{JSON.parse(readCookie('userData')).type === 'le' ? (
						<div className="filter form-group">
							<label className="control-label">{'Cluster: '}</label>
							<Select showSearch style={{ width: '130px' }} value={this.state.cluster} onChange={this.onChange.bind(this, 'cluster')} filterOption={(input, option) => this.searchFilter(input, option)} placeholder="All Clusters">
								{this.state.swavalambanFellows.map((fellow, fellowIndex) => {
									return (
										<Option key={fellowIndex} title={`${fellow.fullName} (${fellow.email})`} value={fellow._id}>
											{fellow.fullName}
										</Option>
									)
								})}
							</Select>
						</div>
					) : (null)}
					<div className="filter form-group">
						<button className="btn reset-filters" onClick={this.resetFilter}>Reset</button>
					</div></div>
			</div>
				<div className="title">{'Performance of SCKs'}</div>
				<div className="top-scks-table-container">
					{this.state.topScks.length ? (
						<ToolkitProvider keyField="id" data={this.state.topScks} columns={this.state.topPerformingSCKColumns}>
							{props => (
								<div className="table-container">
									<BootstrapTable id="top-scks-table" rowStyle={this.rowClassNameFormat} {...props.baseProps} ref={(table) => { this.topScksTable = table; }} />
								</div>
							)}
						</ToolkitProvider>
					) : (
						<div className="no-data-container">No Data Found</div>
					)}
				</div></React.Fragment>}
		</div>
	}
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Col, Row,
    // Modal, Button
} from 'react-bootstrap';
import {
    Select,
    DatePicker,
    InputNumber,
    Input,
    Upload,
    Button,
    // Switch
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const { MonthPicker, } = DatePicker;

const { Option } = Select;



const NewLeadExistingBusinessDetailsForm = ({ existingBusinessDetails, updateLeadBusinessDetails, errorObj }) => {



    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('month');
    }

    // const MONTHS = ['Jan'];
    const monthFormat = 'MM/YYYY';

    const [businessDetails, updateExistingBusinessDetails] = useState({ existingBusinessPhoto: [],...existingBusinessDetails} || {existingBusinessPhoto: []});

    useEffect(() => {
        updateLeadBusinessDetails(businessDetails);
    }, [businessDetails]);

    useEffect(() => {
        const fileInput = document.querySelector("#myfiles");

        if (fileInput) {
            fileInput.addEventListener("change", () => {
                let innerText = '';
                let files = fileInput.files;
                for (const file of fileInput.files) {
                    innerText = innerText + `\n${file.name}`;

                }
                updateExistingBusinessDetails({ ...existingBusinessDetails, existingBusinessPhoto: files });
            });
        }
    }, []);
    return <Row className="step-container step-1">
        <Col md={4}>
            <label className="control-label is-imp">  Business Name</label>
            <Input
                placeholder='Enter Business Name'
                onChange={(e) => {  updateExistingBusinessDetails({ ...businessDetails, businessName: e.target.value })}}
                defaultValue={businessDetails.businessName || ''}
                value={businessDetails.businessName || ""}
            />
            {errorObj.hasOwnProperty('businessName') ? (
                <div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.businessName}</div>
            ) : (null)}</Col>
        
        <Col md={4}>
            <label className="control-label
            //  is-imp
             "> Google Map Location Link</label>
            <Input
                placeholder='Enter google map location link'
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, googleMapLocationLink: e.target.value })}
                defaultValue={businessDetails.googleMapLocationLink || ""}
                value={businessDetails.googleMapLocationLink || ""}
            />
            {errorObj.hasOwnProperty('googleMapLocationLink') ? (
                <div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.googleMapLocationLink}</div>
            ) : (null)}</Col>
        
        <Col md={4}>
            <label className="control-label 
            // is-imp
            "> Add business photo</label>
            {/* <Upload
                accept='image/*'
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, existingBusinessPhoto: e && e.fileList && e.fileList.length ? e.fileList : FileList() })}
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}
            {businessDetails.existingBusinessPhoto && businessDetails.existingBusinessPhoto.length && typeof businessDetails.existingBusinessPhoto[0] === 'string' ? businessDetails.existingBusinessPhoto.map((photo, ind) => <a href={photo} target='_blank' rel="noreferrer noopener">File { ind+1}</a>): <input id="myfiles" type="file" multiple
                // onChange={(e) => { if (e.target.files && e.target.files.length) { updateExistingBusinessDetails({ ...businessDetails, existingBusinessPhoto: e.target.files }) } else { updateExistingBusinessDetails({ ...businessDetails, existingBusinessPhoto: e.target.files }) } }}
                accept='image/*' className="form-control" style={{ padding: '3px' }} />}
            {errorObj.hasOwnProperty('existingBusinessPhoto') ? (
                <div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.existingBusinessPhoto}</div>
            ) : (null)}
        </Col>
        <Col md={4}>
            <label className="control-label is-imp"> Month/Year of starting business</label>
            <MonthPicker
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, businessStartMonth: `${moment(e._d).format('MM/YYYY')}` })}
                disabledDate={disabledDate}
                defaultValue={businessDetails.businessStartMonth ? moment(businessDetails.businessStartMonth || '', monthFormat) : ""}
                value={businessDetails.businessStartMonth ? moment(businessDetails.businessStartMonth || '', monthFormat) : ""}
                format={monthFormat}
                placeholder="Select month" />

        	{errorObj.hasOwnProperty('businessStartMonth') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.businessStartMonth}</div>
					) : (null)}</Col>

        <Col md={4}>
            {/* <label className="control-label is-imp"> Proof of registration (Select all that apply)</label> */}
            <label className="control-label"> Proof of registration (Select all that apply)</label>
            <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select from the dropdown"
                defaultValue={businessDetails.registrationProof || []}
                value={businessDetails.registrationProof || []}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, registrationProof: e })}
            >
                {[{ label: "MSME Udyog Registration", value: "MSME Udyog Registration" }, { label: "Trade license", value: "Trade license" }, { label: "Shops and Establishment license", value: "Shops and Establishment license" },
                { label: "Other licenses(FSSAI / Pollution)", value: "Other licenses(FSSAI / Pollution)" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
            
        	{errorObj.hasOwnProperty('registrationProof') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.registrationProof}</div>
					) : (null)}</Col>

        {businessDetails.businessStartMonth ? <Col md={4}>
            {
                moment().diff(moment(`01/${businessDetails.businessStartMonth}`, `DD/MM/YYYY`), 'days') > 365 ?
                    <span>
                        <label className="control-label is-imp"> Last year's sale (INR)</label>
                        <InputNumber width={"100%"} defaultValue={businessDetails.salesFigures} onChange={(e) => { updateExistingBusinessDetails({ ...businessDetails, salesFigures: e, salesFigureUpdated: moment().format('DD/MM/YYYY') }) }} /> as on {businessDetails.salesFigureUpdated} 
                    </span> : <span>
                        <label className="control-label is-imp"> Lifetime revenue from the date of start till date (INR)</label>
                        <InputNumber width={"100%"} defaultValue={businessDetails.salesFigures} onChange={(e) => { updateExistingBusinessDetails({ ...businessDetails, salesFigures: e, salesFigureUpdated: moment().format('DD/MM/YYYY') }) }} /> as on {businessDetails.salesFigureUpdated} 
                        <note>Extrapolated Annualized revenue = {businessDetails.salesFigures ? (365 / moment().diff(moment(`01/${businessDetails.businessStartMonth}`, `DD/MM/YYYY`), 'days') * businessDetails.salesFigures) : 0} INR </note>
                    </span>
            }
        	{errorObj.hasOwnProperty('salesFigures') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.salesFigures}</div>
					) : (null)}</Col> : null}

        <Col md={4}>
            <label className="control-label is-imp"> Has GST Registration?</label>
            <Select
                style={{ width: '100%' }}
                placeholder="Please select Yes/No"
                defaultValue={businessDetails.hasGstRegistration || ""}
                value={businessDetails.hasGstRegistration || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, hasGstRegistration: e })}
            >
                {[{ label: "Select Yes/No", value: "" }, { label: "Yes", value: "Yes" }, { label: "No", value: "No" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('hasGstRegistration') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.hasGstRegistration}</div>
					) : (null)}</Col>

{ businessDetails.hasGstRegistration === "Yes" ?        <Col md={4}>
            <label className="control-label is-imp"> Month/Year of GST Certificate</label>
            <MonthPicker
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, gstCertMonthYear: `${moment(e._d).format('MM/YYYY')}` })}
                disabledDate={disabledDate}
                defaultValue={businessDetails.gstCertMonthYear ? moment(businessDetails.gstCertMonthYear || '', monthFormat) : ""}
                value={businessDetails.gstCertMonthYear ? moment(businessDetails.gstCertMonthYear || '', monthFormat) : ""}
                format={monthFormat}
                placeholder="Select month & year of GST certification" />
        	{errorObj.hasOwnProperty('gstCertMonthYear') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.gstCertMonthYear}</div>
					) : (null)}</Col>: null}

        {businessDetails.hasGstRegistration === "Yes" ? <Col md={4}>
            <label className="control-label is-imp"> How do you file your GST returns?</label>
            <Select
                style={{ width: '100%' }}
                defaultValue={businessDetails.gstFilingMeans || ""}
                value={businessDetails.gstFilingMeans || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, gstFilingMeans: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "Not filed yet", value: "Not filed yet" }, { label: "My accountant files it.", value: "My accountant files it." }, { label: "I do it myself.", value: "I do it myself."}].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('gstFilingMeans') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.gstFilingMeans}</div>
					) : (null)}</Col> : null}

        <Col md={4}>
            <label className="control-label is-imp"> Files income tax and has IT returns?</label>
            <Select
                style={{ width: '100%' }}
                defaultValue={businessDetails.filesIT || ""}
                value={businessDetails.filesIT || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, filesIT: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "No", value: "No" }, { label: "Last 1 year", value: "Last 1 year" }, { label: "Last 2 years", value: "Last 2 years" }, { label: "Last 3 years", value: "Last 3 years" }, { label: "Last 3 years +", value: "Last 3 years +" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('filesIT') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.filesIT}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp"> Proof of sales and purchase</label>
            <Select
                style={{ width: '100%' }}
                defaultValue={businessDetails.proofOfSalesAndPurchase || ""}
                value={businessDetails.proofOfSalesAndPurchase || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, proofOfSalesAndPurchase: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "No proof", value: "No proof" }, { label: "Has both sale and purchase receipts", value: "Has both sale and purchase receipts" },
                    // { label: "Has both sale and purchase receipts", value: "Has both sale and purchase receipts" },
                    { label: "Has sales receipts only", value: "Has sales receipts only"},
                { label: "Has purchase receipts only", value: "Has purchase receipts only",  },].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('proofOfSalesAndPurchase') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.proofOfSalesAndPurchase}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">Do you issue cheques to your suppliers?</label>
            <Select
                style={{ width: '100%' }}
                placeholder="Please select Yes/No"
                defaultValue={businessDetails.issuesCheques || ""}
                value={businessDetails.issuesCheques || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, issuesCheques: e })}
            >
                {[{ label: "Select Yes/No", value: "" }, { label: "Yes", value: "Yes" }, { label: "No", value: "No" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('issuesCheques') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.issuesCheques}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">Bank account used for managing business transactions</label>
            <Select
                style={{ width: '100%' }}
                defaultValue={businessDetails.businessBankAccountType || ""}
                value={businessDetails.businessBankAccountType || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, businessBankAccountType: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "No Bank account", value: "No Bank account" }, { label: "Savings account", value: "Savings account" }, { label: "Current account", value: "Current account" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('businessBankAccountType') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.businessBankAccountType}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">Has your account been active in 6 months? (If there is any customer-induced debit transaction in 6 months, the account is considered as active.)</label>
            <Select
                style={{ width: '100%' }}
                placeholder="Please select Yes/No"
                defaultValue={businessDetails.bankAccountActive || ""}
                value={businessDetails.bankAccountActive || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, bankAccountActive: e })}
            >
                {[{ label: "Select Yes/No", value: "" }, { label: "Yes", value: "Yes" }, { label: "No", value: "No" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('bankAccountActive') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.bankAccountActive}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">What is the average monthly transaction size (sum of deposits & withdrawals)?</label>
            <Select
                style={{ width: '100%' }}
                defaultValue={businessDetails.averageMonthlyTxnSize || ""}
                value={businessDetails.averageMonthlyTxnSize || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, averageMonthlyTxnSize: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "INR 0", value: "INR 0" }, { label: "Under INR 25000", value: "Under INR 25000" }, { label: "INR 25000-1 lac", value: "INR 25000-1 lac" },
                    { label: "INR 1 lac-5 lacs", value: "INR 1 lac-5 lacs" }, { label: "INR 5 lacs-10 lacs", value: "INR 5 lacs-10 lacs" }, { label: "INR 10 lacs-15 lacs", value: "INR 10 lacs-15 lacs" },
                    { label: "INR 15 lacs-25 lacs", value: "INR 15 lacs-25 lacs" }, { label: ">INR 25 lacs", value: ">INR 25 lacs" },].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('averageMonthlyTxnSize') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.averageMonthlyTxnSize}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">Do you maintain your book of accounts?</label>
            <Select
                style={{ width: '100%' }}
                placeholder="Please select Yes/No"
                defaultValue={businessDetails.maintainAccountBooks || ""}
                value={businessDetails.maintainAccountBooks || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, maintainAccountBooks: e })}
            >
                {[{ label: "Select Yes/No", value: "" }, { label: "Yes", value: "Yes" }, { label: "No", value: "No" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('maintainAccountBooks') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.maintainAccountBooks}</div>
					) : (null)}</Col>

        <Col md={4}>
            <label className="control-label is-imp">Ownership of business premises</label>
            <Select
                style={{ width: '100%' }}
                placeholder="Please select Yes/No"
                defaultValue={businessDetails.businessPremisesOwnership || ""}
                value={businessDetails.businessPremisesOwnership || ""}
                onChange={(e) => updateExistingBusinessDetails({ ...businessDetails, businessPremisesOwnership: e })}
            >
                {[{ label: "Select One", value: "" }, { label: "Own", value: "Own" }, { label: "Rented", value: "Rented" }].map((optionText, i) => <Option value={optionText.value} key={i}>{optionText.label}</Option>)}
            </Select>
        	{errorObj.hasOwnProperty('businessPremisesOwnership') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.businessPremisesOwnership}</div>
					) : (null)}</Col>
        {businessDetails.businessPremisesOwnership === "Rented" ?
            <Col md={4}>
                <label className="control-label is-imp">Enter monthly rent (INR)</label>
                <InputNumber width={"100%"} defaultValue={businessDetails.monthlyRent} onChange={(e) => { updateExistingBusinessDetails({ ...businessDetails, monthlyRent: e }) }} />
                {errorObj.hasOwnProperty('monthlyRent') ? (
                    <div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.monthlyRent}</div>
					) : (null)}</Col> : null}
        
        <Col md={4}>
            <label className="control-label is-imp">Area of business premises (in sq.ft.)</label>
            <InputNumber width={"100%"} defaultValue={businessDetails.premisesArea} onChange={(e) => { updateExistingBusinessDetails({ ...businessDetails, premisesArea: e }) }} />
        	{errorObj.hasOwnProperty('premisesArea') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{errorObj.premisesArea}</div>
					) : (null)}</Col>
    </Row>
}


export default NewLeadExistingBusinessDetailsForm;
import React
	// , { Component } 
	from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import EditModuleModal from './EditModuleModal';

const readCookie = require('../cookie.js').readCookie;

export default class PlatformUserTypes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allUsers: [],
			allModules: [],
			newModules: [],
			hideSubmit: true,
			showEditModal: false,
			selectedModules: null,
			selectedUserTypeId: ''
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-types', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allUsers: data.userTypes });
				}
			});
	}

	deleteModule = (moduleId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You won't be able to revert this action.`,
			type: 'warning',
			showCancelButton: true,
			cancelButtonColor: '#EF1313',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonText: "No, cancel it!",
			closeOnConfirm: true,
			closeOnCancel: true
		}).then((isConfirm) => {
			if (isConfirm.value) {
				if (moduleId) {
					let selectedModules = this.state.selectedModules, allModules = this.state.allModules, newModules = this.state.newModules;
					delete selectedModules[moduleId];
					for (let i = 0; i < allModules.length; i++) {
						if (allModules[i].id === moduleId) allModules[i].selected = false;
					}
					for (let i = newModules.length - 1; i >= 0; i--) {
						if (newModules[i].id === moduleId) newModules.splice(i, 1);
					}
					this.setState({ selectedModules, allModules, newModules });
				} else {
					let newModules = this.state.newModules;
					if (newModules[newModules.length - 1].id === '') newModules.pop();
					this.setState({ newModules });
				}
			}
		})
	}

	editUserModules(userTypeId) {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-type/' + userTypeId, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					let selectedModules = {};
					for (var i = 0; i < data.data.allModules.length; i++) {
						let module = data.data.allModules[i];
						for (var j = 0; j < module.permissions.length; j++) {
							if (module.permissions[j].selected) {
								if (selectedModules.hasOwnProperty(module.id)) {
									selectedModules[module.id].push(module.permissions[j].id);
								} else {
									selectedModules[module.id] = [module.permissions[j].id];
								}
							}
						}
					}
					this.setState({ selectedModules, allModules: data.data.allModules, showEditModal: true, newModules: [], selectedUserTypeId: userTypeId });
				}
			});
	}

	closeEditModal = () => {
		this.setState({ selectedModules: null, showEditModal: false, selectedUserTypeId: '' });
	}

	notifySuccess = (text) => toast.success(text);

	notifyError = (text) => toast.error(text);

	addNewModule = () => {
		let newModules = this.state.newModules,
			moduleObj = {
				id: '',
				name: '',
				permissions: []
			};

		newModules.push(moduleObj);
		this.setState({ newModules });
	}

	changeModule = (index, e) => {
		let allModules = this.state.allModules,
			newModules = this.state.newModules,
			oldModuleId = newModules[index]['id'],
			selectedModules = this.state.selectedModules;

		delete selectedModules[oldModuleId];

		if (e.target.value !== '') {
			newModules[index]['id'] = e.target.value;
			newModules[index]['permissions'] = [];
			for (var i = 0; i < allModules.length; i++) {
				if (allModules[i].id === e.target.value) {
					newModules[index]['name'] = allModules[i].name;
					for (var j = 0; j < allModules[i].permissions.length; j++) {
						let permissionObj = {
							selected: false,
							value: allModules[i].permissions[j].id,
							label: allModules[i].permissions[j].name
						}
						newModules[index]['permissions'].push(permissionObj);
					}
				}
			}
			selectedModules[e.target.value] = [];
			this.setState({ newModules, selectedModules, hideSubmit: true });
		}
	}

	changePermissions = (index, type, values) => {
		let newModules = this.state.newModules,
			allModules = this.state.allModules,
			selectedModules = this.state.selectedModules;

		if (type === 'newModules') {
			for (var i = 0; i < newModules[index]['permissions'].length; i++) {
				if (values.indexOf(newModules[index]['permissions'][i]['value']) > -1) {
					newModules[index]['permissions'][i]['selected'] = true;
				}
			}
			selectedModules[newModules[index]['id']] = values;
		} else {
			selectedModules[allModules[index]['id']] = values;
		}
		this.setState({ newModules, selectedModules, hideSubmit: true });
	}

	submitModule = () => {
		this.validateModules((isValid, error) => {
			if (isValid) {
				let selectedModules = this.state.selectedModules, modules = [];
				for (var i = 0; i < Object.keys(selectedModules).length; i++) {
					modules.push({
						id: Object.keys(selectedModules)[i],
						permissions: Object.values(selectedModules)[i]
					});
				}
				fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-type/' + this.state.selectedUserTypeId, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'x-auth-token': JSON.parse(readCookie('access_token'))
					},
					body: JSON.stringify({ modules })
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							this.setState({ selectedModules: null, showEditModal: false, selectedUserTypeId: '' });
							this.notifySuccess('Successfully updated the modules!');
						}
					});
			} else if (error) {
				this.notifyError(error);
			}
		});
	}

	validateModules = (cb) => {
		let selectedModules = this.state.selectedModules, isValid = true, error = '';

		if (Object.keys(selectedModules).length === 0) cb(false, 'Please choose modules correctly!');
		else {
			for (var i = 0; i < Object.keys(selectedModules).length; i++) {
				if (Object.values(selectedModules)[i].length === 0) {
					isValid = false;
					error = 'Please select some permissions.';
				}
			}
			cb(isValid, error);
		}
	}

	render() {
		return (
			<div className="users-page page-container">
				{this.state.allUsers.length ? (
					<Col md={12} className="all-user-container">
						<h3>User Types</h3>
						{this.state.allUsers.map((userType, index) => {
							return (
								<Col md={12} className="user" key={index}>
									<Col md={9}>
										{userType.isOrg ? (
													userType.customUrl !== 'swavalamban-connect-kendra' ?
												<Link to={userType.customUrl ? `/udaan-centres/${userType.customUrl}` : `/udaan/${userType.id}`} className="user-name">{userType.name}</Link>
												:
													<Link to={userType.customUrl ? `/org-types/${userType.customUrl}` : `/org-types/${userType.id}`} className="user-name">{userType.name}</Link>
										) : (
											<Link to={userType.customUrl ? `/user-types/${userType.customUrl}` : `/user-types/${userType.id}`} className="user-name">{userType.name}</Link>
										)}
									</Col>
									<Col md={3} className="text-right">
										{userType.isOrg ? (
													userType.customUrl !== 'swavalamban-connect-kendra' ?
													<Link to={userType.customUrl ? `/udaan-centres/${userType.customUrl}` : `/udaan/${userType.id}`} className="total-count">
													{userType.memberCount === 1 ? userType.memberCount + ' organisation' : userType.memberCount + ' organisations'}
											</Link>:
													<Link to={userType.customUrl ? `/org-types/${userType.customUrl}` : `/org-types/${userType.id}`} className="total-count">
												{userType.memberCount === 1 ? userType.memberCount + ' organisation' : userType.memberCount + ' organisations'}
											</Link>
										) : (
											<Link to={userType.customUrl ? `/user-types/${userType.customUrl}` : `/user-types/${userType.id}`} className="total-count">
												{userType.memberCount === 1 ? userType.memberCount + ' member' : userType.memberCount + ' members'}
											</Link>
										)}
									</Col>
									{JSON.parse(readCookie('userData')).type === 'le' && JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit' && false ? (
										<Col md={3} className="del-btn-container">
											<div className="btn del-btn mr10" style={{ cursor: 'pointer' }} onClick={this.editUserModules.bind(this, userType.id)}>Edit Modules</div>
										</Col>
									) : (null)}
								</Col>
							)
						})}
					</Col>
				) : (
					<div className="no-user-container">
						<div className="no-user-text">No User Types found</div>
					</div>
				)}
				{this.state.selectedModules !== null ? (
					<EditModuleModal showModal={this.state.showEditModal} closeModal={this.closeEditModal} submitModule={this.submitModule} selectedModules={this.state.selectedModules} allModules={this.state.allModules} addNewModule={this.addNewModule} newModules={this.state.newModules} deleteModule={this.deleteModule} changeModule={this.changeModule} changePermissions={this.changePermissions} hideSubmit={this.state.hideSubmit} />
				) : (null)}
			</div>
		)
	}
}

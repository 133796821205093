const VALIDATOR_TYPE_REQUIRE = 'REQUIRE';
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
const VALIDATOR_TYPE_MIN = 'MIN';
const VALIDATOR_TYPE_MAX = 'MAX';
const VALIDATOR_TYPE_PINCODE = 'PINCODE';
const VALIDATOR_TYPE_TRAINEDcANDIDATE = 'TRAINEDcANDIDATE';
const VALIDATOR_TYPE_CONTACT = 'CONTACT';
const VALIDATOR_TYPE_EMAIL = 'EMAIL';


export const VALIDATOR_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALIDATOR_CONTACT = () => ({ type: VALIDATOR_TYPE_CONTACT });
export const VALIDATOR_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALIDATOR_TRAINEDcANDIDATE = () => ({ type: VALIDATOR_TYPE_TRAINEDcANDIDATE });
export const VALIDATOR_MINLENGTH = val => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val
});
export const VALIDATOR_MAXLENGTH = val => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val
});
export const VALIDATOR_MIN = val => ({ type: VALIDATOR_TYPE_MIN, val: val });
export const VALIDATOR_MAX = val => ({ type: VALIDATOR_TYPE_MAX, val: val });
export const VALIDATOR_PINCODE = () => ({ type: VALIDATOR_TYPE_PINCODE });

export const validate = (value, validators) => {
  let isValid = true;
  for (const validator of validators) {
    if (validator.type === VALIDATOR_TYPE_REQUIRE) {
      isValid = isValid && value.trim().length > 0;
      //console.log(`select ${value.length}`);
    }
    if (validator.type === VALIDATOR_TYPE_TRAINEDcANDIDATE) {
      isValid = isValid && value.trim() >= 0 && value.trim() <= 10000;
    }
    if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
      isValid = isValid && value.trim().length >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
      isValid = isValid && value.trim().length <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MIN) {
      isValid = isValid && +value >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAX) {
      isValid = isValid && +value <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_PINCODE) {
      isValid = isValid && /^[1-9]{1}[0-9]{5}$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_CONTACT) {
      isValid = isValid && value.trim().length === 10;
    }
    if (validator.type === VALIDATOR_TYPE_EMAIL) {
      isValid = isValid && /^\w+(\.-]?\w+)*@\w+(\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }
  }
  return isValid;
};

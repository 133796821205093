// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	mobileRegex = /^[6-9]\d{9}$/,
	nameRegex = /^[a-zA-Z ]+$/,
	bankAccNoRegex = /^\d{9,18}$/,
	// eslint-disable-next-line no-useless-escape
	passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

function callback(errorObj) {
	return errorObj;
}

// var url_expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
var url_regex = new RegExp('@(.*),(.*),');

exports.urlValidate = (value, errorObj) => {
	if (value.trim() === '') {
		errorObj['locationUrl'] = 'This field cannot be empty';
		callback(errorObj);
	} else if (!value.match(url_regex)) {
		errorObj['locationUrl'] = 'Only expanded Google Map URL is allowed in this field!';
		callback(errorObj);
	} else {
		delete errorObj['locationUrl'];
		callback(errorObj);
	}
}

exports.emailValidate = (value, errorObj) => {
	if (value.trim() === '') {
		errorObj['email'] = 'This field cannot be empty';
		callback(errorObj);
	} else if (!value.match(emailRegex)) {
		errorObj['email'] = 'Email format is invalid';
		callback(errorObj);
	} else {
		delete errorObj['email'];
		callback(errorObj);
	}
}

exports.mobileValidate = (type = 'mobile', value, errorObj) => {
	if (value.trim() === '') {
		errorObj[type] = 'This field cannot be empty';
		callback(errorObj);
	} else if (!value.match(mobileRegex)) {
		errorObj[type] = 'Mobile format is invalid(must be a number type and must be 10 digits)';
		callback(errorObj);
	} else {
		delete errorObj[type];
		callback(errorObj);
	}
}

exports.nameValidate = (value, errorObj) => {
	if (value.trim() === '') {
		errorObj['name'] = 'This field cannot be empty';
		callback(errorObj);
	} else if (!value.match(nameRegex)) {
		errorObj['name'] = 'Name format is invalid(only capital and small letters are allowed)';
		callback(errorObj);
	} else {
		delete errorObj['name'];
		callback(errorObj);
	}
}

exports.passwordValidate = (value, errorObj) => {
	if (value.trim() === '') {
		errorObj['password'] = 'This field cannot be empty';
		callback(errorObj);
	} else if (!value.match(passwordRegex)) {
		errorObj['password'] = 'Password format is invalid(must be a combination of capital letters, small letters, numbers and special characters)';
		callback(errorObj);
	} else {
		delete errorObj['password'];
		callback(errorObj);
	}
}

exports.stringValidate = (type, value, errorObj) => {
	if (value.trim() === '') {
		errorObj[type] = 'This field cannot be empty';
		callback(errorObj);
	} else {
		delete errorObj[type];
		callback(errorObj);
	}
}

exports.arrayValidate = (type, value, errorObj) => {
	if (!value.length) {
		errorObj[type] = 'This field cannot be empty';
		callback(errorObj);
	} else {
		delete errorObj[type];
		callback(errorObj);
	}
}

exports.numberValidate = (type, value, errorObj) => {
	if (value.trim() === '') {
		errorObj[type] = 'This field cannot be empty';
		callback(errorObj);
	} else if (value.trim() !== '' && isNaN(value)) {
		errorObj[type] = 'This is not a number';
		callback(errorObj);
	} else {
		delete errorObj[type];
		callback(errorObj);
	}
}

exports.bankAccNoValidate = (value, errorObj) => {
	// if (value.trim() === '') {
	// 	errorObj['bankAccNo'] = 'This field cannot be empty';
	// 	callback(errorObj);
	// } else
		if (!value.match(bankAccNoRegex)) {
		errorObj['bankAccNo'] = 'Bank Account number has to be a 9-18 digit number';
		callback(errorObj);
	} else {
		delete errorObj['bankAccNo'];
		callback(errorObj);
	}
}
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import Label from "../../components/FormElements/label";
import { useForm } from "../../hooks/form-hook";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Input from "../../components/FormElements/Input";
import {
  VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_EMAIL, VALIDATOR_CONTACT
} from "../../util/validators";
import { Spin } from "antd";
import { useHttpHook } from '../../hooks/http-hook';
import Swal from "sweetalert2";

const readCookie = require('../../../cookie.js').readCookie;

const UpdateContact = (props) => {
  const contactType = ["Primary", "Secondary"];
  const contactId = props.match.params.contactId;
  const { isLoading, error, sendRequest,
    // clearError
  } = useHttpHook();
  const [loadedContact, setLoadedContact] = useState();

  const [formState, inputHandler, setFormData] = useForm(
    {
      contact_type: {
        value: "",
        isValid: false,
      },
      contact_name: {
        value: "",
        isValid: false,
      },
      contact_designation: {
        value: "",
        isValid: false,
      },
      contact_phone: {
        value: "",
        isValid: false,
      },
      contact_email: {
        value: "",
        isValid: false,
      }
    },
    false
  );


  useEffect(() => {
    const fetchContact = async () => {

      try {
        const responseData = await sendRequest(process.env.REACT_APP_API_URL + `/api/v1/center/contact/${contactId}`);
        setLoadedContact(responseData.contact);

        setFormData(
          {
            contact_type: {
              value: responseData.contact.type,
              isValid: true,
            },
            contact_name: {
              value: responseData.contact.name,
              isValid: true,
            },
            contact_designation: {
              value: responseData.contact.designation,
              isValid: true,
            },
            contact_phone: {
              value: responseData.contact.phone,
              isValid: true,
            },
            contact_email: {
              value: responseData.contact.email,
              isValid: true,
            }
          },
          true
        );
      } catch (err) {
      }
    };
    fetchContact();
  }, [sendRequest, contactId, setFormData]);

  const saveUpdate = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/contact/${contactId}`,
        'PATCH',
        JSON.stringify({
          type: formState.inputs.contact_type.value,
          name: formState.inputs.contact_name.value,
          designation: formState.inputs.contact_designation.value,
          phone: formState.inputs.contact_phone.value,
          email: formState.inputs.contact_email.value
        }),
        {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token'))
        }
      )
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Updated Successfully',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) { }
    window.history.back();
  };


  if (isLoading) {
    return (
      <React.Fragment>
        <div style={{ position: "absolute", left: "50rem", top: "15rem" }}><Spin size="large" /></div>
      </React.Fragment>
    );
  }


  if (!loadedContact && !error) {
    return (
      <React.Fragment>
        <h2>Couldn't find contact</h2>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {!isLoading && loadedContact && <Card style={{ marginTop: "6rem", marginLeft: "16rem", maxWidth: "80rem" }}>
        <Card.Header>
          <CloseCircleTwoTone style={{ float: "right", fontSize: "2rem", cursor: "pointer" }} onClick={props.onHide} />
          <p>Update ....!</p>
        </Card.Header>
        <Card.Body className="text-center">
          <Card.Title>
            <Label name="Type" />
            <Input
              element="Select"
              id="contact_type"
              onInput={inputHandler}
              errorText="Please Select Correctly"
              validators={[VALIDATOR_REQUIRE()]}
              initialValue={loadedContact.type}
              initialValid={true}
              option={contactType}
              name="contactType"
            />
          </Card.Title>
          <Container>
            <Row>
              <Col sm={6}>
                <Label name="Name" />
                <Input
                  element="input"
                  type="text"
                  onInput={inputHandler}
                  id="contact_name"
                  placeholder="6-Digit-Pincode"
                  errorText="Invalid Name"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
                  initialValue={loadedContact.name}
                  initialValid={true}
                />
              </Col>
              <Col sm={6}>
                <Label name="Designation" />
                <Input
                  element="input"
                  type="text"
                  onInput={inputHandler}
                  id="contact_designation"
                  placeholder="Designation"
                  errorText="Invalid Designation"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
                  initialValue={loadedContact.designation}
                  initialValid={true}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Label name="Phone" />
                <Input
                  element="input"
                  type="text"
                  onInput={inputHandler}
                  id="contact_phone"
                  placeholder="6-Digit-Pincode"
                  errorText="Invalid Contact"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_CONTACT()]}
                  initialValue={loadedContact.phone}
                  initialValid={true}
                />
              </Col>
              <Col sm={6}>
                <Label name="Email" />
                <Input
                  element="input"
                  type="text"
                  onInput={inputHandler}
                  id="contact_email"
                  placeholder="6-Digit-Pincode"
                  errorText="Enter valid email "
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                  initialValue={loadedContact.email}
                  initialValid={true}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer className="text-muted">
          <Button variant="primary" onClick={saveUpdate} disabled={!formState.isValid}>
            Update
          </Button>
          <Button variant="outline-secondary" onClick={() => { window.history.back() }}>Cancel</Button>
        </Card.Footer>
      </Card>}
    </React.Fragment>
  );
};

export default UpdateContact;

import React from 'react';

export default class PartnerLogin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loader: true
		}
	}

	componentDidMount() {
		let token = this.props.location.search.split('?token=')[1];
		fetch(process.env.REACT_APP_API_URL + '/check-partner', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ token: token })
		}).then(data => data.json())
			.then(data => {
				window.location.href = '/dashboard';
			}).catch(err => {
				window.location.href = '/dashboard';
				console.log(err)
			})
	}

	render() {
		return (
			<div className="col-md-12 partner-login">
				<div className="col-md-12 partner-login-container">
					{this.state.loader ? (
						<div className="col-md-12 loader-container">
							<div className="loader-msg">Please wait while we redirect...</div>
							<i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
						</div>
					) : (null)}
				</div>
			</div>
		);
	}
}

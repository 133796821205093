/** @format */

import $ from "jquery";
import moment from "moment";
import Swal from "sweetalert2";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  Row,
  // Button, Modal
} from "react-bootstrap";
import {
  DatePicker,
  // Steps, Icon, Switch,
  Select,
} from "antd";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider from "react-bootstrap-table2-toolkit"; // { Search } // ,
import BasicDDFormComponent from "./DDFormSections/Basic.js";
import StabilityAndBackgroundDDFormComponent from "./DDFormSections/StabilityAndBackground.js";
import BusinessInformationDDFormComponent from "./DDFormSections/BusinessInformation.js";
import LoanAndFinancialInfoDDFormComponent from "./DDFormSections/LoanAndFinancialInfo.js";
import Eligibility from "./DDFormSections/Eligibility.js";
import ExistingEnterpriseRelated from "./DDFormSections/ExistingEnterpriseRelated.js";

const readCookie = require("../cookie.js").readCookie;

const { MonthPicker } = DatePicker;
const {
  Option,
  // OptGroup
} = Select;

const INITIAL_ELIGIBILITY_STATUS = {
  setUp: "",
  familyTakenPmfLoan: "",
  businessIdea: "",
  educationalCertificate: "",
  purposeOfLoan: "",
  cibilScore: "",
  lastYearTurnOver: "",
  loanHistory: "",
  WillingToGiveMargin: "",
  cibilReportDocument: "",
  whatKindOfCollateral: "",
  CalculateEmi: "",
  quotationForMachinery: "",
  StatusOfPlaceOfUnit: "",
  firstBankIFSC: "",
  secondBankIFSC: "",
  bankAccountIsActive: "",
  bankDistanceFromLocation: "",
  relationWithPrimaryBank: "",
  candidateVisitingBankWhenRequired: "",
  comfortableWithBankVisit: "",
  haveActiveFSSAI: "",
};

export default class DueDiligenceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseReqd: false,
      skillTrainingRecommended: false,
      guarantorDetailsColumns: [
        {
          text: "Sl. No.",
          sort: true,
          export: true,
          dataField: "index",
          searchable: false,
          headerStyle: () => {
            return { width: "50px" };
          },
        },
        {
          text: "Name",
          sort: true,
          export: true,
          dataField: "name",
          searchable: true,
          headerStyle: () => {
            return { width: "160px" };
          },
          formatter: this.emptyFormatter.bind(this, "Name"),
        },
        {
          text: "Phone",
          sort: true,
          export: true,
          dataField: "phone",
          searchable: true,
          headerStyle: () => {
            return { width: "120px" };
          },
          formatter: this.emptyFormatter.bind(this, "Phone"),
        },
        {
          text: "Relationship",
          sort: true,
          export: true,
          dataField: "relationship",
          editor: {
            type: Type.SELECT,
            options: [
              { value: "Spouse", label: "Spouse" },
              { value: "Sibling", label: "Sibling" },
              { value: "Father", label: "Father" },
              { value: "Mother", label: "Mother" },
              { value: "Other Relative", label: "Other Relative" },
              { value: "Friend", label: "Friend" },
            ],
          },
          searchable: true,
          headerStyle: () => {
            return { width: "100px" };
          },
          formatter: this.emptyFormatter.bind(this, "Relationship"),
        },
      ],
    };
  }

  componentDidMount = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        "/api/v1/bussinessIdeas/get-details-for-idea?combine=" +
        encodeURIComponent(this.props.businessIdea),
      {
        method: "GET",
        headers: {
          "x-auth-token": JSON.parse(readCookie("access_token")),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          this.setState({
            skillTrainingRecommended: data.idea?.skillTrainingReqd,
            licenseReqd: data.idea?.licenseReqd,
          });
        }
      });
  };

  scrollToNext = () => {
    $(".page-container.single-lead-page").animate(
      { scrollTop: $(".page-container.single-lead-page").scrollTop() + 60 },
      400
    );
  };

  promptCurrentMonthlyIncome = () => {
    let currentMonthlySales =
        this.props.dueDiligence.questions.currentMonthlySales || 0,
      currentMonthlyCosts =
        this.props.dueDiligence.questions.currentMonthlyCosts || 0,
      icon = "info";

    let currentMonthlyIncome = currentMonthlySales - currentMonthlyCosts;
    if (currentMonthlyIncome < 0) icon = "warning";

    Swal.fire(
      "Are you sure?",
      "The Current Monthly Income is INR " +
        String(currentMonthlyIncome)
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      icon
    );
  };

  afterSaveGuarantorCell = (oldValue, newValue, row, column) => {
    console.log(oldValue, newValue, row, column);
  };

  emptyFormatter = (type, cell, row) => {
    if (cell) {
      return cell;
    } else {
      return <div className='empty-container'>{type}</div>;
    }
  };

  render() {
    const INITIAL_DD_STATUS = {
      basic: {},
      businessInfo: {},
      eligibility: {},
      existingenterpriserelated: {},
      loanandfinancialinfo: {},
      stabilityAndBackground: {},
    };
    return (
      <Row className='due-diligence-form'>
        {/* {JSON.parse(readCookie("project_id")) === "64633346af3f097d201c2f1e" || JSON.parse(readCookie("project_id")) === "645e309d82bcc778c0d873c7" ? <> */}
        {["64633346af3f097d201c2f1e", "645e309d82bcc778c0d873c7"].includes(
          readCookie("project_id")
        ) ? (
          <>
            <BasicDDFormComponent
              leadData={this.props.leadData}
              addGuarantor={this.props.addGuarantor}
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <Eligibility
              leadData={this.props.leadData}
              addGuarantor
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              globalDDFormState={
                {
                  eligibility: INITIAL_ELIGIBILITY_STATUS,
                  ...this.props.stages.dueDiligence.questions,
                } || INITIAL_DD_STATUS
              }
            />
            <StabilityAndBackgroundDDFormComponent
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <BusinessInformationDDFormComponent
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <ExistingEnterpriseRelated
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              // globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              // globalDDFormState={{} || globalDDFormState}
            />
          </>
        ) : (
          <>
            <BasicDDFormComponent
              leadData={this.props.leadData}
              addGuarantor={this.props.addGuarantor}
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <StabilityAndBackgroundDDFormComponent
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <BusinessInformationDDFormComponent
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <LoanAndFinancialInfoDDFormComponent
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              //   globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              //   globalDDFormState={{} || globalDDFormState}
            />
            <ExistingEnterpriseRelated
              leadData={this.props.leadData}
              addGuarantor
              stages={this.props.stages}
              dueDiligenceChange={this.props.dueDiligenceChange}
              isEditable={this.props.isEditable}
              saveToProfile={this.props.saveToProfile}
              showHoldPrompt={this.props.showHoldPrompt}
              showDisqualificationPrompt={this.props.showDisqualificationPrompt}
              // globalDDFormState={{}}
              globalDDFormState={
                this.props.stages.dueDiligence.questions || INITIAL_DD_STATUS
              }
              // globalDDFormState={{} || globalDDFormState}
            />
          </>
        )}
      </Row>
    );
  }
}

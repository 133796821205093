import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Tracker from "./tracker";
import BootstrapTable from "react-bootstrap-table-next";
import { Tag, Spin } from "antd";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHttpHook } from "../../training-center/hooks/http-hook";
import "../../training-center/components/FormElements/button.css";
import { EditTwoTone } from "@ant-design/icons";
import Updatetracker from "./updatetracker";

const AddRow = () => {
  const [tracker, setTracker] = useState(false);
  const { isLoading,
    // error,
    sendRequest,
    // clearError
  } = useHttpHook();
  const [data, setData] = useState();
  const [count, setCount] = useState();
  const [update, setUpdate] = useState(false);
  const [updateData, setUpdateData] = useState();

  const editData = async (cell) => {
    if (cell) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
          `/api/v1/follow-up-tracker/follow-update/${cell}`
        );
        setUpdateData(responseData.tracker);
        setUpdate(true);
      } catch (error) { }
    }
  };

  const buttonFormatter = (cell, row, index) => {
    if (cell) {
      return (
        <React.Fragment>
          <EditTwoTone
            onClick={() => {
              editData(cell);
            }}
          />
        </React.Fragment>
      );
    }
  };

  const sentimentFormatter = (cell) => {
    return (
      <Tag
        color={
          !isLoading && data !== undefined && cell === "Positive"
            ? "lightGreen"
            : cell === "Negative"
              ? "#F68C6F"
              : cell === "Neutral"
                ? "lightblue"
                : "lightgrey"
        }
      >
        {!isLoading && data !== undefined && cell}
      </Tag>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Edit",
      formatter: buttonFormatter,
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
    {
      dataField: "followUpDate",
      text: "Follow up-date",
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "followedby",
      text: "Followed up-by",
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "mode",
      text: "Mode",
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "sentiment",
      text: "Overall Sentiment",
      formatter: sentimentFormatter,
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "comments",
      text: "Comments,Contact details,Next steps",
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "nextfollowUp",
      text: "Next Follow up-date",
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: "center",
      headerStyle: () => {
        return { width: "160px", textAlign: "center" };
      },
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + "/api/v1/follow-up-tracker/follow-up/"
        );
        console.log(responseData);
        setData(responseData.tracker);
        setCount(responseData.tracker.length);
      } catch (err) {
        //   alert(err + " " +error);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Button
          style={{
            float: "right",
            position: "absolute",
            top: "10px",
            left: "50rem",
          }}
          bsPrefix="design-center-button"
          onClick={() => {
            setTracker(true);
          }}
        >
          ADD A NEW FOLLOW-UP
        </Button>

        <br />
        {update && (
          <Updatetracker onHide={() => setUpdate(false)} data={updateData} />
        )}

        {tracker && <Tracker onHide={() => setTracker(false)} />}
        <div
          style={{
            marginLeft: "16rem",
            marginTop: "10rem",
            marginRight: "1.5rem",
          }}
        >
          {!isLoading && data !== undefined && (
            <>
              <Button>Total Count : {count}</Button>
              <BootstrapTable
                keyField="id"
                bootstrap4
                data={data}
                columns={columns}
                striped
                hover
                deleteRow
                condensed
                wrapperClasses="table-responsive"
                pagination={paginationFactory()}
              />
            </>
          )}
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default AddRow;

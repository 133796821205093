import React, { useState, useEffect } from "react";
import Contactlist from "./contactList";
import ContactDetail from "./ContactDetail";
import { Spinner } from "react-bootstrap";
import { useHttpHook } from "../../hooks/http-hook";
// import Swal from "sweetalert2";

const ContactPageShow = (props) => {
  const centerId = props.match.params.centerId;
  const [loadedContact, setLoadedContact] = useState();
  const [showCard, setCard] = useState(false);
  const { isLoading,
    // error,
    sendRequest, clearError } = useHttpHook();

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
          `/api/v1/center/contact/view/${centerId}`
        );
        setLoadedContact(responseData.contact);
        // console.log(responseData.contact);
      } catch (err) { }
    };
    fetchPlaces();
  }, [sendRequest, centerId]);

  const centerDeleteHandler = (deletecontactId) => {
    setLoadedContact((prevCenter) =>
      prevCenter.filter((contact) => contact.id !== deletecontactId)
    );
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Spinner
          animation="grow"
          variant="secondary"
          style={{ marginLeft: "40rem" }}
        />
      )}
      {!isLoading && { clearError } && (
        <Contactlist
          items={loadedContact}
          onShow={() => {
            setCard(true);
          }}
          onDeleteContact={centerDeleteHandler}
        />
      )}
      {showCard ? (
        <ContactDetail
          onHide={() => {
            setCard(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
export default ContactPageShow;

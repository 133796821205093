import React, { Component } from "react";
const readCookie = require("../cookie.js").readCookie;

export default class EnterpriseComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allComments: props.allComments,
      rowDetails: props.rowDetails,
      userData: props.userData,
      formattedDate: "",
    };
  }

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedDate = date
      .toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      })
      .replace(/\//g, "-");
    return `${formattedTime} | ${formattedDate}`;
  };

  componentDidMount() {
    // this.getAllComments();
  }

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        {this.props.allComments.length ? (
          <div>
            {" "}
            {this.props.allComments.map((comment, index) => {
              return (
                <div key={index}>
                  <div className="d-flex" style={{ marginTop: "20px" }}>
                    {comment.updatedBy.profilePicture ? (
                      <img
                        style={{
                          width: "35px",
                          height: "35px",
                          marginTop: "6px",
                          borderRadius: "20px",
                        }}
                        src={comment.updatedBy.profilePicture}
                      />
                    ) : (
                      <img
                        style={{
                          width: "35px",
                          height: "35px",
                          marginTop: "6px",
                          borderRadius: "20px",
                        }}
                        src="/images/user_1.png"
                        className="img-circle"
                        alt="userImg"
                      />
                    )}

                    <form style={{ width: "90%" }}>
                      <input
                        disabled
                        style={{
                          border: "1px solid 	rgb(227 227 227))",
                          width: "100%",
                          height: "49px",
                          borderRadius: "10px",
                          marginLeft: "15px",
                          paddingLeft: "16px",
                        }}
                        value={comment.comment}
                      />
                    </form>
                  </div>
                  <div className="d-flex">
                    {comment.documents.map((eachDoc, index) => {
                      return (
                        <div>
                          <div
                            className="d-inline-flex p-2"
                            style={{
                              borderRadius: "6px",
                              width: "fit-content",
                              minWidth: "15%",
                              border: "2px solid rgb(227 227 227)",
                              marginLeft: "48px",
                              marginTop: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <a href={eachDoc.fileUrl} target="_blank">
                              {" "}
                              {eachDoc.type}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    <div className="ml-auto" style={{ marginTop: "10px" }}>
                      {" "}
                      {this.formatDate(comment.createdAt)}
                      <br />
                      <a
                        href={
                          "/user-types/" +
                          this.props.userData.userType.name +
                          "/" +
                          comment.updatedBy._id
                        }
                        target="_blank"
                      >
                        {comment.updatedBy.fullName}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}{" "}
          </div>
        ) : null}
      </div>
    );
  }
}

import React
  // , { useState } 
  from "react";
import { Container } from "react-bootstrap";
import "./trainingList.css";
import Dataloader from './dataloader';



const Traininglist = (props) => {


  if (props.items === undefined) {
    return (
      <h2
        style={{
          position: "absolute",
          left: "45rem",
          top: "30rem",
          color: "#D3D3D3",
        }}
      >
        No Data Found
      </h2>
    );
  }

  return (
    <Container>
      {props.items.map((data) => (
        <Dataloader
          key={data.id}
          id={data.id}
          program={data.program}
          date={data.date}
          onDelete={props.onDeleteTraining}
        />
      ))}
    </Container>
  );
};

export default Traininglist;

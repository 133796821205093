import { Spin } from "antd";
import React, {
	useState,
	// useEffect
} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
	// Col,
	Row,
	// Button, Modal
} from "react-bootstrap";
import { useHttpHook } from "../components/hooks/http-hook.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;

const FollowUpAnalytics = () => {
	const [analytics, setAnalytics] = useState([]);
	const [analyticsLoading, setAnalyticsLoading] = useState(true);

	let columns = [
		{ text: "Swawalamban Fellow Name", sort: false, hidden: false, export: true, align: "center", dataField: "fullName", searchable: true, headerStyle: () => { return { width: "70px", textAlign: "center" } } },
		{ sort: false, export: true, hidden: false, text: "Overdue", searchable: false, align: "center", dataField: "overDue", headerStyle: () => { return { width: "30px", textAlign: "center" } } },
		{ sort: false, export: true, hidden: false, text: "Due Today", searchable: false, align: "center", dataField: "dueToday", headerStyle: () => { return { width: "30px", textAlign: "center" } } },
		{ sort: false, export: true, hidden: false, text: "Due This Week", searchable: false, align: "center", dataField: "dueThisWeek", headerStyle: () => { return { width: "30px", textAlign: "center" } } }
	];
	const {
		// isLoading,
		// error,
		sendRequest,
		// clearError
	} = useHttpHook();
	React.useEffect(() => {
		const fetchAnalytics = async () => {
			try {
				const responseData = await sendRequest(process.env.REACT_APP_API_URL + "/api/v1/followup-counts/analytics");
				setAnalytics(responseData.followUpAnalytics);
				setAnalyticsLoading(false);
			} catch (err) { }
		};
		fetchAnalytics();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<div className="page-container follow-up-analytics-table-container">
				<ToolkitProvider keyField="id" data={analytics} columns={columns} search>
					{(props) => (
						<div>
							<Row className="follow-up-analytics-table-search">
								<SearchBar style={{ width: '100%' }} placeholder="Search by Swawalamban fellow name" {...props.searchProps} />
							</Row>

							<Row className="follow-up-analytics-table justify-content-center">
								<BootstrapTable {...props.baseProps} />
								{(analytics.length === 0 && !analyticsLoading) ? <Row>No data to show</Row> : (null)}
								<div className="center-spinner">
									<Spin spinning={analyticsLoading} delay={200} size="large" />
								</div>
							</Row>
						</div>
					)}
				</ToolkitProvider>
			</div>
		</React.Fragment>
	);
};

export default FollowUpAnalytics;

import React from "react";
import {
    Col, Row, Button,
    // Modal
} from 'react-bootstrap';
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from // , { Search }
    "react-bootstrap-table2-toolkit";
import $ from "jquery";
import { Steps, Icon, } from "antd";

const { Step } = Steps;

export default class AdvancedDetails extends React.Component{
    constructor(props) { 
        super(props);
        this.state = {
            leadData: this.props.leadData,
            currentStep: this.props.currentStep ,
            generatedThrough: this.props.generatedThrough ,
            businessIdeasColumns: this.props.businessIdeasColumns ,

        }
    }

    gotoStep = (hash) => {
        $(".page-container.single-lead-page").animate(
            {
                scrollTop: $(hash).offset().top - 55,
            },
            800
        );
    };

    render() {
        const StepNav = () => 
            <Steps size="small" current={this.state.currentStep}>
                {/* <Step
                        title={
                            <a onClick={this.gotoStep.bind(this, "#basic")}>Basic</a>
                        }
                        icon={<Icon type="idcard" />}
                    /> */}
                <Step
                    title={
                        <a onClick={this.gotoStep.bind(this, "#businessIdea")}>
                            Business Details
                        </a>
                    }
                    icon={<Icon type="shop" />}
                />
                {this.state.leadData.enterpriseStatus.phase === "Scale-up" ? <Step
                    title={
                        <a onClick={this.gotoStep.bind(this, "#existingBusinessDetails")}>
                            Existing Business Details
                        </a>
                    }
                    icon={<Icon type="shop" />}
                /> : null}
                <Step
                    title={
                        <a
                            onClick={this.gotoStep.bind(
                                this,
                                "#individualFamilyDetails"
                            )}
                        >
                            Individual/Family Details
                        </a>
                    }
                    icon={<Icon type="file-text" />}
                />
                <Step
                    title={
                        <a onClick={this.gotoStep.bind(this, "#generalDetails")}>
                            Other General Details
                        </a>
                    }
                    icon={<Icon type="bank" />}
                />
            </Steps>;
        
        return <>
            {/* <Col md={12} className="mt10 mb10">
                <StepNav/>
            </Col> */}
            <h2 id="businessIdea" className="step-heading">
                BUSINESS DETAILS
            </h2>
            <Row className="mt10 lead-data-container lead-financial">
                {this.state.leadData.enterpriseStatus.phase ? (
                    <Col md={4} sm={6} xs={6}>
                        <label className="heading control-label">Phase</label>
                        <span>{this.state.leadData.enterpriseStatus.phase === 'Start-up' ? 'Wants to start a new business' : 'Runs a business, Wants to grow it'}</span>
                    </Col>
                ) : null}
                {this.state.leadData.enterpriseStatus.hasABusinessIdea !==
                    null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Has Business Idea
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.hasABusinessIdea}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.phase === "Scale-up" ? (
                    <Col md={4} sm={6} xs={6}>
                        <label className="heading control-label">
                            Taken business loan before
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.creditLinked ? "Yes" : "No"}
                        </span>
                    </Col>
                ) : null}
                
                {this.state.leadData.enterpriseStatus.businessIdeas !== null &&
                    this.state.leadData.enterpriseStatus.businessIdeas.length ? (
                    <Col md={12}>
                        <label className="heading control-label">
                            Business Ideas
                        </label>
                        <ToolkitProvider
                            keyField="_id"
                            data={this.state.leadData.enterpriseStatus.businessIdeas}
                            columns={this.state.businessIdeasColumns}
                        >
                            {(props) => (
                                <div>
                                    <BootstrapTable
                                        id="business-ideas-table"
                                        {...props.baseProps}
                                        ref={(table) => {
                                            this.businessIdeasTable = table;
                                        }}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.financeType ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Finance Status
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.financeType}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus
                    .canSelfFinanceTheCapitalRequiredUpToINR ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Self investment amount (INR)
                        </label>
                        <span>
                            {
                                this.state.leadData.enterpriseStatus
                                    .canSelfFinanceTheCapitalRequiredUpToINR
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.loanAmountRequired ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Loan amount required (INR)
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.loanAmountRequired}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus
                    .appliedForEnterpriseLoanBefore !== null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Applied for Enterprise Loan Earlier
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus
                                .appliedForEnterpriseLoanBefore
                                ? "Yes"
                                : "No"}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.loanProviderType ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Loan Provider Type
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.loanProviderType}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.loanProviderType ===
                    "Banks" &&
                    this.state.leadData.enterpriseStatus.bankName &&
                    this.state.leadData.enterpriseStatus.bankName.length ? (
                    <Col md={4}>
                        <label className="heading control-label">Bank Names</label>
                        <span>
                            {this.state.leadData.enterpriseStatus.bankName.join(", ")}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.statusOfEnterpriseLoan ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Status of latest enterprise loan
                        </label>
                        <span>
                            {
                                this.state.leadData.enterpriseStatus
                                    .statusOfEnterpriseLoan
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.enterpriseStatus.loanSizeCategory ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Loan Size Category
                        </label>
                        <span>
                            {this.state.leadData.enterpriseStatus.loanSizeCategory}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset
                    .ancestralHistoryOfWorkingInWhichSector ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Ancestral History of working in sector
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .ancestralHistoryOfWorkingInWhichSector
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.familyBackground ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Family background (in the same skill)
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.familyBackground}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.experienceInSector ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Experience (familiarity) in the sector of selected
                            business (in years)
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.experienceInSector}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.leadMaturity.skillTrained
                    .skillTrainedStatus ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Skill Training Status
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.skillTrained
                                    .skillTrainedStatus
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "Skill training ongoing",
                    "Skill training completed and has certificate",
                    "Skill training completed but no certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.skillTrained
                        .skillTrainedStatus
                ) > -1 ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Skill Training Institute Type
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.skillTrained
                                    .instituteType
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "Skill training ongoing",
                    "Skill training completed and has certificate",
                    "Skill training completed but no certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.skillTrained
                        .skillTrainedStatus
                ) > -1 ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Skill Training Institute Name
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.skillTrained
                                    .instituteName
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "Skill training ongoing",
                    "Skill training completed and has certificate",
                    "Skill training completed but no certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.skillTrained
                        .skillTrainedStatus
                ) > -1 &&
                    this.state.leadData.leadMaturity.skillTrained.instituteLocation
                        .label ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Skill Training Institute Location
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.skillTrained
                                    .instituteLocation.label
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.leadMaturity.enterpriseTrained
                    .enterpriseTrainedStatus ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Enterprise Development Program (EDP)
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.enterpriseTrained
                                    .enterpriseTrainedStatus
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "EDP ongoing",
                    "Completed EDP with certificate",
                    "Completed EDP without certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.enterpriseTrained
                        .enterpriseTrainedStatus
                ) > -1 ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            EDP Institute Type
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.enterpriseTrained
                                    .instituteType
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "EDP ongoing",
                    "Completed EDP with certificate",
                    "Completed EDP without certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.enterpriseTrained
                        .enterpriseTrainedStatus
                ) > -1 ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            EDP Institute Name
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.enterpriseTrained
                                    .instituteName
                            }
                        </span>
                    </Col>
                ) : null}

                {[
                    "EDP ongoing",
                    "Completed EDP with certificate",
                    "Completed EDP without certificate",
                ].indexOf(
                    this.state.leadData.leadMaturity.enterpriseTrained
                        .enterpriseTrainedStatus
                ) > -1 &&
                    this.state.leadData.leadMaturity.enterpriseTrained
                        .instituteLocation.label ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            EDP Institute Location
                        </label>
                        <span>
                            {
                                this.state.leadData.leadMaturity.enterpriseTrained
                                    .instituteLocation.label
                            }
                        </span>
                    </Col>
                ) : null}
            </Row>


            {this.state.leadData.enterpriseStatus.phase === "Scale-up" ? <>       <h2 id="existingBusinessDetails" className="step-heading">
                EXISTING BUSINESS DETAILS
            </h2>
                <Row className="mt10 lead-data-container lead-advanced">
                    {/* // , registrationProof, hasGstRegistration, gstCertMonthYear, gstFilingMeans, filesIT, proofOfSalesAndPurchase, issuesCheques, businessBankAccountType, bankAccountActive, averageMonthlyTxnSize,
		          // maintainAccountBooks, businessPremisesOwnership, monthlyRent, premisesArea, salesFigures */}
                    {/* // ,  ,  , , , ,  , ,
		          // , , , ,  */}
                    {this.state.leadData?.existingBusinessDetails?.businessStartMonth ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Month/Year of starting business
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.businessStartMonth}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.salesFigures ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                {/* Sales figures (INR) */}
                                {moment().diff(moment(`01/${this.state.leadData?.existingBusinessDetails?.businessStartMonth}`, `DD/MM/YYYY`), 'days') > 365 ?
                                    "Last year's sale (INR)" : "Lifetime revenue from the date of start till date (INR)"
                                }                    </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.salesFigures} <u><note>as on {this.state.leadData?.existingBusinessDetails?.salesFigureUpdated}
                                </note></u></span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.registrationProof ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Proof of registration
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.registrationProof.join(', ')}
                            </span>
                        </Col>
                    ) : <Col md={4}>
                        <label className="heading control-label">
                            Proof of registration
                        </label>
                        <span>
                            None
                        </span>
                    </Col>}

                    {this.state.leadData?.existingBusinessDetails?.hasGstRegistration ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Has GST Registration?
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.hasGstRegistration}
                            </span>
                        </Col>
                    ) : null}

                    {this.state.leadData?.existingBusinessDetails?.gstCertMonthYear ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Month/Year of GST Certificate
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.gstCertMonthYear}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.gstFilingMeans ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                How do you file your GST returns?
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.gstFilingMeans}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.filesIT ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Files income tax and has IT returns?
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.filesIT}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.proofOfSalesAndPurchase ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Proof of sales and purchase
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.proofOfSalesAndPurchase}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.issuesCheques ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Issues cheques to suppliers
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.issuesCheques}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.businessBankAccountType ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Bank account used for managing business transactions
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.businessBankAccountType}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.bankAccountActive ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Account been active in 6 months? (If there is any customer-induced debit transaction in 6 months, the account is considered as active.)
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.bankAccountActive}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.averageMonthlyTxnSize ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Average monthly transaction size (sum of deposits & withdrawals)
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.averageMonthlyTxnSize}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.maintainAccountBooks ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Maintains book of accounts
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.maintainAccountBooks}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.businessPremisesOwnership ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Ownership of business premises
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.businessPremisesOwnership}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.monthlyRent ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Monthly rent (INR)
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.monthlyRent}
                            </span>
                        </Col>
                    ) : null}
                    {this.state.leadData?.existingBusinessDetails?.premisesArea ? (
                        <Col md={4}>
                            <label className="heading control-label">
                                Area of business premises (in sq.ft.)
                            </label>
                            <span>
                                {this.state.leadData?.existingBusinessDetails?.premisesArea}
                            </span>
                        </Col>
                    ) : null}
                </Row></> : null}
            <h2 id="individualFamilyDetails" className="step-heading">
                INDIVIDUAL/FAMILY DETAILS
            </h2>
            <Row className="mt10 lead-data-container lead-advanced">
                {/* {this.state.leadData.basic.educationalQualification ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Educational Qualification
                        </label>
                        <span>
                            {this.state.leadData.basic.educationalQualification}
                        </span>
                    </Col>
                ) : null} */}

                {this.state.leadData.basic.specializationOfEducation ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Specialization Of Education
                        </label>
                        <span>
                            {this.state.leadData.basic.specializationOfEducation}
                        </span>
                    </Col>
                ) : null}

                {/* {this.state.leadData.basic.religion ? (
                    <Col md={4}>
                        <label className="heading control-label">Religion</label>
                        <span>{this.state.leadData.basic.religion}</span>
                    </Col>
                ) : null} */}

                {/* {this.state.leadData.basic.category ? (
                    <Col md={4}>
                        <label className="heading control-label">Category</label>
                        <span>{this.state.leadData.basic.category}</span>
                    </Col>
                ) : null}

                {this.state.leadData.basic.pwd ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Is a person with disability (PWD)
                        </label>
                        <span>{this.state.leadData.basic.pwd}</span>
                    </Col>
                ) : null} */}

                {this.state.leadData.basic.employmentStatus ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Employment Status
                        </label>
                        <span>{this.state.leadData.basic.employmentStatus}</span>
                    </Col>
                ) : null}

                {this.state.leadData.basic.employmentStatus === "Employed" &&
                    this.state.leadData.basic.employer ? (
                    <Col md={4}>
                        <label className="heading control-label">Employer</label>
                        <span>{this.state.leadData.basic.employer}</span>
                    </Col>
                ) : null}

                {this.state.leadData.basic.currentAnnualIncome ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Current Annual Income (Personal)
                        </label>
                        <span>{this.state.leadData.basic.currentAnnualIncome}</span>
                    </Col>
                ) : null}

                {this.state.leadData.basic.experience !== "" ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Total Work Experience (in years)
                        </label>
                        <span>{this.state.leadData.basic.experience}</span>
                    </Col>
                ) : null}

                {/* {this.state.leadData.familyAndAsset.numberOfFamilyMember !==
                    null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Total number of family members
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.numberOfFamilyMember}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.numberOfDependents !==
                    null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Total number of dependents
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.numberOfDependents}
                        </span>
                    </Col>
                ) : null} */}

                {/* {this.state.leadData.familyAndAsset.backgroundAndStability ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Family Background and Stability
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .backgroundAndStability
                            }
                        </span>
                    </Col>
                ) : null} */}
                {this.state.leadData.basic.backgroundAndStability ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Family Background and Stability
                        </label>
                        <span>
                            {
                                this.state.leadData.basic
                                    .backgroundAndStability
                            }
                        </span>
                    </Col>
                ) : null}
                {/* {this.state.leadData.basic.dob ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Date Of Birth
                        </label>
                        <span>
                            {moment(this.state.leadData.basic.dob).format(
                                "DD/MM/YYYY"
                            )}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.basic.identityType &&
                    this.state.leadData.basic.identityType.length ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Identity Type
                        </label>
                        <span>
                            {this.state.leadData.basic.identityType.join(", ")}
                        </span>
                    </Col>
                ) : null} */}

                {/*this.state.leadData.basic.identityNumber ? (
									<Col md={4}>
										<label className="heading control-label">Identity Number</label>
										<span>{this.state.leadData.basic.identityNumber}</span>
									</Col>
								) : (null)*/}
            </Row>

            <h2 id="generalDetails" className="step-heading">
                OTHER GENERAL DETAILS
            </h2>
            <Row className="mt10 lead-data-container lead-background">
                {this.state.leadData.familyAndAsset.hasLand !== null && false ? (
                    <Col md={4}>
                        <label className="heading control-label">Owns Land?</label>
                        <span>
                            {this.state.leadData.familyAndAsset.hasLand
                                ? "Yes"
                                : "No"}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasLand &&
                    this.state.leadData.familyAndAsset.relationshipWithLandOwner ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Relationship to Land Owner
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .relationshipWithLandOwner
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasLand &&
                    this.state.leadData.familyAndAsset.landOwnerName ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Land Owner Name
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.landOwnerName}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasLand &&
                    this.state.leadData.familyAndAsset.area !== null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Area of Land
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.area.value +
                                " " +
                                this.state.leadData.familyAndAsset.area.unit}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasOtherAssets &&
                    this.state.leadData.familyAndAsset.hasOtherAssets.length && false ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Has Other Assets
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.hasOtherAssets.join(
                                ", "
                            )}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.collateral !== null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Willing to give collateral
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.collateral
                                ? "Yes"
                                : "No"}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasBankAccount !== null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Has Active Bank Account
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.hasBankAccount
                                ? "Yes"
                                : "No"}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasBankAccount &&
                    this.state.leadData.familyAndAsset.bankAccounts &&
                    this.state.leadData.familyAndAsset.bankAccounts.length ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Has Account in Bank(s)
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.bankAccounts.join(
                                ", "
                            )}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.hasBankAccount &&
                    this.state.leadData.familyAndAsset.proximityToBank ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            How far is the bank branch from your home?
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.proximityToBank}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan !==
                    null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Previous loan history
                        </label>
                        <span>
                            {typeof this.state.leadData.familyAndAsset
                                .receivedBankLoan === "string"
                                ? this.state.leadData.familyAndAsset.receivedBankLoan
                                : this.state.leadData.familyAndAsset.receivedBankLoan
                                    ? "Yes"
                                    : "No"}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan &&
                    this.state.leadData.familyAndAsset.receivedBankLoan !==
                    "Never taken a loan" &&
                    this.state.leadData.familyAndAsset.loanType ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Type of Loan
                        </label>
                        <span>{this.state.leadData.familyAndAsset.loanType}</span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan &&
                    this.state.leadData.familyAndAsset.receivedBankLoan !==
                    "Never taken a loan" &&
                    this.state.leadData.familyAndAsset.receivedLoanProviderType ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Loan Provider Type
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .receivedLoanProviderType
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan &&
                    this.state.leadData.familyAndAsset.receivedBankLoan !==
                    "Never taken a loan" &&
                    this.state.leadData.familyAndAsset.loansInstallmentStatus ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Loan Installment Status
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .loansInstallmentStatus
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan ===
                    "Loan taken and ongoing" ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Outstanding loan amount (INR)
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.outstandingLoanAmount}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.receivedBankLoan &&
                    this.state.leadData.familyAndAsset.receivedBankLoan !==
                    "Never taken a loan" &&
                    this.state.leadData.familyAndAsset.receivedLoanProviderType ===
                    "Banks" &&
                    this.state.leadData.familyAndAsset.loanBanks &&
                    this.state.leadData.familyAndAsset.loanBanks.length ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Has taken loan from Bank(s)
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.loanBanks.join(", ")}
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.genuinenessAndHumility ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Candidate's genuineness and humility
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .genuinenessAndHumility
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.familyAndAsset.confidenceAsSwavalambi ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Candidate's confidence as a Swavalambi
                        </label>
                        <span>
                            {
                                this.state.leadData.familyAndAsset
                                    .confidenceAsSwavalambi
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.leadScoreAndSentiment.overallSentiment ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Overall Sentiment
                        </label>
                        <span>
                            {
                                this.state.leadData.leadScoreAndSentiment
                                    .overallSentiment
                            }
                        </span>
                    </Col>
                ) : null}

                {this.state.leadData.leadScoreAndSentiment
                    .tentativeHorizonToCloseThis ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Eagerness to start the enterprise
                        </label>
                        <span>
                            {
                                this.state.leadData.leadScoreAndSentiment
                                    .tentativeHorizonToCloseThis
                            }
                        </span>
                    </Col>
                ) : null}
            </Row>
        </>
    }
}
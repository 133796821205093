/** @format */

import React from "react";
import Swal from "sweetalert2";
import { Select } from "antd";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Col,
  Row,
  // Button, Modal
} from "react-bootstrap";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider
	// ,
	// { Search }
	from 'react-bootstrap-table2-toolkit';
const { OptGroup, Option } = Select;
const LoanAndFinancialInfoDDFormComponent = ({
  leadData,
  projectData,
  globalDDFormState,
  setGlobalDDFormState,
  saveToProfile,
  isEditable,
  guarantorDetailsColumns,
  showHoldPrompt,
  guarantorDetailsTable,
  dueDiligenceChange,
  addGuarantor,
}) => {
  return (
    <>
      {leadData.enterpriseStatus.financeType ===
      "Bank Loan required" ? (
        <div className='bank-loan-reqd-container'>
          <h3>LOAN/FINANCIAL INFORMATION</h3>

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Would you visit the bank to seek the loan?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.visitBankForLoan}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "visitBankForLoan"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Col md={12} className='due-diligence-question same-line'>
            <div className='d-flex flex-column'>
              <label className='control-label'>Previous loan history</label>
              <note>
                To change this,{" "}
                <a
                  href={
                    "/edit-lead/" +
                   leadData.leadId +
                    "?scrollTo=received-bank-loan"
                  }
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  edit the profile
                </a>
                .
              </note>
            </div>
            {globalDDFormState.loanandfinancialinfo.receivedBankLoan &&
            typeof globalDDFormState.loanandfinancialinfo.receivedBankLoan === "string" ? (
              <input
                type='text'
                placeholder='Previous loan history'
                value={globalDDFormState.loanandfinancialinfo.receivedBankLoan}
                className={"form-control"}
                title='To change this, edit the profile.'
                disabled
              />
            ) : (
              <input
                type='text'
                placeholder='Previous loan history'
                value={
                  globalDDFormState.loanandfinancialinfo.receivedBankLoan ? "Yes" : "Never taken a loan"
                }
                className={"form-control"}
                title='To change this, edit the profile.'
                disabled
              />
            )}
          </Col>
          {globalDDFormState.loanandfinancialinfo.receivedBankLoan === "Loan taken and defaulted" ? (
            <Col md={12}>
              <button
                className='btn btn-default mark-as-disqualified-btn'
                onClick={globalDDFormState.loanandfinancialinfo.showDisqualificationPrompt}
                disabled={!isEditable}
              >
                MARK AS DISQUALIFIED
              </button>
            </Col>
          ) : null}
          {globalDDFormState.loanandfinancialinfo.receivedBankLoan === "Loan taken and ongoing" ? (
            <Col md={12}>
              <button
                className='btn btn-default put-on-hold-btn'
                onClick={(e)=>showHoldPrompt("dueDiligence")}
                disabled={!isEditable}
              >
                THE CANDIDATE CAN BE PUT ON HOLD
              </button>
            </Col>
          ) : null}

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Has taken credit card in the past?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.hasTakenCreditCard}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "hasTakenCreditCard"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Col md={12} className='due-diligence-question'>
            <label className='control-label'>
              What is the candidate's CIBIL (300-900) score? (Banking mechanisms
              are interlinked and if your previous track record is not good and
              CIBIL score is less than 700, the banker may reject your
              application.)
              <a
                href='https://www.crifhighmark.com'
                rel='noopener noreferrer'
                target='_blank'
              >
                https://www.crifhighmark.com
              </a>
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.isCibilKnown}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "isCibilKnown"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {[
                "Good (700-900)",
                "Average (500-700)",
                "Poor (Below 500)",
                "I don't know",
              ].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {globalDDFormState.loanandfinancialinfo.isCibilKnown ===
          "Poor (Below 500)" ? (
            <Col md={12}>
              <button
                className='btn btn-default mark-as-disqualified-btn'
                onClick={globalDDFormState.loanandfinancialinfo.showDisqualificationPrompt}
                disabled={!isEditable}
              >
                MARK AS DISQUALIFIED
              </button>
            </Col>
          ) : null}

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Will the candidate be able to supply quotations corresponding to
              the items purchased?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.willSupplyQuotations}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "willSupplyQuotations"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {[
                "Yes",
                "No but I will find within a week",
                "No I will take more time",
                "No I will not give quotations",
                "Quotation not required",
              ].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {globalDDFormState.loanandfinancialinfo.willSupplyQuotations ===
          "No I will not give quotations" ? (
            <Col md={12}>
              <button
                className='btn btn-default mark-as-disqualified-btn'
                onClick={globalDDFormState.loanandfinancialinfo.showDisqualificationPrompt}
                disabled={!isEditable}
              >
                MARK AS DISQUALIFIED
              </button>
            </Col>
          ) : null}
          {globalDDFormState.loanandfinancialinfo.willSupplyQuotations ===
          "No I will take more time" ? (
            <Col md={12}>
              <button
                className='btn btn-default put-on-hold-btn'
                onClick={(e)=>showHoldPrompt("dueDiligence")}
                disabled={!isEditable}
              >
                THE CANDIDATE CAN BE PUT ON HOLD
              </button>
            </Col>
          ) : null}

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Do you own any land (ancestral or otherwise) or other assets?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.ownLand}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "ownLand"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Col md={12} className='due-diligence-question same-line'>
            <div className='d-flex flex-column'>
              <label className='control-label'>
                Willing to give any collateral? (If the loan value is high, say
                above INR 2 lacs)
              </label>
              <note>Changing this will update the profile</note>
            </div>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              defaultValue={globalDDFormState.loanandfinancialinfo.willingToGiveCollateral}
              disabled={!isEditable}
              onChange={(e)=>saveToProfile(
                e,
                "willingToGiveCollateral"
              )}
            >
              <Option value='' disabled>
                Select Yes/No
              </Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Col>

          {globalDDFormState.loanandfinancialinfo.willingToGiveCollateral ? (
            <Col md={12} className='due-diligence-question same-line'>
              <label className='control-label'>Worth of the asset (INR)</label>
              <div className='inr-input'>
                <span className='inr-symbol'>₹</span>
                <input
                  type='text'
                  placeholder='Worth of the asset (INR)'
                  value={
                    globalDDFormState.loanandfinancialinfo.worthOfAsset
                      ? String(globalDDFormState.loanandfinancialinfo.worthOfAsset)
                          .replace(/\D/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : ""
                  }
                  className={"form-control"}
                  disabled={!isEditable}
                  onChange={(e)=>dueDiligenceChange(
                    e,
                    "loanandfinancialinfo",
                    // "questions",
                    "worthOfAsset"
                  )}
                />
              </div>
            </Col>
          ) : null}

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Does the candidate have PAN?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.candidateHasPAN}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "candidateHasPAN"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {globalDDFormState.loanandfinancialinfo.candidateHasPAN === "No" ? (
            <Col md={12}>
              <button
                className='btn btn-default put-on-hold-btn'
                onClick={(e)=>showHoldPrompt("dueDiligence")}
                disabled={!isEditable}
              >
                THE CANDIDATE CAN BE PUT ON HOLD
              </button>
            </Col>
          ) : null}

          <Col md={12} className='due-diligence-question'>
            <label className='control-label'>
              Does the candidate have any guarantor? (A guarantor agrees to
              repay the borrower's debt should the borrower default on agreed
              repayments. The guarantor is required to co-sign the credit
              agreement.)
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.candidateHasGuarantor}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "candidateHasGuarantor"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>

          {globalDDFormState.loanandfinancialinfo.candidateHasGuarantor === "Yes" ? (
            <Col md={12} className='due-diligence-question'>
              <label className='control-label'>
                Enter the name and your relationship with the guarantor
              </label>
              {globalDDFormState.loanandfinancialinfo.guarantorDetails ? (
                <ToolkitProvider
                  keyField='index'
                  data={globalDDFormState.loanandfinancialinfo.guarantorDetails}
                  columns={guarantorDetailsColumns}
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        id='guarantor-details-table'
                        {...props.baseProps}
                        ref={(table) => {
                          guarantorDetailsTable = table;
                        }}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          afterSaveCell: (e)=>dueDiligenceChange(
                            e,
                            "loanandfinancialinfo",
                            // "questions",
                            "guarantorDetails",
                            globalDDFormState.guarantorDetails
                          ),
                        })}
                      />
                      <Row className='justify-content-center'>
                        <button
                          className='btn btn-success react-bs-table-add-btn'
                            // onClick={globalDDFormState.loanandfinancialinfo.addGuarantor}
                            onClick={addGuarantor}
                          disabled={!isEditable}
                        >
                          <span>
                            <i className='fa fa-plus' aria-hidden='true'></i>
                            Add Guarantor
                          </span>
                        </button>
                      </Row>
                    </div>
                  )}
                </ToolkitProvider>
              ) : null}
            </Col>
          ) : null}

          <Col
            md={12}
            className='due-diligence-question same-line'
            title='To change this, edit the profile.'
          >
            <div className='d-flex flex-column'>
              <label className='control-label'>
                In which banks do you have accounts?
              </label>
              <note>
                To change this,{" "}
                <a
                  href={
                    "/edit-lead/" +
                    leadData.leadId +
                    "?scrollTo=bank-accounts"
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  edit the profile
                </a>
                .
              </note>
            </div>
            <Select
              size='large'
              mode='multiple'
              style={{ width: "100%" }}
              value={globalDDFormState.loanandfinancialinfo.bankAccounts}
              disabled
            ></Select>
          </Col>

          <Col md={12} className='due-diligence-question same-line'>
            <label className='control-label'>
              Is your bank account active since the last 6 months?
            </label>
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder='Select'
              value={globalDDFormState.loanandfinancialinfo.bankAccountIsActive}
              disabled={!isEditable}
              onChange={(e)=>dueDiligenceChange(
                e,
                "loanandfinancialinfo",
                // "questions",
                "bankAccountIsActive"
              )}
            >
              <Option value='' disabled>Select Option</Option>
              {["Yes", "No"].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </div>
      ) : null}
    </>
  );
};

export default LoanAndFinancialInfoDDFormComponent;

import React, { Component } from 'react';
import {
    // Steps,
    Select,
    Collapse,
    Switch,
    // Icon
} from "antd";
import {
    Tabs, Tab, Col, Row, Button,
    Modal
    // , Button, Modal 
} from "react-bootstrap";
// import $ from "jquery";
import { Link } from 'react-router-dom';
// import './AddEditUser.css';
import {
    CaretLeftFilled,
} from '@ant-design/icons';

import { toast } from 'react-toastify';

// Tabs
import GeneralDetailsTab from './Profile-Tabs/GeneralDetails';
import CandidatesTab from './Profile-Tabs/Candidates';
import InteractionsTab from './Profile-Tabs/Interactions';

const readCookie = require('../cookie.js').readCookie;
// const { Step } = Steps;

const { Option, OptGroup } = Select;
const { Panel } = Collapse;

export default class BankProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // ifsc: '',
            bankDetails: {},
            bankStats: {},
            bankLeads: {},
            editingLead: false,
            pageLoading: true,
            managerDetails: null,
            editBranch: null,
            selectedCities: [],
            selectedDistricts: [],
            allBanks: [],
            allStates: [],
            // selectedDistricts: [],
            // selectedCities: [],
            focusStates: ["Bihar", "Jharkhand", "Odisha", "Telangana", "Uttar Pradesh"],
            loanInteractionModal: false,

        }
    }

    componentDidMount() {
        let userData = JSON.parse(readCookie('userData'));
        let userType = JSON.parse(readCookie('userData')).userType.name;
        if (userType === 'Swavalamban Fellows') {
            this.setState({
                allStates: userData.state.map(x => { return { name: x, inFocusRegion: true } }),
                filterData: { ...this.state.filterData, state: userData.state[0] },
                allDistricts: userData.district.map(x => { return { name: x } })
            });
        }
        else if (userType === 'Zonal Manager') {
            this.setState({
                allStates: userData.state.map(x => { return { name: x, inFocusRegion: true } }),
                filterData: { ...this.state.filterData, state: userData.state[0] },
                // allDistricts: userData.district
            });
        }
        else if (userType === 'Project Management Unit') {
            fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
                method: 'GET',
                headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token'))
                }
            }).then(data => data.json())
                .then(data => {
                    if (data.status) {
                        this.setState({ allStates: data.data.states });
                    }
                });
        }
        const { search, openModal } = this.props.location;
        // const name = new URLSearchParams(search).get("openModal");
        const searchArr = search.split('=');
        // const openModal = this.props.location
        if ((searchArr && searchArr.length >= 2 && [true, 'true'].indexOf(searchArr[1]) > -1)
            || [true, 'true'].indexOf(openModal) > -1) {
            this.setState({ loanInteractionModal: true });
        }
        let ifsc = this.props.match.params.ifsc;
        this.getAllBanks();
        if (ifsc) {
            this.getBankDetails(ifsc);
        }
    }


    // gotoStep = (hash) => {
    //     $(".page-container.single-lead-page").animate(
    //         {
    //             scrollTop: $(hash).offset().top - 55,
    //         },
    //         800
    //     );
    // };
    getAllBanks = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/banks', {
            method: 'GET',
            headers: {
                'x-auth-token': JSON.parse(readCookie('access_token'))
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    this.setState({ allBanks: data.allBanks });
                }
            });
    }
    hideEditingBranch = () => {
        this.setState({ editBranch: null, ifscFound: false, selectedCities: [], selectedDistricts: [] });
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    updateEditingBranch = (type, value) => {
        let editBranch = this.state.editBranch;
        if (value.target) value = value.target.value;
        if (type === "status") {
            if (value) value = "ACTIVE";
            else value = "INACTIVE";
        }
        editBranch[type] = value;
        if (type === "state") {
            editBranch["district"] = "";
            editBranch["city"] = "";
            if (this.state.focusStates.indexOf(value) > -1) {
                fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + value, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': JSON.parse(readCookie('access_token'))
                    }
                }).then(data => data.json())
                    .then(data => {
                        if (data.status) {
                            this.setState({ selectedDistricts: data.districts });
                        }
                    });
            } else this.setState({ selectedDistricts: [] });
        } else if (type === "district") {
            editBranch["city"] = "";
            if (this.state.focusStates.indexOf(value) > -1) {
                fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?districtId=' + value, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': JSON.parse(readCookie('access_token'))
                    }
                }).then(data => data.json())
                    .then(data => {
                        if (data.status) {
                            this.setState({ selectedCities: data.cities });
                        }
                    });
            } else this.setState({ selectedCities: [] });
        }
        this.setState({ editBranch });
    }
    toggleEditBranchModal = () => {
        let branch = this.state.bankDetails;
        if (this.state.editBranch) this.setState({ editBranch: null });
        else {
            this.setState({ editBranch: branch });
            if (branch.state) {
                fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + branch.state, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': JSON.parse(readCookie('access_token'))
                    }
                }).then(data => data.json())
                    .then(data => {
                        if (data.status) {
                            this.setState({ selectedDistricts: data.districts });
                        }
                    });
            }
            if (branch.district) {
                fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?districtId=' + branch.district, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': JSON.parse(readCookie('access_token'))
                    }
                }).then(data => data.json())
                    .then(data => {
                        if (data.status) {
                            this.setState({ selectedCities: data.cities });
                        }
                    });
            }
        }
    }
    getBankDetails(ifsc = this.state.ifsc) {
        fetch(process.env.REACT_APP_API_URL + "/api/v1/bank-branches/" + ifsc, {//+ '?includeSanctioned=Yes&includeAccounts=Yes', {
            method: "GET",
            headers: {
                "x-auth-token": JSON.parse(readCookie("access_token")),
            },
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status && data.branch) {
                    this.setState({ bankDetails: data.branch, managerDetails: data.managerDetails });
                }
                else {
                    window.location = '/bank-branches';
                }
            });
    }
    saveEditingBranch = () => {
        let editBranch = this.state.editBranch, method = 'POST', path = '/api/v1/bank-branches';
        if (editBranch._id) {
            method = 'PUT';
            path = path + '/' + editBranch._id;
        }
        fetch(process.env.REACT_APP_API_URL + path, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': JSON.parse(readCookie('access_token'))
            },
            body: JSON.stringify(editBranch)
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    this.hideEditingBranch();
                    this.getBankDetails(this.state.bankDetails.ifsc);
                    // this.submitFilter();
                    this.notifySuccess('The branch has successfully been saved!');
                } else {
                    this.notifyError('The branch could not be updated!');
                }
            });
    }

    notifySuccess = (text) => toast.success(text)
    notifyError = (text) => toast.error(text)
    render() {
        return <div className='AddEditUserContainers'>
            <div className='AddEditUserHeader'>
                <div className='AddEditUserHeaderButton'>
                    <Link to='/bank-branches' className='link'>
                        <div className='HeaderText'>
                            <CaretLeftFilled />
                            Go Back
                        </div>
                    </Link>
                </div>
                {
                    this.state.bankDetails.ifsc && <div className='AddEditUserHeaderTitle'>
                        <div className='HeaderText'>{`${this.state.bankDetails.bank} - ${this.capitalizeFirstLetter(this.state.bankDetails.branch)} (IFSC: ${this.state.bankDetails.ifsc.toUpperCase()})`}</div>
                    </div>
                }
                <button className="edit-branch-button btn " onClick={this.toggleEditBranchModal.bind(this)}>Edit</button>
            </div>
            {this.state.bankDetails.ifsc &&
                <Tabs defaultActiveKey={this.state.loanInteractionModal ? "interactions" : "general"} id="uncontrolled-tab-example" >
                    <Tab eventKey="general" title={<div className="add-edit-user-tab-header">General Details</div>}>
                        <GeneralDetailsTab bankDetails={this.state.bankDetails} />
                    </Tab>
                    <Tab eventKey="candidates" title={<div className="add-edit-user-tab-header">Candidates</div>}>
                        <CandidatesTab bankDetails={this.state.bankDetails} />
                    </Tab>
                    <Tab eventKey="interactions" title={<div className="add-edit-user-tab-header">Interactions</div>}>
                        <InteractionsTab modalOpen={this.state.loanInteractionModal} userData={this.props.userData} managerDetails={this.state.managerDetails} bankDetails={this.state.bankDetails} />
                    </Tab>
                </Tabs>}

            {this.state.editBranch ? (
                <Modal className="edit-branch-modal" size="lg" show={this.state.editBranch ? true : false} onHide={() => { }}>
                    <Modal.Header>
                        <Modal.Title className="modal-header-custom">
                            {this.state.editBranch._id ? 'Edit Branch Details' : 'Add New Branch'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mb20">
                        <Row>
                            <Col md={4}>
                                <label className="control-label is-imp">Name of Bank</label>
                                <Select size="large" style={{ width: '100%' }} value={this.state.editBranch.bank} onChange={this.updateEditingBranch.bind(this, 'bank')}>
                                    {this.state.allBanks.map((bank, index) => {
                                        return (
                                            <Option key={index} value={bank}>{bank}</Option>
                                        )
                                    })}
                                </Select>
                            </Col>
                            <Col md={4}>
                                <label className="control-label is-imp">IFSC</label>
                                <input type="text" className="form-control" placeholder="IFSC Code" value={this.state.editBranch.ifsc} onChange={this.updateEditingBranch.bind(this, 'ifsc')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label is-imp">Branch</label>
                                <input type="text" className="form-control" placeholder="Branch Name" value={this.state.editBranch.branch} onChange={this.updateEditingBranch.bind(this, 'branch')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label is-imp">State</label>
                                <Select showSearch size="large" style={{ width: '100%' }} placeholder="State" onChange={this.updateEditingBranch.bind(this, 'state')} value={this.state.editBranch.state} filterOption={(input, option) => this.searchFilter(input, option)}>
                                    <OptGroup label="Focus States">
                                        {this.state.allStates.map((state, stateIndex) => {
                                            if (state.inFocusRegion) {
                                                return <Option key={stateIndex} value={state.name}>{state.name}</Option>
                                            } else return null;
                                        })}
                                    </OptGroup>
                                    <OptGroup label="Not Focussed States">
                                        {this.state.allStates.map((state, stateIndex) => {
                                            if (!state.inFocusRegion) {
                                                return <Option key={stateIndex} value={state.name}>{state.name}</Option>
                                            } else return null;
                                        })}
                                    </OptGroup>
                                </Select>
                            </Col>
                            <Col md={4}>
                                <label className="control-label is-imp">District</label>
                                {this.state.selectedDistricts.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
                                    <Select showSearch size="large" style={{ width: '100%' }} placeholder="District" onChange={this.updateEditingBranch.bind(this, 'district')} value={this.state.editBranch.district} filterOption={(input, option) => this.searchFilter(input, option)}>
                                        {this.state.selectedDistricts.map((district, districtIndex) => {
                                            return (
                                                <Option key={districtIndex} value={district.name}>{district.name}</Option>
                                            )
                                        })}
                                    </Select>
                                ) : (
                                    <input type="text" className="form-control" placeholder="District" value={this.state.editBranch.district} onChange={this.updateEditingBranch.bind(this, 'district')} />
                                )}
                            </Col>
                            <Col md={4}>
                                <label className="control-label is-imp">City</label>
                                {this.state.selectedCities.length && this.state.focusStates.indexOf(this.state.editBranch.state) > -1 ? (
                                    <Select showSearch size="large" style={{ width: '100%' }} placeholder="City" onChange={this.updateEditingBranch.bind(this, 'city')} value={this.state.editBranch.city} filterOption={(input, option) => this.searchFilter(input, option)}>
                                        {this.state.selectedCities.map((city, cityIndex) => {
                                            return (
                                                <Option key={cityIndex} value={city.name}>{city.name}</Option>
                                            )
                                        })}
                                    </Select>
                                ) : (
                                    <input type="text" className="form-control" placeholder="City" value={this.state.editBranch.city} onChange={this.updateEditingBranch.bind(this, 'city')} />
                                )}
                            </Col>
                            <Col md={12}>
                                <label className="control-label">Address</label>
                                <textarea className="form-control" placeholder="Address" value={this.state.editBranch.address} onChange={this.updateEditingBranch.bind(this, 'address')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Pincode</label>
                                <input type="text" className="form-control" placeholder="Pincode" value={this.state.editBranch.pincode} onChange={this.updateEditingBranch.bind(this, 'pincode')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Phone</label>
                                <input type="text" className="form-control" placeholder="Phone (with STD code)" value={this.state.editBranch.phone} onChange={this.updateEditingBranch.bind(this, 'phone')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Email</label>
                                <input type="text" className="form-control" placeholder="Email ID" value={this.state.editBranch.email} onChange={this.updateEditingBranch.bind(this, 'email')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Circle</label>
                                <input type="text" className="form-control" placeholder="Circle" value={this.state.editBranch.circle} onChange={this.updateEditingBranch.bind(this, 'circle')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Module</label>
                                <input type="text" className="form-control" placeholder="Module" value={this.state.editBranch.module} onChange={this.updateEditingBranch.bind(this, 'module')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Business Hours</label>
                                <input type="text" className="form-control" placeholder="Business Hours" value={this.state.editBranch.hours} onChange={this.updateEditingBranch.bind(this, 'hours')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Latitude</label>
                                <input type="text" className="form-control" placeholder="Latitude" value={this.state.editBranch.latitude} onChange={this.updateEditingBranch.bind(this, 'latitude')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Longitude</label>
                                <input type="text" className="form-control" placeholder="Longitude" value={this.state.editBranch.longitude} onChange={this.updateEditingBranch.bind(this, 'longitude')} />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Location Accuracy</label>
                                <Select size="large" style={{ width: '100%' }} value={this.state.editBranch.accuracy} onChange={this.updateEditingBranch.bind(this, 'accuracy')}>
                                    <Option value="approximate">Approximate</Option>
                                    <Option value="Exact(based on pincodes)">Exact(based on pincodes)</Option>
                                </Select>
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Status</label>
                                <Switch checked={this.state.editBranch.status === 'ACTIVE' ? true : false} onChange={this.updateEditingBranch.bind(this, 'status')} checkedChildren="Active" unCheckedChildren="Inactive" />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Is a Main Branch</label>
                                <Switch checked={this.state.editBranch.mainBranch} onChange={this.updateEditingBranch.bind(this, 'mainBranch')} checkedChildren="Yes" unCheckedChildren="No" />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Is a Lending Branch</label>
                                <Switch checked={this.state.editBranch.lendingBranch} onChange={this.updateEditingBranch.bind(this, 'lendingBranch')} checkedChildren="Yes" unCheckedChildren="No" />
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Branch Category</label>
                                <Select size="large" style={{ width: '100%' }} value={this.state.editBranch.category} onChange={this.updateEditingBranch.bind(this, 'category')}>
                                    <Option value="Rural">Rural</Option>
                                    <Option value="Urban">Urban</Option>
                                    <Option value="Semi-urban">Semi-urban</Option>
                                    <Option value="Metro">Metro</Option>
                                </Select>
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Contact Details</label>
                                <Select size="large" style={{ width: '100%' }} value={this.state.editBranch.contactDetails} onChange={this.updateEditingBranch.bind(this, 'contactDetails')}>
                                    <Option value="Verified">Verified</Option>
                                    <Option value="Unverified">Unverified</Option>
                                    <Option value="Not-available">Not-available</Option>
                                </Select>
                            </Col>
                            <Col md={4}>
                                <label className="control-label">Mode of Payment</label>
                                <Select mode="multiple" size="large" style={{ width: '100%' }} value={this.state.editBranch.modeOfPayment} onChange={this.updateEditingBranch.bind(this, 'modeOfPayment')}>
                                    <Option value="Cash">Cash</Option>
                                    <Option value="Cheque">Cheque</Option>
                                    <Option value="Demand Draft">Demand Draft</Option>
                                    <Option value="Net banking">Net banking</Option>
                                </Select>
                            </Col>
                            <Collapse style={{ width: '100%', marginTop: 15 }}>
                                <Panel header="Add Primary Details" key="primaryDetails">
                                    <Row>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Name</label>
                                            <input type="text" className="form-control" placeholder="Person Name" value={this.state.editBranch.primaryContactName} onChange={this.updateEditingBranch.bind(this, 'primaryContactName')} />
                                        </Col>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Mobile</label>
                                            <input type="text" className="form-control" placeholder="Person Mobile" value={this.state.editBranch.primaryContactMob} onChange={this.updateEditingBranch.bind(this, 'primaryContactMob')} />
                                        </Col>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Email ID</label>
                                            <input type="text" className="form-control" placeholder="Person Email ID" value={this.state.editBranch.primaryContactEmail} onChange={this.updateEditingBranch.bind(this, 'primaryContactEmail')} />
                                        </Col>
                                    </Row>
                                </Panel>
                                <Panel header="Add Secondary Details" key="secondaryDetails">
                                    <Row>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Name</label>
                                            <input type="text" className="form-control" placeholder="Person Name" value={this.state.editBranch.secondaryContactName} onChange={this.updateEditingBranch.bind(this, 'secondaryContactName')} />
                                        </Col>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Mobile</label>
                                            <input type="text" className="form-control" placeholder="Person Mobile" value={this.state.editBranch.secondaryContactMob} onChange={this.updateEditingBranch.bind(this, 'secondaryContactMob')} />
                                        </Col>
                                        <Col md={4}>
                                            <label className="control-label">Contact Person Email ID</label>
                                            <input type="text" className="form-control" placeholder="Person Email ID" value={this.state.editBranch.secondaryContactEmail} onChange={this.updateEditingBranch.bind(this, 'secondaryContactEmail')} />
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="footer-center">
                        <Button className="btn brand-color-background" onClick={this.saveEditingBranch}>Save</Button>
                        <Button className="btn btn-danger brand-color-background" onClick={this.hideEditingBranch}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            ) : (null)}
        </div>
    }
}
import React, { useState, useEffect } from 'react';
import './Tab1.css';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import axios from 'axios';
// import {
//     State,
//     // City
// } from 'country-state-city';
import {
    SaveFilled,
    EditFilled,
} from '@ant-design/icons';
import { Multiselect } from 'multiselect-react-dropdown';

const readCookie = require('../../../cookie').readCookie;
const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`;
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYjk2YTA5NjYyNjAzNTE1M2M4NjgzYSIsInVzZXJUeXBlIjp7Il9pZCI6IjVkOGRlMjExMTBiYTkxMDNkMjFkMWE4NSIsIm5hbWUiOiJQcm9qZWN0IE1hbmFnZW1lbnQgVW5pdCJ9LCJ1c2VyUm9sZSI6bnVsbCwibmdvIjpudWxsLCJhY2NvdW50VHlwZSI6IlN1cGVyIFVzZXIiLCJwZXJtaXNzaW9ucyI6W10sImFwcGxpY2F0aW9uIjoiU3dhdGkiLCJpYXQiOjE2MjU1NDM4ODd9.zHckkR2zwaE66s__MthKV0DuSHyJLhNBdXAoDX28OPQ';//

function Tab1(attrs) {
    const { userId } = attrs;
    // console.log('Edit the details of:', userId);
    const [userDetails, setuserDetails] = useState(false);
    const [availableStakeholders, setavailableStakeholders] = useState([]);
    const [APIresponse, setAPIresponse] = useState({});
    const availableStates = [["Andaman and Nicobar Islands", "AN"]
        , ["Andhra Pradesh", "AP"]
        , ["Arunachal Pradesh", "AR"]
        , ["Assam", "AS"]
        , ["Bihar", "BR"]
        , ["Chandigarh", "CH"]
        , ["Chhattisgarh", "CT"]
        , ["Dadra and Nagar Haveli and Daman and Diu", "DH"]
        , ["Delhi", "DL"]
        , ["Goa", "GA"]
        , ["Gujarat", "GJ"]
        , ["Haryana", "HR"]
        , ["Himachal Pradesh", "HP"]
        , ["Jammu and Kashmir", "JK"]
        , ["Jharkhand", "JH"]
        , ["Karnataka", "KA"]
        , ["Kerala", "KL"]
        , ["Ladakh", "LA"]
        , ["Lakshadweep", "LD"]
        , ["Madhya Pradesh", "MP"]
        , ["Maharashtra", "MH"]
        , ["Manipur", "MN"]
        , ["Meghalaya", "ML"]
        , ["Mizoram", "MZ"]
        , ["Nagaland", "NL"]
        , ["Odisha", "OR"]
        , ["Puducherry", "PY"]
        , ["Punjab", "PB"]
        , ["Rajasthan", "RJ"]
        , ["Sikkim", "SK"]
        , ["Tamil Nadu", "TN"]
        , ["Telangana", "TG"]
        , ["Tripura", "TR"]
        , ["Uttar Pradesh", "UP"]
        , ["Uttarakhand", "UT"]
        , ["West Bengal", "WB"]];
    // const [availableStates, setavailableStates] = useState([]);
    async function fetchData() {
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                for (let i = 0; i < response.data.users.length; i += 1) {
                    const { _id } = response.data.users[i];
                    if (_id === userId) setuserDetails(response.data.users[i]);
                }
            }
        });

        const urlListStakeholders = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder`;
        axios.get(urlListStakeholders, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setAPIresponse(response.data.stakeHolders);
                const tempStakeholders = [];
                for (let i = 0; i < response.data.stakeHolders.length; i += 1) {
                    tempStakeholders.push(response.data.stakeHolders[i].type);
                }
                setavailableStakeholders(tempStakeholders);
                // const states = [];
                // for (let key = 0; key < State.getStatesOfCountry('IN').length; key += 1) {
                //     states.push([State.getStatesOfCountry('IN')[key].name, State.getStatesOfCountry('IN')[key].isoCode]);
                // }
                // setavailableStates(states);
            }
        });
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [modalShow, setModalShow] = useState(false);

    function MyVerticallyCenteredModal(props) {
        const multiselectRef = React.createRef();
        const { onHide } = props;
        const [fields, setFields] = useState(userDetails); // Input Fields
        const [availableDesignations, setavailableDesignations] = useState([]);
        const [availableDistricts, setavailableDistricts] = useState([]);

        useEffect(() => {
            if (fields.state)
                getDistrictsForState(fields.state);
            if (fields.stakeHolderType) {
                for (let i = 0; i < APIresponse.length; i += 1) {
                    if (APIresponse[i].type === fields.stakeHolderType) {
                        setavailableDesignations(APIresponse[i].designations);
                        // newUser.stakeHolderType = e.target.value;
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        function handleInputChange(e, field) {
            // const values = { ...userDetails };
            const values = { ...fields };
            values[field] = e.target.value;
            setFields(values);
            // console.log(field);
        }
        function getDistrictsForState(state = "") {
            // const districts = [];
            fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?state=' + state, {
                method: 'GET',
                headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token'))
                }
            }).then(data => data.json())
                .then(data => {
                    if (data.status) {
                        // this.setState({ allDistricts: data.districts })
                        // let districts = [];
                        // for (let i = 0; i < data.districts.length; i++) districts.push(data.districts[i].name);
                        // this.setState({ uploadCandidateDistricts: districts });
                        setavailableDistricts(data.districts);
                    }
                });
            // }
        }
        function handleDropdownSelectChange(e, changeSource) {
            if (changeSource === 'stakeHolderType') {
                for (let i = 0; i < APIresponse.length; i += 1) {
                    if (APIresponse[i].type === e.target.value) {
                        setavailableDesignations(APIresponse[i].designations);
                        setFields({ ...fields, designation: -1 })
                        // newUser.stakeHolderType = e.target.value;
                    }
                }
            }
            if (changeSource === 'state') {
                getDistrictsForState(e.target.value);
                setFields({ ...fields, district: [] });
                multiselectRef.current.resetSelectedValues();
            }
            const values = { ...fields };
            values[changeSource] = e.target.value;
            setFields(values);
        }
        function handleDistricts(selectedList) {
            let districts = selectedList.map(d => d.name);
            const values = { ...fields };
            values['district'] = districts;
            setFields(values);
        }
        // Updating user should also update the updated on and updated by
        // because there is no field for that.
        function updateUser() {
            const values = { ...fields };
            const wrongFields = [];

            // Validating Phone Numbers and email;
            // const a = /^\d{10}$/;
            const a = /(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/;

            if (a.test(values.mobileNumber)) {
                if (!values.email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
                    if (!values.alternateNumber || a.test(values.alternateNumber)) {
                        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user/${userId}`;
                        const config = { headers: { Authorization: AUTH_TOKEN } };
                        axios.put(url, values, config).then((response) => {
                            // console.log(response);
                            if (response.status === 200 && response.data.error === false) {
                                swal('Successfully Updated', '', 'success');
                                fetchData();
                            } else {
                                swal('Try Again !!', '', 'error');
                            }
                        }).catch((err) => {
                            swal(err.response.data.errorMessage, '', 'error');
                        });
                        setuserDetails({ ...fields });
                        onHide();
                    } else if (!values.alternateNumber === true) {
                        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user/${userId}`;
                        const config = { headers: { Authorization: AUTH_TOKEN } };
                        axios.put(url, values, config).then((response) => {
                            // console.log(response);
                            if (response.status === 200 && response.data.error === false) {
                                swal('Successfully Updated', '', 'success');
                                fetchData();
                            } else {
                                swal('Try Again !!', '', 'error');
                            }
                        }).catch((err) => {
                            swal(err.response.data.errorMessage, '', 'error');
                        });
                        setuserDetails({ ...fields });
                        onHide();
                    } else {
                        swal('Your Alternate Number Is Not Valid.', '', 'error');
                        wrongFields.push('Alternate Number');
                    }
                } else {
                    swal('You have entered an invalid email address!', '', 'error');
                    wrongFields.push('Email');
                }
            } else {
                swal('Your Primary Contact Number Is Not Valid.', '', 'error');
                wrongFields.push('Mobile Number');
            }
            // console.log(wrongFields);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Name'}*</div>
                                <input
                                    type="text"
                                    placeholder={userDetails.name}
                                    value={userDetails.name}

                                    onChange={(e) => handleInputChange(e, 'name')}
                                    style={{
                                        width: '60%',
                                        height: '100%',
                                        borderRadius: 5,
                                        borderColor: 'transparent',
                                        borderBottomColor: 'lightgrey',
                                    }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Stakeholder Type'}*</div>
                                <Form.Control value={fields.stakeHolderType || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'stakeHolderType')}>
                                    <option disabled value={-1}></option>
                                    {
                                        availableStakeholders.map(
                                            (value, index) => <option key={index}>{value}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Designation'}*</div>
                                <Form.Control value={fields.designation || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'designation')}>
                                    <option disabled value={-1}></option>
                                    {
                                        availableDesignations.map(
                                            (value, index) => <option key={index}>{value}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'State'}*</div>
                                <Form.Control value={fields.state || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'state')}>
                                    <option disabled value={-1}></option>
                                    {
                                        availableStates.map(
                                            (value, index) => <option key={index}>{value[0]}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'District'}*</div>
                                {/* <Form.Control value={fields.district || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'district')}>
                                <option disabled value={-1}></option>
                                {
                                    availableDistricts.map(
                                        (value, index) => <option key={index}>{value.name}</option>,
                                    )
                                }
                            </Form.Control> */}
                                <div style={{
                                    textAlign: 'left',
                                    width: '70%',
                                }}>
                                    <Multiselect options={availableDistricts}
                                        // key={districtIndex}
                                        // style={{ height: '100%', width: '60%' }}
                                        // style={{
                                        //     width: "85%",
                                        //     marginLeft: "15%"
                                        // }}
                                        selectedValues={fields.district ? fields.district.map(d => { return { name: d } }) : []}
                                        placeholder={"Select District(s)"}
                                        onSelect={handleDistricts}
                                        onRemove={handleDistricts}
                                        ref={multiselectRef}
                                        displayValue={"name"} /></div>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Address'}</div>
                                <input
                                    type="text"
                                    placeholder={userDetails.address}
                                    value={userDetails.address}

                                    onChange={(e) => handleInputChange(e, 'address')}
                                    style={{
                                        width: '60%',
                                        height: '100%',
                                        borderRadius: 5,
                                        borderColor: 'transparent',
                                        borderBottomColor: 'lightgrey',
                                    }}
                                    required
                                />
                            </div>
               
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Alternative Number'}
                                    {/* * */}
                                </div>
                                <input
                                    type="text"
                                    placeholder={userDetails.alternateNumber}
                                    value={userDetails.alternateNumber}

                                    onChange={(e) => handleInputChange(e, 'alternateNumber')}
                                    style={{
                                        width: '60%',
                                        height: '100%',
                                        borderRadius: 5,
                                        borderColor: 'transparent',
                                        borderBottomColor: 'lightgrey',
                                    }}
                                    // required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'E-mail'}
                                    {/* * */}
                                </div>
                                <input
                                    type="text"
                                    placeholder={userDetails.email}
                                    value={userDetails.email}

                                    onChange={(e) => handleInputChange(e, 'email')}
                                    style={{
                                        width: '60%',
                                        height: '100%',
                                        borderRadius: 5,
                                        borderColor: 'transparent',
                                        borderBottomColor: 'lightgrey',
                                    }}
                                    // required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Source of connection'}*</div>
                                <Form.Control value={fields.connectionSource || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'connectionSource')}>
                                    <option disabled value={-1}></option>
                                    <option>Direct</option>
                                    <option>Email</option>
                                    <option>Phone</option>
                                    <option>Visit</option>
                                    <option>Referral</option>
                                    <option>PMU</option>
                                    <option>State-level</option>
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Status'}*</div>
                                <Form.Control value={fields.status || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'status')}>
                                    <option disabled value={-1}></option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Overall sentiment'}*</div>
                                <Form.Control value={fields.sentiment || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'sentiment')}>
                                    <option disabled value={-1}></option>
                                    <option >Positive</option>
                                    <option >Neutral</option>
                                    <option >Negative</option>
                                    <option >Cannot Say</option>
                                </Form.Control>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        // onClick={updateUser}
                        onClick={() => {
                            swal('Do you really want to edit the user details?', {
                                buttons: {
                                    catch: {
                                        text: 'Yes',
                                        value: 'Yes',
                                    },
                                    No: true,
                                },
                            })
                                .then((value) => {
                                    switch (value) {
                                        case 'Yes':
                                            updateUser();
                                            break;

                                        default:
                                    }
                                });
                        }}
                    >
                        SAVE / UPDATE
                        <SaveFilled />
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
    MyVerticallyCenteredModal.propTypes = {
        onHide: PropTypes.func,
        userId: PropTypes.string,
    };

    return (
        <div className='Tab1Container'>
            <div className='editButtonContainer'>
                <div className='editButton'>
                    <div
                        className='editButtonText'
                        onClick={() => setModalShow(true)}
                    // onClick={() => {
                    //     swal('Do you really want to edit the user details?', {
                    //       buttons: {
                    //         catch: {
                    //           text: 'Yes',
                    //           value: 'Yes',
                    //         },
                    //         No: true,
                    //       },
                    //     })
                    //     .then((value) => {
                    //       switch (value) {
                    //         case 'Yes':
                    //           setModalShow(true);
                    //           break;

                    //         default:
                    //       }
                    //     });
                    // }}
                    >
                        Edit User
                        <EditFilled />
                    </div>
                </div>
            </div>
            <div className='Tab1Table'>
                <Table striped hover variant="light" size="lr">
                    <tbody >
                        {
                            userDetails
                            && <div className='Tab1TableElements'>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Name</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.name}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Stakeholder Type</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.stakeHolderType}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Designation</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.designation}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>State</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.state}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>District(s)</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.district.join(', ')}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Address</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.address}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Primary Contact Number</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.mobileNumber}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Alternate Number</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.alternateNumber}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Email</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.email}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Source Of Connection</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.connectionSource}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Status</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.status}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Overall Sentiment</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.sentiment}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Updated By</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.updated.by.name}</td>
                                </tr>
                                <tr style={{ display: 'flex' }}>
                                    <td style={{ textAlign: 'right', width: '50%' }}>Updated On</td>
                                    <td style={{ textAlign: 'left', width: '50%' }}>
                                        {userDetails.updated.on}</td>
                                </tr>
                            </div>
                        }

                    </tbody>
                </Table>
            </div>
            < MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
            />
        </div >
    );
}

export default Tab1;

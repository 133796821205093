import React, { useEffect, useState } from 'react'
import {
  Col,
  // Row,
  // Button,
  // Modal
} from 'react-bootstrap';
import "react-chat-elements/dist/main.css";
import {
  MessageBox,
  // ChatItem
} from "react-chat-elements";

const moment = require('moment');

const SingleLeadCoverations = () => {
  const [messages, setMessages] = useState([]);
  const getMessages = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/whatsapp-cloud-api/get-messages/9482213544',
      {
        method: "GET",
        // headers: { Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiIiwibW9iaWxlTnVtYmVyIjoiOTMyMDAwMDAwMCIsImlkIjoiNjI4YjdjM2FmODZmNjY2ZTk0MmE4ZmRhIiwidXNlclR5cGUiOiJ1c2VyIiwiaWF0IjoxNjUzMzA4NTM4fQ.V6axu_ZxRUJ9oWnA_YgFvZ6V7W19ddwG6GSyrh1cpYY' }
      }
    )
      .then((data) => data.json())
      .then((data) => setMessages(data.messages));
  };

  const status_lookup = { 'waiting': 'waiting', sent: 'sent', delivered: 'received', read: 'read' }
  useEffect(() => {
    getMessages();
  }, []);
  return (
    <div>
      <Col md={12} >
        {

          messages.map(message => {
            console.log((message?.message?.from) ? message.message.timestamp : message?.statuses[0]['timestamp']);
            return <MessageBox
              position={(message?.message?.from) ? 'left' : 'right'}
              status={(message?.message?.from) ? '' : status_lookup[message?.statuses[message.statuses.length - 1]['status']]}
              type={"text"}
              title={(message?.contactInfo && message?.contactInfo[0]?.profile?.name) || "LE Team"}
              titleColor={(message?.message?.from) ? '#ef5a20' : "#abcabc"}
              text={(message?.message?.from) ? message.message.text?.body : message.message.message.message.message.text?.body}
              dateString={(message?.message?.from) ? moment(message.message.timestamp * 1000).format('hh:mm a DD/MM/YY') : moment(message?.statuses[0]['timestamp'] * 1000).format('hh:mm a DD/MM/YY')}
              data={{
                uri: "https://facebook.github.io/react/img/logo.svg",
                status: {
                  click: false,
                  loading: 0,
                },
              }}
            />

          })
        }
      </Col>
    </div >
  )
}

export default SingleLeadCoverations
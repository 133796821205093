import React
  // , { useState }
  from "react";
import Input from "../../components/FormElements/Input";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useForm } from "../../hooks/form-hook";
import Label from "../../components/FormElements/label";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_CONTACT,
} from "../../util/validators";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Spin } from "antd";
import Swal from "sweetalert2";
import { useHttpHook } from "../../hooks/http-hook";

const readCookie = require("../../../cookie.js").readCookie;

const ContactDetail = (props) => {
  const contactType = ["Primary", "Secondary"];
  const centerId = props.match.params.centerId;
  // const [stateload, setStateLoad] = useState();
  const { isLoading,
    // error,
    sendRequest, clearError } = useHttpHook();

  const [formState, inputHandler] = useForm(
    {
      contact_type: {
        value: "",
        isValid: false,
      },
      contact_name: {
        value: "",
        isValid: false,
      },
      contact_designation: {
        value: "",
        isValid: false,
      },
      contact_phone: {
        value: "",
        isValid: false,
      },
      contact_email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const addContactData = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/contact/`,
        "POST",
        JSON.stringify({
          type: formState.inputs.contact_type.value,
          name: formState.inputs.contact_name.value,
          designation: formState.inputs.contact_designation.value,
          phone: formState.inputs.contact_phone.value,
          email: formState.inputs.contact_email.value,
          centerId: centerId,
        }),
        {
          "Content-Type": "application/json",
          "x-auth-token": JSON.parse(readCookie("access_token")),
        }
      );
    } catch (err) {
      alert(err);
    }
    props.onHide();
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Successfully created",
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.reload(false);
  };

  return (
    <Card>
      <Card.Header>
        <CloseCircleTwoTone
          style={{ float: "right", fontSize: "2rem", cursor: "pointer" }}
          onClick={props.onHide}
        />
        <p>Create New One ....!</p>
      </Card.Header>
      <Card.Body className="text-center">
        <Card.Title>
          <Label name="Type" />
          <Input
            element="Select"
            id="contact_type"
            onInput={inputHandler}
            errorText="Please Select Correctly"
            validators={[VALIDATOR_REQUIRE()]}
            option={contactType}
            name="contactType"
          />
        </Card.Title>
        <Container>
          <Row>
            <Col sm={6}>
              <Label name="Name" />
              <Input
                element="input"
                type="text"
                onInput={inputHandler}
                id="contact_name"
                placeholder="6-Digit-Pincode"
                errorText="Invalid Name"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
              />
            </Col>
            <Col sm={6}>
              <Label name="Designation" />
              <Input
                element="input"
                type="text"
                onInput={inputHandler}
                id="contact_designation"
                placeholder="6-Digit-Pincode"
                errorText="Invalid Designation"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Label name="Phone" />
              <Input
                element="input"
                type="text"
                onInput={inputHandler}
                id="contact_phone"
                placeholder="6-Digit-Pincode"
                errorText="Invalid Contact"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_CONTACT()]}
              />
            </Col>
            <Col sm={6}>
              <Label name="Email" />
              <Input
                element="input"
                type="text"
                onInput={inputHandler}
                id="contact_email"
                placeholder="6-Digit-Pincode"
                errorText="Enter valid email "
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer className="text-muted">
        {isLoading ? (
          <div className="center">
            {clearError}
            <Spin size="large" />{" "}
          </div>
        ) : (
          <Button
            variant="primary"
            onClick={addContactData}
            disabled={!formState.isValid}
          >
            ADD
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
export default ContactDetail;

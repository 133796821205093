// import { Table } from 'react-bootstrap';
import React from 'react';
// import Commentretrieve from './commentRetrieve';


const Commenthandler = (props) => {
  if (props.items.length === 0) {
    return (
      <div>
        <h2
          style={{
            position: "absolute",
            left: "45rem",
            top: "30rem",
            color: "#D3D3D3",
          }}
        >
          No Comment Found
        </h2>
      </div>
    );
  }

  return (
    <div>
      hy
    </div>
  );
};

export default Commenthandler;

import { Modal, Row, Col, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { Select, Spin, AutoComplete } from 'antd';

const { Option: AutoCompleteOption } = AutoComplete;
// import Swal from 'sweetalert2';

const { Option, OptGroup } = Select;

const ValidateData = require('../../../../ValidateData.js');
const readCookie = require('../../../partials/cookie.js').readCookie;

export default class AddUdaanUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: {
				fullName: '',
				email: '',
				mobileNumber: '',
				access_type: this.props.match.params.type,
				status: ''
			},
			errorObj: {},
			allCities: [],
			allStates: [],
			allDistricts: [],
			allReportingTo: [],
			reportingToList: [],
			fetchingLeads: false,
			fetchedSaarthiLeads: [],
			selectedSaarthiLeads: {},
			allExistingUsers: [],
			allExistingUsersArray: [],
			addSaarthiLeadModalVisibility: false
		}
		this.lastFetchId = 0;
		this.fetchSaarthiLeads = debounce(this.fetchSaarthiLeads, 800);
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				// let allStates = [];
				this.setState({ allStates: data.data.states });
			});

		let userData = this.state.userData, reportingUserType = '';
		if (this.props.match.params.type === 'zonal-manager') {
			userData.address = { state: [] };
			reportingUserType = 'project-management-unit';
		} else if (this.props.match.params.type === 'swavalamban-fellows' || this.props.match.params.type === 'local-government') {
			userData.address = { state: [], district: [] };
			if (this.props.match.params.type === 'swavalamban-fellows') reportingUserType = 'project-management-unit';
		} else if (this.props.match.params.type === 'udaan') {
			userData.address = { state: '', district: '' };
		} else if (this.props.match.params.type === 'saarthis') {
			userData.leads = [];
		}

		if (this.props.match.params.userId) {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/user/' + this.props.match.params.userId, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						let userData = JSON.parse(JSON.stringify(data.user));
						if (userData.type === 'le') {
							delete userData.memberCount;
							delete userData.admin;
							delete userData.organisation;
							delete userData.websiteUrl;
						}
						delete userData.addedOn;
						delete userData.deleted;
						delete userData._id;
						delete userData.id;
						delete userData.addedBy;
						delete userData.type;
						delete userData.permissions;
						delete userData.profilePicture;
						delete userData.isUserVerified;
						delete userData.accountType;
						delete userData.updatedAt;
						delete userData.createdAt;
						delete userData.__v;

						if (userData.reportingTo && userData.reportingTo._id) userData.reportingTo = userData.reportingTo._id;
						if (this.props.match.params.type === 'swavalamban-fellows') {
							delete userData.location;
							delete userData.userType;
						}
						userData.access_type = this.props.match.params.type;
						this.setState({ userData });
						let allDistrictsData = {}, promiseArr = [];
						for (let i = 0; i < userData['address']['state'].length; i++) {
							var promise = fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + userData['address']['state'][i], {
								method: 'GET',
								headers: {
									'x-auth-token': JSON.parse(readCookie('access_token'))
								}
							}).then(data => data.json())
								.then(data => {
									if (data.status) {
										allDistrictsData[data.state._id] = {
											name: data.state.name,
											districts: data.focusDistricts || []
										};
									}
								});
							promiseArr.push(promise);
						}
						Promise.all(promiseArr).then(() => {
							let allDistricts = [];
							for (let i = 0; i < Object.values(allDistrictsData).length; i++) {
								for (let j = 0; j < Object.values(allDistrictsData)[i].districts.length; j++) {
									let districtData = {};
									districtData['label'] = Object.values(allDistrictsData)[i].districts[j].name + ' (' + Object.values(allDistrictsData)[i].name + ')';
									districtData['value'] = Object.values(allDistrictsData)[i].districts[j]._id;
									allDistricts.push(districtData);
								}
							}
							this.setState({ allDistricts });
						});
					}
				});
		} else {
			this.setState({ userData });
		}

		if (reportingUserType) {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/' + reportingUserType + '?type=list&status=Active', {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						let reportingToList = [];
						for (let i = 0; i < data.users.length; i++) {
							let reportingData = {};
							reportingData['type'] = 'project-management-unit';
							reportingData['label'] = data.users[i].fullName + ' (' + data.users[i].email + ')';
							reportingData['id'] = data.users[i]._id;
							reportingToList.push(reportingData);
						}
						if (this.props.match.params.type === 'swavalamban-fellows') {
							fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/zonal-manager?type=list&status=Active', {
								method: 'GET',
								headers: {
									'x-auth-token': JSON.parse(readCookie('access_token'))
								}
							}).then(data => data.json())
								.then(data => {
									if (data.status) {
										for (let i = 0; i < data.users.length; i++) {
											let reportingData = {};
											reportingData['type'] = 'zonal-manager';
											reportingData['label'] = data.users[i].fullName + ' (' + data.users[i].email + ')';
											reportingData['id'] = data.users[i]._id;
											reportingToList.push(reportingData);
										}
										this.setState({ reportingToList });
									} else this.setState({ reportingToList });
								});
						} else {
							this.setState({ reportingToList });
						}
					}
				});
		}
		this.getAllUsersAcrossProjects();
	}

	getAllUsersAcrossProjects() {
		fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users-across-projects', {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					this.setState({ allExistingUsers: data.users, allExistingUsersArray: Object.keys(data.users||{}).map(x=>x.toLowerCase()) });
				}
			}).catch(err => {
				console.error('Error in getting all exiting users:', err);
			});	
	}

	changeHandler(type, e) {
		let userData = this.state.userData, errorObj = this.state.errorObj;

		if (type === 'mobileNumber') ValidateData.mobileValidate('mobileNumber', e.target.value, errorObj);
		else if (type === 'fullName') ValidateData.nameValidate(e.target.value, errorObj);
		else if (type === 'reportingTo') ValidateData.stringValidate('reportingTo', e, errorObj);
		else if (type === 'status') ValidateData.stringValidate('status', e.target.value, errorObj);

		if (type === 'city') userData['address']['city'] = e;
		else if (type === 'email') {
			if (!this.props.match.params.userId) {
				userData[type] = e.target.value;
				ValidateData.emailValidate(e.target.value, errorObj);
			}
		} else if (e.hasOwnProperty('target')) userData[type] = e.target.value;
		else userData[type] = e;
		this.setState({ userData, errorObj });
	}

	handleState = (val) => {
		let userData = this.state.userData;

		if (userData['address']) userData['address']['state'] = val;
		else userData['address'] = { state: val };

		if (typeof val === 'string') {
			userData['address']['district'] = '';
			userData['address']['city'] = '';
		} else userData['address']['district'] = [];

		this.setState({ userData }, () => {
			if (this.state.userData.access_type === 'swavalamban-fellows' || this.state.userData.access_type === 'local-government') {
				let allDistrictsData = {}, promiseArr = [];
				for (let i = 0; i < userData['address']['state'].length; i++) {
					var promise = fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + userData['address']['state'][i], {
						method: 'GET',
						headers: {
							'x-auth-token': JSON.parse(readCookie('access_token'))
						}
					}).then(data => data.json())
						.then(data => {
							if (data.status) {
								allDistrictsData[data.state._id] = {
									name: data.state.name,
									districts: data.focusDistricts || []
								};
							}
						});
					promiseArr.push(promise);
				}
				Promise.all(promiseArr).then(() => {
					let allDistricts = [];
					for (let i = 0; i < Object.values(allDistrictsData).length; i++) {
						for (let j = 0; j < Object.values(allDistrictsData)[i].districts.length; j++) {
							let districtData = {};
							districtData['label'] = Object.values(allDistrictsData)[i].districts[j].name + ' (' + Object.values(allDistrictsData)[i].name + ')';
							districtData['value'] = Object.values(allDistrictsData)[i].districts[j]._id;
							allDistricts.push(districtData);
						}
					}
					this.setState({ allDistricts });
				});
			} else if (this.state.userData.access_type === 'udaan') {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + userData['address']['state'], {
					method: 'GET',
					headers: {
						'x-auth-token': JSON.parse(readCookie('access_token'))
					}
				}).then(data => data.json())
					.then(data => {
						if (data.status) {
							let allDistricts = [];
							for (let i = 0; i < data.districts.length; i++) {
								let districtData = {};
								districtData['label'] = data.districts[i].name;
								districtData['value'] = data.districts[i]._id;
								allDistricts.push(districtData);
							}
							this.setState({ allDistricts, allCities: [] });
						}
					});
			}
		});
	}

	handleDistrict = (val) => {
		let userData = this.state.userData;
		if (userData['address']) userData['address']['district'] = val;

		if (typeof val === 'string') userData['address']['city'] = '';

		this.setState({ userData });

		if (this.state.userData.access_type === 'udaan') {
			fetch(process.env.REACT_APP_API_URL + '/api/v1/location/get-address?stateId=' + userData['address']['state'] + '&districtId=' + val, {
				method: 'GET',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				}
			}).then(data => data.json())
				.then(data => {
					if (data.status) {
						let allCities = [];
						for (let i = 0; i < data.cities.length; i++) {
							let cityData = {};
							cityData['label'] = data.cities[i].name;
							cityData['value'] = data.cities[i]._id;
							allCities.push(cityData);
						}
						this.setState({ allCities });
					}
				});
		}
	}

	submit = () => {
		let userData = this.state.userData, errorObj = this.state.errorObj, specialError = false;

		if (userData.access_type === 'zonal-manager' || userData.access_type === 'swavalamban-fellows') {
			if (!userData.reportingTo) specialError = true;
			if (!userData.address) specialError = true;
			else {
				if (!userData.address.state) specialError = true;
				else if (!userData.address.state.length) specialError = true;

				if (userData.access_type === 'swavalamban-fellows') {
					if (!userData.address.district) specialError = true;
					else if (!userData.address.district.length) specialError = true;
					else {
						for (var i = 0; i < userData.address.district.length; i++) {
							userData.address.district[i] = userData.address.district[i].split(' (')[0];
						}
					}
				}
			}
			if (userData.access_type === 'swavalamban-fellows' && !userData.fellowAccessType) specialError = true;
		}
		if (userData.access_type === 'udaan') {
			if (!userData.address) specialError = true;
			else if (!userData.address.state || !userData.address.district || !userData.address.city) specialError = true;
		}

		if (!Object.keys(errorObj).length && userData['fullName'].trim() !== '' && userData['email'].trim() !== '' && userData['mobileNumber'].trim() !== '' && userData['access_type'] !== '' && userData['status'] !== '' && !specialError) {
			userData['email'] = userData.email.toLowerCase();

			let data = JSON.parse(JSON.stringify(userData));

			if (data.address) {
				if (data.address.city) data.city = data.address.city;
				if (data.address.state) data.state = data.address.state;
				if (data.address.district) data.district = data.address.district;
			}
			// delete data.address;

			if (data.access_type === 'saarthis' && data.leads.length) {
				data.leads = data.leads.map(lead => lead.id);
			}

			if (data.accountType) {
				if (data.accountType === 'Admin' && this.props.match.params.type === 'udaan') data.accountType = 'Udaan';
				else if (data.accountType === 'Member' && this.props.match.params.type === 'udaan') data.accountType = 'udaanMember';
				else if (data.accountType === 'Admin' && this.props.match.params.type === 'sidbi') data.accountType = 'sidbi';
				else if (data.accountType === 'Member' && this.props.match.params.type === 'sidbi') data.accountType = 'sidbiMember';
			}

			if (this.props.match.params.type === 'udaan') {
				fetch(process.env.REACT_APP_API_URL + '/api/v1/udaan/' + (this.props.match.params.orgId || this.props.match.params.userId), {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'x-auth-token': JSON.parse(readCookie('access_token'))
					},
					body: JSON.stringify(data)
				}).then((response) => {
					return response.json();
				}).then((data) => {
					if (data.status) {
						this.notifySuccess("Successfully added new SCK!");
						setTimeout(function () {
							window.location.pathname = window.location.pathname.split('/add-user')[0];
						}, 1000);
					}
				});
			} else {
				let url = process.env.REACT_APP_API_URL + '/api/v1/user/adduser', method = 'POST';
				if (this.props.match.params.userId) {
					url = process.env.REACT_APP_API_URL + '/api/v1/user/' + this.props.match.params.userId;
					method = 'PATCH';
					delete data.access_type;
				}
				fetch(url, {
					method,
					headers: {
						'Content-Type': 'application/json',
						'x-auth-token': JSON.parse(readCookie('access_token'))
					},
					body: JSON.stringify(data)
				}).then((response) => {
					return response.json();
				}).then((data) => {
					if (data.status) {
						if (this.props.match.params.userId) {
							this.notifySuccess("Successfully updated User!");
							setTimeout(function () {
								window.location.pathname = window.location.pathname.split('/edit')[0];
							}, 1000);
						} else {
							this.notifySuccess("Successfully added new User!");
							setTimeout(function () {
								window.location.pathname = window.location.pathname.split('/add-user')[0];
							}, 1000);
						}
					} else {
						this.notifyError(data.errorMessage || 'Something went wrong, please try again later!');
					}
				});
			}
		} else {
			this.notifyError('Please fill up all the mandatory fields to submit.');
		}
	}

	formatLead = (cell, row) => {
		if (cell && row.id) {
			return (
				<a href={"/lead/" + row.id} rel="noopener noreferrer" target="_blank">{cell}</a>
			)
		} else return null;
	}

	formatDelete = (cell, row, index) => {
		if (cell && row.id) {
			return (
				<button className="btn delete-saarthi-lead-btn" onClick={this.deleteSaarthiLead.bind(this, row.id, index)}>Delete</button>
			)
		} else return null;
	}

	formatIndex = (cell, row) => {
		if (!isNaN(cell)) {
			return parseInt(cell) + 1;
		} else return null;
	}

	deleteSaarthiLead = (leadId, index) => {
		let userData = this.state.userData;
		if (userData.leads[index].id === leadId) userData.leads.splice(index, 1);
		this.setState({ userData });
	}

	openAddSaarthiLeadModal = () => {
		this.setState({ addSaarthiLeadModalVisibility: true, selectedSaarthiLeads: {} });
	}

	closeAddSaarthiLeadModal = () => {
		this.setState({ addSaarthiLeadModalVisibility: false, fetchingLeads: false, fetchedSaarthiLeads: [] });
	}

	assignCandidates = () => {
		let selectedSaarthiLeads = this.state.selectedSaarthiLeads, userData = this.state.userData;

		for (let i = 0; i < Object.keys(selectedSaarthiLeads).length; i++) {
			if (!userData.leads.some(lead => lead.id === Object.keys(selectedSaarthiLeads)[i])) {
				userData.leads.push({
					index: userData.leads.length,
					id: Object.keys(selectedSaarthiLeads)[i],
					name: Object.values(selectedSaarthiLeads)[i].name,
					mobileNumber: Object.values(selectedSaarthiLeads)[i].mobileNumber
				});
			}
		}

		this.setState({ userData, addSaarthiLeadModalVisibility: false, fetchingLeads: false, fetchedSaarthiLeads: [] })
	}

	fetchSaarthiLeads = (value) => {
		this.lastFetchId += 1;
		const fetchId = this.lastFetchId;
		this.setState({ fetchedSaarthiLeads: [], fetchingLeads: true });
		fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/brief?q=' + value, {
			method: 'GET',
			headers: {
				'x-auth-token': JSON.parse(readCookie('access_token'))
			}
		}).then(data => data.json())
			.then(data => {
				if (data.status) {
					if (fetchId !== this.lastFetchId) return; // for fetch callback order
					this.setState({ fetchedSaarthiLeads: data.simpleLeadInfo, fetchingLeads: false });
				}
			});
	}

	handleEmailSearch = (value) => {
		let userData = this.state.userData, errorObj = this.state.errorObj;

		if (!this.props.match.params.userId) {
			userData['email'] = value;
			ValidateData.emailValidate(value, errorObj);
			if (this.state.allExistingUsersArray.includes(value)) {
				userData['fullName'] = this.state.allExistingUsers[value]['fullName'];
				userData['mobileNumber'] = this.state.allExistingUsers[value]['mobileNumber'];
			}
		}
		let recommendedEmails;
		if (!value) {
			recommendedEmails = [];
		} else {
			recommendedEmails = this.state.allExistingUsersArray.filter(x=>x.includes(value.toLowerCase()));
		}
		this.setState({ recommendedEmails, userData, errorObj });
	}

	handleSelectSaarthiLeads = (values) => {
		let fetchedSaarthiLeads = this.state.fetchedSaarthiLeads, selectedSaarthiLeads = this.state.selectedSaarthiLeads;

		for (let i = 0; i < Object.keys(selectedSaarthiLeads).length; i++) {
			let selectedSaarthiLeadId = Object.keys(selectedSaarthiLeads)[i];
			if (values.indexOf(selectedSaarthiLeadId) === -1) {
				delete selectedSaarthiLeads[selectedSaarthiLeadId];
				i -= 1;
			}
		}
		for (let j = 0; j < values.length; j++) {
			if (!selectedSaarthiLeads[values[j]]) {
				selectedSaarthiLeads[values[j]] = fetchedSaarthiLeads.filter(lead => lead.id === values[j])[0];
			}
		}
		this.setState({ selectedSaarthiLeads });
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	render() {
		const columns = [
			{ dataField: 'index', text: 'Sl. No.', headerStyle: () => { return { width: '120px' } }, sort: false, export: false, formatter: this.formatIndex },
			{ dataField: 'mobileNumber', text: 'Phone No. of Candidate', sort: false, export: true },
			{ dataField: 'name', text: 'Name of Candidate', sort: false, export: true, formatter: this.formatLead },
			{ dataField: 'id', text: 'Delete', align: 'center', style: { padding: '4px' }, headerStyle: () => { return { width: '160px', textAlign: 'center' } }, sort: false, export: false, formatter: this.formatDelete }
		];
		return (
			<div className="add-Udaan-user">
				<Col className="d-flex mb20 align-items-center">
					{this.props.match.params.orgId ? (
						<a className="back-btn d-flex align-items-center" href={'/udaan-centres/' + this.props.match.params.type + '/' + this.props.match.params.orgId}>
							<i className="fas fa-chevron-left mr5"></i>Back
						</a>
					) : (
						<a className="back-btn d-flex align-items-center" href={this.props.match.params.userId ? '/user-types/' + this.props.match.params.type + '/' + this.props.match.params.userId : '/user-types/' + this.props.match.params.type}>
							<i className="fas fa-chevron-left mr5"></i>Back
						</a>
					)}
					<h3 className="header-custom">{this.props.match.params.userId ? 'EDIT UDAAN USER' : 'ADD A NEW UDAAN USER'}</h3>
				</Col>
				<Row className="body-conatiner">
				<Col md={4} className="elem-containers">
						<label className="control-label is-imp">Email</label>
						{!this.props.match.params.userId ? <AutoComplete placeholder="Enter email"
							value={this.state.userData.email}
							// className={'form-control'}
							// onChange={this.changeHandler.bind(this, 'email')}
							onSearch={this.handleEmailSearch}
							onSelect={this.handleEmailSearch}
							style={this.state.errorObj.hasOwnProperty('email') ? { borderColor: 'red', width: '285px' } : { width: '285px' }} >
							{
								(this.state.recommendedEmails || []).map(email => <AutoCompleteOption
									key={email} value={email} >{email}</AutoCompleteOption>)
							}
						</AutoComplete> : 
						 <input type="email" placeholder="Enter email" value={this.state.userData.email} className={'form-control'} onChange={this.changeHandler.bind(this, 'email')} disabled={this.props.match.params.userId} style={this.state.errorObj.hasOwnProperty('email') ? { borderColor: 'red' } : {}} />
						}
						{this.state.errorObj.hasOwnProperty('email') ? (
						<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.email}</div>
					) : (null)}
				</Col>
					<Col md={4} className="elem-containers">
						<label className="control-label is-imp">Full Name</label>
						<input type="text" placeholder="Enter name" value={this.state.userData.fullName}
							className={'form-control'} onChange={this.changeHandler.bind(this, 'fullName')}
							style={this.state.errorObj.hasOwnProperty('fullName') ? { borderColor: 'red' } : {}} />
						{this.state.errorObj.hasOwnProperty('fullName') ? (
							<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.fullName}</div>
						) : (null)}
					</Col>
					<Col md={4} className="elem-containers">
						<label className="control-label is-imp">Mobile</label>
						<input type="text" placeholder="Enter mobile" value={this.state.userData.mobileNumber}
							className={'form-control'} onChange={this.changeHandler.bind(this, 'mobileNumber')}
							style={this.state.errorObj.hasOwnProperty('mobileNumber') ? { borderColor: 'red' } : {}} />
						{this.state.errorObj.hasOwnProperty('mobileNumber') ? (
							<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.mobileNumber}</div>
						) : (null)}
					</Col>
					{this.props.match.params.type === 'zonal-manager' || this.props.match.params.type === 'swavalamban-fellows' ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">Reporting To</label>
							<Select size="large"
								// style={{}}
								value={this.state.userData.reportingTo}
								onChange={this.changeHandler.bind(this, 'reportingTo')}
								style={this.state.errorObj.hasOwnProperty('reportingTo') ? { width: '100%', borderColor: 'red' } : { width: '100%' }} filterOption={(input, option) => this.searchFilter(input, option)}>
								<Option value="" disabled>Select an option</Option>
								{this.state.reportingToList.length && this.props.match.params.type === 'swavalamban-fellows' ? (
									<OptGroup label="Zonal Managers">
										{this.state.reportingToList.map((reportingTo, i) => {
											if (reportingTo.type === 'zonal-manager') {
												return (
													<Option value={reportingTo.id} key={i}>{reportingTo.label}</Option>
												)
											} else return null;
										})}
									</OptGroup>
								) : (null)}
								{this.state.reportingToList.length ? (
									<OptGroup label="Project Management Unit">
										{this.state.reportingToList.map((reportingTo, i) => {
											if (reportingTo.type === 'project-management-unit') {
												return (
													<Option value={reportingTo.id} key={i}>{reportingTo.label}</Option>
												)
											} else return null;
										})}
									</OptGroup>
								) : (null)}
							</Select>
							{this.state.errorObj.hasOwnProperty('reportingTo') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.reportingTo}</div>
							) : (null)}
						</Col>
					) : (null)}
					<Col md={4} className="elem-containers">
						<label className="control-label is-imp">Status</label>
						<select value={this.state.userData.status} onChange={this.changeHandler.bind(this, 'status')} className="form-control" style={this.state.errorObj.hasOwnProperty('status') ? { borderColor: 'red' } : {}}>
							<option value="" disabled>Select an option</option>
							<option value="Active">Active</option>
							<option value="Inactive">Inactive</option>
						</select>
						{this.state.errorObj.hasOwnProperty('status') ? (
							<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.status}</div>
						) : (null)}
					</Col>
					{this.props.match.params.type === 'udaan' || this.props.match.params.type === 'local-government' || this.props.match.params.type === 'sidbi' ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">Role</label>
							<Select style={{ width: '100%', height: "38px" }} placeholder="Select Role" defaultValue={this.state.userData.role || ''} onChange={this.changeHandler.bind(this, 'accountType')}>
								<Option disabled value="">Select Role</Option>
								<Option value="Admin">Admin</Option>
								<Option value="Member">Member</Option>
							</Select>
							{this.state.errorObj.hasOwnProperty('city') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.city}</div>
							) : (null)}
						</Col>
					) : (null)}
					{this.state.userData.address && (this.props.match.params.type === 'zonal-manager' || this.props.match.params.type === 'swavalamban-fellows' || this.props.match.params.type === 'local-government' || this.props.match.params.type === 'udaan') ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">State</label>
							<Select mode={typeof this.state.userData.address.state === 'string' ? '' : 'multiple'} style={{ width: '100%', height: "38px" }} placeholder="Select State" value={this.state.userData.address.state} onChange={this.handleState}>
								<Option disabled value="">Select State</Option>
								{this.state.allStates.length ? (
									<OptGroup label="Focus States">
										{this.state.allStates.map((state, stateIndex) => {
											if (state.inFocusRegion) {
												return (
													<Option key={stateIndex} value={state.name}>{state.name}</Option>
												)
											} else return null;
										})}
									</OptGroup>
								) : (null)}
								{this.state.allStates.length ? (
									<OptGroup label="Not Focussed States">
										{this.state.allStates.map((state, stateIndex) => {
											if (!state.inFocusRegion) {
												return (
													<Option key={stateIndex} value={state.name}>{state.name}</Option>
												)
											} else return null;
										})}
									</OptGroup>
								) : (null)}
							</Select>
							{this.state.errorObj.hasOwnProperty('state') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.state}</div>
							) : (null)}
						</Col>
					) : (null)}
					{this.state.userData.address && (this.props.match.params.type === 'swavalamban-fellows' || this.props.match.params.type === 'udaan' || this.props.match.params.type === 'local-government') ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">District</label>
							<Select mode={typeof this.state.userData.address.district === 'string' ? '' : 'multiple'} style={{ width: '100%', height: "38px" }} placeholder="Select District" value={this.state.userData.address.district} onChange={this.handleDistrict}>
								<Option disabled value="">Select District</Option>
								{this.state.allDistricts.length ? (
									this.state.allDistricts.map((district, districtIndex) => {
										return (
											<Option key={districtIndex} value={district.label}>{district.label}</Option>
										)
									})
								) : (null)}
							</Select>
							{this.state.errorObj.hasOwnProperty('district') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.district}</div>
							) : (null)}
						</Col>
					) : (null)}
					{this.props.match.params.type === 'swavalamban-fellows' ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">Access Level</label>
							<Select style={{ width: '100%', height: "38px" }} placeholder="Select Access Level" value={this.state.userData.fellowAccessType} onChange={this.changeHandler.bind(this, 'fellowAccessType')}>
								<Option disabled value="">Select Access Level</Option>
								<Option value="Full">Full</Option>
								<Option value="Partial">Partial</Option>
							</Select>
							{this.state.errorObj.hasOwnProperty('district') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.district}</div>
							) : (null)}
						</Col>
					) : (null)}
					{this.props.match.params.type === 'saarthis' && this.state.userData.leads ? (
						<Col md={12} className="elem-containers">
							<label className="control-label is-imp">Assign Candidates</label>
							<BootstrapTable wrapperClasses="saarthi-leads-table" keyField="index" data={this.state.userData.leads} columns={columns} ref={(table) => { this.table = table; }} />
							<div className="text-center">
								<button className="btn btn-primary" onClick={this.openAddSaarthiLeadModal}><i className="fa fa-plus" />Add new candidate</button>
							</div>
						</Col>
					) : (null)}
					{this.state.userData.address && this.props.match.params.type === 'udaan' ? (
						<Col md={4} className="elem-containers">
							<label className="control-label is-imp">City</label>
							<Select mode={typeof this.state.userData.address.city === 'string' ? '' : 'multiple'} style={{ width: '100%', height: "38px" }} placeholder="Select Cities" defaultValue={this.state.userData.address.city} onChange={this.changeHandler.bind(this, 'city')} disabled={this.state.userData.address.state.length && this.state.userData.address.district.length ? false : true}>
								<Option disabled value="">Select City</Option>
								{this.state.allCities.length ? (
									this.state.allCities.map((city, cityIndex) => {
										return (
											<Option key={cityIndex} value={city.label}>{city.label}</Option>
										)
									})
								) : (null)}
							</Select>
							{this.state.errorObj.hasOwnProperty('city') ? (
								<div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.city}</div>
							) : (null)}
						</Col>
					) : (null)}
				</Row>
				<Col className="add-Udaan-user-footer mt20">
					<Button variant='primary' style={{ marginRight: '20px' }} onClick={this.submit}>Submit</Button>
					{/*<Button variant='secondary' onClick={this.cancelSubmit}>Cancel</Button>*/}
				</Col>

				<Modal className="add-saarthi-leads-modal" show={this.state.addSaarthiLeadModalVisibility} onHide={this.closeAddSaarthiLeadModal}>
					<Modal.Header className="user-modal-header">
						<Modal.Title className="modal-header-custom">Add Candidate</Modal.Title>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<Row className="body-container">
							<Col md={12}>
								<label className="control-label">Select Candidate(s)</label>
								<Select mode="multiple" value={Object.keys(this.state.selectedSaarthiLeads)} placeholder="Select Candidate(s)" notFoundContent={this.state.fetchingLeads ? <Spin size="small" /> : null} filterOption={false} onSearch={this.fetchSaarthiLeads} onChange={this.handleSelectSaarthiLeads} style={{ width: '100%' }}>
									{this.state.fetchedSaarthiLeads.map(lead => (
										<Option key={lead.id} value={lead.id}>{`${lead.name} (${lead.mobileNumber})`}</Option>
									))}
								</Select>
							</Col>
						</Row>
					</Modal.Body>
					<div className="modal-footer mt10">
						<div className="add-saarthi-leads-btn-container">
							<button className="btn btn-default add-saarthi-leads-btn mr10" disabled={this.state.fetchingLeads} onClick={this.assignCandidates}>Submit</button>
							<button className="btn btn-danger" onClick={this.closeAddSaarthiLeadModal}>Cancel</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

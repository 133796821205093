import React, { useState, useEffect } from 'react';
import './Home.css';
import {
    // Navbar, Nav, Container,
    Row,
    Col,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Multiselect } from 'multiselect-react-dropdown';


// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import axios from 'axios';
// import {
//     State,
//     // City
// } from 'country-state-city';
import {
    // EditFilled,
    FileAddFilled,
} from '@ant-design/icons';
import {
    // ConfigProvider, DatePicker,
    Select,
    // Input, Switch, Collapse
} from 'antd';
import moment from 'moment';

const readCookie = require('../../../cookie').readCookie;
const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`;
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYjk2YTA5NjYyNjAzNTE1M2M4NjgzYSIsInVzZXJUeXBlIjp7Il9pZCI6IjVkOGRlMjExMTBiYTkxMDNkMjFkMWE4NSIsIm5hbWUiOiJQcm9qZWN0IE1hbmFnZW1lbnQgVW5pdCJ9LCJ1c2VyUm9sZSI6bnVsbCwibmdvIjpudWxsLCJhY2NvdW50VHlwZSI6IlN1cGVyIFVzZXIiLCJwZXJtaXNzaW9ucyI6W10sImFwcGxpY2F0aW9uIjoiU3dhdGkiLCJpYXQiOjE2MjU1NDM4ODd9.zHckkR2zwaE66s__MthKV0DuSHyJLhNBdXAoDX28OPQ';//
const { Option,
    // OptGroup
} = Select;

function Home() {
    const [users, setusers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [availableStakeholders, setavailableStakeholders] = useState([]);
    // const [availableStates, setavailableStates] = useState([]);
    const availableStates = [["Andaman and Nicobar Islands", "AN"]
        , ["Andhra Pradesh", "AP"]
        , ["Arunachal Pradesh", "AR"]
        , ["Assam", "AS"]
        , ["Bihar", "BR"]
        , ["Chandigarh", "CH"]
        , ["Chhattisgarh", "CT"]
        , ["Dadra and Nagar Haveli and Daman and Diu", "DH"]
        , ["Delhi", "DL"]
        , ["Goa", "GA"]
        , ["Gujarat", "GJ"]
        , ["Haryana", "HR"]
        , ["Himachal Pradesh", "HP"]
        , ["Jammu and Kashmir", "JK"]
        , ["Jharkhand", "JH"]
        , ["Karnataka", "KA"]
        , ["Kerala", "KL"]
        , ["Ladakh", "LA"]
        , ["Lakshadweep", "LD"]
        , ["Madhya Pradesh", "MP"]
        , ["Maharashtra", "MH"]
        , ["Manipur", "MN"]
        , ["Meghalaya", "ML"]
        , ["Mizoram", "MZ"]
        , ["Nagaland", "NL"]
        , ["Odisha", "OR"]
        , ["Puducherry", "PY"]
        , ["Punjab", "PB"]
        , ["Rajasthan", "RJ"]
        , ["Sikkim", "SK"]
        , ["Tamil Nadu", "TN"]
        , ["Telangana", "TG"]
        , ["Tripura", "TR"]
        , ["Uttar Pradesh", "UP"]
        , ["Uttarakhand", "UT"]
        , ["West Bengal", "WB"]];
    // const [APIresponse, setAPIresponse] = useState({});

    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [stakeholders, setStakeholders] = useState([]);
    const [designations, setDesignations] = useState([]);

    const [allDistricts, setAllDistricts] = useState([]);
    // const [allDesignations, setAllDesignations] = useState([]);
    const INITIAL_FILTER_OBJ = {
        state: '',
        district: '',
        stakeholder: '',
        designation: '',
        followUps: {
            isOverdue: false, isThisWeek: false, isToday: false,
        }
    };
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJ);

    const [followUps, setfollowUps] = useState({
        overdue: 0,
        dueToday: 0,
        dueThisWeek: 0,
    });
    const filterHandler = (option, type) => {
        let filterChange = filterObj;
        if (type === 'state') {
            let districtsFilter = allDistricts.filter(district => district.state === option);
            setDistricts(districtsFilter);
            filterChange[type] = option;
            filterChange.district = '';
            setFilterObj(filterChange);
            fetchData();
        }
        else if (type === 'stakeholder') {
            let designationFilter = stakeholders.filter(stakeholder => stakeholder.type === option);
            designationFilter.length === 0 ? setDesignations([]) : setDesignations(designationFilter[0].designations || []);
            filterChange[type] = option;
            filterChange.designation = '';
            setFilterObj(filterChange);
            fetchData();
        }
        else {
            filterChange[type] = option;
            setFilterObj(filterChange);
            fetchData();
        }
    }

    const { SearchBar } = Search;
    const getFilterOptions = () => {
        fetch(`${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/filters`, {
            method: 'GET',
            headers: {
                'Authorization': AUTH_TOKEN
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    setStates(data.states);
                    setAllDistricts(data.districts);
                    setStakeholders(data.stakeholder_types);
                    setavailableStakeholders(data.stakeholder_types);
                }
            });
    }
    async function fetchData(query = false) {
        let filter = '?';

        if (query)
            filter = '';
        else {

            if (filterObj.designation) filter += `designation=${filterObj.designation}&`;
            if (filterObj.district) filter += `district=${filterObj.district}&`;
            if (filterObj.state) filter += `state=${filterObj.state}&`;
            if (filterObj.stakeholder) filter += `stakeholder=${filterObj.stakeholder}&`;
            for (let key in filterObj.followUps) filter += `${key}=${filterObj.followUps[key]}&`;
        }
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/summary${filter}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setusers(response.data.summaryList);
                const { overdue, dueThisWeek, dueToday } = (response.data.followUpObj);
                setfollowUps({
                    overdue,
                    dueToday,
                    dueThisWeek,
                });
            }
        });

        const urlListStakeholders = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder${filter}`;
        axios.get(urlListStakeholders, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // const states = [];
                // for (let key = 0; key < State.getStatesOfCountry('IN').length; key += 1) {
                //     states.push([State.getStatesOfCountry('IN')[key].name, State.getStatesOfCountry('IN')[key].isoCode]);
                // }
                // setavailableStates(states);
            }
        });
    }
    useEffect(() => {
        resetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObj]);
    // useEffect(() => {
    //     console.log('here')
    //     fetchData();
    // }, [filterObj]);

    const resetFilters = () => {
        setDistricts([]);
        setDesignations([]);
        setFilterObj(INITIAL_FILTER_OBJ);
        getFilterOptions();
        fetchData(true);
    }
    function editButton(cell, row) {
        const { _id, name } = row;
        // console.log(_id);
        return (
            <div>
                <Link to={`/stakeholders/user/${_id}/${name}`}>
                    {/* <EditFilled/> */}
                    <button className="btn">View/ Update</button>
                </Link>
            </div>
        );
    }
    const emailFormatter = (cell) => {
        if (cell) {
            return <span style={{ wordBreak: "break-word" }}>{cell}</span>
        }
        else {
            return null;
        }
    }
    const formatLastInteractedTime = (cell, row) => {
        if (cell) {
            return <span>{moment(cell).format('DD-MM-YYYY, hh:mm a')}</span>
        }
        else {
            return <span style={{ textAlign: "center" }}>No successful interactions recorded yet!
                <br />
                <Link to={`/stakeholders/user/${row._id}/${row.name}`}>
                    {/* <EditFilled/> */}
                    <button className="btn btn-sm">Add an interaction now</button>
                </Link></span>
        }
    }
    const formatTime = (cell) => {
        if (cell) {
            return <span>{moment(cell).format('DD-MM-YYYY, HH:mm a')}</span>
        }
        else return null;
    }
    // // eslint-disable-next-line no-unused-vars
    // let stateFilter;
    // // eslint-disable-next-line no-unused-vars
    // let districtFilter;
    // // eslint-disable-next-line no-unused-vars
    // let stakeholderTypeFilter;
    // // eslint-disable-next-line no-unused-vars
    // let designationFilter;
    // // eslint-disable-next-line no-unused-vars
    // let statusFilter;
    const districtFormatter = (cell) => {
        if (cell) {
            return <span>{typeof cell === 'string' ? cell : cell.join(', ')}</span>
        }
        else return null;
    }
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'Edit',
            isDummyField: true,
            formatter: editButton,
            text: 'View/Update',
            headerStyle: () => ({ textAlign: 'center', width: '110px' })
        },

        {
            dataField: 'mobileNumber',
            text: 'Primary Contact Number',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'email',
            text: 'Email',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
            formatter: emailFormatter
        },
        {
            dataField: 'stakeHolderType',
            text: 'Stakeholder Type',
            // filter: textFilter({
            //     getFilter: (filter) => {
            //         stakeholderTypeFilter = filter;
            //     },
            // }),
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'designation',
            text: 'Designation',
            // filter: textFilter({
            //     getFilter: (filter) => {
            //         designationFilter = filter;
            //     },
            // }),
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'state',
            text: 'State',
            // filter: textFilter({
            //     getFilter: (filter) => {
            //         stateFilter = filter;
            //     },
            // }),
            headerStyle: () => ({ textAlign: 'center', width: '100px' }),
        },
        {
            dataField: 'district',
            text: 'District',
            // filter: textFilter({
            //     getFilter: (filter) => {
            //         districtFilter = filter;
            //     },
            // }),
            formatter: districtFormatter,
            headerStyle: () => ({ textAlign: 'center', width: '100px' }),
        },
        {
            dataField: 'sentiment',
            text: 'Overall Sentiment',

            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'lastSuccessfulInteraction.date',
            text: 'Last Successful Interaction',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
            formatter: formatLastInteractedTime,
        },
        {
            dataField: 'successfulInteractions',
            text: 'Total Successful Interactions',

            headerStyle: () => ({ textAlign: 'center', width: '150px' }),
        },
        {
            dataField: 'failedAttempts',
            text: 'Failed Attempts',

            headerStyle: () => ({ textAlign: 'center', width: '150px' }),
        },
        {
            dataField: 'datasetReceived',
            text: 'Dataset Received',

            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
        {
            dataField: 'status',
            text: 'Status',
            // filter: textFilter({
            //     getFilter: (filter) => {
            //         statusFilter = filter;
            //     },
            // }),
            headerStyle: () => ({ textAlign: 'center', width: '100px' }),
        },
        {
            dataField: 'createdAt',
            text: 'Added On',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
            formatter: formatTime
        },
        {
            dataField: 'updated.on',
            text: 'Last Updated On',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
            formatter: formatTime
        },
        {
            dataField: 'updated.by.name',
            text: 'Last Updated By',
            headerStyle: () => ({ textAlign: 'center', width: '200px' }),
        },
    ];

    function MyVerticallyCenteredModal(props) {
        const multiselectRef = React.createRef();
        const { onHide } = props;
        const [availableDesignations, setavailableDesignations] = useState([]);
        const [availableDistricts, setavailableDistricts] = useState([]);

        const [newUser, setnewUser] = useState({
            name: "",
            stakeHolderType: "",
            designation: "",
            state: "",
            district: [],
            address: "",
            mobileNumber: "",
            alternateNumber: "",
            email: "",
            connectionSource: "",
            status: "",
            sentiment: "",
        });

        function handleInputChange(e, field) {
            const tempUser = { ...newUser };
            if (field === 'mobileNumber' || field === 'alternateNumber') {
                tempUser[field] = e.target.value;//parseInt(e.target.value, 10);
            } else {
                tempUser[field] = e.target.value;
            }
            setnewUser(tempUser);
        }

        function handleDropdownSelectChange(e, changeSource) {
            if (changeSource === 'stakeHolderType') {
                // for (let i = 0; i < APIresponse.length; i += 1) {
                //     if (APIresponse[i].type === e.target.value) {
                //         setavailableDesignations(APIresponse[i].designations);
                //     }
                // }
                let designationFilter = stakeholders.filter(stakeholder => stakeholder.type === e.target.value);
                designationFilter.length === 0 ? setavailableDesignations([]) : setavailableDesignations(designationFilter[0].designations || []);
            }
            if (changeSource === 'state') {
                // const districts = [];
                // let stateCode;
                // for (let key = 0; key < availableStates.length; key += 1) {
                //     if (availableStates[key][0] === e.target.value) {
                //         const [, code] = availableStates[key];
                //         stateCode = code;
                //     }
                // }
                // for (let key = 0; key < City.getCitiesOfState('IN', stateCode).length; key += 1) {
                //     districts.push(City.getCitiesOfState('IN', stateCode)[key].name);
                // }
                // setavailableDistricts(districts);
                fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts?state=' + e.target.value, {
                    method: 'GET',
                    headers: {
                        'x-auth-token': JSON.parse(readCookie('access_token'))
                    }
                }).then(data => data.json())
                    .then(data => {
                        if (data.status) {
                            // this.setState({ allDistricts: data.districts })
                            // let districts = [];
                            // for (let i = 0; i < data.districts.length; i++) districts.push(data.districts[i].name);
                            // this.setState({ uploadCandidateDistricts: districts });
                            setavailableDistricts(data.districts);
                        }
                    });
                multiselectRef.current.resetSelectedValues();
            }
            // if (changeSource === 'district') {
            //     console.log(e.target.value);
            //     let x= [].slice.call(e.target.selectedOptions).map(item => item.value);
            //     console.log(x);
            //     const tempUser = { ...newUser };
            //     tempUser[changeSource] = x;
            //     setnewUser(tempUser);
            // }
            // else {
            const tempUser = { ...newUser };
            tempUser[changeSource] = e.target.value;
            setnewUser(tempUser);

            // }
        }



        function updateUser() {
            const values = { ...newUser };
            const wrongFields = [];

            // const a = /^\d{10}$/;
            const a = /(?:\s+|)((0|(?:(\+|)91))(?:\s|-)*(?:(?:\d(?:\s|-)*\d{9})|(?:\d{2}(?:\s|-)*\d{8})|(?:\d{3}(?:\s|-)*\d{7}))|\d{10})(?:\s+|)/;
            if (a.test(values.mobileNumber)) {
                if (!values.alternateNumber || a.test(values.alternateNumber)) {
                    if (!values.email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
                        // console.log(newUser);
                        const config = { headers: { Authorization: AUTH_TOKEN } };
                        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user/add-user`;
                        axios.post(url, newUser, config).then((response) => {
                            // console.log(response);
                            if (response.status === 200 && response.data.error === false) {
                                // console.log(response.data);
                                swal('Successfully Added', '', 'success');
                                fetchData();
                            }
                        }).catch((err) => {
                            swal(err.response.data.errorMessage, '', 'error');
                        });
                        // users.push({ ...newUser });
                        // setusers(users);
                        onHide();
                    } else {
                        swal('You have entered an invalid email address!', '', 'error');
                        wrongFields.push('Email');
                    }
                } else {
                    swal('Your Alternate Number Is Not Valid.', '', 'error');
                    wrongFields.push('Alternate Number');
                }
            } else {
                swal('Your Primary Contact Number Is Not Valid.', '', 'error');
                wrongFields.push('Mobile Number');
            }
        }
        function isTrue(value) {
            return value !== false;
        }
        function handleDistricts(selectedList) {
            let districts = selectedList.map(d => d.name);
            const tempUser = { ...newUser };
            tempUser['district'] = districts;
            setnewUser(tempUser);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Name'}*</div>
                                <input
                                    type="text"
                                    placeholder='Name'
                                    onChange={(e) => handleInputChange(e, 'name')}
                                    style={{ height: '100%', width: '60%', border: 'none' }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Stakeholder Type'}*</div>
                                <Form.Control value={newUser.stakeHolderType || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'stakeHolderType')}>
                                    <option disabled value={-1}>Select Stakeholder Type</option>
                                    {
                                        availableStakeholders.map(
                                            (value, index) => <option key={index}>{value.type}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Designation'}*</div>
                                <Form.Control value={newUser.designation || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'designation')}>
                                    <option disabled value={-1}>Select Designation</option>
                                    {
                                        availableDesignations.map(
                                            (value, index) => <option key={index}>{value}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'State'}*</div>
                                <Form.Control value={newUser.state || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'state')}>
                                    <option disabled value={-1}>Select State</option>
                                    {
                                        availableStates.map(
                                            (value, index) => <option key={index}>{value[0]}</option>,
                                        )
                                    }
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'District(s)'}*</div>
                                {/* <Form.Control value={newUser.district || -1} as="select" multiple style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'district')}> */}
                                {/* <option disabled value={-1}>Select District(s)</option>
                                    {
                                        availableDistricts.map(
                                            (value, index) => <option key={index}>{value.name}</option>,
                                        )
                                    } */}
                                {/* </Form.Control> */}
                                <div style={{
                                    textAlign: 'left',
                                    width: '70%',
                                }}>
                                    <Multiselect options={availableDistricts}
                                        // key={districtIndex}
                                        // style={{ height: '100%', width: '60%' }}
                                        // style={{
                                        //     width: "85%",
                                        //     marginLeft: "15%"
                                        // }}
                                        placeholder={"Select District(s)"}
                                        onSelect={handleDistricts}
                                        onRemove={handleDistricts}
                                        ref={multiselectRef}
                                        selectedValues={newUser.district ? newUser.district.map(d => { return { name: d } }) : []}
                                        displayValue={"name"} /></div>
                                {/* {district.name} */}
                                {/* </Multiselect> */}
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Address'}</div>
                                <input
                                    type="text"
                                    placeholder='Address'
                                    onChange={(e) => handleInputChange(e, 'address')}
                                    style={{ height: '100%', width: '60%', border: 'none' }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Mobile'}*</div>
                                <input
                                    type="text"
                                    placeholder='Mobile'
                                    onChange={(e) => handleInputChange(e, 'mobileNumber')}
                                    style={{ height: '100%', width: '60%', border: 'none' }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Alternative Number'}
                                    {/* * */}
                                </div>
                                <input
                                    type="text"
                                    placeholder='Alternate Number'
                                    onChange={(e) => handleInputChange(e, 'alternateNumber')}
                                    style={{ height: '100%', width: '60%', border: 'none' }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'E-mail'}
                                    {/* * */}
                                </div>
                                <input
                                    type="text"
                                    placeholder='Email'
                                    onChange={(e) => handleInputChange(e, 'email')}
                                    style={{ height: '100%', width: '60%', border: 'none' }}
                                    required
                                />
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Source of connection'}*</div>
                                <Form.Control
                                    value={newUser.connectionSource || -1} as="select"
                                    style={{ height: '100%', width: '60%' }}
                                    onChange={(e) => handleDropdownSelectChange(e, 'connectionSource')}>
                                    <option disabled value={-1}>Select Source</option>
                                    <option>Direct</option>
                                    <option>Email</option>
                                    <option>Phone</option>
                                    <option>Visit</option>
                                    <option>Referral</option>
                                    <option>PMU</option>
                                    <option>State-level</option>
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Status'}*</div>
                                <Form.Control value={newUser.status || -1} as="select" style={{ height: '100%', width: '60%' }} onChange={(e) => handleDropdownSelectChange(e, 'status')}>
                                    <option disabled value={-1}>Select Status</option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </Form.Control>
                            </div>
                            <div
                                className='dynamicInputFields'>
                                <div style={{
                                    textAlign: 'right',
                                    width: '30%',
                                }}>{'Overall sentiment'}*</div>
                                <Form.Control
                                    value={newUser.sentiment || -1} as="select"
                                    style={{ height: '100%', width: '60%' }}
                                    onChange={(e) => handleDropdownSelectChange(e, 'sentiment')}>
                                    <option disabled value={-1}>Select Overall Sentiment</option>
                                    <option >Positive</option>
                                    <option >Neutral</option>
                                    <option >Negative</option>
                                    <option >Cannot Say</option>
                                </Form.Control>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        // onClick={updateUser}
                        onClick={() => {
                            swal('Do you really want to add a new user?', {
                                buttons: {
                                    catch: {
                                        text: 'Yes',
                                        value: 'Yes',
                                    },
                                    No: true,
                                },
                            })
                                .then((value) => {
                                    switch (value) {
                                        case 'Yes':
                                            updateUser();
                                            break;

                                        default:
                                    }
                                });
                        }}
                        disabled={!(newUser.name && newUser.stakeHolderType && newUser.designation && newUser.state && newUser.district && newUser.mobileNumber  && newUser.connectionSource && newUser.status && newUser.sentiment)}
// newUser.alternateNumber && // newUser.email && newUser.address
                    >Add</Button>
                </Modal.Footer>
            </Modal >
        );
    }
    MyVerticallyCenteredModal.propTypes = {
        onHide: PropTypes.func,
    };

    return (
        <div className='homeContainer'>
            {/* <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>LetsEndorse</Navbar.Brand>
                </Container>
            </Navbar> */}
            <div className='homeHeader'>
                {/* <div className='totalTextcontainer'>
                    <div className='homeTotalText'>Total: {users.length} Users</div>
                </div> */}
                {/* <div className='followUpContainer'>
                    <div style={{ color: 'white', fontWeight: '600' }}>FOLLOW-UPs:</div>
                    <div className='followUpElement'>{followUps.overdue} OVERDUE</div>
                    <div className='followUpElement'>{followUps.dueToday} DUE TODAY</div>
                    <div className='followUpElement'>{followUps.dueThisWeek} DUE THIS WEEK</div>
                </div> */}
                <div className='follow-up-nav-container d-flex'>
                    <span className='follow-up-text'>Total: {users.length} Users</span>
                    <div className="count">
                        {followUps.overdue} OVERDUE
                    </div>
                    <div className="count">
                        {followUps.dueToday} DUE TODAY
                    </div>
                    <div className="count">
                        {followUps.dueThisWeek} DUE THIS WEEK
                    </div>
                </div>
            </div>

            {/* <Navbar collapseOnSelect expand="lg" >
                <Container>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Row>
                            <Nav.Link href="/stakeholders/manage" style={{ color: 'white', fontWeight: '600' }}>Manage Stakeholders</Nav.Link>
                               <Nav.Link onClick={() => setModalShow(true)} style={{
                                color: 'white',
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                }}>
                                Add New User
                                <FileAddFilled style={{ paddingLeft: 5 }} />
                                </Nav.Link></Row>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}

            {/* <Row>
                <Col md={3}>

                </Col>
                <Col md={3}>

                </Col>
                <Col md={3}>

                </Col>
                <Col md={3}>

                </Col>
            </Row> */}
            {/* <div className="filters-container">
                <div className="filter form-group">
                    <label className="control-label">{'State: '}</label>
                    <Select showSearch style={{ width: '100%' }} value={this.state.filterData.state} onChange={this.changeStateFilter} filterOption={(input, option) => this.searchFilter(input, option)}>
                        <Option value="">All States</Option>
                        <OptGroup label="Focus States">
                            {availableStates.map((state, stateIndex) => {
                                if (state.inFocusRegion) {
                                    return (
                                        <Option key={stateIndex} value={state.name}>{state.name}</Option>
                                    )
                                } else return null;
                            })}
                        </OptGroup>
                    </Select>
                </div>
                <div className="filter form-group">
                    <label className="control-label">{'District: '}</label>
                    <Select showSearch style={{ width: '100%' }} value={filterObj.district} onChange={this.changeFilterHandler.bind(this, 'district')} filterOption={(input, option) => this.searchFilter(input, option)}>
                        <Option value="">All Districts</Option>
                        {this.state.filterData.state ? (
                            this.state.allDistricts.map((district, districtIndex) => {
                                // let districtName = district.name;
                                // if(district.state) districtName += ' (' + district.state + ')';
                                return (
                                    <Option value={district.name} title={district.name} key={districtIndex}>{district.name}</Option>
                                )
                            })
                        ) : (null)}
                    </Select>
                </div>
                <div className="filter form-group">
                    <label className="control-label">{'Main Branch: '}</label>
                    <Select style={{ width: '100%' }} value={this.state.filterData.mainBranch} onChange={this.changeFilterHandler.bind(this, 'mainBranch')}>
                        <Option value="">Select</Option>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </div>
                <div className="filter form-group">
                    <label className="control-label">{'Lending Branch: '}</label>
                    <Select style={{ width: '100%' }} value={this.state.filterData.lendingBranch} onChange={this.changeFilterHandler.bind(this, 'lendingBranch')}>
                        <Option value="">Select</Option>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </div>
                <div className="filter form-group">
                    <label className="control-label">{'Experience: '}</label>
                    <Select style={{ width: '100%' }} value={this.state.filterData.experience} onChange={this.changeFilterHandler.bind(this, 'experience')}>
                        <Option value="">Select</Option>
                        <Option value="Good">Good</Option>
                        <Option value="Average">Average</Option>
                        <Option value="Poor">Poor</Option>
                        <Option value="Unknown">Unknown</Option>
                    </Select>
                </div>
                <div className="filter form-group">
                    <label className="control-label">{'Has Contact Details: '}</label>
                    <Select style={{ width: '100%' }} value={this.state.filterData.contactDetails} onChange={this.changeFilterHandler.bind(this, 'contactDetails')}>
                        <Option value="">Select</Option>
                        <Option value="Verified">Yes - Verified</Option>
                        <Option value="Unverified">Yes - Unverified</Option>
                        <Option value="Not-available">Not-available</Option>
                    </Select>
                </div>
                {/* <div className="filter form-group">
						<label className="control-label">{'Verification: '}</label>
						<Select style={{width: '100%'}} value={this.state.filterData.verification} onChange={this.changeFilterHandler.bind(this, 'verification')}>
							<Option value="">Select</Option>
							<Option value="Verfied">Verfied</Option>
							<Option value="Not Verified">Not Verified</Option>
						</Select>
					</div> */}
            {/* <div className="filter form-group">
                    <label className="control-label">{'Bank Name: '}</label>
                    <Select style={{ width: '100%' }} value={this.state.filterData.bankName} onChange={this.changeFilterHandler.bind(this, 'bankName')}>
                        <Option value="">Select Bank</Option>
                        {
                            this.state.allBanks.map((bank, index) => {
                                return <Option key={index} value={bank}>{bank}</Option>
                            })
                        }
                    </Select>
                </div>
                <div className="filter form-group reset">
                    <button className="btn reset-filters" onClick={this.resetFilter}>Reset</button>
                </div>
            </div> */}
            <div
                style={{
                    marginTop: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexDirection: 'colomn',
                    height: 50,
                    background: 'White',
                    width: '100%',
                    // marginBottom: 5,
                }}
            >
                <div
                    style={{
                        color: 'white',
                        height: '100%',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        background: 'tomato',
                        marginRight: 10,
                        cursor: 'pointer',
                        borderRadius: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    <a style={{ color: 'white', display: 'block' }} href="/stakeholders/manage">
                        Manage Stakeholders
                    </a>
                </div>
                <div
                    onClick={() => setModalShow(true)}
                    style={{
                        color: 'white',
                        fontWeight: '600',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        background: 'tomato',
                        marginRight: 10,
                        cursor: 'pointer',
                        borderRadius: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 15,
                    }}
                >
                    Add New User
                    <FileAddFilled style={{ paddingLeft: 5 }} />
                </div>
            </div>

            <div className='homeTableContainer'>
                <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    data={users}
                    columns={columns}
                    search
                >
                    {
                        (props) => (
                            <>
                                <div className="filter-container">
                                    <Row>

                                        <Col md={2}>
                                            <div className="filter-header">
                                                State</div>
                                            <div className="filter-dropdown">
                                                <Select showSearch style={{ width: '100%' }} value={filterObj.state} onChange={(e) => { filterHandler(e, 'state') }} >
                                                    <Option value="">All States</Option>
                                                    {/* <OptGroup label="Focus States"> */}
                                                    {states.map((state, stateIndex) => {
                                                        // if (state.inFocusRegion) {
                                                        return (
                                                            <Option key={stateIndex} value={state.name}>{state.name}</Option>
                                                        )
                                                        // } else return null;
                                                    })}
                                                    {/* </OptGroup> */}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="filter-header">

                                                District
                                            </div>      <div className="filter-dropdown">
                                                <Select showSearch style={{ width: '100%' }} value={filterObj.district} onChange={(e) => { filterHandler(e, 'district') }} >
                                                    <Option value="">All Districts</Option>
                                                    {/* <OptGroup label="Focus States"> */}
                                                    {districts.map((district, districtIndex) => {
                                                        // if (state.inFocusRegion) {
                                                        return (
                                                            <Option key={districtIndex} value={district.name}>{district.name}</Option>
                                                        )
                                                        // } else return null;
                                                    })}
                                                    {/* </OptGroup> */}
                                                </Select>
                                            </div>              </Col>
                                        <Col md={3}>
                                            <div className="filter-header">
                                                Stakeholder Type
                                            </div>
                                            <div className="filter-dropdown">
                                                <Select showSearch style={{ width: '100%' }} value={filterObj.stakeholder} onChange={(e) => { filterHandler(e, 'stakeholder') }} >
                                                    <Option value="">All Stakeholder Types</Option>
                                                    {/* <OptGroup label="Focus States"> */}
                                                    {stakeholders.map((stakeholder, stakeholderIndex) => {
                                                        // if (state.inFocusRegion) {
                                                        return (
                                                            <Option key={stakeholderIndex} value={stakeholder.type}>{stakeholder.type}</Option>
                                                        )
                                                        // } else return null;
                                                    })}
                                                    {/* </OptGroup> */}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="filter-header">
                                                Designation
                                            </div>
                                            <div className="filter-dropdown">
                                                <Select showSearch style={{ width: '100%' }} value={filterObj.designation} onChange={(e) => { filterHandler(e, 'designation') }} >
                                                    <Option value="">All Designations</Option>
                                                    {/* <OptGroup label="Focus States"> */}
                                                    {designations.map((designation, designationIndex) => {
                                                        // if (state.inFocusRegion) {
                                                        return (
                                                            <Option key={designationIndex} value={designation}>{designation}</Option>
                                                        )
                                                        // } else return null;
                                                    })}
                                                    {/* </OptGroup> */}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="filter-header">
                                                {/* Designation */}
                                            </div>
                                            <div className="filter-dropdown">
                                                <button className="btn btn-danger btn-filter-reset" disabled={!filterObj.designation && !filterObj.district
                                                    && !filterObj.stakeholder && !filterObj.state} onClick={resetFilters}>Reset</button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <div className="form-check">

                                                <input defaultChecked={filterObj.followUps.isOverdue} className="form-check-input" id="is-overdue" type="checkbox" onChange={(e) => { setFilterObj({ ...filterObj, followUps: { ...filterObj.followUps, isOverdue: e.target.checked } }); }} />
                                                <label className="form-check-label" for="is-overdue">
                                                    Overdue
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-check" id="is-today">

                                                <input defaultChecked={filterObj.followUps.isToday} className="form-check-input" type="checkbox" onChange={(e) => { setFilterObj({ ...filterObj, followUps: { ...filterObj.followUps, isToday: e.target.checked } }); }} />
                                                <label className="form-check-label" for="is-today">   Due Today
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-check">

                                                <input defaultChecked={filterObj.followUps.isThisWeek} className="form-check-input" id="is-this-week" type="checkbox" onChange={(e) => { setFilterObj({ ...filterObj, followUps: { ...filterObj.followUps, isThisWeek: e.target.checked } }); }} />
                                                <label className="form-check-label" for="is-this-week">  Due This Week
                                                </label>
                                            </div>
                                        </Col>
                              

                                        <Col md={6}>
                                {/* <div style={{ paddingLeft: 20 }}> */}

                                            {/* <div className="col-6"> */}
                                            <Row>
                                                <Col md={1}>Search:</Col><Col md={11}><SearchBar {...props.searchProps} style={{height: "30px", width:"70%",marginLeft: "2%"}} />
                                                </Col> {/* </div><div className="col-6"> */}
                                            {/* </div> */}
                                            </Row></Col>
                                    </Row>
                                </div>
                            <div className='homeTableContainer'>
                                

                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory({ sizePerPage: 50, sizePerPageList: [50, 100, 150, 200] })}
                                // filter={ filterFactory() }
                                />
                                </div>
                                </>
                        )
                    }
                </ToolkitProvider>
            </div>
            < MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
            />

        </div>
    );
}

Home.propTypes = {
    baseProps: PropTypes.object,
    searchProps: PropTypes.object,
};

export default Home;

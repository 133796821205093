/** @format */

import React from "react";
import Swal from "sweetalert2";
import {
 
  Select,
} from "antd";
import {
  Col,
  Row,
  // Button, Modal
} from "react-bootstrap";
const { OptGroup, Option } = Select;

const BasicDDFormComponent = ({
  leadData,
  projectData,
  globalDDFormState,
  setGlobalDDFormState,
  dueDiligenceChange, 
  isEditable,
  saveToProfile,
  showHoldPrompt,
  showDisqualificationPrompt,
  stages,
}) => {
  // console.log(stages.dueDiligence.questions, "stages.dueDiligence.questions")
  return (
    <>
      <h3>BASIC</h3>
      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Are you {leadData.basic.name.toUpperCase()}
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.basic.correctName}
          disabled={!isEditable}
          onChange={(e)=>dueDiligenceChange(
            e,
            "basic",
            // "questions",
            "correctName"
          )}
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Yes",
            "Not available to speak now",
            "Wrong number",
            "Relative explaining the business",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.basic.correctName ===
      "Wrong number" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Are you interested in starting a new business/scaling-up your existing
          business?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.basic.interestedInBusiness}
          disabled={!isEditable}
          onChange={(e)=>dueDiligenceChange(
            e,
            "basic",
            // "questions",
            "interestedInBusiness"
          )}
        >
          <Option value='' disabled>Select Option</Option>
          {["Yes", "No", "After some time"].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>
      {globalDDFormState.basic.interestedInBusiness ===
      "No" ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            disabled={!isEditable}
            onClick={showDisqualificationPrompt}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.basic.interestedInBusiness ===
      "After some time" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e)=>showHoldPrompt(
              "dueDiligence"
            )}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      {globalDDFormState.basic.financeType ===
      "Bank Loan required" ? (
        <Col md={12} className='due-diligence-question same-line'>
          <label className='control-label'>
            Is the person who is involved in day-to-day activities of business
            same as loan applicant?
          </label>
          <Select
            size='large'
            style={{ width: "100%" }}
            placeholder='Select'
            value={
              globalDDFormState.basic
                .isCandidateInvolvedInBusiness
            }
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "isCandidateInvolvedInBusiness"
            )}
          >
            <Option value='' disabled>Select Option</Option>
            {["Yes", "No"].map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Col>
      ) : null}

      {globalDDFormState.basic.isCandidateInvolvedInBusiness === "No" ? (
        <Col md={12} className='due-diligence-question same-line'>
          <label className='control-label'>
            Check with the loan applicant about their knowledge of the business
          </label>
          <Select
            size='large'
            style={{ width: "100%" }}
            placeholder='Select'
            value={globalDDFormState.basic.knowledgeOfBusiness}
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "knowledgeOfBusiness"
            )}
          >
            <Option value='' disabled>Select Option</Option>
            {[
              "The loan applicant has no idea of the business",
              "The loan applicant will be actively involved in business.",
            ].map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Col>
      ) : null}
      {globalDDFormState.basic.isCandidateInvolvedInBusiness === "No" &&
      globalDDFormState.basic.knowledgeOfBusiness ===
        "The loan applicant has no idea of the business" ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e)=>showHoldPrompt(
              "dueDiligence"
            )}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          How did you come to know about the toll-free number/NGO?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.basic.sourceOfKnowledge}
          disabled={!isEditable}
          onChange={(e)=>dueDiligenceChange(
            e,
            "basic",
            // "questions",
            "sourceOfKnowledge"
          )}
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Referred by friend",
            "Referred by other candidate",
            "Referred by NGO",
            "WhatsApp",
            "Social Media",
            "Poster/Pamphlet",
            "Radio",
            "Newspaper",
            "Other",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>

      {globalDDFormState.basic.sourceOfKnowledge ===
      "Other" ? (
        <Col md={12} className='due-diligence-question same-line'>
          <label className='control-label'>
            Please enter the source of your information
          </label>
          <input
            type='text'
            placeholder='Enter source of information'
            value={
              globalDDFormState.basic.otherSourceOfInformation
            }
            className={"form-control"}
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "otherSourceOfInformation"
            )}
          />
        </Col>
      ) : null}

      {/* {console.log(leadData, "LEAD DATA")} */}

      {leadData.enterpriseStatus.phase === "Start-up" ? (
        <Col md={12} className='due-diligence-question'>
          <label className='control-label'>
            You want to start a {globalDDFormState.basic.businessIdea} enterprise. Why
            do you want to do this business?
          </label>
          <Select
            size='large'
            mode='multiple'
            style={{ width: "100%" }}
            placeholder='Select'
            value={globalDDFormState.basic.reasonForBusiness || []}
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "reasonForBusiness"
            )}
          >
            <Option value='' disabled>Select Option</Option>
            {[
              "Prior Experience",
              "Family business",
              "Training received",
              "Understand the business well",
              "Motivated by family/friends",
              "Good potential in the area",
              "Little idea (can learn more and get back)",
              "No idea/intention",
            ].map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Col>
      ) : null}
      {globalDDFormState.basic.reasonForBusiness &&
      globalDDFormState.basic.reasonForBusiness.indexOf(
        "No idea/intention"
      ) > -1 ? (
        <Col md={12}>
          <button
            className='btn btn-default mark-as-disqualified-btn'
            onClick={showDisqualificationPrompt}
            disabled={!isEditable}
          >
            MARK AS DISQUALIFIED
          </button>
        </Col>
      ) : null}
      {globalDDFormState.basic.reasonForBusiness &&
      globalDDFormState.basic.reasonForBusiness.indexOf(
        "Little idea (can learn more and get back)"
      ) > -1 ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e)=>showHoldPrompt(
              "dueDiligence"
            )}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <div className='d-flex flex-column'>
          <label className='control-label'>
            Skill training (
            {globalDDFormState.basic.skillTrainingRecommended
              ? "Required"
              : "Not required"}{" "}
            for this enterprise)
          </label>
          <note>
            To change this,{" "}
            <a
              href={
                "/edit-lead/" + leadData.leadId + "?scrollTo=skill-training"
              }
              rel='noopener noreferrer'
              target='_blank'
            >
              edit the profile
            </a>
            
          </note>
        </div>
        <input
          type='text'
          placeholder='Skill Training status'
          value={globalDDFormState.basic.skillTrainedStatus}
          className={"form-control"}
          title='To change this, edit the profile.'
          disabled
        />
      </Col>

      <Col md={12} className='due-diligence-question same-line'>
        <div className='d-flex flex-column'>
          <label className='control-label'>
            Total number of years practicing/learning this skill?
          </label>
          <note>Changing this will update the profile</note>
        </div>
        <input
          type='number'
          placeholder='Total number of years'
          defaultValue={parseInt(leadData.familyAndAsset.experienceInSector)}
          className={"form-control"}
          title="To change this, edit 'Experience (familiarity) in the sector of selected business' field in the profile."
          onBlur={(e)=>saveToProfile(
            "experienceInSector",
            e,
          )}
        />
      </Col>

      {globalDDFormState.basic.skillTrainingRecommended && false ? (
        <Col md={12} className='due-diligence-question'>
          <label className='control-label'>
            {globalDDFormState.basic.businessIdea} requires skill training. Do you
            want to inform the SCK to get him training connected first?
          </label>
          <div className='d-flex align-items-center'>
            <button
              className='btn btn-default mr5'
              onClick={(e)=>showHoldPrompt(
                e,
                "dueDiligence"
              )}
              disabled={!isEditable}
            >
              Yes (Put on hold and push to SCK)
            </button>
            <button
              className='btn btn-danger'
              onClick={globalDDFormState.basic.scrollToNext}
              disabled={!isEditable}
            >
              No (Proceed without skill training)
            </button>
          </div>
        </Col>
      ) : null}

      {globalDDFormState.experienceInSector && parseInt(globalDDFormState.experienceInSector) ? (
        <Col md={12} className='due-diligence-question'>
          <label className='control-label'>
            Experience in which company and what role
          </label>
          <textarea
            placeholder='Experience'
            value={globalDDFormState.basic.experienceDetails}
            className={"form-control"}
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "experienceDetails"
            )}
          />
        </Col>
      ) : null}

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>
          Is the candidate’s family/spouse aware about the new enterprise
          initiative?
        </label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.basic.familyAwareness}
          disabled={!isEditable}
          onChange={(e)=>dueDiligenceChange(
            e,
            "basic",
            // "questions",
            "familyAwareness"
          )}
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "Supportive and providing financial support to start the business",
            "Supportive but not providing financial support to start the business",
            "Aware but not supportive",
            "Not aware/Will inform later",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>

      <Col md={12} className='due-diligence-question same-line'>
        <label className='control-label'>Is this a joint venture?</label>
        <Select
          size='large'
          style={{ width: "100%" }}
          placeholder='Select'
          value={globalDDFormState.basic.isJointVenture}
          disabled={!isEditable}
          onChange={(e) =>
            dueDiligenceChange(e, "basic",
              // "questions",
              "isJointVenture")
          }
        >
          <Option value='' disabled>Select Option</Option>
          {[
            "No (Doing it on own)",
            "Yes (with family members)",
            "Yes (with friends)",
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </Col>

      {globalDDFormState.basic.licenseReqd ? (
        <Col md={12} className='due-diligence-question same-line'>
          <label className='control-label'>
            This business requires a license to start. Do you have all the
            necessary licenses required for this business?
          </label>
          <Select
            size='large'
            style={{ width: "100%" }}
            placeholder='Select'
            value={globalDDFormState.basic.licenseReqd}
            disabled={!isEditable}
            onChange={(e)=>dueDiligenceChange(
              e,
              "basic",
              // "questions",
              "licenseReqd"
            )}
          >
            <Option value='' disabled>Select Option</Option>
            {["Yes", "No", "Not required now"].map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              );
            })}
          </Select>
        </Col>
      ) : null}
      {globalDDFormState.basic.licenseReqd === "No" &&
      globalDDFormState.basic.licenseReqd ? (
        <Col md={12}>
          <button
            className='btn btn-default put-on-hold-btn'
            onClick={(e)=>showHoldPrompt(
              "dueDiligence"
            )}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
    </>
  );
};

export default BasicDDFormComponent;

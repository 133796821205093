import React, { useState, useEffect } from "react";
import ToolkitProvider
    // ,{ Search }
    from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Select, InputNumber, Input } from 'antd';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Col, Row, Modal, Button, Container, CloseButton } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const readCookie = require('../cookie.js').readCookie;

// const { SearchBar } = Search;
const { Option } = Select;
const notifySuccess = (text) => toast.success(text)
const notifyError = (text) => toast.error(text)
const SckClaimsTab = (compProps) => {
    const { orgId } = compProps;
    const TODAY = new Date();
    const userType = JSON.parse(readCookie('userData')).userType.name;
    const urlFormatter = (cell) => {
        if (cell) {
            return <a href={cell} target="_blank" rel="noopener noreferrer">View Bill</a>
        }
        else {
            return null;
        }
    }

    const formatDate = (cell) => {
        if (cell) {
            return <span>{moment(cell).format('DD-MM-YYYY')}</span>
        }
        else {
            return null;
        }
    }
    const formatTime = (cell) => {
        if (cell) {
            return <span>{moment(cell).format('hh:mm a, DD-MM-YYYY')}</span>
        }
        else {
            return null;
        }
    }
    const serialNoFormatter = (_cell, _row, index) => {
        return index + 1;
    }
    const editFormatter = (_cell, _row, index) => {
        return <Button variant="primary" className="btn btn-success react-bs-table-add-btn hidden-print" onClick={() => { showClaimModal(index) }}>Edit Claim</Button>
    }
    // Table props
    // sckId, finalBillDate, billLabel, billValue, billCoverage, billStatus
    const [grandTotalRow, setGrandTotalRow] = useState({ billLabel: "Grand Total", billValue: 0, billCoverage:0});
    const columns = [
        { dataField: '_id', text: '', width: '0', sort: false, hidden: true, export: true, searchable: false, footer: ""},
        { dataField: 'slNo', text: 'Sl. No.', sort: true, hidden: false, export: true, searchable: true, formatter: serialNoFormatter, footer: "" },
        { dataField: 'finalBillDate', text: 'Final Bill Date', sort: true, hidden: false, export: true, searchable: true, formatter: formatDate, footer: "" },
        { dataField: 'billLabel', text: 'Bill Label', sort: true, hidden: false, export: true, searchable: true, footer: (grandTotalRow.billLabel || "").toString() },
        { dataField: 'billUrl', text: 'Uploaded Bill', sort: true, hidden: false, export: true, searchable: true, formatter: urlFormatter, footer: ""},
        { dataField: 'billValue', text: 'Total Paid (INR)', sort: true, hidden: false, export: true, searchable: true, footer: (grandTotalRow.billValue || 0).toLocaleString() },
        { dataField: 'billCoverage', text: 'Bill Coverage (Total Enterprises)', sort: true, hidden: false, export: true, searchable: true, footer: (grandTotalRow.billCoverage || 0).toLocaleString() },
        { dataField: 'billStatus', text: 'Bill Status', sort: true, hidden: false, export: true, searchable: true, footer: "" },
        { dataField: 'createdBy.fullName', text: 'Created By', sort: true, hidden: false, export: true, searchable: true, footer: ""},
        { dataField: 'createdAt', text: 'Created On', sort: true, hidden: false, export: false, searchable: false, formatter: formatTime, footer: ""},
        { dataField: 'updatedBy.fullName', text: 'Last Updated By', sort: true, hidden: false, export: true, searchable: true, footer: ""},
        { dataField: 'updatedAt', text: 'Last Updated On', sort: true, hidden: false, export: false, searchable: false, formatter: formatTime, footer: "" },
        { dataField: 'editBill', text: 'Edit Claim', sort: false, hidden: userType !== 'Project Management Unit', export: false, searchable: false, formatter: editFormatter, footer:"" },

    ];



    // List of all claims to show in the table
    const [claims, setClaims] = useState([]);
    const sizePerPage = 50;
    // const [sizePerPage, setSizePerPage] = useState(50);
    const [showModal, setShowModal] = useState(false);
    const [claimBill, setClaimBill] = useState({});

    const newClaimBill = {
        // sckId, finalBillDate, billLabel, billValue, billCoverage, billStatus
        sckId: orgId,
        finalBillDate: new Date(),
        billLabel: undefined,
        billValue: undefined,
        billCoverage: undefined,
        billStatus: "",
        bill: undefined
    };
    const [errorsList, setErrors] = useState([]);
    const [errorsMessages, setErrorMessages] = useState([]);

    const fetchAllClaims = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/sck/by-sck-id/' + orgId, {
            method: 'GET',
            headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
                // 'Content-Type': 'application/json',
            }
        }).then(data => data.json())
            .then(data => {
                if (data.status) {
                    setClaims(data.billDetails);
                    setGrandTotalRow(data.grandTotalRow);
                }
            }).catch(err => {
                console.error(err);
                notifyError(err.errorMsg);
            });
    }

    // make claim calls - update or get call
    const makeClaimCall = () => {
        let claimObj = claimBill;
        // let claimObj = claimBill;
        const formDataBody = new FormData();
        formDataBody.append('sckId', claimObj.sckId);
        formDataBody.append('finalBillDate', claimObj.finalBillDate);
        formDataBody.append('billLabel', claimObj.billLabel);
        formDataBody.append('billValue', claimObj.billValue);
        formDataBody.append('billCoverage', claimObj.billCoverage);
        formDataBody.append('billStatus', claimObj.billStatus);

        if (claimObj.bill) {
            formDataBody.append('bill', claimObj.bill);
        }
        // let formDataBody = getDataForm();
        // const errors =
        validateBill();
        if (errorsList.length) {
            notifyError('Please make sure you\'ve filled all the fields!');
            return;
        }

        if (claimObj._id) {
            Swal.fire({
                icon: 'warning',
                title: 'Are you sure about this?',
                html: 'You are about override existing claim bill. This is an irreversible action!',
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Please update',
                cancelButtonAriaLabel: 'No, Cancel',
                confirmButtonAriaLabel: 'Yes, Please update'
            }).then((result) => {
                if (result.value) {
                    setUpdating(true);
                    fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/sck/' + claimObj._id, {
                        method: 'PUT',
                        headers: {
                            'x-auth-token': JSON.parse(readCookie('access_token')),
                            // 'Content-Type': 'application/json',
                            // 'Content-Type': 'undefined',
                        },
                        body: formDataBody
                    }).then(data => data.json())
                        .then(data => {
                            if (data.status) {
                                notifySuccess(data.message);
                                fetchAllClaims();
                                closeModal();
                                setUpdating(false);
                            }
                        }).catch(err => {
                            console.error(err);
                            notifyError(err.errorMsg);
                            setUpdating(false);
                        });
                }
            });

        }
        else {
            setUpdating(true);
            fetch(process.env.REACT_APP_API_URL + '/api/v1/payment-claims/sck/', {
                method: 'POST',
                headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                    // 'Content-Type': 'application/json',
                    // 'Content-Type': 'undefined',

                },
                body: formDataBody
            }).then(data => data.json())
                .then(data => {
                    if (data.status) {
                        notifySuccess(data.message);
                        fetchAllClaims();
                        closeModal();
                        setUpdating(false);
                    }
                }).catch(err => {
                    console.error(err);
                    notifyError(err.errorMsg);
                    setUpdating(false);
                });
        }
    }


    const validateBill = (submittingForm = false) => {
        let claimObj = claimBill;
        let errorMsgs = {};
        let errors = [];
        if (!claimObj.sckId) {
            errors.push('Invalid SCK ID!');
        }
        if (!claimObj.finalBillDate) {
            errors.push('Invalid Final Bill Date!');
        }
        if (!claimObj.billLabel) {
            if (claimObj.billLabel === '' || submittingForm) {
                errorMsgs.billLabel = 'Bill Label can\'t be empty';
            }
            errors.push('Invalid Bill Label!');
        }
        if (!claimObj.billValue) {
            if (claimObj.billValue === null || claimObj.billValue === "" || submittingForm) {
                errorMsgs.billValue = 'Total Paid can\'t be empty';
            }
            errors.push('Invalid Total Amount Paid!');
        }
        if (!claimObj.billCoverage && claimObj.billCoverage!==0) {
            if (claimObj.billCoverage === null || claimObj.billCoverage === "" || submittingForm) {
                errorMsgs.billCoverage = 'Bill Coverage can\'t be empty';
            }
            errors.push('Invalid bill Coverage Value!');
        }
        if (!claimObj.billStatus) {
            if (claimObj.billStatus === null || claimObj.billStatus === "" || submittingForm) {
                errorMsgs.billStatus = 'Bill status has to be selected';
            }
            errors.push('Invalid Bill Status!');
        }
        if ((!claimObj._id && !claimObj.bill)
            || (!claimObj.billUrl && !claimObj.bill)) {

            if (claimObj.billUrl === null || claimObj.billUrl === "" || submittingForm) {
                errorMsgs.billUrl = 'Please make sure you have attached the bill for the claim!';
            }
            errors.push('Please make sure you have attached the bill for the claim!');
        }
        setErrorMessages(errorMsgs);
        setErrors(errors);
    }
    useEffect(() => {
        fetchAllClaims();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateBill();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimBill]);
    //Show claim modal - set modal object to show details
    const showClaimModal = (index = -1) => {
        validateBill();
        setClaimBill(index === -1 ? newClaimBill : claims[index]);
        setShowModal(true);
    };
    const [updating, setUpdating] = useState(false);

    // Pagination props
    const pagination = paginationFactory({
        sizePerPage
    });

    const closeModal = () => { setClaimBill({}); setShowModal(false); setErrors([]); setErrorMessages({}); setUpdating(false); }
    return <div className="manage-claims">
        <ToolkitProvider keyField="_id" data={claims} columns={columns} search={{ searchFormatted: true }}>
            {props => (
                <div>
                    <Row className="mt10">
                        <Col md={10}>
                            <span className="total-members m0"
                                style={{
                                    color: 'white',
                                    marginTop: '10px',
                                    padding: '6px 12px',
                                    background: 'var(--themeColor)'
                                }}>
                                {'Total Claims: ' + claims.length}
                            </span>

                        </Col>

                        {/* <Col md={7}>
                            <SearchBar {...props.searchProps} placeholder="Search by Name/Registration Date/Mobile/Email"
                                className="members-table-search" delay={500} />
                        </Col> */}

                        <Col md={2}>
                            {userType === 'Project Management Unit' ? (
                                <button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
                                    style={{ marginLeft: '5px', marginBottom: '10px' }}
                                    onClick={() => showClaimModal(-1)}>
                                    <span>
                                        <i className="fas fa-file-invoice-dollar" aria-hidden="true"></i>
                                        Add Claim
                                    </span>
                                </button>
                            ) : (null)}</Col>
                    </Row>
                    <h5 style={{ color: "red" }}>
                        NOTE: PLEASE NOTE THAT THIS SECTION IS ONLY FOR UPLOADING THE FINAL BILL AGAINST WHICH THE PAYMENT CLAIM HAS BEEN APPROVED OR PAID ALREADY.
                    </h5>
                    <BootstrapTable id="platform-users-table" {...props.baseProps}
                        footerClasses="bootstrap-sck-table-footer-class"
                        /*selectRow={this.state.selectRowProp} ref={(table) => { this.table = table; }}*/
                        pagination={pagination} />
                    {
                        showModal && <Modal
                            onHide={() => { closeModal() }}
                            show={showModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {claimBill._id ? 'Update Claim' : 'Add new Claim'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                <Container>
                                    {/* // sckId, finalBillDate, billLabel, billValue, billCoverage, billStatus */}
                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">  Final Bill Date
                                            </label>    </Col>
                                        <Col sm={6}>
                                            <DatePicker
                                                placeholder="Select Final Bill Date"
                                                selected={claimBill._id ? new Date(claimBill.finalBillDate) : claimBill.finalBillDate}
                                                // format={'DD/MM/YYYY'}
                                                dateFormat={'dd-MM-yyyy'}
                                                maxDate={TODAY}
                                                className="form-control"
                                                required
                                                onChange={(e) => { setClaimBill({ ...claimBill, 'finalBillDate': new Date(e) }); }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">  Bill Label
                                            </label> </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={claimBill.billLabel}
                                                    placeholder="Enter Bill Label"
                                                    onChange={(e) => { setClaimBill({ ...claimBill, billLabel: e.target.value }); }}

                                                    required
                                                />
                                            </Row>
                                            <Row>
                                                <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }} className="has-error">{errorsMessages['billLabel'] || ''}</small>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">      Total Paid (INR)
                                            </label>  </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <InputNumber
                                                    min={0}
                                                    className="form-control"
                                                    value={claimBill.billValue}
                                                    placeholder="Enter Total Paid (in INR)"
                                                    onChange={(e) => { setClaimBill({ ...claimBill, billValue: e }); }}

                                                    required
                                                />
                                            </Row>
                                            <Row>
                                                <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }} className="has-error">{errorsMessages['billValue'] || ''}
                                                </small>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">      Bill Attachment
                                            </label>  </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=" Upload bill file here"

                                                    required
                                                    onChange={(e) => {
                                                        if (e.target.files.length) {
                                                            setClaimBill({ ...claimBill, bill: e.target.files[0], billUrl: null })
                                                        }

                                                    }} />
                                                {claimBill.billUrl &&
                                                    <>
                                                        <a href={claimBill.billUrl} target="_blank" rel="noopener noreferrer">
                                                            Bill Attachment
                                                        </a>
                                                        &nbsp;
                                                        <CloseButton onClick={() => {
                                                            setErrorMessages({
                                                                ...errorsMessages,
                                                                billUrl: 'Please make sure you have attached the bill for the claim!'
                                                            });
                                                            setClaimBill({ ...claimBill, billUrl: null });

                                                        }} />
                                                    </>
                                                }
                                            </Row>
                                            <Row>
                                                <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }} className="has-error">{errorsMessages['billUrl'] || ''}
                                                </small>
                                            </Row>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">
                                                Bill Coverage (Total Enterprises)
                                            </label></Col>
                                        <Col sm={6}>
                                            <Row>
                                                <InputNumber
                                                    min={0}
                                                    className="form-control"
                                                    value={claimBill.billCoverage}
                                                    placeholder="Enter Bill Coverage"

                                                    required
                                                    onChange={(e) => { setClaimBill({ ...claimBill, billCoverage: e }); }}
                                                />
                                            </Row>
                                            <Row>
                                                <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }} className="has-error">{errorsMessages['billCoverage'] || ''}
                                                </small>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <label className="control-label is-imp">     Bill Status
                                            </label>   </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Select
                                                    showSearch
                                                    // className="form-control"
                                                    style={{ width: 250 }}
                                                    // placeholder="Select Status"
                                                    onChange={(e) => { setClaimBill({ ...claimBill, billStatus: e }); }}
                                                    value={claimBill.billStatus}>
                                                    <Option value="">Select Status</Option>
                                                    <Option value='Settlement Pending'>Settlement Pending</Option>
                                                    <Option value='Settled'>Settled</Option>
                                                    <Option value='Settlement Rejected'>Settlement Rejected</Option>

                                                    {/* {['Settlement Pending', 'Settled', 'Settlement Rejected'].map((x, index)=>{
                                                    return <Option key={index} value={x}>{x}</Option>
                                                })} */}

                                                </Select>
                                            </Row>
                                            <Row>
                                                <small style={{ margin: "10px 10px", color: 'red', fontSize: "12px", fontWeight: "800" }} className="has-error">{errorsMessages['billStatus'] || ''}
                                                </small>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Container>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button
                                    variant={updating ? "secondary" : "primary"}
                                    disabled={errorsList.length > 0 || updating}

                                    onClick={() => {
                                        makeClaimCall()
                                    }}
                                >
                                    {updating ? (claimBill._id ? 'Updating' : 'Adding')
                                        : (claimBill._id ? 'Update' : 'Add')}

                                </Button>
                                <Button variant="secondary" onClick={() => { closeModal() }}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            )}
        </ToolkitProvider>
    </div>
};

export default SckClaimsTab;
import React from 'react';
import { Select } from 'antd';
import {
	Modal,
	// Row,
	Col, Button
} from "react-bootstrap";

const { Option } = Select;

export default class EditModuleModal extends React.Component {
	render() {
		return (
			<Modal className="edit-module-modal" show={this.props.showModal} onHide={this.props.closeModal} size="lg"
				aria-labelledby="contained-modal-title-lg">
				<Modal.Header className="edit-module-modal-header">
					<Modal.Title className="modal-header-custom">Edit Modules</Modal.Title>
				</Modal.Header>
				<Modal.Body className="edit-module-modal-body">
					<div className="body-conatiner">
						{this.props.allModules.map((module, index) => {
							if (this.props.selectedModules.hasOwnProperty(module.id) && module.selected) {
								return (
									<Col key={index} className="d-flex mb10">
										<Col>
											<select className="form-control" disabled={true}>
												<option value={module.id} key={index}>{module.name}</option>
											</select>
										</Col>
										<Col>
											<Select mode="multiple" style={{ width: '100%' }} placeholder="Select permissions" defaultValue={this.props.selectedModules[module.id]} onChange={this.props.changePermissions.bind(this, index, 'oldModules')}>
												{module.permissions.length ? (
													module.permissions.map((modulePermission, permissionIndex) => {
														if (this.props.selectedModules[module.id].indexOf(modulePermission.id) > -1) {
															return (
																<Option key={modulePermission.id}>{modulePermission.name}</Option>
															)
														} else return null;
													})
												) : (null)}
											</Select>
										</Col>
										<div>
											<Button variant="secondary" onClick={this.props.deleteModule.bind(this, module.id)}>Delete</Button>
										</div>
									</Col>
								)
							} else return null;
						})}

						{this.props.newModules.map((newModule, index) => {
							if (newModule.id === '' || (newModule.id && this.props.selectedModules.hasOwnProperty(newModule.id))) {
								return (
									<Col key={index} className="d-flex mb10">
										<Col>
											<select className="form-control" value={newModule.id} onChange={this.props.changeModule.bind(this, index)}>
												<option value='' disabled>Select a module</option>
												{this.props.allModules.map((module, moduleIndex) => {
													if (Object.keys(this.props.selectedModules).indexOf(module.id) === -1) {
														return (
															<option value={module.id} key={moduleIndex}>{module.name}</option>
														)
													} else {
														return (
															<option value={module.id} key={moduleIndex} disabled>{module.name}</option>
														);
													}
												})}
											</select>
										</Col>
										{newModule.id ? (
											<Col>
												<Select mode="multiple" style={{ width: '100%' }} placeholder="Select permissions" onChange={this.props.changePermissions.bind(this, index, 'newModules')}>
													{newModule.permissions.length ? (
														newModule.permissions.map((permission, permissionIndex) => {
															return (
																<Option key={permission.value}>{permission.label}</Option>
															)
														})
													) : (null)}
												</Select>
											</Col>
										) : (null)}
										<div>
											<Button variant="secondary" onClick={this.props.deleteModule.bind(this, newModule.id)}>Delete</Button>
										</div>
									</Col>
								)
							} else return null;
						})}

						<Col md={12} className="mt20 mb20">
							<button className="btn add-module-btn" onClick={this.props.addNewModule}>Add Module</button>
						</Col>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Col md={12} className="d-flex justify-content-between">
						<Button variant="secondary" onClick={this.props.closeModal}>Cancel</Button>
						<Button variant="primary" onClick={this.props.submitModule}>Submit</Button>
					</Col>
				</Modal.Footer>
			</Modal>
		)
	}
}

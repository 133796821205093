import React, { useState, useEffect, } from "react";
import { Table } from 'antd';
import { readCookie } from "../../cookie";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { Modal, Button, Row, Col, } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";

const knowlarity_emails = require('../../../../emails_knowlarity.json');

const CallLogDetails = ({
    callerNumber,
    userData,
    setModalVisibility,
    setCallerNumber,
    modalVisibility,
}) => {
    const [callHistory, setCallHistory] = useState([]);

    const [loading, setLoading] = useState(true);
    const getCallHistory = () => {
        fetch(
            process.env.REACT_APP_API_URL +
            `/api/v1/click-to-call-logs/call-history/${callerNumber}`,
            {
                method: "GET",
                headers: {
                    "x-auth-token": JSON.parse(readCookie("access_token")),
                },
            }
        )
            .then((data) => data.json())
            .then((data) => {
                if (data.status) {
                    setLoading(false);
                    setCallHistory(data.callHistory || []);
                }
            }).catch((err) => {
                console.log("🚀 ~ file: CallLogDetails.js:40 ~ .then ~ err:", err)
                setLoading(false);
            });
    }
    useEffect(() => {
        getCallHistory();
    }, []);

    const paginationOptions = {
        page: 1,
        sizePerPageList: [{
            text: '10', value: 10      }, {
            text: '20', value: 20      }, {
            text: '50', value: 50      }, {
            text: '100', value: 100
        }],
        sizePerPage: 50,
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: '<',
        nextPage: '>',
        firstPage: '<<',
        lastPage: '>>',
        paginationPosition: 'bottom'
    };

    const formatMobile = (cell, row) => {
        if (cell) {
            if (row.history && row.history.length) {
                return (
                    <div className="d-flex align-items-center justify-content-between">
                        {(/*userData.userType.name==='Project Management Unit' ||*/knowlarity_emails.includes(userData.email) || /*userData.userType.name === 'Zonal Manager' ||*/ (userData.userType.name === 'Swavalamban Fellows'
                            // && userData.fellowAccessType === 'Full'
                        )) && <span style={{ cursor: "pointer", marginLeft: "10px", marginRight: "2px", }} className="call-icon" onClick={this.callNumber.bind(this, cell)}>
                                <i style={{ fontSize: '20px', color: "#00dd00" }} title="Call this number" className="fa fa-phone" />
                            </span>}	<span>{cell}</span>
                        <span className="note">{'Attempts: ' + (row.history.length + 1)}</span>
                    </div>
                )
            } else {
                return <div className="d-flex ">{(/*userData.userType.name==='Project Management Unit' ||*/knowlarity_emails.includes(userData.email) || /*userData.userType.name === 'Zonal Manager' ||*/ (userData.userType.name === 'Swavalamban Fellows'
                    // && userData.fellowAccessType === 'Full'
                )) && <span style={{ cursor: "pointer", marginLeft: "10px", marginRight: "2px", }} className="call-icon" onClick={this.callNumber.bind(this, cell)}>
                        <i style={{ fontSize: '20px', color: "#00dd00" }} title="Call this number" className="fa fa-phone fa-lg" /> &nbsp;
                    </span>}<span>{cell}</span></div>;
            }
        } else return null;
    }

    const formatDate = (cell, row) => {
        if (cell) {
            return moment(cell).format('DD/MM/YYYY hh:mm A');
        } else return null;
    }

    const formatSeconds = (cell) => {
        if (cell) return new Date(cell * 1000).toISOString().slice(11, 19);
        else return '00:00:00';
    }


    const columns = [
        { text: '', sort: false, hidden: true, export: true, dataField: '_id', searchable: false },
        { sort: true, export: true, hidden: false, text: 'From', searchable: true, dataField: 'From', formatter: formatMobile, headerStyle: () => { return { width: '220px' } } },
        { sort: true, export: true, hidden: false, text: 'To', searchable: true, dataField: 'To', formatter: formatMobile, headerStyle: () => { return { width: '220px' } } },
        { sort: true, export: true, hidden: false, text: "Status", searchable: true, dataField: 'Status', headerStyle: () => { return { width: '220px' } } },
        { sort: true, export: true, hidden: false, text: "Direction", searchable: true, dataField: 'Direction', headerStyle: () => { return { width: '220px' } } },
        { sort: true, export: true, hidden: false, text: "Conversation Duration (in seconds)", searchable: true, dataField: 'ConversationDuration', formatter: formatSeconds, headerStyle: () => { return { width: '220px' } } },
        { sort: true, export: true, hidden: false, text: "Timestamp", searchable: true, dataField: 'StartTime', formatter: formatDate, headerStyle: () => { return { width: '250px' } } },

        {
            title: 'From',
            dataIndex: 'From',
            // width: '25%',
            // editable: true,
        },
        {
            title: 'To',
            dataIndex: 'To',
            // width: '15%',
            // editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            // width: '40%',
            // editable: true,
        },
        {
            title: 'Direction',
            dataIndex: 'Direction',
            // width: '40%',
            // editable: true,
        },
        {
            title: 'Conversation Duration (in seconds)',
            dataIndex: 'ConversationDuration',
            formatter: formatSeconds,
            // width: '40%',
            // editable: true,
        },
        {
            title: 'Timestamp',
            dataIndex: 'StartTime',
            // formatter: formatSeconds,
            // width: '40%',
            // editable: true,
        }];
    
    return <Modal dialogClassName="ivrs-call-logs-modal" className="ivrs-call-logs-modal" show={modalVisibility} size="xl" onHide={() => { }}>
            <Modal.Header>
                <Modal.Title className="modal-header-custom">
                    Call Logs
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20">
            <Row>
                <Col md={12} className="padding0 d-flex">
                    <div className="call-modal-loading">
                    <LoadingOverlay
                        // className="call-modal-loading"
                        active={loading || false}
                        spinner={<PulseLoader />}
                        /></div>
                    <ToolkitProvider keyField="_id"
            data={callHistory} columns={columns}
            search>
            {props => (
                <div>

                    {callHistory && <BootstrapTable id="business-ideas-table" {...props.baseProps} pagination={paginationFactory(paginationOptions)} sizePerPage={20} />}

                </div>)}
        </ToolkitProvider>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
                <Button className="btn btn-danger brand-color-background" onClick={() => { setModalVisibility(false); setCallerNumber(null); }}>Close</Button>
                {/* <Button className="btn btn-warning brand-color-background" onClick={this.updateLead} disabled={this.state.gettingDetailsForPincode}>Save</Button> */}
            </Modal.Footer>
        </Modal>;
}


export default CallLogDetails;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import paginationFactory
// ,
// { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone }
    from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    // ConfigProvider, DatePicker,
    // Switch,
    Select, Mentions,
} from 'antd';
// import TextareaAutocomplete from 'react-textarea-autocomplete';
// import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
// import TextInput from 'react-autocomplete-textarea';

const { SearchBar } = Search;
const { Option,
    // OptGroup
} = Select;
// const readCookie = require('../cookie').readCookie;
const slugify = require('slugify');

// const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

const BplanLiterature = ({ userData }) => {
    const dummyParameters = [
        { "section": "Introduction", "phrase": "Stage of business", "helptext": "", "variable": "business_stage", "optionType": "Dropdown", "options": "Start-up, Scale-up", "priority": "1", "sentence": "$name is looking to $business_stage their business of $business_idea.", "skipIf": "" },
        { "section": "Introduction", "phrase": "Age of establishment (years)", "helptext": "", "variable": "age_of_establishment", "optionType": "Number", "options": "Any", "priority": "1", "sentence": "This enterprise has been operational since $age_of_establishment years and has been serving its customers since then.", "skipIf": "$age_of_establishment=0" },
        { "section": "Introduction", "phrase": "Products/Services offfered", "helptext": "", "variable": "product_service_offered", "optionType": "Textbox", "options": "", "priority": "1", "sentence": "This establishment offers products/services like- $product_service_offered to $offered_to.", "skipIf": "" },
        { "section": "Introduction", "phrase": "Offered to", "helptext": "", "variable": "offered_to", "optionType": "Dropdown", "options": "End customers, Wholesalers, Distributors, Retailers", "priority": "1", "sentence": "", "skipIf": "" },
        { "section": "Introduction", "phrase": "Unique Selling Proposition", "helptext": "", "variable": "usp", "optionType": "Multiselect", "options": "the entrepreneur's experience in this field, innovative product/service, superior customer support, quality and affordable products/service, high demand in the target market (forward linkage), abundant supply of raw materials (backward linkage), ", "priority": "1", "sentence": "The enterprise is uniquely positioned because of its - ", "skipIf": "" },
        { "section": "Location", "phrase": "Name of the area", "helptext": "", "variable": "business_area", "optionType": "Textbox", "options": "", "priority": "1", "sentence": "The enterprise is located in $business_locality area of $city in a $infra_ownership property.", "skipIf": "" },
        { "section": "Location", "phrase": "Locality of business", "helptext": "", "variable": "business_locality", "optionType": "Dropdown", "options": "Urban, Rural, Semi-urban, Slum", "priority": "1", "sentence": "", "skipIf": "" },
        { "section": "Location", "phrase": "Ownership of infrastructure", "helptext": "", "variable": "infra_ownership", "optionType": "Dropdown", "options": "Rented, Leased, Self-owned", "priority": "1", "sentence": "", "skipIf": "" },
        { "section": "Location", "phrase": "Establishment area (in square feet)", "helptext": "", "variable": "establishment_area", "optionType": "Number", "options": "Non-zero", "priority": "1", "sentence": "The size of the establishment is $establishment_area sq.ft.", "skipIf": "" },
        { "section": "Location", "phrase": "Reason for selecting this location", "helptext": "", "variable": "reason_for_location", "optionType": "Multiselect", "options": "Nearness to market, Good footfall, Nearness to targeted customer demographics, Lesser competition around, Access to permit/license of operate, Nearness to suppliers, Affordability (rent), Other such factors", "priority": "1", "sentence": "This locality is selected because of $array{$reason_for_location}.", "skipIf": "" },
        { "section": "Target Market", "phrase": "Years of relevant experience", "helptext": "", "variable": "relevant_experience", "optionType": "Number", "options": "", "priority": "1", "sentence": "$name has relevant experience of $relevant_experience years in this field. ", "skipIf": "$relevant_experience=0" },
        { "section": "Target Market", "phrase": "Primary market", "helptext": "", "variable": "primary_market", "optionType": "Dropdown", "options": "Local, Regional, National, International", "priority": "1", "sentence": "The enterprise shall focus on offering its products/services to $primary_market markets. ", "skipIf": "" },
        { "section": "Target Market", "phrase": "Competition", "helptext": "", "variable": "competition", "optionType": "Dropdown", "options": "No similar goods/service provider in this locality, Only a few similar goods/service providers in this locality, Many similar goods/service providers in this locality", "priority": "1", "sentence": "Regarding competition, there are $competition.", "skipIf": "" }
    ].map((item, ind) => { return { ...item, _id: ind, remark:""} });
    const [parameters,
        // setParameters
    ] = useState(dummyParameters);
    const INITIAL_PARAMETER = {
        "section": "",
        "phrase": "",
        "helptext": "",
        "variable": "",
        "optionType": "",
        "options": "",
        "priority": "",
        "sentence": "",
        "skipIf": "",
        "remark": "",
        "addAfter": "",
    };
    const [selectedParameter, setSelectedParameter] = useState(INITIAL_PARAMETER);
    const paginationOptions = {
        page: 1,
        sizePerPageList: [{
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }, {
            text: '500', value: 500
        }],
        sizePerPage: 500,
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: '<',
        nextPage: '>',
        firstPage: '<<',
        lastPage: '>>',
        paginationPosition: 'bottom'
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let valueToSet = selectedParameter;
        valueToSet = { ...valueToSet, [name]: value };
        if (name === 'phrase') {
            valueToSet = {
                ...valueToSet, variable: slugify(value, {
                    replacement: '_',  // replace spaces with replacement character, defaults to `-`
                    remove: undefined, // remove characters that match regex, defaults to `undefined`
                    lower: true,      // convert to lower case, defaults to `false`
                    strict: false,     // strip special characters except replacement, defaults to `false`
                    locale: 'en',       // language code of the locale to use
                    trim: true         // trim leading and trailing replacement chars, defaults to `true`
                })
            }
        };
        setSelectedParameter(valueToSet);

    }

const editFormatter = (cell, row)=>{
    return <Button type="primary" onClick={()=>{setSelectedParameter(row); setShowModal(true);}}>Edit</Button>
}
    const resetModal = () => {
        setShowModal(false);
        setSelectedParameter(INITIAL_PARAMETER);
    }
    const [showModal, setShowModal] = useState(false);
    // const [value, onChange] = React.useState('');

    const columns = [{ text: '', sort: false, hidden: true, export: true, dataField: '_id', searchable: false },
    // { sort: true, export: true, hidden: false, text: 'Section', searchable: true, dataField: 'section', headerStyle: () => { return { width: '180px' } } },
    { sort: true, export: true, hidden: false, text: 'Question Phrase', searchable: true, dataField: 'phrase', headerStyle: () => { return { width: '180px' } } },
        { sort: true, export: true, hidden: false, text: 'Help Text', searchable: true, dataField: 'helptext', headerStyle: () => { return { width: '180px' } } },
    { sort: true, export: true, hidden: false, text: 'Question Variable', searchable: true, dataField: 'variable', headerStyle: () => { return { width: '180px' } } },
    { sort: true, export: true, hidden: false, text: 'Option Type', searchable: true, dataField: 'optionType', headerStyle: () => { return { width: '180px' } } },
    { sort: true, export: true, hidden: false, text: 'Options', searchable: true, dataField: 'options', headerStyle: () => { return { width: '180px' } } },
    // { sort: true, export: true, hidden: false, text: 'Ordering/Priority', searchable: true, dataField: 'priority', headerStyle: () => { return { width: '180px' } } },
        { sort: true, export: true, hidden: false, text: 'Sentence', searchable: true, dataField: 'sentence', headerStyle: () => { return { width: '180px' } } },
        { sort: true, export: true, hidden: false, text: 'Remark', searchable: true, dataField: 'remark', headerStyle: () => { return { width: '180px' } } },
        { sort: true, export: true, hidden: false, text: 'Skip Question if', searchable: true, dataField: 'skipIf', headerStyle: () => { return { width: '180px' } } },
        { sort: false, export: false, hidden: false, text: 'Edit Parameter', searchable: false, dataField: 'edit', headerStyle: () => { return { width: '80px' } }, formatter:editFormatter },

    ];
    return <div className='page-container'>
        <ToolkitProvider keyField="_id" data={dummyParameters} columns={columns} search>
            {props => (
                // 
                //    <PaginationProvider
                // 	pagination={ paginationFactory(paginationOption)}
                // 	>
                // 	{
                // 		({
                // 		paginationProps,
                // 		paginationTableProps
                // 		}) => ( 
                <div>
                    <div className="page-heading d-flex align-items-center">
                        <h3>Literature Parameters ({parameters.length})</h3>
                        <div className="filter add-new-scheme-container" style={{ right: "40px", position: "absolute", flexDirection: 'row', display: 'flex' }}>
                            <div style={{ marginRight: 20, width: 300 }}>
                                <SearchBar {...props.searchProps} placeholder='Search by name, variable, section etc.' />
                            </div>
                            <div style={{ height: 30 }}>
                                {userData.userType.name === 'Project Management Unit' &&
                                    <button className="btn add-new-scheme-btn" onClick={() => setShowModal(!showModal)}>
                                        <i className="fa fa-plus mr5"></i>
                                        Add a parameter
                                    </button>}
                            </div>
                        </div>
                    </div>
                    {parameters && <BootstrapTable id="business-ideas-table" {...props.baseProps} pagination={paginationFactory(paginationOptions)} sizePerPage={20} />}
                </div>
            )
            }
            {/* </PaginationProvider>)} */}
        </ToolkitProvider>
        <Modal show={showModal} style={{ paddingBottom: 50 }} onHide={resetModal}>
            <Row>
                <div style={{ marginLeft: '95%', marginTop: 10 }} onClick={resetModal}><a>X</a>
                </div>
            </Row>
            <Modal.Header>
                <Modal.Title className="modal-header-custom">
                    {selectedParameter._id ? 'Edit' : 'Add new'} Parameter
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="mb20">
                <Row>
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Phrase</label>
                            <input className="form-control" type="text" style={{ width: '100%' }} name="phrase" placeholder="Question Phrase" onChange={(e) => { handleChange(e) }} value={selectedParameter.phrase} />
                        </Col>
                    </Col>
                    {parameters.length && (selectedParameter._id===undefined || selectedParameter._id===null) && <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Add after</label>
                            {/* <input className="form-control" type="text" style={{ width: '100%' }} name="phrase" placeholder="Question Phrase" onChange={(e) => { handleChange(e) }} value={selectedParameter.phrase} /> */}
                            <select className="form-control" type="text" style={{ width: '100%' }} name="addAfter" placeholder="Add parameter after" onChange={(e) => { handleChange(e) }} value={selectedParameter.addAfter}>
                                <option value="">Select add after parameter</option>
                                {parameters.map((parameter, ind) => <option value={parameter._id}>{ parameter.phrase}</option>)}
                                {/* <option value="dropdown">Dropdown</option> */}
                            </select>
                        </Col>
                    </Col>}
                    <Col md={12} className="padding0 d-flex">

                        <Col>
                            <label className="control-label is-imp">Variable</label>
                            <input className="form-control" type="text" name="variable" placeholder="Variable" style={{ width: '100%', textTransform: "none" }} onChange={(e) => { handleChange(e) }} disabled value={selectedParameter.variable} />
                        </Col>
                    </Col>
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label">Helptext</label>
                            {/* <input className="form-control" type="text" style={{ width: '100%' }} name="helptext" placeholder="Question Helptext" onChange={(e) => { handleChange(e) }} value={selectedParameter.helptext} /> */}
                            <textarea className="form-control" name="helptext" cols={100} rows={3} placeholder='Question helptext' onChange={(e) => { handleChange(e) }} value={selectedParameter.helptext} />

                            {/* </textarea> */}
                        </Col>
                    </Col>  
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Option Type</label>
                            {/* <input className="form-control" type="text" style={{ width: '100%' }} name="optionType" placeholder="Question Option Type" onChange={(e) => { handleChange(e) }} value={selectedParameter.optionType} /> */}
                            <select className="form-control" type="text" style={{ width: '100%' }} name="optionType" placeholder="Question Option Type" onChange={(e) => { handleChange(e) }} value={selectedParameter.optionType}>
                                <option value="">Select option type</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="Number">Number</option>
                                <option value="Textbox">Textbox</option>
                                <option value="Multiselect">Multiselect</option>
                                {/* <option value="dropdown">Dropdown</option> */}
                            </select>
                        </Col>
                    </Col>  
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Options</label>
                            {/* <input className="form-control" type="text" style={{ width: '100%' }} name="options" placeholder="Options" onChange={(e) => { handleChange(e) }} value={selectedParameter.options} /> */}
                            <Select mode="tags" style={{ width: '100%' }} value={typeof selectedParameter.options === 'string' ? selectedParameter.options.split(",") : selectedParameter.options || [] } onChange={(e)=>{setSelectedParameter({...selectedParameter, options:e})}} tokenSeparators={[',']} placeholder={'Start typing and click enter once done.'}>
                                {typeof selectedParameter.options === 'string' ? selectedParameter.options.split(",").map(option => <Option key={option} value={option.toString()}>{option.toString()}</Option>) : selectedParameter.options||[] }
                            </Select>
                        </Col>
                    </Col>  
                    {/* <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Priority</label>
                            <input className="form-control" type="text" style={{ width: '100%' }} name="priority" placeholder="Question Priority" onChange={(e) => { handleChange(e) }} value={selectedParameter.priority} />
                        </Col>
                    </Col> */}
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label is-imp">Sentence</label>
                           {/* <TextInput options={parameters.map(x=>`${x.variable}`)} trigger='$' value={selectedParameter.sentence} onChange={(e)=>{setSelectedParameter({...selectedParameter, sentence: e})}} /> */}
                        <Mentions onChange={(e)=>{setSelectedParameter({...selectedParameter, sentence:e})}} prefix='$' placeholder="Enter the sentence. Use @ as the variable prefix" autoSize value={selectedParameter.sentence}>
  {parameters.map(x=><Mentions.Option value={x.variable} key={x._id}>{x.phrase}</Mentions.Option>)}
</Mentions>
                        </Col>
                    </Col>
                    <Col md={12} className="padding0 d-flex">
                        <Col>
                            <label className="control-label">Skip if</label>
                            <input className="form-control" type="text" style={{ width: '100%' }} name="skipIf" placeholder="Skip if?" onChange={(e) => { handleChange(e) }} value={selectedParameter.skipIf} />
                        </Col>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
                <Button className="btn btn-danger brand-color-background" onClick={resetModal}>Cancel</Button>
                <Button className="btn btn-warning brand-color-background" onClick={console.log(selectedParameter)}>Save</Button>
            </Modal.Footer>

        </Modal>
    </div>
};

export default BplanLiterature;

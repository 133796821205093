
const createCookie = require('../cookie.js').createCookie;





function ProjectReducer (state,action){
 createCookie("project_id",JSON.stringify(action.payload))
  return {...state,project:action.payload}
}
export default ProjectReducer;
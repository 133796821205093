import React, { useState } from "react";
//import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useHttpHook } from '../../hooks/http-hook';
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const PlaceCenter = (props) => {
  const { isLoading,
    // error,
    sendRequest, clearError } = useHttpHook();
  const [showDelete, setDelete] = useState(false);

  const confirmDelete = async () => {
    setDelete(false);
    try {
      sendRequest(process.env.REACT_APP_API_URL + `/api/v1/center/${props.id}`, 'DELETE');
      props.onDelete(props.id);
    } catch (err) { }
  };

  return (
    <React.Fragment>
      {/* Modal for delete the data */}
      {clearError}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDelete}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to proceed and delete this place? Please note that it
          can't be undone thereafter.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDelete(false);
            }}
          >
            CANCEL
          </Button>
          {isLoading ? <div className="center"> <Spin /> </div> : <Button variant="danger" onClick={confirmDelete}>
            {" "}
            DELETE{" "}
          </Button>}
        </Modal.Footer>
      </Modal>

      <tr>
        <td>{props.type}</td>
        <td>{props.state}</td>
        <td>{props.district}</td>
        <td>{props.pincode}</td>
        <td>{props.rating}</td>
        <td>{props.database}</td>
        <td>{props.trained}</td>
        <td>{props.employed}</td>
        <td>{props.settlement}</td>
        <td>
          <Link to={`/training-center/centers/${props.id}`}>
            <Button>Edit</Button>
          </Link>
        </td>
        <td>
          <Button
            variant="danger"
            onClick={() => {
              setDelete(true);
            }}
          >
            Delete
          </Button>
        </td>
        <td>
          <Link to={`/training-center/contactView/${props.id}`}>
            <Button variant="success">View</Button>
          </Link>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default PlaceCenter;
